import React, { useState, useEffect } from 'react';
import './ChatRoomHeader.scss';
import { Modal, ModalBody } from "reactstrap";
import Helper from '../../../helper/Helper';
import HelperChat from '../../../helper/HelperChat';
import iconDeleted from '../../../assets/img/delete-account-icon.svg';
import { taptalk, tapCoreChatRoomManager, tapCoreRoomListManager } from '@taptalk.io/web-sdk';
import ChatRoomHeaderInfoModal from './chatRoomHeaderInfoModal/ChatRoomHeaderInfoModal';
import ChatRoomAddBlockContact from '../chatRoomAddBlockContact/ChatRoomAddBlockContact';
import ChatRoomHeaderPinMessageTop from './chatRoomHeaderPinMessageTop/ChatRoomHeaderPinMessageTop';
import { setActiveRoom, clearActiveRoom } from '../../../redux/actions/reduxActionActiveRoom';
import { setParticipantList, clearParticipantList } from '../../../redux/actions/reduxActionParticipantList';
import { setMentionUsername, clearMentionUsername } from '../../../redux/actions/reduxActionMentionUsername';
import { setBlockingAddBlockContact } from '../../../redux/actions/reduxActionBlockingAddBlockContact';
import { clearUserClick } from '../../../redux/actions/reduxActionUserClick';
import { IoIosArrowBack } from 'react-icons/io';
import { connect } from 'react-redux';
import { FiBookmark } from 'react-icons/fi';

const ACTIVE_ROOM_INFO_MODAL = {
  main: 1,
  memberList: 2,
  memberDetail: 3,
  editGroup: 4,
  addMember: 5,
  starMessage: 6,
  pinMessage: 7
}

var ChatRoomHeader = (props) => {
  // let [isShowAddToContact, setIsShowAddToContact] = useState(false);
  let [roomData, setRoomData] = useState(null);
  let [isActive, setIsActive] = useState(false);
  let [isTyping, setIsTyping] = useState([]);
  let [toggleRoomInfoModal, setToggleRoomInfoModal] = useState(false);
  // let [noLongerParticipant, setNoLongerParticipant] = useState(false);
  let [mentionUsernameTemp, setMentionUsernameTemp] = useState(false);
  let [showLoadingFetchUser, setIsShowLoadingFetchUser] = useState(false);
  let [temporaryLastSeen, setTemporaryLastSeen] = useState(false); 
  let [lastSeenText, setLastSeenText] = useState("");
  let [intervalLastSeen, setIntervalLastSeen] = useState();
  let [activeModalRoomInfo, setActiveModalRoomInfo] = useState(false);
  let [isMessageListenerSetupFinished, setIsMessageListenerSetupFinished] = useState(false);
  // let intervalLastSeen = false;

  let runLastSeen = async (_roomData) => {
    let lastOnline;
    let myAccountID = taptalk.getTaptalkActiveUser().userID;
    let participantIndex = _roomData.participants.findIndex(val => val.userID !== myAccountID);
    let otherParticipant = _roomData.participants[participantIndex];

    if(otherParticipant && otherParticipant.isOnline) {
      lastOnline = {
        isActive: true,
        lastOnline: false
      }
    }else {
      lastOnline = {
        isActive: false,
        lastOnline: otherParticipant ? otherParticipant.lastActivity : 0
      }
    }

    return lastOnline;
  }

  useEffect(() => {
    if(temporaryLastSeen) {
      if(temporaryLastSeen.activeRoom.roomID === props.activeRoom.roomID) {
        // let _lastSeen = runLastSeen(temporaryLastSeen.roomData);
        runLastSeen(temporaryLastSeen.roomData).then(_lastSeen => {
          if(_lastSeen.isActive) {
            setIsActive(true)
          }else {
            setLastSeenText(Helper.getLastActivityString(_lastSeen.lastOnline));
            
            let handler = setInterval(() => {
              setLastSeenText(Helper.getLastActivityString(_lastSeen.lastOnline));
            }, 60000)
  
            setIntervalLastSeen(handler);
            return () => clearInterval(handler);
          }
        });
      }
    }

    return () => clearInterval(intervalLastSeen);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryLastSeen])

  useEffect(() => {
    let runRoomData = async () => {
      if(roomData !== null) {
        if(roomData.room.type === 1) {
          setTemporaryLastSeen({
            activeRoom: props.activeRoom,
            roomData: roomData,
            time: new Date().valueOf()
          })
  
          //check contact
          let myAccountID = taptalk.getTaptalkActiveUser().userID;
          let participantIndex = roomData.participants.findIndex(val => val.userID !== myAccountID);
          let otherParticipant = roomData.participants[participantIndex];

          let checkIsContactExist = () => {
            let exist = false;

            if(otherParticipant) {
              let findIndex = props.userContactsNoGroup.findIndex(val => val.user.userID === otherParticipant.userID);
              
              exist = findIndex !== -1;
            }

            return exist;;
          }
  
          if(!checkIsContactExist() && otherParticipant && !props.blockingAddBlockContact.dismiss[otherParticipant.userID]) {
            let _blockingAddBlockContact = {...props.blockingAddBlockContact};
            _blockingAddBlockContact.isShow = true;
            _blockingAddBlockContact.user = otherParticipant;
            props.setBlockingAddBlockContact(_blockingAddBlockContact)
          }
          //check contact
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    runRoomData();
  }, [roomData])

  useEffect(() => {
    if(props.userClick) {
      // if(props.userClick.room !== 2) {
      //   toggleRoomInfoModalAction();
      // }else {
        setMentionUsernameTemp({
          time: new Date(),
          username: props.userClick.username,
          userID: props.userClick.userID
        })
      // }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userClick])

  useEffect(() => {
    if(!toggleRoomInfoModal) {
      setTimeout(() => {
        props.clearUserClick();
      }, 100)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleRoomInfoModal])

  let toggleShowLoadingFetchUser = () => {
    setIsShowLoadingFetchUser(!showLoadingFetchUser)
  }

  useEffect(() => {
    let runThis = async () => {
      if(mentionUsernameTemp.time) {
        // let myUserName =  taptalk.getTaptalkActiveUser().username;
        let username = mentionUsernameTemp.username;
        let userID = mentionUsernameTemp.userID;
        let myUserID =  taptalk.getTaptalkActiveUser().userID;

        if(userID !== myUserID) {
          let indexParticipant = roomData.participants.findIndex(val => val.username === username);

          let runGoToMention = (username, userData = false) => {
            props.setMentionUsername({
              username: username,
              userData: userData
            });

            toggleRoomInfoModalAction();  
          }
          
          if(indexParticipant !== -1) {
            runGoToMention(username, roomData.participants[indexParticipant]);
          }else {
            // if not found
            toggleShowLoadingFetchUser();

            tapCoreRoomListManager.getUserByIdFromApi(userID, {
              onSuccess : (response) => {
                console.log(response)
                setIsShowLoadingFetchUser(false);
                runGoToMention(response.user.username, response.user);
              },
      
              onError : (errorCode, errorMessage) => {
                setIsShowLoadingFetchUser(false);
                Helper.doToast(errorMessage, "fail");
                // setLoadingSearchContact(false);
                console.log(errorCode, errorMessage);
              }
            })
          }
        }
      }    
    }
    
    runThis();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionUsernameTemp]);

  useEffect(() => {
    if (roomData && roomData.room && roomData.room.roomID === props.activeRoom.roomID) {
      return;
    }
    let runThisFirst = async () => {
      // setIsShowAddToContact(false)
      props.clearParticipantList();
      setIsTyping([]);
      setRoomData(null);
      getRoomData();
      // setNoLongerParticipant(false);

      //mention click
      window.clickMention = (username) => {
        setMentionUsernameTemp({
          time: new Date(),
          username: username
        })
      }
      //mention click

      if (props.activeRoom && !isMessageListenerSetupFinished) {
        tapCoreChatRoomManager.addMessageListener({
            onReceiveNewMessage: (messageModel, isScheduled) => {
                if (props.activeRoom.roomID === messageModel.room.roomID && 
                  (messageModel.action === "user/update" || messageModel.action === "room/update") &&
                  messageModel.user.userID !== taptalk.getTaptalkActiveUser().userID
                ) {
                  props.setActiveRoom(messageModel.room);
                }
            },

            onReceiveUpdateMessage: (messageModel) => {
                
            },
        })
        setIsMessageListenerSetupFinished(true);
      }
    }
    runThisFirst();

    clearInterval(intervalLastSeen)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeRoom]);

  useEffect(() => {
    if(props.listenerStartTypingProps !== null) {
      if(props.activeRoom.roomID === props.listenerStartTypingProps.roomID) {
        setIsTyping(isTyping => [...isTyping, props.listenerStartTypingProps.user]);
      }
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listenerStartTypingProps])

  useEffect(() => {
    if(props.listenerStopTypingProps !== null) {
      if(props.activeRoom.roomID === props.listenerStopTypingProps.roomID) {
        setIsTyping(isTyping => isTyping.filter(value => value.userID !== props.listenerStopTypingProps.user.userID));
      }
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listenerStopTypingProps])

  useEffect(() => {
    let runlistenerUserOnlineProps = async () => {
      let splitRoomID = props.activeRoom.roomID.split("-");
      let participantID = splitRoomID[0] === taptalk.getTaptalkActiveUser().userID ? splitRoomID[1] : splitRoomID[0];
  
      if(props.listenerUserOnlineProps !== null) {
        if(participantID === props.listenerUserOnlineProps.user.userID) {
          if(props.listenerUserOnlineProps.isOnline) {
            setIsActive(true);
  
          }else {
            setIsActive(false);
          }
        }
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    runlistenerUserOnlineProps();
  }, [props.listenerUserOnlineProps])

  useEffect(() => {
    let runThisListener = async () => {
      if(props.messageListenerNewMessageProps !== null) {
        if(props.messageListenerNewMessageProps.type === 9001 && props.messageListenerNewMessageProps.action !== "message/pin" && props.messageListenerNewMessageProps.action !== "") {
          getRoomData();
          //disabled header avatar click
          // if(
          //     props.messageListenerNewMessageProps.room.roomID === props.activeRoom.roomID &&
          //     props.messageListenerNewMessageProps.action === "room/delete"
          // ) {
          //   setNoLongerParticipant(true);
          // }
    
          // if(
          //     props.messageListenerNewMessageProps.room.roomID === props.activeRoom.roomID &&
          //     props.messageListenerNewMessageProps.action === "room/leave" &&
          //     props.messageListenerNewMessageProps.user.userID === taptalk.getTaptalkActiveUser().userID
          // ) {
          //   setNoLongerParticipant(true);
          // }
    
          // if(
          //     props.messageListenerNewMessageProps.room.roomID === props.activeRoom.roomID &&
          //     props.messageListenerNewMessageProps.action === "room/removeParticipant" &&
          //     props.messageListenerNewMessageProps.target.targetID === taptalk.getTaptalkActiveUser().userID
          // ) {
          //   setNoLongerParticipant(true);
          // }
        }
      }
    }

    runThisListener();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messageListenerNewMessageProps])
  
  let getRoomData = async () => {
    tapCoreChatRoomManager.getGroupChatRoom(props.activeRoom.roomID, {
      onSuccess: (response) => {
        setRoomData(response);
        if (response.room) {
          props.setActiveRoom(response.room);
        }

        // if(response.room.type === 2) {
          let myAccountID = taptalk.getTaptalkActiveUser().userID;

          props.setParticipantList(
            response.participants.filter((arr) => {
              return arr.userID !== myAccountID
            })
          )
        // }
      
        // if(props.activeRoom.type === 1) {
        //   let participants = response.participants;
        //   let splitRoomID = props.activeRoom.roomID.split("-");
        //   let participantID = splitRoomID[0] === taptalk.getTaptalkActiveUser().userID ? splitRoomID[1] : splitRoomID[0];
        //   let participantIndex = participants.findIndex(value => value.userID === participantID);
        //   setIsActive(participants[participantIndex].isOnline);
        // }
      },

      onError: (errorCode, errorMessage) => {
          // setNoLongerParticipant(true);
          console.log(errorCode, errorMessage);
      }
    })
  }
  
  let toggleRoomInfoModalAction = (showPinned = false) => {
    // if(!noLongerParticipant && !props.multiForward[props.activeRoom.roomID]) {
      setActiveModalRoomInfo(showPinned ? showPinned : false);
      setToggleRoomInfoModal(!toggleRoomInfoModal);
    // }
  }

  let modalFetchUser = () =>{
    return (
      <Modal isOpen={showLoadingFetchUser} className="modal-fetch-user">
        <ModalBody>
          <div className="lds-ring">
            <div /><div /><div /><div />
          </div>
          <p>
            <b>Fetch User Data</b>
          </p>
        </ModalBody>
      </Modal>
    )
  }

  let onClickPinnedMessageModal = () => {
    toggleRoomInfoModalAction(ACTIVE_ROOM_INFO_MODAL.pinMessage);
    // setActiveModalRoomInfo(ACTIVE_ROOM_INFO_MODAL.pinMessage);
  }

  let getOtherUserID = () => {
    if (props.activeRoom.type === 1) {
      return HelperChat.getOtherUserIDFromRoomID(props.activeRoom.roomID);
    }
    return "";
  }

  let isOtherUserAddedToContacts = () => {
    if (props.activeRoom.type === 1) {
      let findIndex = props.userContactsNoGroup.findIndex(val => val.user.userID === getOtherUserID());
      return findIndex !== -1;
    }
    return false;
  }

  return (
    <div 
      className={`
        chat-room-header-container 
        ${(!props.showScheduledMessagesChatRoom && !Helper.isObjectEmpty(props.allPinnedMessages.messages) && props.allPinnedMessages.roomID === props.activeRoom.roomID) ?
          'chat-room-header-height-100'
          :
          ''
        }
      `}
    >
        {
          !props.showScheduledMessagesChatRoom && 
          <ChatRoomHeaderPinMessageTop 
            {...props} 
            onClickPinnedMessageModal={onClickPinnedMessageModal}
            messageListenerNewMessageProps={props.messageListenerNewMessageProps}
            messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
            isShow={(!Helper.isObjectEmpty(props.allPinnedMessages.messages) && (props.allPinnedMessages.roomID === props.activeRoom.roomID))}
          />
        }

        {(props.activeRoom.type === 1 &&
          !props.showScheduledMessagesChatRoom && 
          props.blockingAddBlockContact.isShow && 
          props.activeRoom.roomID !== Helper.savedMessageRoomID()) &&
          !isOtherUserAddedToContacts() &&
          props.blockedUserIDs.indexOf(getOtherUserID()) === -1 &&
          <ChatRoomAddBlockContact {...props} roomData={roomData} />
        }

        {modalFetchUser()}

        <div className="chat-room-identifier">
            <IoIosArrowBack 
              className="arrow-back-chat-room" 
              onClick={() => setTimeout(props.clearActiveRoom(), 500)}
            />
            
            {
              props.showScheduledMessagesChatRoom &&
              <IoIosArrowBack 
                className="arrow-back-scheduled-message" 
                onClick={() => props.setShowScheduledMessagesChatRoom(false)}
              />
            }
            {/* <p className="room-name-centered">
              <b>Saved Message</b>
            </p> */}

            <p className={(props.activeRoom.deleted !== 0 || props.activeRoom.roomID === Helper.savedMessageRoomID() || props.showScheduledMessagesChatRoom) ? "room-name-centered" : ""}>
              <b>{props.showScheduledMessagesChatRoom ? "Scheduled Message" : props.activeRoom.name}</b>
            </p>

            <br />

            {(roomData && props.activeRoom.roomID !== Helper.savedMessageRoomID() && !props.showScheduledMessagesChatRoom) &&
              (isTyping.length > 0 ?
                  props.activeRoom.type === 1 ?
                    <div className={`connectivity-status`}>
                      Typing<span className="typing-dot-one">.</span><span className="typing-dot-two">.</span><span className="typing-dot-three">.</span>
                    </div>
                    :
                    isTyping.length > 1 ?
                      <div className={`connectivity-status`}>
                        {isTyping.length} peoples typing<span className="typing-dot-one">.</span><span className="typing-dot-two">.</span><span className="typing-dot-three">.</span>
                      </div>
                      :
                      <div className={`connectivity-status`}>
                        {isTyping[0].fullname} is typing<span className="typing-dot-one">.</span><span className="typing-dot-two">.</span><span className="typing-dot-three">.</span>
                      </div>
                  :
                  props.activeRoom.type === 2 ?
                    <div className={`connectivity-status ${isActive ? "" : "status-offline"}`}>
                        {`${roomData.participants.length} Member${roomData.participants.length > 1 ? 's' : ''}`}
                    </div>
                    :
                    <div className={`connectivity-status ${isActive ? 'status-online' : 'status-offline'}`}>
                        {isActive ?
                          "Active Now"
                          :
                          // "Not Active now"
                          lastSeenText
                        }
                    </div>
              )
            }
        </div>
        
        {props.activeRoom.deleted !== 0 ?
          <img src={iconDeleted} alt="" className="cursor-default" />
          :
          (props.activeRoom.roomID === Helper.savedMessageRoomID() ?
            <div className="saved-message-avatar-wrapper" onClick={() => toggleRoomInfoModalAction()}>
              <FiBookmark />
            </div>
            :
            (props.activeRoom.imageURL.thumbnail === "" ?
              <div className="user-avatar-name" 
                  style={{background: taptalk.getRandomColor(props.activeRoom.name)}}
                  onClick={() => toggleRoomInfoModalAction()}
              >
                  <b>{Helper.renderUserAvatarWord(props.activeRoom.name, props.activeRoom.type === 2)}</b>
              </div>
              :
              <img src={props.activeRoom.imageURL.thumbnail} alt="" onClick={() => toggleRoomInfoModalAction()} />
            )
          )
        }

        <ChatRoomHeaderInfoModal 
            roomDataProps={roomData}
            toggleRoomInfoModalprops={toggleRoomInfoModal}
            toggleRoomInfoModalActionprops={toggleRoomInfoModalAction}
            chatRoomDataForHeader={props.chatRoomDataForHeader}
            mentionUsernameTemp={mentionUsernameTemp}
            clearMentionUsernameTemp={() => setMentionUsernameTemp(false)}
            activeModalRoomInfo={activeModalRoomInfo}
        />
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  userClick: state.userClick,
  blockingAddBlockContact: state.blockingAddBlockContact,
  userContactsNoGroup: state.userContactsNoGroup,
  multiForward: state.multiForward,
  allPinnedMessages: state.allPinnedMessages,
  blockedUserIDs: state.blockedUserIDs
});

const mapDispatchToProps = {
  setParticipantList,
  clearParticipantList,
  setMentionUsername,
  clearMentionUsername,
  setActiveRoom,
  clearActiveRoom,
  clearUserClick,
  setBlockingAddBlockContact
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeader);
