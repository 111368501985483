import React from 'react';
import './ChatRoomHeaderInfoLink.scss';
import 'react-circular-progressbar/dist/styles.css';
import { FiLink } from 'react-icons/fi';
import ChatRoomSharedMediaHoverDropDownMenu from '../chatRoomHeaderSharedMedia/chatRoomSharedMediaHoverDropDownMenu/ChatRoomSharedMediaHoverDropDownMenu';
import Helper from '../../../../../helper/Helper';

var ChatRoomHeaderInfoLink = (props) => {

    let url = Helper.checkAndAddUrlPrefix(props.mediaData.data.url);

    return (
        <div className="room-data-link-wrapper">
            <div className="room-data-link-icon-wrapper">
                <FiLink className="room-data-link-icon" />
            </div>
            <b className="room-data-link-label">{props.mediaData.data.url}</b>
            <div className="room-data-link-separator"></div>
            <a href={url} target="_blank" rel="noopener noreferrer">
                <span className="room-data-link-clickable"></span>
            </a>
            <ChatRoomSharedMediaHoverDropDownMenu 
                singleChatDataProps={props.mediaData}
                activeRoom={props.mediaData.room}
                buttonList={[{
                    val: "viewInChat",
                    text: "View in Chat",
                    action: () => props.onClickToMessage(props.mediaData.localID),
                }]}
            />
        </div>
    )
}

export default ChatRoomHeaderInfoLink;
