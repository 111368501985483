import React, { useState  } from 'react';
import './RoomList.scss';
import RoomListHeader from './roomListHeader/RoomListHeader';
import RoomListConnectionBar from './roomListConnectionBar/RoomListConnectionBar';
import RoomListChat from './roomListChat/RoomListChat';
import RoomListMyAccount from './roomListMyAccount/RoomListMyAccount';
import RoomListNewChat from './roomListNewChat/RoomListNewChat';
import RoomListOnSearch from './roomListOnSearch/RoomListOnSearch';
import RoomListSetupModal from './roomListSetupModal/RoomListSetupModal';
import RoomListBlockedContacts from './roomListBlockedContacts/RoomListBlockedContacts';
import ChatRoomHeaderInfoModal from '../chatRoom/chatRoomHeader/chatRoomHeaderInfoModal/ChatRoomHeaderInfoModal';
import { connect } from 'react-redux';
import { setActiveRoom } from '../../redux/actions/reduxActionActiveRoom';

const SETUP_ROOM_MODAL_STATUS = {
  loading: 1,
  success: 3,
  fail: 4
}

var RoomList = (props) => {
  let [myAccountModal, setMyAccountModal] = useState(false);
  let [searching, setSearching] = useState("");

  let toggleMyAccountModal = () => {
    setMyAccountModal(!myAccountModal);
  }

  let [newChatModal, setNewChatModal] = useState(false);
  let toggleNewChatModal = () => {
    setNewChatModal(!newChatModal);
  }

  let onChangeSearchRoomList = (e) => {
    setSearching(e.target.value);
    // tapCoreMessageManager.markAllMessagesInRoomAsRead("26-31731");
  }

  let clearSearching = () => {
    setSearching("");
  }

  let [showBlockedContactsModal, setShowBlockedContactsModal] = useState(false);
  let toggleBlockedContactsModal = () => {
    setShowBlockedContactsModal(!showBlockedContactsModal);
  }

  let [previousActiveRoom, setPreviousActiveRoom] = useState(null);
  let [selectedBlockedUserRoom, setSelectedBlockedUserRoom] = useState(false);

  let hideBlockedContactProfileModal = () => {
    setSelectedBlockedUserRoom(false);
    props.setActiveRoom(previousActiveRoom);
    toggleBlockedContactsModal();
    toggleMyAccountModal();
  }

  return (
    <React.Fragment>
      <RoomListSetupModal setupModal={props.setupModal} />
      
      {props.setupModal === SETUP_ROOM_MODAL_STATUS.success &&
        <>
          <div className="room-list-container">
            <RoomListHeader toggleMyAccountModal={toggleMyAccountModal}
                            toggleNewChatModal={toggleNewChatModal}
                            onChangeSearchRoomListProps={onChangeSearchRoomList} 
                            searchingProps={searching}
                            clearSearchingProps={clearSearching}
            />

            <RoomListConnectionBar connectingStatusProps={props.connectingStatusProps} />
            

            <RoomListChat onClickRoomListProps={props.onClickRoomListProps} 
                          style={searching !== "" ? {display: 'none'} : {display: 'block'}}
                          messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                          messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                          messageListenerScheduledMessageSentProps={props.messageListenerScheduledMessageSentProps}
                          forceRefreshRoomProps={props.forceRefreshRoomProps}
                          undoForceRefreshRoomProps={props.undoForceRefreshRoomProps}
                          connectingStatusProps={props.connectingStatusProps}
                          deleteLocalChatRoomProps={props.deleteLocalChatRoomProps}
            />

            <RoomListOnSearch 
              style={searching !== "" ? {display: 'block'} : {display: 'none'}}
              searchingProps={searching} 
              connectingStatusProps={props.connectingStatusProps}
              clearSearchingProps={clearSearching}
            />
          </div>

          <RoomListMyAccount 
              myAccountModal={myAccountModal}
              toggleMyAccountModal={toggleMyAccountModal}
              onClickBlockedContacts={() => toggleBlockedContactsModal()}
          />

          <RoomListNewChat newChatModal={newChatModal}
              toggleNewChatModal={toggleNewChatModal}
              newEmitMessageProps={props.newEmitMessageProps}
          />

          <RoomListBlockedContacts 
              showBlockedContactsModal={showBlockedContactsModal}
              toggleBlockedContactsModal={toggleBlockedContactsModal}
              setSelectedBlockedUserRoom={setSelectedBlockedUserRoom}
              setPreviousActiveRoom={setPreviousActiveRoom}
          />

          { /* Room Info Modal for Blocked User Profile */
            selectedBlockedUserRoom &&
            <ChatRoomHeaderInfoModal 
                roomDataProps={{room: selectedBlockedUserRoom}}
                toggleRoomInfoModalprops={selectedBlockedUserRoom}
                toggleRoomInfoModalActionprops={hideBlockedContactProfileModal}
                chatRoomDataForHeader={{}}
            />
          }
        </>
      }
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  appData: state.appData,
});

const mapDispatchToProps = {
  setActiveRoom
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomList);
