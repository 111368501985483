import React, { useState, useEffect } from 'react';
import './ChatRoomUpdateModal.scss'
import { Modal, ModalBody } from 'reactstrap';
import { FiCheck } from 'react-icons/fi';

const SETUP_ROOM_MODAL_STATUS = {
    loading: 1,
    success: 3,
    fail: 4
}

var ChatRoomUpdateModal = (props) => {
  let [modalSetupRoomList, setModalSetupRoomList] = useState(false);

  useEffect(() => {
    if(props.uploadStatus === SETUP_ROOM_MODAL_STATUS.loading) setModalSetupRoomList(true)
    if(props.uploadStatus === SETUP_ROOM_MODAL_STATUS.success || props.uploadStatus === SETUP_ROOM_MODAL_STATUS.fail) {
        setTimeout(() => {
            setModalSetupRoomList(false)
        }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.uploadStatus]);
  
  let setupOnProgressModal = () => {
    return (
        <div className="setup-main-content">
            <div className="setup-image-content">
                <div className="lds-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            <br />
            <b>
                Updating...
            </b>
        </div>
    )
  }

  let setupSuccessModal = () => {
    return (
        <div className="setup-main-content">
            <div className="setup-image-content setup-success">
                <FiCheck />
            </div>
            <br />
            <b>
                Picture Updated
            </b>
        </div>
    )
  }

//   let retrySetupAction = () => {
//     window.location.reload();
//   }

//   let setupFailModal = () => {
//     return (
//         <div className="setup-main-content">
//             <div className="setup-image-content setup-fail">
//                 <FiX />
//             </div>
//             <br />
//             <b>
//                 Failed
//             </b>
//         </div>
//     )
//   }

  return (
    <Modal isOpen={modalSetupRoomList} className="chat-room-update-modal">
        <ModalBody>
            {props.uploadStatus === SETUP_ROOM_MODAL_STATUS.loading ?
                setupOnProgressModal()
                :
                props.uploadStatus === SETUP_ROOM_MODAL_STATUS.success ?
                    setupSuccessModal()
                    :
                    ""
            }
        </ModalBody>
    </Modal>
  );
}

export default ChatRoomUpdateModal;
