import React, { useState, useEffect } from 'react';
import './ChatRoomHeaderPinMessageTop.scss';
// import Helper from '../../../../helper/Helper';
import { taptalkHelper, taptalk } from '@taptalk.io/web-sdk';
import ActionPinMessage from "../../../../assets/img/chatroom/message-action/action-pin-orange.svg";
import { setLastClickStarMessage } from '../../../../redux/actions/reduxActionLastClickStarMessage';
import { setAllPinnedMessages } from '../../../../redux/actions/reduxActionAllPinnedMessage';
import WebWorker from "../../../../helper/HelperWebWorker";
import { connect } from 'react-redux';

var ChatRoomHeaderPinMessageTop = ({
  activeRoom,
  allPinnedMessages,
  setAllPinnedMessages,
  onClickPinnedMessageModal,
  setLastClickStarMessage,
  messageListenerNewMessageProps,
  messageListenerUpdateMessageProps,
  isShow
}) => {
  let [webWorkerDeletePinnedMessage, setWebWorkerDeletePinnedMessage] = useState(null);
  let [webWorkerEditPinnedMessage, setWebWorkerEditPinnedMessage] = useState(null);
  let [webWorkerNewPinnedMessage, setWebWorkerNewPinnedMessage] = useState(null);
  let [webWorkerNewUnPinnedMessage, setWebWorkerNewUnPinnedMessage] = useState(null);

  useEffect(() => {
    //webwokrer delete pinned
    setWebWorkerDeletePinnedMessage(
      new WebWorker(() => {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('message', function(e) {
          let { _listenerUpdateMessage, _allPinnedMessages } = e.data;

          if(_allPinnedMessages.messages[_listenerUpdateMessage.room.roomID]) {
            delete _allPinnedMessages.messages[_listenerUpdateMessage.messageID];
          }

          if(_allPinnedMessages.pinMessages.messages.length > 0) {
            let _idx = _allPinnedMessages.pinMessages.messages.findIndex(v => v.messageID === _listenerUpdateMessage.messageID);

            if(_idx !== -1) {
              _allPinnedMessages.pinMessages.messages.splice(_idx, 1);
            }
          }
          
          // eslint-disable-next-line no-restricted-globals
          self.postMessage({
            newAllPinnedMessages: _allPinnedMessages
          })
        })
      })
    )
    //web worker delete pinned

    //webwokrer edit pinned
    setWebWorkerEditPinnedMessage(
      new WebWorker(() => {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('message', function(e) {
          let { _listenerUpdateMessage, _allPinnedMessages } = e.data;

          if(_allPinnedMessages.pinMessages.messages.length > 0) {
            let _idx = _allPinnedMessages.pinMessages.messages.findIndex(v => v.messageID === _listenerUpdateMessage.messageID);

            if(_idx !== -1) {
              _allPinnedMessages.pinMessages.messages[_idx] = _listenerUpdateMessage;
            }
          }
          
          // eslint-disable-next-line no-restricted-globals
          self.postMessage({
            newAllPinnedMessages: _allPinnedMessages
          })
        })
      })
    )
    //web worker edit pinned

    //web worker new pinned
    setWebWorkerNewPinnedMessage(
      new WebWorker(() => {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('message', function(e) {
          let { _listenerNewMessage, _allPinnedMessages } = e.data;

          _allPinnedMessages.messages[_listenerNewMessage.messageID] = true;

          if(_allPinnedMessages.pinMessages.length === 0) {
            let data = {
              hasMore: false,
              messages: [_listenerNewMessage],
              pageNumber: 0,
              totalItems: 0,
              totalPages: 0
            }

            _allPinnedMessages.pinMessages = data;
          }else {
            _allPinnedMessages.pinMessages.messages.push(_listenerNewMessage);
          }
          
          // eslint-disable-next-line no-restricted-globals
          self.postMessage({
            newAllPinnedMessages: _allPinnedMessages,
            _listenerNewMessage: _listenerNewMessage
          })
        })
      })
    )
    //web worker new pinned

    // web worker new unpinned
    setWebWorkerNewUnPinnedMessage(
      new WebWorker(() => {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('message', function(e) {
          let { _listenerNewMessage, _allPinnedMessages } = e.data;

          let actionRemove = () => {
            let indexMes = _allPinnedMessages.pinMessages.messages.findIndex(val => val.messageID === _listenerNewMessage.data.messageID);

            delete _allPinnedMessages.messages[_listenerNewMessage.data.messageID];
            
            if(indexMes !== -1) {
                _allPinnedMessages.pinMessages.messages.splice(indexMes, 1);
            }
          }

          if(Object.keys(_allPinnedMessages.messages).length > 0) {
            actionRemove();
          }

          // eslint-disable-next-line no-restricted-globals
          self.postMessage({
            newAllPinnedMessages: _allPinnedMessages,
          })
        })
      })
    )
    // web worker new unpinned
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let listenerUpdateMessage = {...messageListenerUpdateMessageProps};

    if(listenerUpdateMessage) {
      //handle delete pinned message
      if(webWorkerDeletePinnedMessage && messageListenerUpdateMessageProps.isDeleted) {
        webWorkerDeletePinnedMessage.postMessage({
          _listenerUpdateMessage: listenerUpdateMessage,
          _allPinnedMessages : allPinnedMessages
        })
      }
      //handle delete pinned message

      //handle edit pinned message
      if(webWorkerEditPinnedMessage && !messageListenerUpdateMessageProps.isDeleted) {
        webWorkerEditPinnedMessage.postMessage({
          _listenerUpdateMessage: listenerUpdateMessage,
          _allPinnedMessages : allPinnedMessages
        })
      }
      //handle edit pinned message
    }
  }, [messageListenerUpdateMessageProps])
  
  useEffect(() => {
    let listenerNewMessage = {...messageListenerNewMessageProps};

    if(listenerNewMessage) {
      //handle new pinned message
      if(
        webWorkerNewPinnedMessage && 
        !allPinnedMessages.messages[listenerNewMessage.data.messageID] && 
        activeRoom.roomID === listenerNewMessage.room.roomID &&
        listenerNewMessage.action === "message/pin"
      ) {
        
        let _messagePin = {...listenerNewMessage};
        let newMes = _messagePin.data;
        newMes.created = newMes.createdTime;
        newMes.user = _messagePin.user;

        webWorkerNewPinnedMessage.postMessage({
          _listenerNewMessage: newMes,
          _allPinnedMessages : allPinnedMessages
        })
      }
      //handle new pinned message

      //handle new unpinned
      if(
        webWorkerNewUnPinnedMessage && 
        allPinnedMessages.messages[listenerNewMessage.data.messageID] && 
        activeRoom.roomID === listenerNewMessage.room.roomID &&
        listenerNewMessage.action === "message/unpin"
      ) {
        webWorkerNewUnPinnedMessage.postMessage({
          _listenerNewMessage: listenerNewMessage,
          _allPinnedMessages : allPinnedMessages
        })
      }
      //handle new unpinned
    }
  }, [messageListenerNewMessageProps])

  useEffect(() => {
    let runWebWorkerDeletePinnedMessage = async () => {
      if(webWorkerDeletePinnedMessage !== null) {
        webWorkerDeletePinnedMessage.addEventListener('message', (e) => {
          let { newAllPinnedMessages } = e.data;
          setAllPinnedMessages(newAllPinnedMessages);
        })
      }
    } 

    runWebWorkerDeletePinnedMessage();

    let runWebWorkerEditPinnedMessage = async () => {
      if(webWorkerEditPinnedMessage !== null) {
        webWorkerEditPinnedMessage.addEventListener('message', (e) => {
          let { newAllPinnedMessages } = e.data;
          setAllPinnedMessages(newAllPinnedMessages);
        })
      }
    } 

    runWebWorkerEditPinnedMessage();

    let runWebWorkerNewPinnedMessage = async () => {
      if(webWorkerNewPinnedMessage !== null) {
        webWorkerNewPinnedMessage.addEventListener('message', (e) => {
          let { newAllPinnedMessages, _listenerNewMessage } = e.data;
          
          taptalkHelper.orderArrayFromLargestToSmallest(newAllPinnedMessages.pinMessages.messages, "created", "desc", (new_arr) => {
            let _pinMes = {...newAllPinnedMessages.pinMessages};
            let tempActiveMesIdx = _pinMes.messages[newAllPinnedMessages.activePinnedMessageIndex];

            newAllPinnedMessages.pinMessages.messages = new_arr;
            
            let myAccountID = taptalk.getTaptalkActiveUser().userID;

            if(myAccountID === _listenerNewMessage.user.userID) {
              newAllPinnedMessages.activePinnedMessageIndex = newAllPinnedMessages.pinMessages.messages.findIndex(v => v.messageID === _listenerNewMessage.messageID);
            }else {
              newAllPinnedMessages.activePinnedMessageIndex = newAllPinnedMessages.pinMessages.messages.findIndex(v => v.messageID === tempActiveMesIdx.messageID);
            }

            setAllPinnedMessages(newAllPinnedMessages);
          });
        })
      }
    } 

    runWebWorkerNewPinnedMessage();

    let runWebWorkerNewUnPinnedMessage = async () => {
      if(webWorkerNewUnPinnedMessage !== null) {
        webWorkerNewUnPinnedMessage.addEventListener('message', (e) => {
          let { newAllPinnedMessages } = e.data;
          let actionSetActivePinnedMessageIndex = () => {
            let val = 0;
            let _activePinnedMessageIndex = newAllPinnedMessages.activePinnedMessageIndex;

            if((newAllPinnedMessages.pinMessages.messages.length === _activePinnedMessageIndex)) {
              val = _activePinnedMessageIndex - 1;
            }else if(newAllPinnedMessages.pinMessages.messages.length === 1) {
              val = 0;
            }else {
              val = _activePinnedMessageIndex;
            }

            return val;
          }

          newAllPinnedMessages.activePinnedMessageIndex = actionSetActivePinnedMessageIndex();

          setAllPinnedMessages(newAllPinnedMessages);
        })
      }
    } 

    runWebWorkerNewUnPinnedMessage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webWorkerDeletePinnedMessage, webWorkerEditPinnedMessage, webWorkerNewPinnedMessage, webWorkerNewUnPinnedMessage])

  let onClickPinned = () => {
    let _allPinnedMessages = {...allPinnedMessages};
    
    setTimeout(() => {
      onClickToPinMessage(_allPinnedMessages.pinMessages.messages[_allPinnedMessages.activePinnedMessageIndex].localID)
      _allPinnedMessages.activePinnedMessageIndex = _allPinnedMessages.activePinnedMessageIndex === allPinnedMessages.pinMessages.messages.length - 1 ? 0 : _allPinnedMessages.activePinnedMessageIndex + 1;
      setAllPinnedMessages(_allPinnedMessages);
    }, 1)
  }
  
  let onClickToPinMessage = (localID) => {
    setLastClickStarMessage({
        time: new Date().valueOf(),
        localID: localID
    });
  }

  return (
    <div 
      className={`
        chat-room-header-pin-message-container
        ${isShow ? "" : "pinned-message-top-hide"}
      `}
    >
        <div 
          className={`
            pinned-message-counter-bar-wrapper 
            pinned-bar-${allPinnedMessages.pinMessages.messages.length > 3 ? "4" : allPinnedMessages.pinMessages.messages.length}
          `}
        >
            <div className={allPinnedMessages.activePinnedMessageIndex === 0 ? "active" : ""} />
            
            {(allPinnedMessages.pinMessages.messages.length > 1) &&
              <div className={allPinnedMessages.activePinnedMessageIndex === 1 ? "active" : ""} />
            }

            {(allPinnedMessages.pinMessages.messages.length > 2) &&
              <div className={(allPinnedMessages.activePinnedMessageIndex === 2 || (allPinnedMessages.pinMessages.messages.length > 4 && allPinnedMessages.activePinnedMessageIndex > 2)) ? "active" : ""} />
            }

            {(allPinnedMessages.pinMessages.messages.length > 3) &&
              <div className={allPinnedMessages.pinMessages.messages.length > 4 ?  "infinite-active" : (allPinnedMessages.activePinnedMessageIndex > 2 ? "active" : "")} />
            }
        </div>

        <div className='pinned-message-text-wrapper' onClick={onClickPinned}>
            <p className='pinned-title'>
                <b>Pinned Message {allPinnedMessages.activePinnedMessageIndex > 0 ? `#${allPinnedMessages.pinMessages.messages.length - allPinnedMessages.activePinnedMessageIndex}` : ""}</b>
            </p>

            <p className='pinned-body'>
                {allPinnedMessages.pinMessages.messages[allPinnedMessages.activePinnedMessageIndex] && allPinnedMessages.pinMessages.messages[allPinnedMessages.activePinnedMessageIndex].body}
            </p>
        </div>

        <img src={ActionPinMessage} alt="" className='right-pinned-icon' onClick={onClickPinnedMessageModal} />
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  allPinnedMessages: state.allPinnedMessages
});

const mapDispatchToProps = {
  setLastClickStarMessage,
  setAllPinnedMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderPinMessageTop);
