import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './ChatRoomMessageActionComp.scss';
import { tapCoreRoomListManager } from '@taptalk.io/web-sdk';
import ActionReplyWhite from "../../../../assets/img/chatroom/message-action/action-reply-white.svg";
import ActionReplyOrange from "../../../../assets/img/chatroom/message-action/action-reply-orange.svg";
import ActionForwardWhite from "../../../../assets/img/chatroom/message-action/action-forward-white.svg";
import ActionForwardOrange from "../../../../assets/img/chatroom/message-action/action-forward-orange.svg";
import ActionDeleteWhite from "../../../../assets/img/chatroom/message-action/action-delete-white.svg";
import ActionDeleteOrange from "../../../../assets/img/chatroom/message-action/action-delete-orange.svg";
import ActionCopyWhite from "../../../../assets/img/chatroom/message-action/action-copy-white.svg";
import ActionCopyOrange from "../../../../assets/img/chatroom/message-action/action-copy-orange.svg";
import ActionStarWhite from "../../../../assets/img/chatroom/message-action/action-star-white.svg";
import ActionStarOrange from "../../../../assets/img/chatroom/message-action/action-star-orange.svg";
import ActionEditOrange from "../../../../assets/img/chatroom/message-action/action-edit-orange.svg";
import ActionPinOrange from "../../../../assets/img/chatroom/message-action/action-pin-orange.svg";
import ActionUnpinOrange from "../../../../assets/img/chatroom/message-action/action-unpin-orange.svg";
import ActionSendNowOrange from "../../../../assets/img/chatroom/message-action/action-send-now-orange.svg";
import ActionRescheduleOrange from "../../../../assets/img/chatroom/message-action/action-reschedule-orange.svg";
import ActionMessageInfo from "../../../../assets/img/chatroom/message-action/action-message-info.svg";
import ActionReportRed from '../../../../assets/img/icon-warning-red.svg';
import { FiMoreHorizontal, FiArrowRight } from 'react-icons/fi';
import Helper from "../../../../helper/Helper";
import { connect } from "react-redux";
import { setActiveRoom } from "../../../../redux/actions/reduxActionActiveRoom";
import { setGoToChatBubble } from '../../../../redux/actions/reduxActionGoToChatBubble';

var ChatRoomMessageActionComp = ({buttonList, isIn, activeRoom, singleChatDataProps, setActiveRoom, setGoToChatBubble}) => {
    let [openChatAction, setOpenChatAction] = useState(false);

    let toggleChatAction = () => {
        setOpenChatAction(!openChatAction)
    }

    const ACTION_ICON = {
        forward: {
            white: ActionForwardWhite,
            orange: ActionForwardOrange
        },
        reply: {
            white: ActionReplyWhite,
            orange: ActionReplyOrange
        },
        copy: {
            white: ActionCopyWhite,
            orange: ActionCopyOrange
        },
        delete: {
            white: ActionDeleteWhite,
            orange: ActionDeleteOrange
        },
        star: {
            white: ActionStarWhite,
            orange: ActionStarOrange
        },
        edit: {
            white: ActionEditOrange,
            orange: ActionEditOrange
        },
        pin: {
            white: ActionPinOrange,
            orange: ActionPinOrange
        },
        unpin: {
            white: ActionUnpinOrange,
            orange: ActionUnpinOrange
        },
        send: {
            white: ActionSendNowOrange,
            orange: ActionSendNowOrange
        },
        reschedule: {
            white: ActionRescheduleOrange,
            orange: ActionRescheduleOrange
        },
        report: {
            white: ActionReportRed,
            orange: ActionReportRed
        },
        messageInfo: {
            white: ActionMessageInfo,
            orange: ActionMessageInfo
        }
    }

    const HOUR_LIMIT = 48 * 60 * 60 * 1000;

    let PrintActionButton = ({_val, idx}) => (
        <DropdownItem onClick={_val.action} key={`action-${idx}`}>
            <img src={ACTION_ICON[_val.val].orange} className="action-icon action-orange" alt="" />
            <img src={ACTION_ICON[_val.val].white} className="action-icon action-white" alt="" />
            {_val.text}
        </DropdownItem>
    );

    let scrollToReply = (localID) => {
        let targetScroll = document.querySelectorAll(".chat-room-main-content")[0];
        let targetLocalID = document.querySelector(`#message-${localID}`);
        
        if(targetLocalID !== null) {
            targetScroll.scrollTop = targetLocalID.offsetTop;
    
            targetLocalID.classList.add("highlight-chat-bubble");

            setTimeout(() => {
                targetLocalID.classList.remove("highlight-chat-bubble");
            }, 2000);
        }
    }

    let goToChatBubble = () => {
        if(Helper.isForwardMessageIgnoreRoom(singleChatDataProps)) {
            let roomModel = tapCoreRoomListManager.getRoomModelFromRoomList(singleChatDataProps.forwardFrom.roomID).lastMessage.room;

            if(roomModel) {
                if(activeRoom !== null) {
                    if(singleChatDataProps.forwardFrom.roomID === activeRoom.roomID) {
                        scrollToReply(singleChatDataProps.forwardFrom.localID);
                    }else {
                        setGoToChatBubble({
                            localID: singleChatDataProps.forwardFrom.localID,
                            roomID: singleChatDataProps.forwardFrom.roomID
                        })
                    }
                }else {
                    setGoToChatBubble({
                        localID: singleChatDataProps.forwardFrom.localID,
                        roomID: singleChatDataProps.forwardFrom.roomID
                    })
                }

                setActiveRoom(roomModel);
            }else {
                Helper.doToast("Room not found");
            }
        }else {
            Helper.doToast("Room not found");
        }
    }

    return (
        <>
            <div className={`message-action-wrapper-new ${isIn ? "message-action-wrapper-new-in" : ""}`}>
                <Dropdown isOpen={openChatAction} toggle={toggleChatAction}>
                    <DropdownToggle>
                        <FiMoreHorizontal />
                    </DropdownToggle>
                    <DropdownMenu>
                        {buttonList.map((_val, idx) => {
                            return (
                                (_val.val === "edit" && _val.message) ?
                                    (
                                        (
                                            ((new Date().valueOf() - _val.message.created) < HOUR_LIMIT && _val) &&
                                            !(_val.message.forwardFrom !== null && _val.message.forwardFrom.localID !== null && _val.message.forwardFrom.localID !== "")
                                        ) ?
                                            <PrintActionButton _val={_val} idx={idx} />
                                            :
                                            ""
                                    )
                                    :
                                    (_val.val === "messageInfo" ?
                                        (activeRoom.type === 2 &&
                                            <PrintActionButton _val={_val} idx={idx} />
                                        )
                                        :
                                        <PrintActionButton _val={_val} idx={idx} />
                                    )
                            )
                        })}
                    </DropdownMenu>

                    <DropdownMenu>

                    </DropdownMenu>
                </Dropdown>
            </div>
            
            {(isIn && (activeRoom.roomID === Helper.savedMessageRoomID())) &&
                <div 
                    className={`message-action-wrapper-new message-action-wrapper-new-arrow-right ${isIn ? "message-action-wrapper-new-in" : ""}`}
                    onClick={goToChatBubble}
                >
                    <button>
                        <FiArrowRight />
                    </button>
                </div>
            }
        </>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
});

const mapDispatchToProps = {
    setActiveRoom,
    setGoToChatBubble
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageActionComp);
