import React, { useState, useEffect } from "react";
import "./ModalMessageInfo.scss";
import Helper from '../../helper/Helper';
import { CHAT_TYPE } from "../../helper/HelperConst";
import HelperDate from "../../helper/HelperDate";
import { FiX } from "react-icons/fi";
import { connect } from 'react-redux';
import { setMessageInfo } from '../../redux/actions/reduxActionMessageInfo';
import { taptalk, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import readOrange from '../../assets/img/message-info/icon-read-orange.svg';
import deliveredGrey from '../../assets/img/message-info/icon-delivered-grey.svg';
import ChatRoomMessageOut from '../chatRoom/chatRoomMain/chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutFile from '../chatRoom/chatRoomMain/chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from '../chatRoom/chatRoomMain/chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from '../chatRoom/chatRoomMain/chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutLocation from '../chatRoom/chatRoomMain/chatRoomMessageOutLocation/ChatRoomMessageOutLocation';
import ChatRoomMessageOutVoice from '../chatRoom/chatRoomMain/chatRoomMessageOutVoice/ChatRoomMessageOutVoice';

let ModalMessageInfo = (props) => {
    let { messageInfo, setMessageInfo } = props;
    let [messageInfoData, setMessageInfoData] = useState(false);
    let [isLoadingGetInfo, setIsLoadingGetInfo] = useState(true);
    
    useEffect(() => {
        if(messageInfo.isShow) {
            runFetchMessageInfo();
        }else {
            setMessageInfoData(false);
            setIsLoadingGetInfo(true);
        }
    }, [messageInfo.isShow])
    
    let closeMessageInfo = () => {
        let _mes = {...messageInfo};
        _mes.isShow = false;

        setMessageInfo(_mes);
    }

    let runFetchMessageInfo = () => {
        setIsLoadingGetInfo(true);

        tapCoreMessageManager.fetchMessageInfo(messageInfo.message.messageID, {
            onSuccess: (data) => {
                setMessageInfoData(data);
                setIsLoadingGetInfo(false);
            },
            onError: (errCode, errorMessage) => {
                console.log(errCode, errorMessage);
            }
        });
    }

    let readDeliveredTime = (time, pre) => {
        let _time = "";
        let todayDate = HelperDate.formatToString(new Date(new Date().valueOf()), "dd/MM/yyyy");
        let timeDate = HelperDate.formatToString(new Date(time), "dd/MM/yyyy");
        _time = todayDate !== timeDate ? 
            `${HelperDate.formatToString(new Date(time), "dd/MM/yyyy")} <span class="centered-dot"></span> <b>${HelperDate.formatToString(new Date(time), "HH:mm")}</b>`
            :
            `<b>${HelperDate.formatToString(new Date(time), "HH:mm")}</b>`;
        ;
    
        return `${pre} ${_time}`;
    }

    let generateMessageBubble = (messageData) => {
        let activeUser = taptalk.getTaptalkActiveUser().userID;

        switch(messageData.type) {
          case CHAT_TYPE.TAPChatMessageTypeText:
          case CHAT_TYPE.TAPChatMessageTypeLink:
            return (
                <ChatRoomMessageOut
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={() => {}}
                  onForwardMessage={() => {}}
                  onEditMessage={() => {}}
                  onStarredMessage={() => {}}
                  onUnstarredMessage={() => {}}
                  onPinnedMessage={() => {}}
                  onUnpinnedMessage={() => {}}
                  scrollToReply={() => {}}
                  mentionList={false}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
            )
          case CHAT_TYPE.TAPChatMessageTypeImage:
              return (
                    
                    <ChatRoomMessageOutImage 
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={() => {}}
                      onForwardMessage={() => {}}
                      onStarredMessage={() => {}}
                      onUnstarredMessage={() => {}}
                      onPinnedMessage={() => {}}
                      onUnpinnedMessage={() => {}}
                      scrollToReply={() => {}}
                      mentionList={false}
                      onEditMessage={() => {}}
                    />
              )
          case CHAT_TYPE.TAPChatMessageTypeVideo:
              return (
                    <ChatRoomMessageOutVideo 
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={() => {}}
                      onForwardMessage={() => {}}
                      onStarredMessage={() => {}}
                      onUnstarredMessage={() => {}}
                      onPinnedMessage={() => {}}
                      onUnpinnedMessage={() => {}}
                      scrollToReply={() => {}}
                      mentionList={false}
                      onEditMessage={() => {}}
                    />
              )
          case CHAT_TYPE.TAPChatMessageTypeLocation:
              return (
                    <ChatRoomMessageOutLocation 
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={() => {}}
                      onForwardMessage={() => {}}
                      onStarredMessage={() => {}}
                      onUnstarredMessage={() => {}}
                      onPinnedMessage={() => {}}
                      onUnpinnedMessage={() => {}}
                      scrollToReply={() => {}}
                      mentionList={false}
                    />
              )
          case CHAT_TYPE.TAPChatMessageTypeFile:
              return (
                    <ChatRoomMessageOutFile 
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={() => {}}
                      onForwardMessage={() => {}}
                      onStarredMessage={() => {}}
                      onUnstarredMessage={() => {}}
                      onPinnedMessage={() => {}}
                      onUnpinnedMessage={() => {}}
                      scrollToReply={() => {}}
                      mentionList={false}
                    />
              )
          
          case CHAT_TYPE.TAPChatMessageTypeVoice:
              return (
                    <ChatRoomMessageOutVoice
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={() => {}}
                      onForwardMessage={() => {}}
                      onStarredMessage={() => {}}
                      onUnstarredMessage={() => {}}
                      onPinnedMessage={() => {}}
                      onUnpinnedMessage={() => {}}
                      scrollToReply={() => {}}
                      mentionList={false}
                    />
              )
          default:
            break;
        }
    }

    let printTimeStamp = (data) => {
        let res = HelperDate.formatToString(new Date(data.message.created), "dd/MM/yyyy");
        let todayDate = HelperDate.formatToString(new Date(), "dd/MM/yyyy");
        let yesterdayDate = HelperDate.formatToString(new Date(new Date().setDate(new Date().getDate() - 1)), "dd/MM/yyyy");
        
        if(res === todayDate) {
            res = "Today";
        }

        if(res === yesterdayDate) {
            res = "Yesterday";
        }

        return res;
    }

    return (
        <div className={`modal-message-info ${messageInfo.isShow ? "active-modal-message-info" : ""}`}>
            <div className={`modal-message-info-body ${messageInfo.isShow ? "active-modal-message-info-body" : ""}`}>
                <div className="close-modal-message-info" onClick={closeMessageInfo}>
                    <FiX />
                </div>

                <div className="modal-message-info-title">
                    <b>Message Info</b>
                </div>

                <div className="modal-message-info-content-wrapper">
                    <div className="timestamp-wrapper">
                        <span className="timestamp"><b>{printTimeStamp(messageInfo)}</b></span>
                    </div>

                    {generateMessageBubble(messageInfo.message)}
                </div>
                
                <div className="read-delivered-wrapper">
                    {(messageInfoData && messageInfoData.readBy.length > 0) &&
                        <div className="read-delivered-content">
                            <div className={`read-delivered-title read-by-title read-delivered-title-sticky`}>
                                <p>
                                    <b>READ BY</b>

                                    <img src={readOrange} alt="" />
                                </p>
                            </div>

                            <div className="read-delivered-list-wrapper">
                                {isLoadingGetInfo ?
                                    <div className="lds-ring">
                                        <div /><div /><div /><div />
                                    </div>
                                    :
                                    messageInfoData.readBy.map((v, i) => {
                                        return (
                                            <div className="read-delivered-list" key={`read-delivered-list-${i}`}>
                                                <div className="room-info-avatar" style={{background: taptalk.getRandomColor(v.fullname)}}>
                                                {v.userImageURL.thumbnail !== "" ?
                                                    <img src={v.userImageURL.thumbnail} alt="" className="room-info-avatar-image" />
                                                    :
                                                    <div className="user-avatar-name">
                                                        {Helper.renderUserAvatarWord(v.fullname, true)}
                                                    </div>
                                                }
                                                </div>

                                                <div className="message-info-main">
                                                    <p className="user-name">
                                                        <b>{v.fullname}</b>
                                                    </p>

                                                    <div className="timestamp-read-delivered-list-wrapper">
                                                        <p dangerouslySetInnerHTML={{__html: readDeliveredTime(v.readTime, "read")}} />

                                                        <p dangerouslySetInnerHTML={{__html: readDeliveredTime(v.deliveredTime, "delivered")}} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                    
                    {(messageInfoData && messageInfoData.deliveredTo.length > 0) &&
                        <div className="read-delivered-content">
                            <div className={"read-delivered-title delivered-to-title read-delivered-title-sticky"}>
                                <p>
                                    <b>DELIVERED TO</b>

                                    <img src={deliveredGrey} alt="" />
                                </p>
                            </div>
                            
                            <div className="read-delivered-list-wrapper">
                                {isLoadingGetInfo ?
                                    <div className="lds-ring">
                                        <div /><div /><div /><div />
                                    </div>
                                    :
                                    messageInfoData.deliveredTo.map((v, i) => {
                                        return (
                                            <div className="read-delivered-list" key={`read-delivered-list-${i}`}>
                                                <div className="room-info-avatar" style={{background: taptalk.getRandomColor(v.fullname)}}>
                                                {v.userImageURL.thumbnail !== "" ?
                                                    <img src={v.userImageURL.thumbnail} alt="" className="room-info-avatar-image" />
                                                    :
                                                    <div className="user-avatar-name">
                                                        {Helper.renderUserAvatarWord(v.fullname, true)}
                                                    </div>
                                                }
                                                </div>

                                                <div className="message-info-main">
                                                    <p className="user-name">
                                                        <b>{v.fullname}</b>
                                                    </p>

                                                    <div className="timestamp-read-delivered-list-wrapper">
                                                        <p dangerouslySetInnerHTML={{__html: readDeliveredTime(v.deliveredTime, "delivered")}} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>    
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,  
    messageInfo: state.messageInfo
});
  
const mapDispatchToProps = {
    setMessageInfo
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ModalMessageInfo);