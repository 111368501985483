import React, { useState, useEffect, useRef } from 'react';
import './ChatRoomScheduledMessage.scss';
import { Modal, ModalBody } from 'reactstrap';
import { FiUpload, FiCopy, FiTrash } from "react-icons/fi";
import MediaQuery from 'react-responsive';
import ChatRoomChatInfo from '../chatRoomChatInfo/ChatRoomChatInfo';
import ChatRoomMessageOut from '../chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutFile from '../chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from '../chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from '../chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutLocation from '../chatRoomMessageOutLocation/ChatRoomMessageOutLocation';
import ChatRoomMessageOutVoice from '../chatRoomMessageOutVoice/ChatRoomMessageOutVoice';
import ChatRoomInputMessage from '../chatRoomInputMessage/ChatRoomInputMessage';
import ChatRoomInputEditMessage from '../chatRoomInputMessage/chatRoomInputEditMessage/ChatRoomInputEditMessage';
import { taptalk, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { setActiveRoom } from '../../../../redux/actions/reduxActionActiveRoom';
import { clearGoToChatBubble } from '../../../../redux/actions/reduxActionGoToChatBubble';
import { setDraftMessage } from "../../../../redux/actions/reduxActionDraftMessage";
import { setReplyMessage, clearReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { setForwardMessage, clearForwardMessage } from '../../../../redux/actions/reduxActionForwardMessage';
import { setLinkPreview, clearLinkPreview } from '../../../../redux/actions/reduxActionLinkPreview';
import { setEditMessage, clearEditMessage } from '../../../../redux/actions/reduxActionEditMessage';
import { setAllStarredMessages } from '../../../../redux/actions/reduxActionAllStarredMessage';
import { clearActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import { setAllPinnedMessages } from '../../../../redux/actions/reduxActionAllPinnedMessage';
import { clearParticipantList } from '../../../../redux/actions/reduxActionParticipantList';
import { connect } from 'react-redux';
import Helper from "../../../../helper/Helper";
import HelperChat from "../../../../helper/HelperChat";
import WebWorker from "../../../../helper/HelperWebWorker";
import { CHAT_TYPE } from "../../../../helper/HelperConst";
import iconClock from '../../../../assets/img/icon-clock.png';
import noScheduledMessage from '../../../../assets/img/chatroom/no-scheduled-message.svg';
import ScheduledMessageTimePicker from '../../../reuseableComponent/scheduledMessageTimePicker/ScheduledMessageTimePicker';

var ChatRoomScheduledMessage = (props) => {
  let [chatDataRoomMain, setChatDataRoomMain] = useState([]);
  let [chatDataRoomMainTemp, setChatDataRoomMainTemp] = useState(false);
  let [isLoadingChat, setIsLoadingChat] = useState(false);
  let [isFirstLoadScheduledMessages, setIsFirstLoadScheduledMessages] = useState(true);
  let chatRoomMesageListRef = useRef("messageLists");

  let [roomIsDeleted, setRoomIsDeleted] = useState(false);

  let [isNoLongerParticipantOrUserDelete, setIsNoLongerParticipantOrUserDelete] = useState(false);
  let [isShowLoadingBlocking, setIsShowLoadingBlocking] = useState(false);

  let [fileUploadProgress, setFileUploadProgress] = useState({});

  let [showDropFileHere, setShowDropFileHere] = useState(false);
  let [lastDragAndDropFiles, setLastDragAndDropFiles] = useState({
    files: [],
    time: ""
  });

  let [mentionList, setMentionList] = useState({});
  let [webWorkerMentionList, setWebWorkerMentionList] = useState(null);
  let [openModalActionMessage, setOpenModalActionMessage] = useState(false);

  let [isShowScheduledMessageDateTimePicker, setIsShowScheduledMessageDateTimePicker] = useState(false);
  let [scheduledMessageTime, setScheduledMessageTime] = useState(new Date());
  let [isScheduledMessageLoading, setIsScheduledMessageLoading] = useState(false);
  let [isScheduledMessageEmpty, setIsScheduledMessageEmpty] = useState(false);
  let [pendingEncryptedScheduledMessage, setPendingEncryptedScheduledMessage] = useState(false);
  let [pendingScheduledMedia, setPendingScheduledMedia] = useState(false);
  let [pendingRescheduleMessage, setPendingRescheduleMessage] = useState(false);

  let toggleModalActionMessage = () => {
    setOpenModalActionMessage(!openModalActionMessage);
  }

  let reverseMessagesObject = (object) => {
    var newObject = {};
    var keys = [];

    for (var key in object) {
        keys.push(key);
    }

    for (var i = keys.length - 1; i >= 0; i--) {
      var value = object[keys[i]];
      newObject[keys[i]]= value;
    }       

    return newObject;
  }

  useEffect(() => {
    setIsShowLoadingBlocking(false);

    if (props.activeMessage) {
      toggleModalActionMessage();
    }
    else {
      setOpenModalActionMessage(false);
    }
  }, [props.activeMessage]);

  useEffect(() => {
    if (pendingEncryptedScheduledMessage) {
      sendScheduledMessage(pendingEncryptedScheduledMessage);
    }
  }, [pendingEncryptedScheduledMessage]);

  useEffect(() => {
    if (pendingScheduledMedia || pendingRescheduleMessage) {
      toggleScheduledMessageDateTimePicker();
    }
  }, [pendingScheduledMedia, pendingRescheduleMessage]);

  //set webworker
  useEffect(() => {
    setWebWorkerMentionList(
      new WebWorker(() => {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('message', function(e) {
          let { chatDataRoomMain, activeRoom, chatType } = e.data;
          let hashmap = {};
          
          let updateMessageMentionIndexes = (message) => {
            let originalText;
            if (message.type === chatType.TAPChatMessageTypeText || message.type === chatType.TAPChatMessageTypeLink) {
                originalText = message.body;
            } else if ((message.type === chatType.TAPChatMessageTypeImage || message.type === chatType.TAPChatMessageTypeVideo) && null !== message.data) {
                originalText = message.data.caption;
            } else if (message.type === chatType.TAPChatMessageTypeLocation && null !== message.data) {
                originalText = message.data.address;
            } else {
                return false;
            }
            if (null === originalText) {
                return false;
            }
            let mentionIndexes = [];
            
            if (originalText.includes("@")) {
                let length = originalText.length;
                let startIndex = -1;
                for (let i = 0; i < length; i++) {
                    if (originalText.charAt(i) === '@' && startIndex === -1) {
                        // Set index of @ (mention start index)
                        startIndex = i;
                    } else {
                        let endOfMention = originalText.charAt(i) === ' ' || originalText.charAt(i) === '\n';
    
                        if (i === (length - 1) && startIndex !== -1) {
                            let endIndex = endOfMention ? i : (i + 1);
                            if (endIndex > (startIndex + 1)) {
                                mentionIndexes.push(startIndex);
                                mentionIndexes.push(endIndex);
                            }
                            startIndex = -1;
                        } else if (endOfMention && startIndex !== -1) {
                            // End index for mentioned username
                            if (i > (startIndex + 1)) {
                                mentionIndexes.push(startIndex);
                                mentionIndexes.push(i);
                            }
                            startIndex = -1;
                        }
                    }
                }
                if (mentionIndexes.length > 0) {
                    return {
                        localID: [message.localID],
                        mentionIndex: mentionIndexes
                    }
                }else {
                    return false;
                }
            }
          }
        
          Object.keys(chatDataRoomMain).map((val) => {
            let result = updateMessageMentionIndexes(chatDataRoomMain[val], activeRoom);

            if(result) {
              hashmap[result.localID] = result.mentionIndex;
            }
            
            return null;
          })
          
          // eslint-disable-next-line no-restricted-globals
          self.postMessage({
            data: {
              activeRoom: activeRoom,
              mentionList: hashmap
            }
          })
        })
      })
    )
  }, []);

  useEffect(() => {
    let runWebWorkerMentionList = async () => {
      if(webWorkerMentionList !== null) {
        webWorkerMentionList.addEventListener('message', (e) => {
          let { data } = e.data;
          setMentionList(data.mentionList);
        })
      }
    } 

    runWebWorkerMentionList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webWorkerMentionList]);

  useEffect(() => {
    let runFileUploadProgress = async () => {
      let _fileUploadProgress = {...fileUploadProgress};

      if(Object.keys(_fileUploadProgress).length > 0) {
        let _localID = Object.keys(_fileUploadProgress)[0];
        let _chatDataRoomMain = {...chatDataRoomMain};
        
        if(_fileUploadProgress[_localID].message !== null) {
          _chatDataRoomMain[_fileUploadProgress[_localID].message.localID] = _fileUploadProgress[_localID].message;
          setChatDataRoomMain(_chatDataRoomMain);
          scrollChatViewToBottom();
        }else {
          if(_chatDataRoomMain[_localID]) {
            _chatDataRoomMain[_localID].bytesUpload = _fileUploadProgress[_localID].bytes;
            _chatDataRoomMain[_localID].percentageUpload = _fileUploadProgress[_localID].percentage;
            setChatDataRoomMain(_chatDataRoomMain);
          }
        }

        setFileUploadProgress({});
      }
    }

    runFileUploadProgress();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadProgress]);
  
  useEffect(() => {
    let runFirst = async () => {
      setChatDataRoomMain([]);
      setRoomIsDeleted(false);
      setIsNoLongerParticipantOrUserDelete(false);
  
      setTimeout(function() {
        if(props.activeRoom !== null) { 
          fetchScheduledMessages();
        }
      }, 0);
  
      setIsNoLongerParticipantOrUserDelete(false);
    }

    runFirst();

    let onlineCallbackOnChatRoom = () => {
      if(props.activeRoom !== null) {
        setTimeout(() => {
          fetchScheduledMessages(true)
        }, 5000);
      }
    }

    window.addEventListener('online', onlineCallbackOnChatRoom);
    
    return () => {
      window.removeEventListener('online', onlineCallbackOnChatRoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeRoom]);

  useEffect(() => {
    let runLinkPreview = async () => {
      let _linkPreview = {...props.linkPreview};

      let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
      let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 108 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
      let heightNew = 0;

      let setNewHeight = (height, isSet) => {
        heightNew = height;
        elChatRoomMain.style.setProperty("max-height", "calc(100% - " + (heightNew - 51) + "px)", "important");
        _linkPreview.setHeightChatRoom = isSet;
        props.setLinkPreview(_linkPreview);
      }
      
      if (_linkPreview.url && !_linkPreview.setHeightChatRoom) {
        setNewHeight(chatRoomContainerHeight + 68, true);
      }
    }

    runLinkPreview();
  }, [props.linkPreview]);

  useEffect(() => {
    let runEditMessage = async () => {
      let _editMessage = {...props.editMessage};
      let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
      let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 108 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
      let heightNew = 0;
      
      let setNewHeight = (height, isSet) => {
        heightNew = height;
        elChatRoomMain.style.setProperty("max-height", "calc(100% - " + (heightNew - 51) + "px)", "important");
        _editMessage.setHeightChatRoom = isSet;
        props.setEditMessage(_editMessage);
      }

      if(_editMessage.message && !_editMessage.setHeightChatRoom) {
        setNewHeight(chatRoomContainerHeight + 68, true);
      }
    }

    runEditMessage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMessage]);

  useEffect(() => {
    let runChatDataRoomMainTemp = () => {
      if(chatDataRoomMainTemp) {
        if(chatDataRoomMainTemp.activeRoom.roomID === props.activeRoom.roomID) {
          setChatDataRoomMain(chatDataRoomMainTemp.chats);

          if (chatDataRoomMainTemp.isFirstLoad) {
            scrollChatViewToBottom();
          } 
        
          if(chatDataRoomMainTemp.scrollingBackHeight) {
            setTimeout(() => {
              if(document.querySelectorAll(".chat-room-main-content")[0]) {
                chatRoomMesageListRef.current.scrollTop = document.querySelectorAll(".chat-room-main-content")[0].scrollHeight - chatDataRoomMainTemp.scrollingBackHeight;
              }
            }, 0)
          }
        }
      }
    }

    runChatDataRoomMainTemp();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDataRoomMainTemp]);

  useEffect(() => {
    let lengthChatData = Object.keys(chatDataRoomMain).length - 1;
    let lastChat = chatDataRoomMain[Object.keys(chatDataRoomMain)[lengthChatData]];

    let runNoLongerParticipantOrUserDelete = () => {
      setIsNoLongerParticipantOrUserDelete(true);
    };

    if(
      lastChat &&
      lastChat.action === 'user/delete' &&
      lastChat.type === 9001
    ) {
      runNoLongerParticipantOrUserDelete();
    }else if(
      lastChat &&
      lastChat.action === 'room/removeParticipant' &&
      lastChat.type === 9001 &&
      lastChat.target.targetID === taptalk.getTaptalkActiveUser().userID
    ) {
      runNoLongerParticipantOrUserDelete();
    }else {
      setIsNoLongerParticipantOrUserDelete(false);
    }

    //mention
    let runMention = async () => {
      if(props.activeRoom.type === 2 && webWorkerMentionList !== null) {
        webWorkerMentionList.postMessage({
            activeRoom: props.activeRoom,
            chatDataRoomMain: chatDataRoomMain,
            chatType: CHAT_TYPE
        })
      }
    }
    runMention();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDataRoomMain]);

  useEffect(() => {
    if (props.roomListenerScheduledMessageProps && 
        props.roomListenerScheduledMessageProps.room.roomID === props.activeRoom.roomID
    ) {
      fetchScheduledMessages();
    }
  }, [props.roomListenerScheduledMessageProps]);
  
  let generateNewFileUploadProgress = (localID, percentage, bytes, message = null) => {
    let newFileUploadProgress = {
      [localID]: {
        percentage: percentage,
        bytes: bytes,
        message: message
      }
    }

    return newFileUploadProgress;
  }

  let scrollChatViewToBottom = async () => {
    setTimeout(function() {
      if(chatRoomMesageListRef.current !== null) {
        chatRoomMesageListRef.current.scrollTop = chatRoomMesageListRef.current.scrollHeight;
      }
    }, 0);
  }

  let fetchScheduledMessages = async (isReconnect) => {
    setTimeout(function() {
      if (!isReconnect) {
       setIsLoadingChat(true);
      }

      tapCoreMessageManager.fetchScheduledMessages(props.activeRoom.roomID, {
        onSuccess: (scheduledItems, scheduledMessages) => {
          setIsLoadingChat(false);

          if (!scheduledMessages) {
            setIsScheduledMessageEmpty(true);
            return;
          }

          setIsScheduledMessageEmpty(false);

          let messages = {...scheduledMessages};
          if (props.pendingScheduledMessages.length > 0) {
            props.pendingScheduledMessages.forEach((message) => {
              if (!messages.hasOwnProperty(message.localID)) {
                messages = insertNewMessageToChatRoom(message, messages);
              }
            });
          }
          
          setChatDataRoomMainTemp({
            chats: reverseMessagesObject(messages),
            activeRoom: props.activeRoom,
            timeStamp: new Date().valueOf()
          });
          
          if (isFirstLoadScheduledMessages) {
            setIsFirstLoadScheduledMessages(false);
            scrollChatViewToBottom();
          }
        },
        onError: (errorCode, errorMessage) => {
          setIsLoadingChat(false);
          console.log(errorCode, errorMessage);
        }
      });
    }, isReconnect ? 0 : 100);
  }

  let generateMessageBubble = (messageData, index) => {
    let activeUser = taptalk.getTaptalkActiveUser().userID;

    switch(messageData.type) {
      case CHAT_TYPE.TAPChatMessageTypeText:
      case CHAT_TYPE.TAPChatMessageTypeLink:
        return (
          <ChatRoomMessageOut
            key={index} 
            singleChatDataProps={messageData} 
            activeUserID={activeUser}
            mentionList={mentionList[messageData.localID]}
            isScheduledMessage={true}
            sendScheduledMessageNow={sendScheduledMessageNow}
            rescheduleMessage={rescheduleMessage}
            editScheduledMessage={editScheduledMessage}
            deleteScheduledMessage={deleteScheduledMessage}
          />
        )
      case CHAT_TYPE.TAPChatMessageTypeImage:
        return (
          <ChatRoomMessageOutImage 
            key={index} 
            singleChatDataProps={messageData} 
            activeUserID={activeUser}
            mentionList={mentionList[messageData.localID]}
            isScheduledMessage={true}
            sendScheduledMessageNow={sendScheduledMessageNow}
            rescheduleMessage={rescheduleMessage}
            editScheduledMessage={editScheduledMessage}
            deleteScheduledMessage={deleteScheduledMessage}
          />
        )
      case CHAT_TYPE.TAPChatMessageTypeVideo:
        return (
          <ChatRoomMessageOutVideo 
            key={index} 
            singleChatDataProps={messageData} 
            activeUserID={activeUser}
            mentionList={mentionList[messageData.localID]}
            isScheduledMessage={true}
            sendScheduledMessageNow={sendScheduledMessageNow}
            rescheduleMessage={rescheduleMessage}
            editScheduledMessage={editScheduledMessage}
            deleteScheduledMessage={deleteScheduledMessage}
          />
        )
      case CHAT_TYPE.TAPChatMessageTypeSystemMessage:
        return (
          <ChatRoomChatInfo 
            key={index} 
            singleChatDataProps={messageData} 
            activeUserID={activeUser}
            // onReplyMessage={onReplyMessage}
            // onForwardMessage={onForwardMessage}
            // onStarredMessage={onStarredMessage}
            // onUnstarredMessage={onUnstarredMessage}
            // onPinnedMessage={onPinnedMessage}
            // onUnpinnedMessage={onUnpinnedMessage}
            // scrollToReply={scrollToReply}
            mentionList={mentionList[messageData.localID]}
          />
        )
      case CHAT_TYPE.TAPChatMessageTypeLocation:
        return (
          <ChatRoomMessageOutLocation 
            key={index} 
            singleChatDataProps={messageData} 
            activeUserID={activeUser}
            mentionList={mentionList[messageData.localID]}
            isScheduledMessage={true}
            sendScheduledMessageNow={sendScheduledMessageNow}
            rescheduleMessage={rescheduleMessage}
            editScheduledMessage={editScheduledMessage}
            deleteScheduledMessage={deleteScheduledMessage}
          />
        )
      case CHAT_TYPE.TAPChatMessageTypeFile:
        return (
          <ChatRoomMessageOutFile 
            key={index} 
            singleChatDataProps={messageData} 
            activeUserID={activeUser}
            mentionList={mentionList[messageData.localID]}
            isScheduledMessage={true}
            sendScheduledMessageNow={sendScheduledMessageNow}
            rescheduleMessage={rescheduleMessage}
            editScheduledMessage={editScheduledMessage}
            deleteScheduledMessage={deleteScheduledMessage}
          />
        )
      case CHAT_TYPE.TAPChatMessageTypeVoice:
        return (
          <ChatRoomMessageOutVoice
            key={index} 
            singleChatDataProps={messageData} 
            activeUserID={activeUser}
            mentionList={mentionList[messageData.localID]}
            isScheduledMessage={true}
            sendScheduledMessageNow={sendScheduledMessageNow}
            rescheduleMessage={rescheduleMessage}
            editScheduledMessage={editScheduledMessage}
            deleteScheduledMessage={deleteScheduledMessage}
          />
        )
      default:
        break;
    }
  }

  let onInputNewMessage = (messageText) => {
    messageText = messageText.trim();
    
    if ((props.activeRoom) && (messageText !== "") && (messageText.replace(/\s/g, '').length !== 0)) {
      let split4000char = messageText.match(/.{1,4000}/gs);

      for (let i in split4000char) {
        if (props.forwardMessage.message && props.forwardMessage.message.multipleMessages) {
          let _multiForward = {...props.forwardMessage.message.multipleMessages};
          let forwardLength = Object.keys(_multiForward).length;

          Object.keys(_multiForward).map((v, i) => {
            runSendTextOrLinkMessage(i, split4000char, _multiForward[v], !(forwardLength === (i + 1)));

            return null;
          })
        }
        else {
          runSendTextOrLinkMessage(i, split4000char, props.forwardMessage.message, false);
        }
      }
    }
  }

  let runSendTextOrLinkMessage = (i, split4000char, forwardMessage, isForwardOnly) => {
    const messageBody = i+1 === split4000char.length ?split4000char[i] : split4000char[i];
    const urls = Helper.getUrlsFromString(messageBody);

    generateScheduledMessage(messageBody, urls);
  };

  let generateScheduledMessage = async (scheduledMessageBody, urls) => {
    setIsScheduledMessageLoading(true);
    setIsScheduledMessageEmpty(false);

    let message;
    let decryptedMessage;
    let messageData = "";
    if ((props.linkPreview.url && props.linkPreview.roomID === props.activeRoom.roomID) || urls.length > 0) {
      messageData = {
        urls: props.linkPreview.urls.length > 0 ? props.linkPreview.urls : urls, 
        url: props.linkPreview.urls.length > 0 ? props.linkPreview.urls[0] : urls[0], 
        title: props.linkPreview.title, 
        description: props.linkPreview.description, 
        image: props.linkPreview.image, 
        siteName: props.linkPreview.siteName, 
        type: props.linkPreview.type, 
      }
      message = tapCoreMessageManager.constructTapTalkMessageModel(
        scheduledMessageBody,
        props.activeRoom,
        CHAT_TYPE.TAPChatMessageTypeLink,
        messageData,
        null
      );
    }
    else {
      message = tapCoreMessageManager.constructTapTalkMessageModel(
        scheduledMessageBody,
        props.activeRoom,
        CHAT_TYPE.TAPChatMessageTypeText,
        messageData,
        null
      );
    }

    message.created = scheduledMessageTime.valueOf();
    decryptedMessage = {...message};
    decryptedMessage.body = scheduledMessageBody;
    decryptedMessage.data = messageData;
    decryptedMessage.quote.content = "";

    let _pendingScheduledMessages = props.pendingScheduledMessages.slice();
    _pendingScheduledMessages.push(decryptedMessage);
    props.setPendingScheduledMessages(_pendingScheduledMessages);
    setPendingEncryptedScheduledMessage(message);

    let updatedScheduledMessages = insertNewMessageToChatRoom(decryptedMessage, chatDataRoomMain);
    setChatDataRoomMainTemp({
      chats: reverseMessagesObject({...updatedScheduledMessages}),
      activeRoom: props.activeRoom,
      timeStamp: new Date().valueOf()
    })
    handleSendOrScheduledMessageFinished(true);
  }

  let insertNewMessageToChatRoom = (message, chatData) => {
    let updatedScheduledMessages = {};
    let currentScheduledMessagesArray = Object.keys(chatData).map((k) => {return chatData[k]});
    let isNewMessageInserted = false;
    let insertNewMessage = () => {
      isNewMessageInserted = true;
      updatedScheduledMessages = Object.assign({[message.localID] : message}, updatedScheduledMessages);
    }
    if (currentScheduledMessagesArray.length > 0) {
      for (var i in currentScheduledMessagesArray) {
        if (!isNewMessageInserted && currentScheduledMessagesArray[i].created > message.created) {
          insertNewMessage();
        }
        updatedScheduledMessages = Object.assign({[currentScheduledMessagesArray[i].localID] : currentScheduledMessagesArray[i]}, updatedScheduledMessages);
        if (!isNewMessageInserted && i >= currentScheduledMessagesArray.length - 1) {
          insertNewMessage();
        }
      }
    }
    else {
      insertNewMessage();
    }
    return updatedScheduledMessages;
  } 

  let handleSendOrScheduledMessageFinished = (clearInput = false) => {
    setIsScheduledMessageLoading(false);
    setPendingScheduledMedia(false);
    hideScheduledMessageDateTimePicker();
    if (clearInput) {
      props.clearReplyMessage(); 
      props.clearLinkPreview(); 
      props.clearForwardMessage();
      props.clearParticipantList();
      HelperChat.resetChatRoomHeightAndInputText();
    }
  }

  let onSendEditMessage = (editMessageModel, val) => {
    val = val.trim();

    let _message = {...editMessageModel.message};
    
    if ((_message.data !== "") && (typeof _message.data.caption !== "undefined")) {
        _message.data.caption = val;
    }
    else {
        _message.body = val;
    }

    if (_message.type === CHAT_TYPE.TAPChatMessageTypeText || _message.type === CHAT_TYPE.TAPChatMessageTypeLink) {
      const urls = Helper.getUrlsFromString(val);
      if ((props.linkPreview.url && props.linkPreview.roomID === props.activeRoom.roomID) || urls.length > 0) {
        // Message contains url
        if (_message.data !== "") {
          _message.data.urls = props.linkPreview.urls ? props.linkPreview.urls : urls;
          _message.data.url = props.linkPreview.urls ? props.linkPreview.urls[0] : urls[0];
          _message.data.title = props.linkPreview.title ? props.linkPreview.title : "";
          _message.data.description = props.linkPreview.description ? props.linkPreview.description : "";
          _message.data.image = props.linkPreview.image ? props.linkPreview.image : "";
          _message.data.siteName = props.linkPreview.siteName ? props.linkPreview.siteName : "";
          _message.data.type = props.linkPreview.type ? props.linkPreview.type : "";
        } 
        else {
          _message.data = {
              urls: props.linkPreview.urls ? props.linkPreview.urls : urls, 
              url: props.linkPreview.urls ? props.linkPreview.urls[0] : urls[0], 
              title: props.linkPreview.title ? props.linkPreview.title : "", 
              description: props.linkPreview.description ? props.linkPreview.description : "", 
              image: props.linkPreview.image ? props.linkPreview.image : "", 
              siteName: props.linkPreview.siteName ? props.linkPreview.siteName : "", 
              type: props.linkPreview.type ? props.linkPreview.type : "", 
          };
        }

        if (_message.type === CHAT_TYPE.TAPChatMessageTypeText) {
            // Change text message to link message
            _message.type = CHAT_TYPE.TAPChatMessageTypeLink;
        }
      }
      else {
          // Change link message to text message and remove data
          _message.type = CHAT_TYPE.TAPChatMessageTypeText;
          _message.data = "";
      }
      props.clearLinkPreview();
    }

    callEditScheduledMessageAPI(_message);
  }

  let onStartUploadFile = (message) => {
    setFileUploadProgress(generateNewFileUploadProgress(message.localID, 0, 0, message));
  }

  let onProgressUploadFile = (localID, percentage, bytes) => {
    setFileUploadProgress(generateNewFileUploadProgress(localID, percentage, bytes));
  }

  let scheduleMediaMessage = () => {
    switch (pendingScheduledMedia.type) {
      case CHAT_TYPE.TAPChatMessageTypeImage:
        scheduleImageMessage();
        break;
      case CHAT_TYPE.TAPChatMessageTypeVideo:
        scheduleVideoMessage();
        break;
      case CHAT_TYPE.TAPChatMessageTypeFile:
        scheduleFileMessage();
        break;
      default:
        break;
    }
  }

  let runningImageMessage = (file, caption, index) => {
    setPendingScheduledMedia({file: file, caption: caption, type: CHAT_TYPE.TAPChatMessageTypeImage});
  }

  let scheduleImageMessage = async () => {
    if (!pendingScheduledMedia) {
      return;
    }
    const file = pendingScheduledMedia.file;
    const caption = pendingScheduledMedia.caption;
    const scheduledTimestamp = scheduledMessageTime.valueOf();
    let obtainedMessage;

    tapCoreMessageManager.sendImageMessageWithoutEmit(file, caption, props.activeRoom, 
      {
        onStart: (message) => {
          message.created = scheduledTimestamp;
          obtainedMessage = message;
          handleMediaUploadStart(message);
        },

        onProgress: (localID, percentage, bytes) => {
          onProgressUploadFile(localID, percentage, bytes);
        },

        onSuccess: (message) => {
          handleMediaUploadSuccess(message, scheduledTimestamp);
        },

        onError: (errorCode, errorMessage) => {
          handleMediaUploadError(errorCode, errorMessage, obtainedMessage);
        }
      }
    );
  }

  let runningVideoMessage = (file, caption, index) => {
    setPendingScheduledMedia({file: file, caption: caption, type: CHAT_TYPE.TAPChatMessageTypeVideo});
  }

  let scheduleVideoMessage = async() => {
    if (!pendingScheduledMedia) {
      return;
    }
    const file = pendingScheduledMedia.file;
    const caption = pendingScheduledMedia.caption;
    const scheduledTimestamp = scheduledMessageTime.valueOf();
    let obtainedMessage;

    tapCoreMessageManager.sendVideoMessageWithoutEmit(file, caption, props.activeRoom, 
      {
        onStart: (message) => {
          message.created = scheduledTimestamp;
          obtainedMessage = message;
          handleMediaUploadStart(message);
        },

        onProgress: (localID, percentage, bytes) => {
          onProgressUploadFile(localID, percentage, bytes);
        },

        onSuccess: (message) => {
          handleMediaUploadSuccess(message, scheduledTimestamp);
        },

        onError: (errorCode, errorMessage) => {
          handleMediaUploadError(errorCode, errorMessage, obtainedMessage);
        }
      }
    );
  }

  let runningFileMessage = (file, index) => {
    setPendingScheduledMedia({file: file, caption: "", type: CHAT_TYPE.TAPChatMessageTypeFile});
  }

  let scheduleFileMessage = async () => {
    if (!pendingScheduledMedia) {
      return;
    }
    const file = pendingScheduledMedia.file;
    const scheduledTimestamp = scheduledMessageTime.valueOf();
    let obtainedMessage;

    tapCoreMessageManager.sendFileMessageWithoutEmit(file, props.activeRoom, 
      {
        onStart: (message) => {
          message.created = scheduledTimestamp;
          obtainedMessage = message;
          handleMediaUploadStart(message);
        },

        onProgress: (localID, percentage, bytes) => {
          onProgressUploadFile(localID, percentage, bytes);
        },

        onSuccess: (message) => {
          handleMediaUploadSuccess(message, scheduledTimestamp);
        },

        onError: (errorCode, errorMessage) => {
          handleMediaUploadError(errorCode, errorMessage, obtainedMessage);
        }
      }
    );
  }

  let handleMediaUploadStart = (message) => {
    onStartUploadFile(message);
    HelperChat.resetChatRoomHeightAndInputText();
    props.clearReplyMessage(); 
    props.clearForwardMessage();
    setIsScheduledMessageEmpty(false);

    // Update chat room messages
    let updatedScheduledMessages = insertNewMessageToChatRoom(message, chatDataRoomMain);
    setChatDataRoomMainTemp({
      chats: reverseMessagesObject({...updatedScheduledMessages}),
      activeRoom: props.activeRoom,
      timeStamp: new Date().valueOf()
    })
    handleSendOrScheduledMessageFinished(true);
  }

  let handleMediaUploadSuccess = (message, scheduledTimestamp) => {
    // Update message timestamp
    message.created = scheduledTimestamp;

    // Remove message from chat room
    let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
    delete currentScheduledMessages[message.localID];

    // Add updated message to chat room
    let updatedScheduledMessages = insertNewMessageToChatRoom(message, currentScheduledMessages);

    setChatDataRoomMainTemp({
      chats: reverseMessagesObject({...updatedScheduledMessages}),
      activeRoom: props.activeRoom,
      timeStamp: new Date().valueOf()
    })

    // Add encrypted message to queue
    let encryptedMessage = tapCoreMessageManager.constructTapTalkMessageModel(
      message.body,
      message.room,
      message.type,
      message.data,
      message.localID
    );
    encryptedMessage.created = scheduledTimestamp;
    let _pendingScheduledMessages = props.pendingScheduledMessages.slice();
    _pendingScheduledMessages.push(message);
    props.setPendingScheduledMessages(_pendingScheduledMessages);
    setPendingEncryptedScheduledMessage(encryptedMessage);
    // sendScheduledMessage(encryptedMessage);
  }

  let handleMediaUploadError = (errorCode, errorMessage, message) => {
    Helper.doToast(errorMessage);
    
    if (message) {
      // Remove message from chat room
      let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
      delete currentScheduledMessages[message.localID];
      setChatDataRoomMainTemp({
        chats: currentScheduledMessages,
        activeRoom: props.activeRoom,
        timeStamp: new Date().valueOf()
      })
    }
  }

  let generateBubbleThisGroupUnavailable = () => {
    return (
      <div className="room-is-unavailable">
        Sorry, this group is unavailable
      </div>
    )
  }

  let onEditMessage = async (message) => {
    HelperChat.resetChatRoomHeightAndInputText();
    props.clearForwardMessage();
    props.clearReplyMessage();
    props.clearLinkPreview();

    let _editMessage = {...props.editMessage};
    _editMessage.message = message;
    props.setEditMessage(_editMessage);

    let _message = {...message};
    if (_message.type = CHAT_TYPE.TAPChatMessageTypeLink &&
        _message.data.title &&
        _message.data.description
    ) {
      let _linkPreview = {
        urls: _message.data.urls ? _message.data.urls : [], 
        url: _message.data.urls ? _message.data.urls[0] : "", 
        title: _message.data.title ? _message.data.title : "", 
        description: _message.data.description ? _message.data.description : "", 
        image: _message.data.image ? _message.data.image : "", 
        siteName: _message.data.siteName ? _message.data.siteName : "", 
        type: _message.data.type ? _message.data.type : "", 
      };
      props.setLinkPreview(_linkPreview);
    } 
  }

  let runSetLastDragAndDropFiles = () => {
    setLastDragAndDropFiles({
      files: [],
      time: ""
    })
  }

  let toggleDropFileHere = () => {
    setShowDropFileHere(!showDropFileHere);
  }

  let hidedropFileHere = () => {
    setShowDropFileHere(false);
  }

  let checkIsFileorMedia = (files) => {
    let _files = Array.from(files);

    if(_files.length > 0) {
      let isMedia = null;
  
      _files.map((value) => {
        if(value.type.split("/")[0] === "video" || value.type.split("/")[0] === "image") {
          isMedia === null && (isMedia = true);
        }else {
          isMedia = false;
        }
  
        return null;
      })
  
      if(!isMedia) {
        runningFileMessage(_files);
      }else {
        setLastDragAndDropFiles({
          files: _files,
          time: new Date().valueOf()
        })
      }
    }
  }

  let generateViewDropFileHere = () => {
    let handleDropFile = (e) => {
      toggleDropFileHere();
      e.preventDefault();
      let files= e.dataTransfer.files;
      checkIsFileorMedia(files);
    }

    return (
      <div className={`drop-file-here-wrapper ${showDropFileHere ? "active-drop-file-here-wrapper" : ""}`}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {handleDropFile(e)}}
      >
        <div className="drop-file-here-content">
          <div className="drop-file-here-inner-content">
            <FiUpload />

            <p>
              Drop your files, image or video here
            </p>
          </div>
        </div>
      </div>
    )
  }

  let modalActionMessage = () => {
    let myAccountID = taptalk.getTaptalkActiveUser().userID;
    let message = props.activeMessage;

    let isShowCopyForward = () => {
      let show = false;

      if (message.type === CHAT_TYPE.TAPChatMessageTypeText || 
          message.type === CHAT_TYPE.TAPChatMessageTypeLocation ||
          message.type === CHAT_TYPE.TAPChatMessageTypeLink
      ) {
        show = true;
      }

      return show;
    }

    let isShowDelete = () => {
      let show = true;

      if(myAccountID !== message.user.userID) {
        show = false;
      }

      return show;
    }

    return (
      <Modal className="modal-action-message" isOpen={openModalActionMessage}>
        <ModalBody>
            <div className="action-message-wrapper-chat">
              {isShowCopyForward() &&
                <div 
                  className="action-message-content" 
                  onClick={() => {
                    props.clearActiveMessage();
                    HelperChat.copyToClipBoard(props.activeMessage.body);
                  }}
                >
                    <FiCopy />
                    <span>Copy</span>
                </div>
              }

              {isShowDelete() &&
                <div 
                  className="action-message-content" 
                  onClick={() => {
                    props.clearActiveMessage();
                    deleteScheduledMessage(props.activeMessage);
                  }}
                >
                    <FiTrash />
                    <span>Delete</span> 
                </div>
              }
            </div>

            <div className="cancel-action-message" onClick={props.clearActiveMessage}>
              <b>Cancel</b>
            </div>
        </ModalBody>
      </Modal>
    )
  }

  let countVisibleBubble = () => {
    let bubbleIn = document.querySelectorAll(".chat-room-message-out-wrapper").length;
    let bubbleOut = document.querySelectorAll(".chat-room-message-in-wrapper").length;
    
    return bubbleIn + bubbleOut;
  }

  let hideScheduledMessageDateTimePicker = () => {
    setScheduledMessageTime(new Date());
    setPendingRescheduleMessage(false);
    setIsShowScheduledMessageDateTimePicker(false);
  }

  let toggleScheduledMessageDateTimePicker = () => {
    if (isShowScheduledMessageDateTimePicker) {
      // Hide time picker
      setScheduledMessageTime(new Date());
      setIsShowScheduledMessageDateTimePicker(false);
    }
    else {
      if (pendingRescheduleMessage) {
        // Show reschedule message time picker
        setScheduledMessageTime(new Date(pendingRescheduleMessage.created));
        setIsShowScheduledMessageDateTimePicker(true);
        return;
      }
      else if (pendingScheduledMedia || document.querySelectorAll(".main-textarea-input")[0].value.trim()) {
        // Show new schedule message time picker
        let initialScheduledTime = new Date(new Date().valueOf() + 60000);
        initialScheduledTime.setSeconds(0);
        setScheduledMessageTime(initialScheduledTime);
        setIsShowScheduledMessageDateTimePicker(true);
      }
    }
  }

  let onScheduledTimePickerCalendarChanged = (date) => {
    let _scheduledMessageTime = new Date(scheduledMessageTime.valueOf());
    const selectedHour = _scheduledMessageTime.getHours();
    const selectedMinute = _scheduledMessageTime.getMinutes();
    _scheduledMessageTime = date;
    _scheduledMessageTime.setHours(selectedHour);
    _scheduledMessageTime.setMinutes(selectedMinute);
    setScheduledMessageTime(_scheduledMessageTime);
  }

  let onScheduledTimePickerHourChanged = (e) => {
    let _scheduledMessageTime = new Date(scheduledMessageTime.valueOf());
      if (e.target.value < 0) {
          e.target.value = 0;
      }
      if (e.target.value > 23) {
          e.target.value = 23;
      }
      _scheduledMessageTime.setHours(e.target.value);
      setScheduledMessageTime(_scheduledMessageTime);
      if (parseInt(e.target.value, 10) < 10) {
          e.target.value = "0" + e.target.value;
      }
      while (e.target.value.charAt(0) === "0" && e.target.value.length > 2) {
        e.target.value = e.target.value.substring(1);
      }
  }

  let onScheduledTimePickerMinuteChanged = (e) => {
    let _scheduledMessageTime = new Date(scheduledMessageTime.valueOf());
      if (e.target.value < 0) {
          e.target.value = 0;
      }
      if (e.target.value > 59) {
          e.target.value = 59;
      }
      _scheduledMessageTime.setMinutes(e.target.value);
      setScheduledMessageTime(_scheduledMessageTime);
      if (parseInt(e.target.value, 10) < 10) {
          e.target.value = "0" + e.target.value;
      }
      while (e.target.value.charAt(0) === "0" && e.target.value.length > 2) {
        e.target.value = e.target.value.substring(1);
      }
  }

  let submitScheduledMessage = () => {
    if (pendingScheduledMedia) {
      scheduleMediaMessage();
    }
    if (pendingRescheduleMessage) {
      callRescheduleMessageAPI();
    }
    else {
      const scheduledMessageBody = document.querySelectorAll(".main-textarea-input")[0].value;
      if (!scheduledMessageBody) {
        return;
      }
      onInputNewMessage(scheduledMessageBody);
    }
  }

  let sendScheduledMessage = (message) => {
    tapCoreMessageManager.createScheduledMessage(message, message.created, {
      onSuccess: (response) => {
        let pendingScheduledMessages = props.pendingScheduledMessages.slice(); 
        let pendingDecryptedMessage;
        if (pendingScheduledMessages.length > 0) {
          let _pendingScheduledMessages = pendingScheduledMessages.slice();
          _pendingScheduledMessages.forEach((pendingMessage, index) => {
            if (message.localID === pendingMessage.localID) {
              pendingDecryptedMessage = pendingMessage;
              pendingScheduledMessages.splice(index, 1);
            }
          });
          props.setPendingScheduledMessages(pendingScheduledMessages);
        }
        
        if (response && response.success) {
          // Update chat room messages
          setTimeout(() => {
            let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
            let updateMessageWithDecryptedContent = (targetMessage, decryptedMessage) => {
              targetMessage.body = decryptedMessage.body;
              targetMessage.data = decryptedMessage.data;
              targetMessage.quote.content = decryptedMessage.quote.content;
              return targetMessage;
            }
            if (pendingDecryptedMessage) {
              message = updateMessageWithDecryptedContent(message, pendingDecryptedMessage);
            }
            else if (currentScheduledMessages[message.localID]) {
              message = updateMessageWithDecryptedContent(currentScheduledMessages[message.localID]);
            }
            // Update message state
            message.messageID = response.createdItem.id;
            message.created = response.createdItem.scheduledTime;
            message.isSending = false;
            currentScheduledMessages = Object.assign(currentScheduledMessages, {[message.localID] : message});
            setChatDataRoomMainTemp({
              chats: currentScheduledMessages,//reverseMessagesObject({...currentScheduledMessages}),
              activeRoom: props.activeRoom,
              timeStamp: new Date().valueOf()
            })
          },
          (
            message.type === CHAT_TYPE.TAPChatMessageTypeImage || 
            message.type === CHAT_TYPE.TAPChatMessageTypeVideo || 
            message.type === CHAT_TYPE.TAPChatMessageTypeFile
          ) ? 2000 : 0);
        }
        else {
          // Remove message from chat room
          let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
          delete currentScheduledMessages[message.localID];
          setChatDataRoomMainTemp({
            chats: currentScheduledMessages,
            activeRoom: props.activeRoom,
            timeStamp: new Date().valueOf()
          })
        }
      },
      onError: (errCode, errMes) => {
        console.log(errMes)
      }
    });
  }

  let sendScheduledMessageNow = async (message) => {
    tapCoreMessageManager.sendScheduledMessageNow([message.messageID], props.activeRoom.roomID, {
      onSuccess: (response) => {
        // Remove message from chat room
        let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
        delete currentScheduledMessages[message.localID];
        setChatDataRoomMainTemp({
          chats: currentScheduledMessages,
          activeRoom: props.activeRoom,
          timeStamp: new Date().valueOf()
        })
      },
      onError: (errCode, errMes) => {
        console.log(errMes)
      }
    });
  }

  let rescheduleMessage = (message) => {
    setPendingRescheduleMessage({...message});
  }

  let callRescheduleMessageAPI = async () => {
    let _pendingRescheduleMessage = {...pendingRescheduleMessage};
    let _rescheduledMessageTimestamp = scheduledMessageTime.valueOf();
    hideScheduledMessageDateTimePicker();
    if (_rescheduledMessageTimestamp < new Date().valueOf()) {
      sendScheduledMessageNow(_pendingRescheduleMessage);
    }
    else {
      tapCoreMessageManager.editScheduledMessageTime(_pendingRescheduleMessage.messageID, _rescheduledMessageTimestamp, {
        onSuccess: (response) => {
          // Remove message from chat room
          let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
          delete currentScheduledMessages[_pendingRescheduleMessage.localID];

          // Update message state
          _pendingRescheduleMessage.created = _rescheduledMessageTimestamp;

          // Add updated message to chat room
          let updatedScheduledMessages = insertNewMessageToChatRoom(_pendingRescheduleMessage, currentScheduledMessages);

          setChatDataRoomMainTemp({
            chats: reverseMessagesObject({...updatedScheduledMessages}),
            activeRoom: props.activeRoom,
            timeStamp: new Date().valueOf()
          })
        },
        onError: (errCode, errMes) => {
          console.log(errMes)
        }
      });
    }
  }

  let editScheduledMessage = (message) => {
    onEditMessage(message);
  }

  let callEditScheduledMessageAPI = async (message) => {
    let encryptedMessage;
    let messageData = "";
    const urls = Helper.getUrlsFromString(message.body);
    if ((props.linkPreview.url && props.linkPreview.roomID === props.activeRoom.roomID) || urls.length > 0) {
      messageData = {
        urls: props.linkPreview.urls.length > 0 ? props.linkPreview.urls : urls, 
        url: props.linkPreview.urls.length > 0 ? props.linkPreview.urls[0] : urls[0], 
        title: props.linkPreview.title ? props.linkPreview.title : "", 
        description: props.linkPreview.description ? props.linkPreview.description : "", 
        image: props.linkPreview.image ? props.linkPreview.image : "", 
        siteName: props.linkPreview.siteName ? props.linkPreview.siteName : "", 
        type: props.linkPreview.type ? props.linkPreview.type : "", 
      }
      encryptedMessage = tapCoreMessageManager.constructTapTalkMessageModel(
        message.body,
        props.activeRoom,
        CHAT_TYPE.TAPChatMessageTypeLink,
        messageData,
        message.localID
      );
    }
    else {
      encryptedMessage = tapCoreMessageManager.constructTapTalkMessageModel(
        message.body,
        props.activeRoom,
        CHAT_TYPE.TAPChatMessageTypeText,
        messageData,
        message.localID
      );
    }
    encryptedMessage.created = message.created;
    tapCoreMessageManager.editScheduledMessageContent(message.messageID, encryptedMessage, {
      onSuccess: (response) => {
        // Update chat room messages
        let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
        currentScheduledMessages = Object.assign(currentScheduledMessages, {[message.localID] : message});
        setChatDataRoomMainTemp({
          chats: currentScheduledMessages,//reverseMessagesObject({...currentScheduledMessages}),
          activeRoom: props.activeRoom,
          timeStamp: new Date().valueOf()
        })
      },
      onError: (errCode, errMes) => {
        console.log(errMes)
      }
    });
  }

  let deleteScheduledMessage = async (message) => {
    tapCoreMessageManager.deleteScheduledMessage([message.messageID], props.activeRoom.roomID, {
      onSuccess: (response) => {
        // Remove message from chat room
        let currentScheduledMessages = {...chatDataRoomMainTemp.chats};
        delete currentScheduledMessages[message.localID];
        setChatDataRoomMainTemp({
          chats: currentScheduledMessages,
          activeRoom: props.activeRoom,
          timeStamp: new Date().valueOf()
        })
      },
      onError: (errCode, errMes) => {
        console.log(errMes)
      }
    });
  }

  return (
    <div 
    className={`wrap-all-chatroom`}
      onDragEnter={() => {toggleDropFileHere()}}
      onDragLeave={() => {toggleDropFileHere()}}
      onPaste={(e) => {
        checkIsFileorMedia(e.clipboardData.files);
      }}
    >
      {generateViewDropFileHere()}

      {props.activeMessage &&
        <MediaQuery maxDeviceWidth={767}>
          {modalActionMessage()}
        </MediaQuery>
      }

      <div className={`chat-room-main-wrapper ${isNoLongerParticipantOrUserDelete ? "hide-reply-forward" : ""}`}
           style={{maxHeight: `calc(100% - ${isNoLongerParticipantOrUserDelete ? '129' : '57'}px)`}}
      > 
          {isShowLoadingBlocking &&
            <div className="chat-room-main-wrapper-loading-blocking">
                  <div className="lds-ring">
                    <div /><div /><div /><div />
                  </div>
            </div>
          }

          {
            isScheduledMessageEmpty &&
            <div className="no-scheduled-message-container">
                <img src={noScheduledMessage} alt="" />
                <p className="no-scheduled-message-desc">No scheduled message here yet</p>
            </div>
          } 

          {roomIsDeleted && generateBubbleThisGroupUnavailable()}
              
          <div className="chat-room-main-content" 
                ref={chatRoomMesageListRef}
                style={{paddingTop: chatDataRoomMain && (countVisibleBubble() === 1) ? '170px' : '0'}}
          >
            {isLoadingChat &&
              <div className="loading-message-wrapper">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            }

            {chatDataRoomMain !== null &&
              !roomIsDeleted &&
                Object.keys(chatDataRoomMain).map((value, index) => {
                  if(!chatDataRoomMain[value].isHidden) {
                    return (
                      generateMessageBubble(chatDataRoomMain[value], index)
                    )
                  }

                  return null;
                })
            }
          </div>
      </div>
      
      {(!roomIsDeleted && !isNoLongerParticipantOrUserDelete) &&
        (!props.editMessage.message ?
          <ChatRoomInputMessage
            onInputNewMessageProps={onInputNewMessage}
            runningFileMessageProps={runningFileMessage}
            runningImageMessageProps={runningImageMessage}
            runningVideoMessageProps={runningVideoMessage}
            parentProps={props.parentProps}
            lastDragAndDropFilesProps={lastDragAndDropFiles}
            setLastDragAndDropFilesProps={runSetLastDragAndDropFiles}
            hidedropFileHereProps={hidedropFileHere}
            toggleScheduledMessageDateTimePicker={toggleScheduledMessageDateTimePicker}
            scheduledMessageTime={scheduledMessageTime}
            showScheduledMessagesChatRoom={true}
          />
          :
          <ChatRoomInputEditMessage
            onInputNewMessageProps={onInputNewMessage}
            parentProps={props.parentProps}
            onSendEditMessage={onSendEditMessage}
          />
        )
      }

      {(isNoLongerParticipantOrUserDelete && props.activeRoom.deleted === 0) &&
        <div className="no-longer-participant">
          <p>
            <img src={iconClock} alt="" />
            <b>This is a chat History</b>
          </p>

          <p>
            You are no longer a participant in this group
          </p>
        </div>
      }

      {(isNoLongerParticipantOrUserDelete && props.activeRoom.deleted !== 0) &&
        <div className="no-longer-participant">
          <p>
            <img src={iconClock} alt="" />
            <b>This is a chat History</b>
          </p>

          <p>
            This user is no longer available
          </p>
        </div>
      }

      {
        isShowScheduledMessageDateTimePicker &&
        <ScheduledMessageTimePicker
          isOpen={isShowScheduledMessageDateTimePicker}
          toggle={toggleScheduledMessageDateTimePicker}
          date={scheduledMessageTime}
          onCalendarChanged={onScheduledTimePickerCalendarChanged}
          onHourChanged={onScheduledTimePickerHourChanged}
          onMinuteChanged={onScheduledTimePickerMinuteChanged}
          submitScheduledMessage={submitScheduledMessage}
          isLoading={isScheduledMessageLoading}
          disabledDays={{
            before: new Date(),
          }}
          {...props}
        />
      }
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  replyMessage: state.replyMessage,
  forwardMessage: state.forwardMessage,
  linkPreview: state.linkPreview,
  editMessage: state.editMessage,
  goToChatBubble: state.goToChatBubble,
  activeMessage: state.activeMessage,
  draftMessage: state.draftMessage,
  allStarredMessages: state.allStarredMessages,
  lastClickStarMessage: state.lastClickStarMessage,
  multiForward: state.multiForward,
  userClick: state.userClick,
  allPinnedMessages: state.allPinnedMessages,
  pinnedRoomID: state.pinnedRoomID
});

const mapDispatchToProps = {
  setActiveRoom,
  setReplyMessage,
  setForwardMessage,
  setLinkPreview,
  clearReplyMessage,
  clearForwardMessage,
  clearLinkPreview,
  clearGoToChatBubble,
  clearActiveMessage,
  setDraftMessage,
  setAllStarredMessages,
  setMultiForward,
  setEditMessage,
  clearEditMessage,
  setAllPinnedMessages,
  clearParticipantList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomScheduledMessage);
