import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomMessageOutImage.scss';
import { IoIosClose } from "react-icons/io";
import Helper from '../../../../helper/Helper';
import HelperChat from '../../../../helper/HelperChat';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { taptalk, tapCoreChatRoomManager, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import { connect } from 'react-redux';

const LONG_PRESS = 700;

var ChatRoomMessageOutImage = (props) => {
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [imageSrc, setImageSrc] = useState('');
    let [isImageExistInDB, setIsImageExistInDB] = useState(false);
    let [onDownloadImageProgress, setOnImageDownloadProgress] = useState(false);
    let [imageFromUrl, setImageFromUrl] = useState(false);
    let [isShowModalImage, setIsShowModalImage] = useState(false);
    let [lastClickBubble, setLastClickBubble] = useState(false);

    let {
        activeRoom,
        singleChatDataProps,
        activeUserID,
        onForwardMessage,
        onEditMessage,
        onReplyMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        mentionList,
        onClickToStarMessage,
        onMessageInfo,
        multiForward,
        noForward,
        isScheduledMessage,
        sendScheduledMessageNow,
        rescheduleMessage,
        editScheduledMessage,
        deleteScheduledMessage
    } = props;

    let delay;

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted && !isShowModalImage) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }

    useEffect(() => {
        let isUnmounted = false;
        let fetchFromDB = () => {
            tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
                if(data) {
                    if(!isUnmounted) {
                        setImageSrc(data.file);
                        setIsImageExistInDB(true);
                    }
                }else {
                    if(!isUnmounted) {
                        setImageSrc(singleChatDataProps.data.thumbnail);
                        setIsImageExistInDB(false);
                    }
                }
            })
        } 

        if(singleChatDataProps.data.url) {
            setImageFromUrl(true);
            setImageSrc(singleChatDataProps.data.url);
        }else if(singleChatDataProps.data.fileURL) {
            if(singleChatDataProps.data.fileURL !== "") {
                setImageFromUrl(true);
                setImageSrc(singleChatDataProps.data.fileURL);
            }else {
                setImageFromUrl(false);
                fetchFromDB();
            }
        }else {
            fetchFromDB();
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps])

    let downloadFile = () => {
        setOnImageDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnImageDownloadProgress(false);
                setImageSrc(data.base64);
                setIsImageExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
            },

            onError: (errorCode, errorMessage) => {
                setOnImageDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    // let getImageBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setImageSrc(data.file);
    //             setIsImageExistInDB(true);
    //         }else {
    //             setImageSrc(singleChatDataProps.data.thumbnail);
    //             setIsImageExistInDB(false);
    //         }
    //     })
    // }

    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }

    let toggleModalImage = () => {
        if(isImageExistInDB || imageFromUrl) {
            setIsShowModalImage(!isShowModalImage);
        }
    }

    let generateModalImage = () => {
        let zoomImage = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '200%';
            var offsetX = e.nativeEvent.offsetX;
            var offsetY = e.nativeEvent.offsetY;
            
            var x = offsetX/zoomer.offsetWidth*100
            var y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        }

        let zoomImageOut = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '0%';
        }
        
        return (
            <div>
              <Modal isOpen={isShowModalImage} className={'modal-image'}>
                <ModalBody>
                    <div className="header-modal-preview">
                        <div className="message-info-wrapper">
                            {singleChatDataProps.user.imageURL.thumbnail === "" ?
                                <div className="user-avatar-name" 
                                    style={{background: taptalk.getRandomColor(singleChatDataProps.user.fullname, singleChatDataProps.room.type === 2)}}
                                >
                                    <b>{Helper.renderUserAvatarWord(singleChatDataProps.user.fullname, singleChatDataProps.room.type === 2)}</b>
                                </div>
                                :
                                <img className="user-avatar-image" src={singleChatDataProps.user.imageURL.thumbnail} alt="" />
                            }
                            <p className="fullname-text">
                                <b>{singleChatDataProps.user.fullname}</b>
                            </p>

                            <p className="timestamp-text">
                                <b>Sent <span className="centered-dot" /> {HelperChat.getDateMonthYear(singleChatDataProps.created)} {HelperChat.getHourMinute(singleChatDataProps.created)}</b>
                            </p>
                        </div>

                        <div className="modal-preview-action-button-wrapper">
                            <a className="modal-preview-action-button" href={isImageExistInDB ? `data:image/png;base64, ${imageSrc}` : imageSrc} download={`taptalk_image_${new Date().valueOf()}`}>
                                <FiDownload />
                                <b>Download</b>
                            </a>
                        </div>

                        <div className="close-modal-preview-wrapper">
                            <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />
                        </div>
                    </div>
                    
                    <div className="image-wrapper">
                        <figure 
                            className="zoom" 
                            style={{"backgroundImage": `url("${isImageExistInDB ? `data:image/png;base64, ${imageSrc}` : imageSrc}")`}} 
                            onMouseMove={(e) => zoomImage(e, imageSrc)}
                            onMouseLeave={(e) => zoomImageOut(e)}
                        >
                            <img 
                                src={isImageExistInDB ? `data:image/png;base64, ${imageSrc}` : imageSrc} 
                                alt="" 
                                className="image-preview-val"
                            />
                        </figure>
                    </div>
                </ModalBody>
              </Modal>
            </div>
        );
    }

    //   let renderChatStatus = (message) => {
    //     let messageStatus;
        
    //     if(message.isSending) {
    //         messageStatus = "sending";
    //     }

    //     if(!message.isSending && message.isDelivered) {
    //         messageStatus = "sent";
    //     }

    //     if(!message.isSending && message.isDelivered && !message.isRead) {
    //         messageStatus = "receive";
    //     }

    //     if(message.isRead) {
    //         messageStatus = "read";
    //     }

    //     if(!message.isDelivered) {
    //         messageStatus = "not delivered";
    //     }
        
    //     switch(messageStatus) {
    //         case "sending":
    //             return AirplaneDark;
    //         case "sent":
    //             return CheckMarkDark;
    //         case "receive":
    //             return CheckMarkDoubleDark;
    //         case "read":
    //             return CheckMarkDoubleWhite;
    //         case "not delivered":
    //             return CheckMarkDark;
    //     }
    //   }

  return (
    <div 
        className="chat-room-message-image-out-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
        onClick={() => { 
            if(onClickToStarMessage) {
                onClickToStarMessage(singleChatDataProps.localID);
            }

            if(activeRoom.type === 2) {
                setLastClickBubble(new Date().valueOf());
            }
        }}    
    >
        {(isImageExistInDB || imageFromUrl) && generateModalImage()}

        {
            (
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
            <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
        }
        
        <div className={`message-out-bubble ${props.status !== 'uploaded' ? 'not-sent-message-bubble' : ''}`}
            //  style={
            //     Helper.isReplyMessage(singleChatDataProps) ? 
            //         (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
            //             {paddingTop: "60px"} 
            //             :
            //             {paddingTop: "80px"}
            //         )
            //         : 
            //         {}
            // }
        >
            {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                <div className="forwarded-message">
                    <b>Forwarded</b>
                    
                    <p>
                        From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                    </p>
                </div>
            }
            {/* {Helper.isReplyMessage(singleChatDataProps) &&
                <div className="reply-message reply-file">
                    {singleChatDataProps.quote.fileType !== "" &&  
                        <div className="reply-file-thumbnail">
                            {singleChatDataProps.quote.fileType === "file" ? 
                                <MdInsertDriveFile />
                                :
                                <img src={''} alt="" />
                            }
                        </div>
                    }

                    <div className="reply-text-wrapper">
                        <p className="reply-from">
                            <b> 
                                {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                    "You"
                                    :
                                    singleChatDataProps.replyTo.fullname
                                }
                            </b>
                        </p>
                        <p className="reply-text">{singleChatDataProps.quote.content}</p>
                    </div>
                </div>
            } */}

            {/* ${singleChatDataProps.data.caption !== "" && Helper.isReplyMessage(singleChatDataProps) ? "with-reply-caption" : ""}
            ${singleChatDataProps.data.caption === "" && Helper.isReplyMessage(singleChatDataProps) ? "with-reply-no-caption" : ""}
            ${singleChatDataProps.data.caption === "" ? "bubble-wrapper-without-caption" : ""} */}
            <div className={`
                bubble-image-wrapper 
                ${singleChatDataProps.data.caption !== "" ? "bubble-wrapper-with-caption" : ""}
            `}>
                {Helper.isReplyMessage(singleChatDataProps) &&
                    <ChatRoomReplyOutMessage 
                        message={singleChatDataProps}
                        activeUserID={activeUserID}
                    />
                }

                {imageFromUrl ?
                    <React.Fragment>
                        <img src={imageSrc} className={`image-val image-from-url`} alt="" onClick={() => {toggleModalImage()}} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {isImageExistInDB ? 
                            <React.Fragment>
                                <img src={'data:image/png;base64, '+imageSrc} alt="" className="image-val main-image-message" onClick={() => {toggleModalImage()}} />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <img src={'data:image/png;base64, '+imageSrc} alt="" className="thumbnail-image-message" />
                            </React.Fragment>
                        }
                        
                        {((!isImageExistInDB && !onDownloadImageProgress) && singleChatDataProps.percentageUpload === undefined && !imageFromUrl) &&
                            <div className="icon-status-wrapper">
                                <FiDownload onClick={() => downloadFile()} />
                            </div>
                        }
                            
                        {onDownloadImageProgress && 
                            <div className="icon-status-wrapper">
                                <div className="onprocess-wrapper">
                                    <CircularProgressbar value={percentageDownload} />
                                    {/* <MdClose onClick={() => console.log('cancel download')} /> */}
                                    <FiDownload />
                                </div>
                            </div>
                        }

                        {singleChatDataProps.bytesUpload !== undefined && 
                            <div className="icon-status-wrapper">
                                <div className="onprocess-wrapper">
                                    <CircularProgressbar value={singleChatDataProps.percentageUpload} />
                                    {/* <MdClose onClick={() => console.log('cancel upload')} /> */}
                                    <FiUpload />
                                </div>
                            </div>
                        }
                    </React.Fragment>
                }
            </div>

            {singleChatDataProps.data.caption !== '' && 
                <p
                    className="caption-text" 
                    dangerouslySetInnerHTML={{
                        __html: mentionList ?
                            HelperChat.generateMentionSpan(HelperChat.lineBreakChatRoom(singleChatDataProps.data.caption), mentionList)
                            :
                            HelperChat.lineBreakChatRoom(singleChatDataProps.data.caption)
                    }} 
                />
            }

            {/* <div className="dark-cover">
                {(!isImageExistInDB && !onDownloadImageProgress) &&
                    <div className="icon-status-wrapper">
                        <MdFileDownload onClick={() => downloadFile()} />
                    </div>
                }
                    
                {onDownloadImageProgress && 
                    <div className="icon-status-wrapper">
                        <div className="onprocess-wrapper">
                            <CircularProgressbar value={percentageDownload} />
                            <MdClose onClick={() => console.log('cancel')} />
                        </div>
                    </div>
                }
            </div> */}

            <ChatRoomMessageInfoComp 
                message={singleChatDataProps}
                className={singleChatDataProps.data.caption === '' ? "message-info-dark message-info-in-media" : "message-info-in-media"}
                isOut
                isWhiteIcon
                lastClickBubble={lastClickBubble}
            />

            <ChatRoomMessageActionComp
                 buttonList={
                    isScheduledMessage && !singleChatDataProps.isSending ?
                    [
                        {
                            val: "send",
                            text: "Send Now",
                            action: () => sendScheduledMessageNow(singleChatDataProps),
                        },
                        {
                            val: "reschedule",
                            text: "Reschedule",
                            action: () => rescheduleMessage(singleChatDataProps),
                        },
                        {
                            val: "edit",
                            text: "Edit",
                            action: () => editScheduledMessage(singleChatDataProps),
                        },
                        {
                            val: "delete",
                            text: "Delete",
                            action: () => deleteScheduledMessage(singleChatDataProps),
                        },
                    ]
                    :    
                    [
                        {
                            val: "reply",
                            text: "Reply",
                            action: () => onReplyMessage(singleChatDataProps),
                        },
                        {
                            val: "forward",
                            text: "Forward",
                            action: () => onForwardMessage(singleChatDataProps),
                        },
                        {
                            val: "delete",
                            text: "Delete Message",
                            action: () => deleteMessageAction(singleChatDataProps),
                        },
                        {
                            val: "star",
                            text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                            action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                        },
                        {
                            val: "edit",
                            text: "Edit",
                            action: () => onEditMessage(singleChatDataProps),
                            message: singleChatDataProps
                        },
                        {
                            val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                            text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                            action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                        },
                        {
                            val: "messageInfo",
                            text: "Message Info",
                            action: () => onMessageInfo(singleChatDataProps)
                        }
                    ]
                }
            />
        </div>

        {props.status === 'fail' &&
            <React.Fragment>
                <br />
                <b className="failed-sending-file-warning">Failed to send tap to retry</b>
            </React.Fragment>
        }

        {(
            activeRoom &&
            !singleChatDataProps.isDeleted &&
            multiForward[activeRoom.roomID] &&
            Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
        ) &&
            <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-out">
                <input 
                    type="checkbox" 
                    id={`message-${singleChatDataProps.messageID}`} 
                    checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                />
                <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
            </div>
        }
    </div>
  );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutImage);