import React, { useState, useEffect } from 'react';
import './Login.scss';
import { Modal, ModalBody } from 'reactstrap';
import LogoTaptalk from '../../assets/img/logo-taptalk.svg';
import FlagGlobe from '../../assets/img/flag-globe.svg';
import IconSMS from '../../assets/img/icon-sms-circle.svg';
import IconRefresh from '../../assets/img/icon-refresh.svg';
import WhatsAppIcon from '../../assets/img/whatsappIcon.svg'; 
import MobileIcon from '../../assets/img/icon-mobile-orange.svg'; 
import ChatService from '../../services/ChatService';
import axios from 'axios';
import Helper from "../../helper/Helper";
import { connect } from 'react-redux';
import { taptalk } from '@taptalk.io/web-sdk';
import PhoneCodeModal from '../../component/reuseableComponent/phoneCodeModal/PhoneCodeModal';
import { FiAlertCircle, FiArrowLeft, FiArrowRight, FiCheck, FiChevronDown, FiRotateCcw, FiWifiOff, FiX } from 'react-icons/fi';

const STATE = {
  INPUT_PHONE: 0,
  WA_VERIFICATION: 1,
  INPUT_OTP: 2,
  VERIFICATION_LOADING: 3,
  VERIFICATION_SUCCESS: 4,
  VERIFICATION_ERROR: 5,
}

const OTP_CHANNEL = {
  sms: "sms",
  whatsapp: "whatsapp"
}

const ERROR_MESSAGE_NUMBER = {
  required: "This field is required",
  length: "The combined country code and phone number must be between 7-15 digits",
  isNumeric: "Only numerics are allowed",
  isStartZero: "Phone number cannot start from 0",
  incorrect: "Please input your correct phone number"
}

const redirectSecondsStart = 3;

var Login = (props) => {

  let authenticationHeader = {
    "App-Key": btoa(`${props.appData.data.appID}:${props.appData.data.appSecret}`),
    "Authorization": "",
    "Device-Model": navigator.appName,
    "Device-Platform": "web",
    // "Server-Key": btoa(`${props.appData.data.serverID}:${props.appData.data.serverSecret}`)
  };

  let [valPhone, setValPhone] = useState("");
  let [beautifiedPhone, setBeautifiedPhone] = useState("");
  let [isLoadingRequestOTP, setIsLoadingRequestOTP] = useState(false);
  let [isLoadingRequestWhatsApp, setIsLoadingRequestWhatsApp] = useState(false);
  let [otpID, setOtpID] = useState("");
  let [otpKey, setOtpKey] = useState("");
  let [valOtpCode, setValOtpCode] = useState("");
  let [loadingVerifyOTP, setLoadingVerifyOTP] = useState(false);

  let [waitRequestOtp, setWaitRequestOtp] = useState(false);
  let [isOTPFalse, setIsOTPFalse] = useState(false);
  let [otpChannel, setOtpChannel] = useState(OTP_CHANNEL.sms);
  let [country, setCountry] = useState(false);
  let [countryList, setCountryList] = useState(false);
  let [countryListArray, setCountryListArray] = useState(false);
  let [loadingCountry, setLoadingCountry] = useState(false);
  let [isOpenPhoneCodeModal, setIsOpenPhoneCodeModal] = useState(false);

  let [phoneErrorInfoText, setPhoneErrorInfoText] = useState("");
  let [activeState, setActiveState] = useState(STATE.INPUT_PHONE);
  let [whatsAppVerification, setWhatsAppVerification] = useState({
    id: "",
    waLink: "",
    waMessage: "",
    qrCode: ""
  });
  let [isShowingQR, setIsShowingQR] = useState(false);
  let [isCheckWhatsAppVerificationPending, setIsCheckWhatsAppVerificationPending] = useState(false);
  let [checkVerificationAttempts, setCheckVerificationAttempts] = useState(0);
  let [nextRequestWA, setNextRequestWA] = useState(new Map());
  let [nextRequestOTP, setNextRequestOTP] = useState(new Map());
  let [countdownStringOTP, setCountdownStringOTP] = useState("");
  let [redirectSeconds, setRedirectSeconds] = useState(redirectSecondsStart + 1);
  let [isOnline, setIsOnline] = useState(false);
  
  let togglePhoneCodeModal = () => {
    if(!loadingCountry) {
      setIsOpenPhoneCodeModal(!isOpenPhoneCodeModal);
    }
  }

  let onSelectPhoneCode = (val) => {
    setValPhone("");
    setCountry(val);
  }

  useEffect(() => {
    if (taptalk.isAuthenticated()) {
      window.location.href = "/chat";
    }
    else {
      getCountry();
    }

    const isOnline = window.navigator.onLine;
    setIsOnline(isOnline);
    window.addEventListener('offline', function (event) {
      setIsOnline(false);
    });
    window.addEventListener('online', function (event) {
      setIsOnline(true);
    });
  }, []);

  let getCountry = () => {
    setLoadingCountry(true);

    ChatService.postGetCountry((response) => {
      let newCountryList = {};

      response.dataResult.data.countries.map((value) => {
          let firstWord = value.commonName[0];

          if(newCountryList[firstWord]) {
            newCountryList[firstWord].push(value);
          }else {
            newCountryList[firstWord] = [value];
          }

          setCountryListArray(response.dataResult.data.countries);
          return null;
      })

      setCountryList(newCountryList);
    })
  }

  useEffect(() => {
    if (countryList) {
      setLoadingCountry(false);
      setCountry({
        callingCode: "62",
        commonName: "Indonesia",
        currencyCode: "IDR",
        flagIconURL: "https://storage.googleapis.com/9a3048-taptalk-prd-public/static/flags/ID@2x.png",
        id: 1,
        isEnabled: true,
        isHidden: false,
        iso2Code: "ID",
        iso3Code: "IDN",
        officialName: "Republic of Indonesia"
      })
      setValPhone("");
    }
  }, [countryList]);

  useEffect(() => {
    setBeautifiedPhone(Helper.phoneBeautify(valPhone));
    if (phoneErrorInfoText) {
      checkPhoneError();
    }
  }, [valPhone])

  let onChangePhoneNumber = (e) => {
    const phone = e.target.value.replace(/[^\d]/g,'');
    setValPhone(phone);
  }

  let checkPhoneError = () => {
    let errorMessage = "";
    let phoneLength = (country.callingCode + valPhone.replaceAll(" ", "")).length;
    
    if (!country.callingCode ||
        !valPhone ||
        country.callingCode.length <= 0 ||
        valPhone.replaceAll(" ", "").length <= 0
    ) {
      errorMessage = ERROR_MESSAGE_NUMBER.required;
    }
    else if (valPhone[0] === "0") {
      errorMessage = ERROR_MESSAGE_NUMBER.isStartZero;
    }
    else if (phoneLength < 7 || phoneLength > 15) {
      errorMessage = ERROR_MESSAGE_NUMBER.length;
    }
    else if (!/^[0-9]*$/.test(valPhone.replaceAll(" ", ""))) {
      errorMessage = ERROR_MESSAGE_NUMBER.isNumeric;
    }
    setPhoneErrorInfoText(errorMessage);
    return errorMessage;
  }

  let checkAndShowNetworkError = () => {
    if (!isOnline) {
      Helper.doToast("Please check your network", "fail", <FiWifiOff />);
      return true;
    }
    return false;
  }

  let requestOTP = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (checkPhoneError() || isLoadingRequestOTP || isLoadingRequestWhatsApp) {
      return;
    }
    
    const phone = valPhone.replaceAll(" ", "");
    if (nextRequestOTP.has(phone)) {
      const timeLeft = nextRequestOTP.get(phone) - Date.now();
      if (timeLeft > 0) {
        setPhoneErrorInfoText(`Please wait ${Math.floor(timeLeft / 1000)} seconds before requesting OTP`);
        return;
      }
    }

    setIsOTPFalse(false);
    setIsLoadingRequestOTP(true);
		let url = `${props.appData.data.baseApiUrl}/v1/client/login/request_otp/v1_6`;

		let data = {
      countryID: country.id,
			method: 'phone',
			phone: phone
    };
    
    axios.post(url, data, {headers: authenticationHeader})
      .then(function (response) {
        setIsLoadingRequestOTP(false);
        let _response = response.data;

        if (_response.error.code === "") {
          if (_response.data.success) {
            setActiveState(STATE.INPUT_OTP);
            setOtpID(_response.data.otpID);
            setOtpKey(_response.data.otpKey);
            setOtpChannel(_response.data.channel);
            const nextRequest = new Map(nextRequestOTP);
            const nextRequestTime = Date.now() + (_response.data.nextRequestSeconds * 1000);
            nextRequest.set(phone, nextRequestTime);
            setNextRequestOTP(nextRequest);
          }
          else {
            setPhoneErrorInfoText(_response.data.message);
          }
        }
        else {
          if (!checkAndShowNetworkError()) {
            setPhoneErrorInfoText(ERROR_MESSAGE_NUMBER.incorrect);
          }
          console.log(_response.error.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let requestWhatsAppVerification = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (checkPhoneError() || isLoadingRequestOTP || isLoadingRequestWhatsApp) {
      return;
    }
    
    const phone = valPhone.replaceAll(" ", "");
    if (nextRequestWA.has(phone)) {
      const timeLeft = nextRequestWA.get(phone) - Date.now();
      if (timeLeft > 0) {
        setPhoneErrorInfoText(`Please wait ${Math.floor(timeLeft / 1000)} seconds before requesting verification`);
        return;
      }
    }

    setIsLoadingRequestWhatsApp(true);
		let url = `${props.appData.data.baseApiUrl}/v1/client/login/request_whatsapp_verification`;

		let data = {
      countryID: country.id,
			phone: phone,
      languageCode: country.id === 1 ? "id" : "en"
    };
    
    axios.post(url, data, {headers: authenticationHeader})
      .then(function (response) {
        let _response = response.data;

        if (_response.error.code === "") {
          if (_response.data.success) {
            setWhatsAppVerification({
              id: _response.data.verification.id,
              waLink: _response.data.verification.waLink,
              waMessage: _response.data.verification.waMessage,
              qrCode: _response.data.verification.qrCode
            });
            const nextRequest = new Map(nextRequestOTP);
            const nextRequestTime = Date.now() + (_response.data.nextRequestSeconds * 1000);
            nextRequest.set(phone, nextRequestTime);
            setNextRequestWA(nextRequest);
            showWhatsAppVerification();
          }
          else {
            setPhoneErrorInfoText(
              _response.data.message ?
              _response.data.message :
              _response.data.whatsAppFailureReason ?
              _response.data.whatsAppFailureReason :
              "An error occured. Please try again later"
            );
          }
        }
        else {
          if (!checkAndShowNetworkError()) {
            setPhoneErrorInfoText(ERROR_MESSAGE_NUMBER.incorrect);
          }
          console.log(_response.error.message);
        }
        setIsLoadingRequestWhatsApp(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (valOtpCode.length >= 6) {
      verifyOTP();
    }
  }, [valOtpCode]);
  
  let onChangeOtpNumber = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setIsOTPFalse(false);
      setValOtpCode(e.target.value);

      if (e.target.value.length >= 6) {
        e.target.setSelectionRange(0, 0);
        e.target.blur();
      }
    }
  }

	let verifyOTP = (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoadingVerifyOTP(true);

		let url = `${props.appData.data.baseApiUrl}/v1/client/login/verify_otp`;
		let data = {
			otpID: otpID,
			otpKey: otpKey,
			otpCode: valOtpCode
		};

    axios.post(url, data, {headers: authenticationHeader})
      .then(function (response) {
        setLoadingVerifyOTP(false);
        let _response = response.data;
        if (_response.error.code === "") {
          setIsOTPFalse(false);
          onVerificationSuccess(_response.data);
        }
        else {
          setValOtpCode("");
          setIsOTPFalse(true);
          console.log(_response.error.message);
        }
      })
      .catch(function (error) {
        if (!checkAndShowNetworkError()) {
          setValOtpCode("");
          setIsOTPFalse(true);
        }
        console.log(error);
      });
  }

  let requestAccessToken = (token) => {
    let appData = props.appData.data;
    let appID = appData.appID;
    let appSecret = appData.appSecret;
    // let serverID = appData.serverID;
    // let serverSecret = appData.serverSecret;
    let baseApiUrl = appData.baseApiUrl;

    taptalk.init(appID, appSecret, baseApiUrl);

    taptalk.authenticateWithAuthTicket(token, false, {
      onSuccess: (successMessage) => {
        setRedirectSeconds(redirectSecondsStart);
        setActiveState(STATE.VERIFICATION_SUCCESS);
      },
      onError: (errorCode, errorMessage) => {
        setActiveState(STATE.VERIFICATION_ERROR);
        console.log(errorCode, errorMessage);
      }
    })
  }
  
  let requestAgainAction = () => {
    setIsOTPFalse(false);
    setWaitRequestOtp(true);
    requestOTP();
  };

  useEffect(() => {
    if (activeState != STATE.INPUT_OTP) {
      return;
    }
    if (!countdownStringOTP) {
      setWaitRequestOtp(true);
    }
    setTimeout(() => {
      const phone = valPhone.replaceAll(" ", "");
      if (nextRequestOTP.has(phone)) {
        const timeLeft = nextRequestOTP.get(phone) - Date.now();
        if (timeLeft > 0) {
          const minuteLeft = Math.floor(timeLeft / 60000);
          const secondLeft = Math.floor((timeLeft / 1000) % 60);
          setCountdownStringOTP(`Wait ${minuteLeft}:${secondLeft >= 10 ? secondLeft : `0${secondLeft}`}`);
          setWaitRequestOtp(true);
        }
        else {
          nextRequestOTP.delete(phone);
          setWaitRequestOtp(false);
          setCountdownStringOTP("");
        }
      }
      else {
        nextRequestOTP.delete(phone);
        setWaitRequestOtp(false);
        setCountdownStringOTP("");
      }
    }, 1000);
  }, [nextRequestOTP, countdownStringOTP]);

  let toggleLoadingModal = () => setLoadingVerifyOTP(!loadingVerifyOTP);

  let generateLoadingModal = () => {
    return (
      <Modal isOpen={loadingVerifyOTP} toggle={toggleLoadingModal} className="loading-modal">
        <ModalBody>
          <div className="lds-ring loading">
            <div /><div /><div /><div />
          </div>
          Loading...
        </ModalBody>
      </Modal>
    )
  }

  let showPhoneInput = () => {
    setActiveState(STATE.INPUT_PHONE);
  }

  let showWhatsAppVerification = () => {
    setActiveState(STATE.WA_VERIFICATION);
  }

  let toggleQR = () => {
    setIsShowingQR(!isShowingQR);
  }

  let openWhatsAppLink = () => {
    setActiveState(STATE.VERIFICATION_LOADING);
    setIsCheckWhatsAppVerificationPending(true);
    window.open(whatsAppVerification.waLink);
  }

  useEffect(() => {
    if (isCheckWhatsAppVerificationPending) {
      window.addEventListener("focus", onWindowFocus);
      window.addEventListener("blur", onWindowBlur);
    }
    else {
      window.removeEventListener("focus", onWindowFocus);
      window.removeEventListener("blur", onWindowBlur);
    }
  }, [isCheckWhatsAppVerificationPending]);

  useEffect(() => {
    if (checkVerificationAttempts > 5) {
      setCheckVerificationAttempts(0);
      setActiveState(STATE.VERIFICATION_ERROR);
    }
    else if (checkVerificationAttempts > 0) {
      setTimeout(checkWhatsAppVerification, 3000);
    }
  }, [checkVerificationAttempts]);

  let onWindowFocus = () => {
    if (isCheckWhatsAppVerificationPending) {
      setIsCheckWhatsAppVerificationPending(false);
      checkWhatsAppVerification(true);
      window.removeEventListener("focus", onWindowFocus);
    }
  }

  let onWindowBlur = () => {
    setIsCheckWhatsAppVerificationPending(false);
    window.removeEventListener("focus", onWindowFocus);
    window.removeEventListener("blur", onWindowBlur);
  }

  let checkWhatsAppVerification = (resetAttempt) => {
    if (resetAttempt) {
      setCheckVerificationAttempts(0);
    }
    setActiveState(STATE.VERIFICATION_LOADING);
		let url = `${props.appData.data.baseApiUrl}/v1/client/login/check_whatsapp_verification`;
    let phoneWithCode = country.callingCode + valPhone.replaceAll(" ", "");

		let data = {
			phoneWithCode: phoneWithCode,
      verificationID: whatsAppVerification.id
    };
    
    axios.post(url, data, {headers: authenticationHeader})
      .then(function (response) {
        let _response = response.data;

        if (_response.error.code === "") {
          onVerificationSuccess(_response.data);
        }
        else {
          setCheckVerificationAttempts(checkVerificationAttempts + 1);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
      if (activeState === STATE.INPUT_OTP) {
          setTimeout(() => {
              Helper.autoFocusField("input-otp");
          }, 400)
      }
  }, [activeState])

  let onVerificationSuccess = (responseData) => {
    if (responseData.isRegistered && responseData.ticket && responseData.ticket.length > 0) {
      // Login
      requestAccessToken(responseData.ticket);
    }
    else {
      // Register
      setRedirectSeconds(redirectSecondsStart);
      setActiveState(STATE.VERIFICATION_SUCCESS);
    }
  }

  useEffect(() => {
    if (redirectSeconds > 3) {
      return;
    }
    if (redirectSeconds <= 0) {
      continueToHome();
      return;
    }
    setTimeout(() => {
      setRedirectSeconds(redirectSeconds - 1);
    }, 1000);
  }, [redirectSeconds]);

  let continueToHome = () => {
    if (taptalk.isAuthenticated()) {
      window.location.href = "/chat";
    }
    else {
      props.history.push({
        pathname: "/register",
        state: {
          country: country,
          valPhone: valPhone
        }
      });
    }
  }

  return (
    <div className="login-wrapper">
      <PhoneCodeModal 
        isOpen={isOpenPhoneCodeModal}
        onSelect={onSelectPhoneCode}
        toggle={togglePhoneCodeModal}
        value={country}
        countryList={countryList}
        countryListArray={countryListArray}
      />
      
      <div className="login-main-content">
        <img className="logo-image" src={LogoTaptalk} alt="" />
        <p className="subtitle">Chat Messenger</p>

        {
          activeState === STATE.WA_VERIFICATION
          ?
          <div className="login-card left">
            <b className="title-welcome">WhatsApp Verification</b>
            {
              (isShowingQR && whatsAppVerification.qrCode) ?
              <p>To continue the verification process, scan the QR code to open WhatsApp.<br />Please send the generated message to the opened chat without making any changes and confirm when you have sent the generated message by clicking the button below.</p>
              :
              <p>To continue the verification process, click on the ‘Open WhatsApp’ button. If you wish to use WhatsApp from another device, click on the ‘QR Code’ button to scan it on your device.<br /><br />Please send the generated message to the opened chat without making any changes.</p>
            }
            
            <b className="verification-phone-number">{"+" + Helper.phoneBeautify(country.callingCode + valPhone.replaceAll(" ", ""))}</b>

            {
              (isShowingQR && whatsAppVerification.qrCode) ?
              <>
                <img className="qr-code" src={whatsAppVerification.qrCode} />
                <button
                  className="orange-button"
                  style={{marginTop: "24px"}}
                  onClick={(() => {checkWhatsAppVerification(true)})}
                >
                  I Have Sent a Message
                </button>
    
                <div className="label-button-wrapper">
                  <div className="label-button" onClick={toggleQR}>
                    <FiArrowLeft />
                    <b>Back</b>
                  </div>
                </div>
              </>
              :
              <>
                <button
                  className="orange-button"
                  style={{marginTop: "24px"}}
                  onClick={openWhatsAppLink}
                >
                  Open WhatsApp
                </button>
    
                <button
                  className="no-fill-orange-border-button"
                  style={{marginTop: "12px"}}
                  onClick={toggleQR}
                >
                  QR Code
                </button>
    
                <div className="label-button-wrapper">
                  <div className="label-button" onClick={showPhoneInput}>
                    <FiArrowLeft />
                    <b>Change Phone Number</b>
                  </div>
                </div>
              </>
            }
          </div>
          :
          activeState === STATE.INPUT_OTP
          ?
          <div className="login-card left">
            <b className="title-welcome">OTP Verification</b>
            {
              <p>Enter the 6 digit OTP we’ve sent by {otpChannel === "whatsapp" ? "WhatsApp" : "SMS"} to your number below.</p>
            }

            {
              otpChannel === "whatsapp" ?
              <img className="otp-channel-icon" src={WhatsAppIcon} alt="" />
              :
              <img className="otp-channel-icon" src={IconSMS} alt="" />
            }
            <b className="verification-phone-number">{"+" + Helper.phoneBeautify(country.callingCode + valPhone.replaceAll(" ", ""))}</b>

            <div className="input-otp-container">
              <b className={isOTPFalse ? "error" : valOtpCode.length === 0 ? "active" : ""}>{valOtpCode.charAt(0)}</b>
              <b className={isOTPFalse ? "error" : valOtpCode.length === 1 ? "active" : ""}>{valOtpCode.charAt(1)}</b>
              <b className={isOTPFalse ? "error" : valOtpCode.length === 2 ? "active" : ""}>{valOtpCode.charAt(2)}</b>
              <b className={isOTPFalse ? "error" : valOtpCode.length === 3 ? "active" : ""}>{valOtpCode.charAt(3)}</b>
              <b className={isOTPFalse ? "error" : valOtpCode.length === 4 ? "active" : ""}>{valOtpCode.charAt(4)}</b>
              <b className={isOTPFalse ? "error" : valOtpCode.length >= 5 ? "active" : ""} style={{margin: "0"}}>{valOtpCode.charAt(5)}</b>
              <input
                id="input-otp"
                className="input-otp"
                type="text"
                onChange={(e) => onChangeOtpNumber(e)}
                value={valOtpCode}
                maxLength={6}
              />
            </div>

            {
              isOTPFalse ?
              <p className="invalid-otp">Invalid OTP, please try again</p>
              :
              <p className="did-not-receive-otp">Didn’t receive the 6 digit OTP?</p>
            }

            {
              isLoadingRequestOTP ?
              <div className="loading-resend-otp">
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
                <b>Requesting OTP</b>
              </div>
              :
              !waitRequestOtp ?
              <div className="resend-otp-wrapper">
                <p className="resend-otp" onClick={() => requestAgainAction()}>
                  <img src={IconRefresh} alt="" /> <b>Request again</b>
                </p>
              </div>
              :
              <p className="wait-otp">
                {countdownStringOTP}
              </p>
            }
            
            <div className="label-button-wrapper">
              <div className="label-button" onClick={showPhoneInput}>
                <FiArrowLeft />
                <b>Change Phone Number</b>
              </div>
            </div>
          </div>
          :
          activeState === STATE.VERIFICATION_LOADING
          ?
          <div className="verification-status-wrapper">
            <div className="lds-ring verification-status-loading">
              <div /><div /><div /><div />
            </div>
            <b className="verification-status-title">Loading...</b>
            <p className="verification-status-description">We are checking your verification status.<br/>Please wait, this may take a while.</p>
          </div>
          :
          activeState === STATE.VERIFICATION_ERROR
          ?
          <div className="verification-status-wrapper">
            <div className="verification-status-failed">
              <FiX />
            </div>
            <b className="verification-status-title">There’s something wrong</b>
            <p className="verification-status-description">Your number has not been verified.</p>
    
            <div className="label-button-wrapper">
              <div
                className="label-button"
                onClick={showWhatsAppVerification}
                style={{marginTop: "24px"}}
              >
                <FiRotateCcw />
                <b>Retry Verification</b>
              </div>
            </div>
          </div>
          :
          activeState === STATE.VERIFICATION_SUCCESS
          ?
          <div className="verification-status-wrapper">
            <div className="verification-status-success">
              <FiCheck />
            </div>
            <b className="verification-status-title">Verification Success</b>
            <p className="verification-status-description">Your number has been successfully verified</p>
            <p className="verification-status-description redirect">{`You will be redirected in ${redirectSeconds} seconds...`}</p>
    
            <div className="label-button-wrapper">
              <div
                className="label-button"
                onClick={continueToHome}
                style={{marginTop: "4px"}}
              >
                <b>If not redirected, click here</b>
                <FiArrowRight />
              </div>
            </div>
          </div>
          :
          <div className="login-card">
            <b className="title-welcome">Welcome</b>
            <p>Enter your mobile number to continue</p>
            
            <form onSubmit={(e) => requestWhatsAppVerification(e)}>
              <div className="phone-wrapper">
                <div className={`phone-number${(isLoadingRequestOTP || isLoadingRequestWhatsApp) ? ` loading` : phoneErrorInfoText ? ` error` : ``}`}>
                  <div className="phone-country" onClick={togglePhoneCodeModal}>
                    {
                      loadingCountry ?
                      <div className="lds-ring">
                        <div /><div /><div /><div />
                      </div>
                      :
                      <img src={!country ? FlagGlobe : country.flagIconURL} alt="" onError={(e) => {e.target.onerror = null; e.target.src = FlagGlobe;}} />
                    }
                    <FiChevronDown />
                    {
                      country &&
                      <p>+{country.callingCode}</p>
                    }
                  </div>

                  <div className="phone-input-container">
                    <p className="phone-input-placeholder">{loadingCountry ? "Loading Countries" : valPhone ? "" : country ? "8XXX XXXX XXX" : "Select country first"}</p>
                    <p className="beautified-phone-label">{beautifiedPhone}</p>
                    <input
                      id="phone-number-input"
                      type="text" 
                      // placeholder={loadingCountry ? "Loading Countries" : country ? "8XXX XXXX XXX" : "Select country first"}
                      value={valPhone}
                      disabled={loadingCountry || !country || isLoadingRequestOTP || isLoadingRequestWhatsApp}
                      onChange={(e) => onChangePhoneNumber(e)}
                      maxLength={country.callingCode ? 15 - country.callingCode.length : 15}
                    />
                  </div>
                </div>
              </div>
              {
                phoneErrorInfoText &&
                <div className="input-error-info">
                  <FiAlertCircle />
                  <b>{phoneErrorInfoText}</b> 
                </div>
              }

              <button
                className={`orange-button${(isLoadingRequestOTP || isLoadingRequestWhatsApp) ? `-loading` : ``}`}
                style={{marginTop: "24px"}}
              >
                {
                  isLoadingRequestWhatsApp ?
                  <div className="lds-ring">
                    <div /><div /><div /><div />
                  </div>
                  :
                  <img className="button-icon" src={WhatsAppIcon} alt="" />
                }
                Login via WhatsApp
              </button>
            </form>

            <button
              className={`no-fill-orange-border-button${(isLoadingRequestOTP || isLoadingRequestWhatsApp) ? `-loading`: ``}`}
              style={{marginTop: "12px"}}
              onClick={(e) => requestOTP(e)}
            >
              {
                isLoadingRequestOTP ?
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
                :
                <img className="button-icon" src={MobileIcon} alt="" />
              }
              Login via OTP
            </button>
          </div>
        }
      </div>

      {generateLoadingModal()}
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.appData,
});

export default connect(mapStateToProps, null)(Login);
