const INITIAL_STATE = {
    message: false,
    isShow: false,
    lastOpen: false
  };
  
  let reducerMessageInfo = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case 'SET_MESSAGE_INFO':
          return action.messageInfo;
        case 'CLEAR_MESSAGE_INFO':
          return INITIAL_STATE;
        default:
          return state;
      }
  };
  
  export default reducerMessageInfo;