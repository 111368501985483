const INITIAL_STATE = {
  last_play: false,
  message: false
};

let reduxReducerPlayVoice = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_PLAY_VOICE':
        return action.playVoice;
      case 'CLEAR_PLAY_VOICE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerPlayVoice;