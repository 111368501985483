const INITIAL_STATE = {
  roomID: "",
  messages: {},
  pinMessages: {
    hasMore: false,
    messages: [],
    pageNumber: 0,
    totalItems: 0,
    totalPages: 0
  },
  activePinnedMessageIndex: 0,
  pinMessagesInsideModal: null
};

let reduxReducerAllPinnedMessages = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ALL_PINNED_MESSAGES':
        return action.allPinnedMessages;
      case 'CLEAR_ALL_PINNED_MESSAGES':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerAllPinnedMessages;