import React, { useEffect, useState } from 'react';
import './ChatRoom.scss';
import ChatRoomHeader from './chatRoomHeader/ChatRoomHeader';
import ChatRoomNoChat from './chatRoomNoChat/ChatRoomNoChat';
import ChatRoomMain from './chatRoomMain/ChatRoomMain';
import ChatRoomScheduledMessage from './chatRoomMain/chatRoomScheduledMessage/ChatRoomScheduledMessage';
// import ChatRoomStartChat from './chatRoomMain/chatRoomStartChat/ChatRoomStartChat';
import { connect } from 'react-redux';

var ChatRoom = (props) => {
  // const [startNewChat] = useState(false);
  let [chatRoomDataForHeader, setchatRoomDataForHeader] = useState(null);
  let [showScheduledMessagesChatRoom, setShowScheduledMessagesChatRoom] = useState(false);
  let [pendingScheduledMessages, setPendingScheduledMessages] = useState([]);
  
  useEffect(() => {
    setchatRoomDataForHeader(null);
    setShowScheduledMessagesChatRoom(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.activeRoom])

  let runSetChatRoomDataForHeader = (data) => {
    setchatRoomDataForHeader(data)
  }

  return (
    <div 
      className={`
        chat-room-wrapper 
        ${props.activeRoom !== null ? `active-chat-room ${(props.multiForward[props.activeRoom.roomID] || props.activeRoom.deleted !== 0) ? "no-chat-action" : ""}` : ""}
      `}
    >
        {props.activeRoom === null ? 
          <ChatRoomNoChat />
          :
          <React.Fragment>
            <ChatRoomHeader listenerStartTypingProps={props.listenerStartTypingProps}
                            listenerStopTypingProps={props.listenerStopTypingProps}
                            listenerUserOnlineProps={props.listenerUserOnlineProps}
                            messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                            messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                            chatRoomDataForHeader={chatRoomDataForHeader}
                            showScheduledMessagesChatRoom={showScheduledMessagesChatRoom}
                            setShowScheduledMessagesChatRoom={setShowScheduledMessagesChatRoom}
            />

            {
              showScheduledMessagesChatRoom ?
              <ChatRoomScheduledMessage 
                messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                deleteLocalChatRoomProps={props.deleteLocalChatRoomProps}
                setNewEmitMessageProps={props.setNewEmitMessageProps}
                runSetChatRoomDataForHeader={runSetChatRoomDataForHeader}
                setShowScheduledMessagesChatRoom={setShowScheduledMessagesChatRoom}
                pendingScheduledMessages={pendingScheduledMessages}
                setPendingScheduledMessages={setPendingScheduledMessages}
                roomListenerScheduledMessageProps={props.roomListenerScheduledMessageProps}
              />
              :
              <ChatRoomMain messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                            messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                            deleteLocalChatRoomProps={props.deleteLocalChatRoomProps}
                            setNewEmitMessageProps={props.setNewEmitMessageProps}
                            runSetChatRoomDataForHeader={runSetChatRoomDataForHeader}
                            setShowScheduledMessagesChatRoom={setShowScheduledMessagesChatRoom}
                            pendingScheduledMessages={pendingScheduledMessages}
                            setPendingScheduledMessages={setPendingScheduledMessages}
              /> 
            }
          </React.Fragment>
        }
    </div>
  );
}


const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  multiForward: state.multiForward
});

export default connect(mapStateToProps, null)(ChatRoom);
