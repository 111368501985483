import React, { useState, useEffect } from 'react';
import './RoomListBlockedContacts.scss'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FiChevronLeft, FiMinusCircle } from 'react-icons/fi';
import { connect } from 'react-redux';
import { taptalk, tapCoreContactManager } from '@taptalk.io/web-sdk';
import Helper from "../../../helper/Helper";
import noBlockedContact from '../../../assets/img/no-blocked-contact.svg';
import PopupConfirmation from '../../reuseableComponent/popupConfirmation/PopupConfirmation';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import { setActiveRoom } from '../../../redux/actions/reduxActionActiveRoom';
import { setBlockedUserIDs } from '../../../redux/actions/reduxActionBlockedUserIDs';
import BlockingScreenLoading from '../../reuseableComponent/blockingScreenLoading/BlockingScreenLoading';

var RoomListBlockedContacts = (props) => {

    let [blockedUsersArray, setBlockedUsersArray] = useState([]);
    let [isLoadingBlockedContacts, setIsLoadingBlockedContacts] = useState(false);
    let [selectedUser, setSelectedUser] = useState(false);
    let [showUnblockUserConfirmation, setShowUnblockUserConfirmation] = useState(false);
    let [showUnblockUserError, setShowUnblockUserError] = useState(false);
    let [isLoadingUnblockUser, setIsLoadingUnblockUser] = useState(false);
    let [isEditing, setIsEditing] = useState(false);
    let [isContactListenerSetupFinished, setIsContactListenerSetupFinished] = useState(false);

    useEffect(() => {
        if (props.showBlockedContactsModal) {
            getBlockedContacts(true);
        }
        else {
            setShowUnblockUserError(false);
            setIsLoadingUnblockUser(false);
            setIsEditing(false);
        }
    }, [props.showBlockedContactsModal]);

    useEffect(() => {
        if (props.blockedUserIDs && 
            !props.blockedUserIDs.blockedUserIDs && // props.blockedUserIDs returns {blockedUserIDs: []} when not set
            !isContactListenerSetupFinished
        ) {
            // Contact blocked/unblocked listener
            tapCoreContactManager.addContactListener({
                onContactBlocked: (user) => {
                    // addUserToBlockedList(user);
                    getBlockedContacts(false);
                },
                onContactUnblocked: (user) => {
                    // removeUserFromBlockedList(user);
                    getBlockedContacts(false);
                }
            });
            setIsContactListenerSetupFinished(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.blockedUserIDs]);

    useEffect(() => {
        if (selectedUser) {
            setShowUnblockUserConfirmation(true);
        }
        else if (showUnblockUserConfirmation) {
            hideUnblockUserConfirmation();
        }
    }, [selectedUser]);

    let hideUnblockUserConfirmation = () => {
        setShowUnblockUserConfirmation(false);
        setTimeout(() => {
            // Added delay to keep confirmation modal title
            if (selectedUser) {
                setSelectedUser(false);
            }
        }, 200);
    }

    let toggleUnblockUserError = () => {
        setShowUnblockUserError(!showUnblockUserError);
    }

    let getBlockedContacts = async (showLoading = true) => {
        if (showLoading) {
            setIsLoadingBlockedContacts(true);
        }
        tapCoreContactManager.getBlockedUserList({
            onSuccess: (response) => {
                if (showLoading) {
                    setIsLoadingBlockedContacts(false);
                }
                if (response) {
                    setBlockedUsersArray(response);

                    let blockedUserIDs = [];
                    response.forEach((user, index) => {
                        blockedUserIDs.push(user.userID);
                    });
                    props.setBlockedUserIDs(blockedUserIDs);
                }
            },
            onError: (errorCode, errorMessage) => {
                if (showLoading) {
                    setIsLoadingBlockedContacts(false);
                }
                console.log(errorCode, errorMessage)
            }
        });
    }

    // let addUserToBlockedList = (user) => {
    //     if (user) {
    //         let _blockedUserIDs = props.blockedUserIDs;
    //         let _blockedUsersArray = blockedUsersArray.slice();
    //         let isUserAlreadyInList = false;
    //         const index = _blockedUserIDs.indexOf(user.userID);

    //         // Update blocked user list for UI
    //         blockedUsersArray.forEach((blockedUser, blockedUsersIndex) => {
    //             if (blockedUser.userID === user.userID) {
    //                 isUserAlreadyInList = true;
    //             }
    //         });
    //         if (!isUserAlreadyInList) {
    //             _blockedUsersArray.push(user);
    //             setBlockedUsersArray(_blockedUsersArray);
    //         }

    //         if (index === -1) {
    //             // Update blocked user IDs in redux
    //             _blockedUserIDs.push(user.userID);
    //             props.setBlockedUserIDs(_blockedUserIDs);
    //         }
    //     }
    // }

    let removeUserFromBlockedList = (user) => {
        if (user) {
            let _blockedUserIDs = props.blockedUserIDs;
            let _blockedUsersArray = blockedUsersArray.slice();
            const index = _blockedUserIDs.indexOf(user.userID);
            if (index === -1) {
                // Update blocked user list for UI
                blockedUsersArray.forEach((blockedUser, blockedUsersIndex) => {
                    if (blockedUser.userID === user.userID) {
                        _blockedUsersArray.splice(blockedUsersIndex, 1);
                        setBlockedUsersArray(_blockedUsersArray);
                        return;
                    }
                });
            }
            else {
                // Update blocked user list for UI
                if (_blockedUsersArray.length > index && _blockedUsersArray[index].userID === user.userID) {
                    _blockedUsersArray.splice(index, 1);
                    setBlockedUsersArray(_blockedUsersArray);
                }

                // Update blocked user IDs in redux
                _blockedUserIDs.splice(index, 1);
                props.setBlockedUserIDs(_blockedUserIDs);
            }
        }
    }

    let unblockUser = async () => {
        if (!selectedUser) {
            return;
        }
        hideUnblockUserConfirmation();
        setIsLoadingUnblockUser(true);
        tapCoreContactManager.unblockUser(
            selectedUser.userID,
            {
                onSuccess : (response) => {
                    setIsLoadingUnblockUser(false);
                    setSelectedUser(false);
                    removeUserFromBlockedList(selectedUser);
                },

                onError : (errorCode, errorMessage) => {
                    setIsLoadingUnblockUser(false);
                    setSelectedUser(false);
                    toggleUnblockUserError();
                    console.log(errorCode, errorMessage);
                }
            }
        )
    }

    let onBlockedContactClicked = (user) => {
        if (isEditing) {
            return;
        }

        let room = {};
        let currentActiveUser = taptalk.getTaptalkActiveUser();
        let roomID = "";
    
        if (parseInt(user.userID) < parseInt(currentActiveUser.userID)) {
            roomID = user.userID+"-"+currentActiveUser.userID;
        }
        else {
            roomID = currentActiveUser.userID+"-"+user.userID;
        }
    
        room.roomID = roomID;
        room.name = user.fullname;
        room.imageURL = user.imageURL;
        room.type = 1;
            
        props.setPreviousActiveRoom(props.activeRoom ? {...props.activeRoom} : null);
        props.setActiveRoom(room);
        props.setSelectedBlockedUserRoom(room);
    }

    return (
        <Modal isOpen={props.showBlockedContactsModal} className="blocked-contacts-modal" toggle={() => props.toggleBlockedContactsModal()}>
            <ModalHeader>
                <div className="header-top-title abc">
                    <FiChevronLeft    
                        onClick={props.toggleBlockedContactsModal}
                        height={32}
                        width={32}
                    />
                    
                    <p><b className="blocked-contacts-title">Blocked Contacts</b></p>
                    
                    {
                        (isEditing && blockedUsersArray.length > 0) ?
                        <p className='edit-mode-button' onClick={() => setIsEditing(false)}>Done</p>
                        :
                        blockedUsersArray.length > 0 ?
                        <p className='edit-mode-button' onClick={() => setIsEditing(true)}>Edit</p>
                        :
                        ""
                    }
                    
                </div>
            </ModalHeader>

            <ModalBody>
                <div>
                    <div>
                        {
                            isLoadingBlockedContacts ?
                                <div className="loading-contact-wrapper">
                                    <div className="lds-ring">
                                        <div /><div /><div /><div />
                                    </div>
                                </div>
                            :
                            blockedUsersArray.length === 0 ?
                            <div className="no-blocked-contact-container">
                                <img src={noBlockedContact} alt="" />
                                <p className="no-blocked-contact-desc">You don’t have any blocked contact</p>
                            </div>
                            :
                            blockedUsersArray.map((user, index) => {
                                return (
                                    <div className="blocked-contact-wrapper" key={`blocked-contact-${user.userID}-${index}`} onClick={() => onBlockedContactClicked(user)}>
                                        <div className="blocked-contact-avatar-wrapper" style={{background: taptalk.getRandomColor(user.fullname)}}
                                        >
                                            {user.imageURL.thumbnail === "" ?
                                                <b>{Helper.renderUserAvatarWord(user.fullname, false)}</b>
                                                :
                                                <img src={user.imageURL.thumbnail} alt="" />
                                            }
                                        </div>
                                     
                                        <div className={"blocked-contact-name" + (isEditing ? " is-editing" : "")}>
                                            <p><b>{user.fullname}</b></p>
                                        </div>

                                        <div className={"unblock-icon" + (isEditing ? " is-editing" : "")} onClick={() => {setSelectedUser(user)}}>
                                            <FiMinusCircle />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </ModalBody>
            
            <PopupConfirmation
                isOpen={showUnblockUserConfirmation}
                toggle={hideUnblockUserConfirmation}
                title={"Unblock " + (selectedUser.fullname ? selectedUser.fullname : "User") + "?"}
                description="Unblocking lets you receive messages and calls from this contact. Are you sure you want to continue?"
            >
                {/* {
                    isLoadingUnblockUser ?
                    <ButtonWithLoadingOrIcon
                        text="Yes"
                        className="main-button-48 orange-button confirmation-button"
                        isLoading
                        loadingWhite
                        position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Yes"
                        className="main-button-48 orange-button confirmation-button"
                        onClickAction={() => unblockUser()}
                    />
                } */}
                <ButtonWithLoadingOrIcon
                    text="Yes"
                    className="main-button-48 orange-button confirmation-button"
                    onClickAction={() => unblockUser()}
                />
            </PopupConfirmation>
            
            <PopupConfirmation
                isOpen={showUnblockUserError}
                toggle={toggleUnblockUserError}
                title="Failed to unblock user"
                description="There has been an error, please try again."
                cancelButtonText="OK"
                cancelButtonStyle="orange-button"
                singleButton={true}
            />

            <BlockingScreenLoading isOpen={isLoadingUnblockUser} />

        </Modal>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    blockedUserIDs: state.blockedUserIDs
})

const mapDispatchToProps = {
    setActiveRoom,
    setBlockedUserIDs
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomListBlockedContacts);
