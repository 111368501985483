import React, { useState, useEffect } from 'react';
import './ChatRoomMessageOutFile.scss';
import { MdInsertDriveFile } from 'react-icons/md';
import { FiDownload, FiUpload } from 'react-icons/fi';
import HelperChat from '../../../../helper/HelperChat';
import Helper from '../../../../helper/Helper';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import { tapCoreChatRoomManager, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';
import { connect } from 'react-redux';

const LONG_PRESS = 700;

var ChatRoomMessageOutFile = (props) => {
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [bytesDownload, setBytesDownload] = useState(0);
    let [fileSrc, setFileSrc] = useState('');
    let [fileContentType, setFileContentType] = useState('');
    let [isFileExistInDB, setIsFileExistInDB] = useState(false);
    let [onFileDownloadProgress, setOnFileDownloadProgress] = useState(false);
    let [fileExtension, setFileExtension] = useState("");
    let [fileFromUrl, setFileFromUrl] = useState(false);
    let [lastClickBubble, setLastClickBubble] = useState(false);

    let { 
        activeRoom,
        singleChatDataProps,
        activeUserID,
        status,
        onReplyMessage,
        onForwardMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        onClickToStarMessage,
        onMessageInfo,
        multiForward,
        noForward,
        isScheduledMessage,
        sendScheduledMessageNow,
        rescheduleMessage,
        // editScheduledMessage,
        deleteScheduledMessage
    } = props;

    let delay;

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }

    useEffect(() => {
        let isUnmounted = false;
        let splitFileName = singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName.split(".") : singleChatDataProps.body.split('.');

        if(singleChatDataProps.data.url) {
            setFileFromUrl(true);
            setFileSrc(singleChatDataProps.data.url);
            setFileContentType(singleChatDataProps.data.mediaType);
        }else if(singleChatDataProps.data.fileURL) {
            if(singleChatDataProps.data.fileURL !== "") {
                setFileFromUrl(true);
                setFileSrc(singleChatDataProps.data.fileURL);
                setFileContentType(singleChatDataProps.data.mediaType);
            }else {
                setFileFromUrl(false);
                tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
                    if(data) {
                        if(!isUnmounted) {
                            setFileSrc(`data:${data.type};base64,${data.file}`);
                            setFileContentType(data.type);
                            setIsFileExistInDB(true);
                        }
                    }else {
                        if(!isUnmounted) {
                            setIsFileExistInDB(false);
                        }
                    }
                })
            }
        }
        
        if(!isUnmounted) {
            setFileExtension(splitFileName[splitFileName.length - 1].toUpperCase());
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps])

    let downloadFile = () => {
        setOnFileDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnFileDownloadProgress(false);
                setFileSrc(`data:${data.contentType};base64,${data.base64}`);
                setFileContentType(data.contentType);
                setIsFileExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
                setBytesDownload(HelperChat.bytesToSize(bytes));
            },

            onError: (errorCode, errorMessage) => {
                setOnFileDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    // let getFileBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setFileSrc(`data:${data.type};base64,${data.file}`);
    //             setIsFileExistInDB(true);
    //         }else {
    //             setIsFileExistInDB(false);
    //         }
    //     })
    // }

    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }

    //   let renderChatStatus = (message) => {
    //     let messageStatus;
        
    //     if(message.isSending) {
    //         messageStatus = "sending";
    //     }

    //     if(!message.isSending && message.isDelivered) {
    //         messageStatus = "sent";
    //     }

    //     if(!message.isSending && message.isDelivered && !message.isRead) {
    //         messageStatus = "receive";
    //     }

    //     if(message.isRead) {
    //         messageStatus = "read";
    //     }

    //     if(!message.isDelivered) {
    //         messageStatus = "not delivered";
    //     }
        
    //     switch(messageStatus) {
    //         case "sending":
    //             return AirplaneDark;
    //         case "sent":
    //             return CheckMarkDark;
    //         case "receive":
    //             return CheckMarkDoubleDark;
    //         case "read":
    //             return CheckMarkDoubleWhite;
    //         case "not delivered":
    //             return CheckMarkDark;
    //     }
    //   }

    // let downloadFileToStorage = (file, fileName) => {
    //     fetch(file)
    //         .then(resp => resp.blob())
    //         .then(blob => {
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             const fileID = `file-${new Date().valueOf()}`;

    //             a.style.display = 'none';
    //             a.href = url;
    //             a.id = fileID;

    //             // the filename you want
    //             a.download = fileName;

    //             document.body.appendChild(a);
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //             a.remove();
    //         });
    // }

    let clickBubble = () => {
        if (fileFromUrl) {
            window.open(fileSrc, "_blank");
        }
        else if (isFileExistInDB) {
            const byteCharacters = atob(fileSrc.substr(`data:${fileContentType};base64,`.length));
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const slice = byteCharacters.slice(offset, offset + 1024);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, {type: fileContentType});
            const blobUrl = URL.createObjectURL(blob);

            window.open(blobUrl, '_blank');
        }
    }

    return (
        <div 
            className="chat-room-message-file-out-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
            onClick={() => { 
                if(onClickToStarMessage) {
                    onClickToStarMessage(singleChatDataProps.localID);
                }

                if(activeRoom.type === 2) {
                    setLastClickBubble(new Date().valueOf());
                }
            }}    
        >
            {
                (
                    activeRoom &&
                    !singleChatDataProps.isDeleted &&
                    multiForward[activeRoom.roomID] &&
                    Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
                ) &&
                <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
            }

            <div className="message-out-bubble"
                // style={
                //     Helper.isReplyMessage(singleChatDataProps) ? 
                //         (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
                //             {paddingTop: "64px"} 
                //             :
                //             {paddingTop: "84px"}
                //         )
                //         : 
                //         {}
                // }
            >
                <div className="click-area-file" onClick={clickBubble}>
                    {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            
                            <p>
                                From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                            </p>
                        </div>
                    }
                    {/* <div className="replied-file-message">
                        <div className="file-icon-wrapper">
                            <MdInsertDriveFile />
                        </div>

                        <div className="file-detail-wrapper">
                            <div className="filename-wrapper">
                                <b>file.docx</b>
                            </div>
                            285 KB
                        </div>
                    </div> */}

                    {/* {forwarded &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>Keju Manis</b>
                        </div>
                    } */}

                    {/* {Helper.isReplyMessage(singleChatDataProps) &&
                        <div className="reply-message reply-file">
                            {singleChatDataProps.quote.fileType !== "" &&  
                                <div className="reply-file-thumbnail">
                                    {singleChatDataProps.quote.fileType === "file" ? 
                                        <MdInsertDriveFile />
                                        :
                                        <img src={''} alt="" />
                                    }
                                </div>
                            }

                            <div className="reply-text-wrapper">
                                <p className="reply-from">
                                    <b> 
                                        {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                            "You"
                                            :
                                            singleChatDataProps.replyTo.fullname
                                        }
                                    </b>
                                </p>
                                <p className="reply-text">{singleChatDataProps.quote.content}</p>
                            </div>
                        </div>
                    }  */}
                    
                    {Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyOutMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }
                    
                    <div className="file-inner-wrapper">
                        <div className="file-icon-wrapper">
                            {/* {status === 'onprogress' ?
                                <div className="onprocess-wrapper">
                                    <MdClose />
                                </div>

                                :

                                status === 'success' ?
                                    <MdInsertDriveFile />
                                    :
                                    <MdRefresh />
                            }   */}

                            {((!isFileExistInDB && !onFileDownloadProgress && !fileFromUrl) && singleChatDataProps.percentageUpload === undefined) &&
                                <div className="icon-status-wrapper">
                                    <FiDownload onClick={() => downloadFile()} />
                                </div>
                            }

                            {(onFileDownloadProgress) && 
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={percentageDownload} />
                                        {/* <MdClose onClick={() => console.log('cancel download')} /> */}
                                        <FiDownload />
                                    </div>
                                </div>
                            }

                            {singleChatDataProps.bytesUpload !== undefined && 
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={singleChatDataProps.percentageUpload} />
                                        {/* <MdClose onClick={() => console.log('cancel upload')} /> */}
                                        <FiUpload />
                                    </div>
                                </div>
                            }
                            
                            {(isFileExistInDB || fileFromUrl) &&
                                // <a href={fileSrc} target="_blank" download={singleChatDataProps.data.fileName}>
                                // <MdInsertDriveFile onClick={() => downloadFileToStorage(fileFromUrl ? singleChatDataProps.data.url : fileSrc, singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName : singleChatDataProps.body)} /> 
                                
                                // <a href={fileSrc} target="_blank" rel="noopener noreferrer">
                                    <MdInsertDriveFile />
                                // </a>
                            }
                        </div>

                        <div className="message-bubble-file-wrapper">
                            <p>
                                <b>
                                    {singleChatDataProps.data.fileName ? 
                                        singleChatDataProps.data.fileName.replace(fileExtension.toLowerCase(), '').replace('.', '')
                                        :
                                        singleChatDataProps.body.replace(fileExtension.toLowerCase(), '').replace('.', '')
                                    }
                                </b>
                            </p>

                            <div className={`file-size-and-extension ${(onFileDownloadProgress || singleChatDataProps.bytesUpload) ? 'file-size-and-extension-download-progress' : ''}`}>
                                {onFileDownloadProgress && `${bytesDownload} / `}

                                {singleChatDataProps.bytesUpload !== undefined && `${HelperChat.bytesToSize(singleChatDataProps.bytesUpload)} / `}{singleChatDataProps.data.size !== 0 && HelperChat.bytesToSize(singleChatDataProps.data.size)}{" "+fileExtension}
                            </div>
                        </div>
                    </div>

                    <ChatRoomMessageInfoComp 
                        isOut
                        message={singleChatDataProps}
                        lastClickBubble={lastClickBubble}
                    />
                </div>
                
                <ChatRoomMessageActionComp
                     buttonList={
                        isScheduledMessage && !singleChatDataProps.isSending ?
                        [
                            {
                                val: "send",
                                text: "Send Now",
                                action: () => sendScheduledMessageNow(singleChatDataProps),
                            },
                            {
                                val: "reschedule",
                                text: "Reschedule",
                                action: () => rescheduleMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete",
                                action: () => deleteScheduledMessage(singleChatDataProps),
                            },
                        ]
                        :    
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete Message",
                                action: () => deleteMessageAction(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                            {
                                val: "messageInfo",
                                text: "Message Info",
                                action: () => onMessageInfo(singleChatDataProps)
                            }
                        ]
                    }
                />

                {status === 'fail' &&
                    <React.Fragment>
                        <br />
                        <b className="failed-sending-file-warning">Failed to send tap to retry</b>
                    </React.Fragment>
                }
            </div>
             
            {(
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
                <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-out">
                    <input 
                        type="checkbox" 
                        id={`message-${singleChatDataProps.messageID}`} 
                        checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                    />
                    <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutFile);