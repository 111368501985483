import React from "react";
import "./MuteOptionModal.scss";
import HelperChat from "../../../helper/HelperChat";
// import { MUTE_OPTION } from "../../../helper/HelperConst";
import { setMutedRooms } from '../../../redux/actions/reduxActionMutedRooms';
import { connect } from 'react-redux';

let MuteOptionModal = (props) => {
    let {
        options,
        isShow,
        toggle,
        _className,
        mutedRooms,
        setMutedRooms,
        activeRoom
    } = props;

    let muteUnmuteClick = (val) => {
        if(val === "unmute") {
            let _mutedRooms = {...mutedRooms};
            delete _mutedRooms[activeRoom.roomID];
            
            setMutedRooms(_mutedRooms);

            HelperChat.unmuteAction([activeRoom.roomID], {
                onSuccess: (res) => {
                },
                onError: (errCode, errMes) => {
                    console.log(errCode, errMes)
                }
            })
        }else {
            if(val) {
                val = new Date().valueOf() + val;
            }

            let _mutedRooms = {...mutedRooms};
            _mutedRooms[activeRoom.roomID] = {
                roomID: activeRoom.roomID,
                expiredAt: val ? val : 0
            }
            
            HelperChat.muteAction([activeRoom.roomID], val, {
                onSuccess: (res) => {

                    setMutedRooms(_mutedRooms);
                },
                onError: (errCode, errMes) => {
                    console.log(errCode, errMes)
                }
            })
        }

        toggle();
    }

    return (
        <div className={`modal-option-mute-unmute ${isShow ? "modal-option-mute-unmute-show" : ""} ${_className}`}>
            <ul>
                {options.map((v) => {
                    return (
                        <li onClick={() => muteUnmuteClick(v.val)}><b>{v.label}</b></li>
                    )
                })}

                <li onClick={toggle}>
                    <b>Cancel</b>
                </li>
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    mutedRooms: state.mutedRooms
});

const mapDispatchToProps = {
    setMutedRooms,
};

export default connect(mapStateToProps, mapDispatchToProps)(MuteOptionModal);