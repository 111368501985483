import React, { useState, useEffect } from 'react';
import './ChatRoomHeaderSharedMedia.scss';
import { connect } from 'react-redux';
import { tapCoreMessageManager } from '@taptalk.io/web-sdk';
import HelperDate from '../../../../../helper/HelperDate';
import { CHAT_TYPE } from '../../../../../helper/HelperConst';
import { IoIosArrowBack } from 'react-icons/io';
import { ModalHeader, ModalBody } from 'reactstrap';
import ChatRoomHeaderSharedMediaTabButton from './chatRoomHeaderSharedMediaTabButton/ChatRoomHeaderSharedMediaTabButton';
import ChatRoomHeaderSharedMediaMonthlyList from './chatRoomHeaderSharedMediaMonthlyList/ChatRoomHeaderSharedMediaMonthlyList';
import noSharedMedia from '../../../../../assets/img/chatroom/no-shared-media.svg';

const SHARED_MEDIA_TAB = {
    media: 0,
    links: 1,
    documents: 2
}

var ChatRoomHeaderSharedMedia = ({
    activeRoom,
    activeRoomConst,
    chatRoomDataForHeader,
    activeModalRoomInfo,
    setActiveModalRoomInfo,
    toggleModalFile,
    onClickToStarMessage,
}) => {
    let [activeSharedMediaTab, setActiveSharedMediaTab] = useState(0);
    let [isLoadingMedias, setIsLoadingMedias] = useState(false);
    let [isSharedMediaFetched, setIsSharedMediaFetched] = useState(false);
    let [maxCreated, setMaxCreated] = useState(Date.now());

    // Nested objects for shared contents separated by month
    let [monthlySharedMedias, setMonthlySharedMedias] = useState({});
    let [monthlySharedLinks, setMonthlySharedLinks] = useState({});
    let [monthlySharedDocuments, setMonthlySharedDocuments] = useState({});
    
    useEffect(() => {
        let isUnmounted = false;
    
        let reverseMessagesObject = (object) => {
            var newObject = {};
            var keys = [];
    
            for (var key in object) {
                keys.push(key);
            }
    
            for (var i = keys.length - 1; i >= 0; i--) {
            var value = object[keys[i]];
            newObject[keys[i]]= value;
            }       
    
            return newObject;
        }
    
        let mapSharedContents = (medias, reverse) => {
            let _medias;
            if (reverse) {
                _medias = reverseMessagesObject({...medias});
            }
            else {
                _medias = {...medias};
            }
    
            Object.keys(_medias).map((value, index) => {
                const message = _medias[value];
                const month = HelperDate.formatToString(new Date(message.created), "MMMM yyyy");
    
                // Reassign incomplete properties
                if (message.type === undefined) {
                    message.type = message.messageType;
                }
                if (message.room === undefined) {
                    message.room = activeRoom;
                }
                if (message.user === undefined) {
                    message.user = {
                        userID: message.userID,
                        fullname: message.userFullname
                    };
                }
    
                if (message.type === CHAT_TYPE.TAPChatMessageTypeImage || message.type === CHAT_TYPE.TAPChatMessageTypeVideo) {
                    if (monthlySharedMedias[month] === undefined) {
                        // Add image/video in a new month
                        monthlySharedMedias[month] = {[message.localID] : message};
                        setMonthlySharedMedias(monthlySharedMedias);
                    } 
                    else {
                        // Add image/video to existing month
                        monthlySharedMedias[month][message.localID] = message;
                        setMonthlySharedMedias(previousMedias => ({
                            ...previousMedias,
                            [month]: {
                                ...previousMedias[month],
                                [message.localID]: message
                            }
                        }))
                    }
                }
                else if (message.type === CHAT_TYPE.TAPChatMessageTypeLink) {
                    if (monthlySharedLinks[month] === undefined) {
                        // Add link in a new month
                        monthlySharedLinks[month] = {[message.localID] : message};
                        setMonthlySharedLinks(monthlySharedLinks);
                    } 
                    else {
                        // Add link to existing month
                        monthlySharedLinks[month][message.localID] = message;
                        setMonthlySharedLinks(previousLinks => ({
                            ...previousLinks,
                            [month]: {
                                ...previousLinks[month],
                                [message.localID]: message
                            }
                        }))
                    }
                }
                else if (message.type === CHAT_TYPE.TAPChatMessageTypeFile) {
                    if (monthlySharedDocuments[month] === undefined) {
                        // Add file/document in a new month
                        monthlySharedDocuments[month] = {[message.localID] : message};
                        setMonthlySharedDocuments(monthlySharedDocuments);
                    } 
                    else {
                        // Add file/document to existing month
                        monthlySharedDocuments[month][message.localID] = message;
                        setMonthlySharedDocuments(previousLinks => ({
                            ...previousLinks,
                            [month]: {
                                ...previousLinks[month],
                                [message.localID]: message
                            }
                        }))
                    }
                }
    
                if (maxCreated > message.created) {
                    // Assign lowest max created
                    setMaxCreated(message.created);
                }

                return null;
            });
            
            setMonthlySharedMedias(monthlySharedMedias);
        }
    
        let fetchSharedMediasFromAPI = () => {
            if (isLoadingMedias || isSharedMediaFetched) {
                return;
            }
    
            setIsLoadingMedias(true);
        
            tapCoreMessageManager.fetchSharedContentMessages(
                activeRoom.roomID,
                maxCreated,
                0,
                "DESC",
                {
                    onSuccess: (mediasResponse, filesResponse, linksResponse) => {
                        if (!isUnmounted) {
                            mapSharedContents(mediasResponse, false);
                            mapSharedContents(filesResponse, false);
                            mapSharedContents(linksResponse, false);
                            setIsSharedMediaFetched(true);
                            setIsLoadingMedias(false);
                        }
                    },
                    onError: (errCode, errMessage) => {
                        console.log(errCode, errMessage)
                        if (!isUnmounted) {
                            setIsLoadingMedias(false);
                        }
                    }
                }
            );
        }

        let fetchSharedMediasAsync = async () => {
            fetchSharedMediasFromAPI();
        }

        mapSharedContents(chatRoomDataForHeader, true);
        fetchSharedMediasAsync();

        return () => {
            isUnmounted = true;
        }
    }, [activeRoom]);

    let onSharedMediaTabButtonClicked = (index) => {
        setActiveSharedMediaTab(index);
    }

    // let onScrollListener = () => {
    //     let el = document.querySelectorAll(".room-modal-shared-media")[0];
    //     let _scrollHeight = el.scrollHeight;
    //     let _scrollPosition = el.scrollTop + el.clientHeight;

    //     if (_scrollPosition >= (_scrollHeight * 0.75)) {
    //         fetchSharedMediasFromAPI();
    //     }
    // }
      
    return (
        <React.Fragment>
            <ModalHeader className={`member-list-header room-info-member-list ${activeModalRoomInfo === activeRoomConst.sharedMedia ? "active-room-info-member-list" : ""}`}> 
                <IoIosArrowBack 
                    className="header-room-info-button back-arrow-modal" 
                    onClick={() => {
                        setActiveModalRoomInfo(activeRoomConst.main);
                    }}
                />
  
                <b className="room-info-title">Shared Media</b>
            </ModalHeader>
  
            <ModalBody 
              className={`room-info-member-list ${activeModalRoomInfo === activeRoomConst.sharedMedia ? "active-room-info-member-list" : ""} room-modal-shared-media`}
            //   onScroll={() => onScrollListener()}
            >
                <div className="shared-media-container">
                    <div className="shared-media-tab-container">
                        <div className="shared-media-tab-buttons-wrapper">
                            <ChatRoomHeaderSharedMediaTabButton
                                buttonText="Media"
                                tabIndex={SHARED_MEDIA_TAB.media}
                                activeSharedMediaTab={activeSharedMediaTab}
                                onSharedMediaTabButtonClicked={() => onSharedMediaTabButtonClicked(SHARED_MEDIA_TAB.media)}
                            />
                            <ChatRoomHeaderSharedMediaTabButton
                                buttonText="Links"
                                tabIndex={SHARED_MEDIA_TAB.links}
                                activeSharedMediaTab={activeSharedMediaTab}
                                onSharedMediaTabButtonClicked={() => onSharedMediaTabButtonClicked(SHARED_MEDIA_TAB.links)}
                            />
                            <ChatRoomHeaderSharedMediaTabButton
                                buttonText="Documents"
                                tabIndex={SHARED_MEDIA_TAB.documents}
                                activeSharedMediaTab={activeSharedMediaTab}
                                onSharedMediaTabButtonClicked={() => onSharedMediaTabButtonClicked(SHARED_MEDIA_TAB.documents)}
                            />
                        </div>
                    </div>
                    {
                        activeSharedMediaTab === SHARED_MEDIA_TAB.media &&
                        (
                            Object.keys(monthlySharedMedias).length > 0 ?
                            Object.keys(monthlySharedMedias).map((value, index) => {
                                return (
                                    <ChatRoomHeaderSharedMediaMonthlyList
                                        monthString={value}
                                        activeSharedMediaTab={activeSharedMediaTab}
                                        sharedMediaItems={monthlySharedMedias[value]}
                                        toggleModalFile={toggleModalFile}
                                        onClickToMessage={onClickToStarMessage}
                                        sharedMediaTab={SHARED_MEDIA_TAB}
                                    />
                                );
                            })
                            :
                            isSharedMediaFetched &&
                            <div className="no-shared-media-container">
                                <img src={noSharedMedia} alt="" className="no-starred-message-img" />
                                <p className="no-shared-media-title"><b>No Shared Media</b></p>
                                <p className="no-shared-media-desc">You can see all your shared media from this chat here once you start sharing them.</p>
                            </div>
                        )
                    }
                    {
                        activeSharedMediaTab === SHARED_MEDIA_TAB.links &&
                        (
                            Object.keys(monthlySharedLinks).length > 0 ?
                            Object.keys(monthlySharedLinks).map((value, index) => {
                                return (
                                    <ChatRoomHeaderSharedMediaMonthlyList
                                        monthString={value}
                                        activeSharedMediaTab={activeSharedMediaTab}
                                        sharedMediaItems={monthlySharedLinks[value]}
                                        toggleModalFile={toggleModalFile}
                                        onClickToMessage={onClickToStarMessage}
                                        sharedMediaTab={SHARED_MEDIA_TAB}
                                    />
                                );
                            }) 
                            :
                            isSharedMediaFetched &&
                            <div className="no-shared-media-container">
                                <img src={noSharedMedia} alt="" className="no-starred-message-img" />
                                <p className="no-shared-media-title"><b>No Shared Link</b></p>
                                <p className="no-shared-media-desc">You can see all your shared link from this chat here once you start sharing them.</p>
                            </div>
                        )
                    }
                    {
                        activeSharedMediaTab === SHARED_MEDIA_TAB.documents &&
                        (
                            Object.keys(monthlySharedDocuments).length > 0 ?
                            Object.keys(monthlySharedDocuments).map((value, index) => {
                                return (
                                    <ChatRoomHeaderSharedMediaMonthlyList
                                        monthString={value}
                                        activeSharedMediaTab={activeSharedMediaTab}
                                        sharedMediaItems={monthlySharedDocuments[value]}
                                        toggleModalFile={toggleModalFile}
                                        onClickToMessage={onClickToStarMessage}
                                        sharedMediaTab={SHARED_MEDIA_TAB}
                                    />
                                );
                            })
                            :
                            isSharedMediaFetched &&
                            <div className="no-shared-media-container">
                                <img src={noSharedMedia} alt="" className="no-starred-message-img" />
                                <p className="no-shared-media-title"><b>No Shared Document</b></p>
                                <p className="no-shared-media-desc">You can see all your shared document from this chat here once you start sharing them.</p>
                            </div>
                        )
                    }
                </div>

                {isLoadingMedias &&
                <div className="loading-message-wrapper">
                    <div className="lds-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                    </div>
                </div>
                }
            </ModalBody>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderSharedMedia);
