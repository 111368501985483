import React from 'react';
import "./ChatRoomInputLinkPreview.scss";
// import { taptalk } from "@taptalk.io/web-sdk";
import { connect } from 'react-redux';
import { FiX, FiLink } from 'react-icons/fi';
import { setLinkPreview, clearLinkPreview } from '../../../../../redux/actions/reduxActionLinkPreview';
// import { CHAT_TYPE } from '../../../../../helper/HelperConst';
import HelperChat from '../../../../../helper/HelperChat';
// import Helper from '../../../../../helper/Helper';

let ChatRoomInputLinkPreview = (props) => {
    // let [myAccountData, setMyAccountAccountData] = useState(false);

    // useEffect(() => {
    //     if(props.replyMessage.message) {  
    //         let myAccountData = taptalk.getTaptalkActiveUser();
    //         setMyAccountAccountData(myAccountData)
    //     }else {
    //         setMyAccountAccountData(false);
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.replyMessage])

    return (
        // myAccountData &&
        <div className="chat-room-input-link-preview-wrapper">
            <div className="chat-room-input-link-preview-wrapper-inner">
                <FiX 
                    className="cancel-link-preview-button" 
                    onClick={() => {
                        // HelperChat.resetHeightClearReply(props.replyMessage);
                        HelperChat.resetChatRoomHeight();
                        props.clearLinkPreview(); 
                    }} 
                />

                <div className={`link-preview-content-outer-wrapper 
                                    ${!props.linkPreview.image ? "with-border" : ""}
                `}>

                    {props.linkPreview.isFetching &&
                        <div className="link-preview-message-file">
                            <FiLink />
                        </div>
                    }
                    
                    {props.linkPreview.image &&
                        <div className="link-preview-file-media-wrapper">
                            <img 
                                src={props.linkPreview.image}
                                alt="" 
                                className="link-preview-message-image"
                            />
                        </div>
                    }

                    <div className="link-preview-name-text-wrapper with-image-or-video-or-file">
                        <p className="link-preview-name">
                            <b>{
                                props.linkPreview.title ? 
                                props.linkPreview.title : 
                                props.linkPreview.isFetching ? 
                                "Loading..." : 
                                "" 
                            }</b>
                        </p>

                        <p className="link-preview-text">
                            {
                                props.linkPreview.description ?
                                props.linkPreview.description :
                                props.linkPreview.isFetching ? 
                                props.linkPreview.url : 
                                "" 
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    linkPreview: state.linkPreview,
    activeRoom: state.activeRoom
});

const mapDispatchToProps = {
    setLinkPreview,
    clearLinkPreview
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomInputLinkPreview);
