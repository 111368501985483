import React from 'react';
import './ScheduledMessageTimePicker.scss';
import { DayPicker } from 'react-day-picker';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { FiX } from 'react-icons/fi';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import HelperDate from '../../../helper/HelperDate';
import 'react-day-picker/src/style.css';

const ScheduledMessageTimePicker = (props) => {

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="single-date-modal-calendar">
            <ModalBody>
                <div className="date-time-picker-calendar-wrapper">
                    <FiX className="modal-date-time-picker-icon" cursor="pointer" onClick={props.toggle} />
                    <div id="date-filter-singlewrapper" className="custom-date-picker">
                        <DayPicker
                            mode="single"
                            disabled={props.disabledDays}
                            selected={props.date}
                            onDayClick={(date, {disabled}) => props.onCalendarChanged(date, {disabled})}
                        />
                    </div>
                </div>
                
                <div className="time-input">
                    <input 
                        type="number" 
                        value={props.date.getHours() < 10 ? "0" + props.date.getHours() : props.date.getHours()} 
                        onChange={(e) => props.onHourChanged(e)} 
                    />
                    <p>:</p>
                    <input 
                        type="number" 
                        value={props.date.getMinutes() < 10 ? "0" + props.date.getMinutes() : props.date.getMinutes()} 
                        onChange={(e) => props.onMinuteChanged(e)} 
                    />
                </div>
            </ModalBody>
            
            <ModalFooter>
                {!props.date ?
                    <button className="orange-button main-button-40" disabled>
                        Select a Time
                    </button>
                    :
                    props.isLoading ?
                    <ButtonWithLoadingOrIcon
                        text=""
                        className="orange-button main-button-40"
                        isLoading
                        loadingWhite
                        // position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text={"Send " + HelperDate.formatToString(props.date, "dd/MM/yy") + " at " + HelperDate.formatToString(props.date, "HH:mm")}
                        className="orange-button main-button-40"
                        onClickAction={() => props.submitScheduledMessage()}
                    />
                }
            </ModalFooter>
        </Modal>
    );
};

export default ScheduledMessageTimePicker;
