import Helper from "./Helper";

let leftchannel = [];
let rightchannel = [];
let recorder = null;
let recording = false;
let recordingLength = 0;
let volume = null;
let audioInput = null;
let sampleRate = null;
let AudioContext = window.AudioContext || window.webkitAudioContext;
let context = null;
let analyser = null;
// let stream = null;
let tested = false;
////

let stream = null;
let mediaRecorder, blob, audioSrc;
let chunks = [];
let audio = new Audio();
let type = {
    'type': 'audio/wav'
};
let navigatorMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia);
let getUserMedia = navigatorMedia && navigatorMedia.bind(navigator);
let _dataMedia = { audio: true, video: false };

var HelperAudio = {
    mergeBuffers: (channelBuffer, recordingLength) => {
        let result = new Float32Array(recordingLength);
        let offset = 0;
        let lng = channelBuffer.length;
        for (let i = 0; i < lng; i++){
          let buffer = channelBuffer[i];
          result.set(buffer, offset);
          offset += buffer.length;
        }
        return result;
    },
    interleave: (leftChannel, rightChannel) => {
        let length = leftChannel.length + rightChannel.length;
        let result = new Float32Array(length);
    
        let inputIndex = 0;
    
        for (let index = 0; index < length; ){
          result[index++] = leftChannel[inputIndex];
          result[index++] = rightChannel[inputIndex];
          inputIndex++;
        }
        return result;
      },
    writeUTFBytes: (view, offset, string) => { 
        let lng = string.length;
        for (let i = 0; i < lng; i++){
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    },
    stop: () => {
        recording = false;
        
        // we flat the left and right channels down
        let leftBuffer = HelperAudio.mergeBuffers ( leftchannel, recordingLength );
        let rightBuffer = HelperAudio.mergeBuffers ( rightchannel, recordingLength );
        // we interleave both channels together
        let interleaved = HelperAudio.interleave ( leftBuffer, rightBuffer );
        
        ///////////// WAV Encode /////////////////
        // from http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
        //
    
        // we create our wav file
        let buffer = new ArrayBuffer(44 + interleaved.length * 2);
        let view = new DataView(buffer);
    
        // RIFF chunk descriptor
        HelperAudio.writeUTFBytes(view, 0, 'RIFF');
        view.setUint32(4, 44 + interleaved.length * 2, true);
        HelperAudio.writeUTFBytes(view, 8, 'WAVE');
        // FMT sub-chunk
        HelperAudio.writeUTFBytes(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        // stereo (2 channels)
        view.setUint16(22, 2, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, sampleRate * 4, true);
        view.setUint16(32, 4, true);
        view.setUint16(34, 16, true);
        // data sub-chunk
        HelperAudio.writeUTFBytes(view, 36, 'data');
        view.setUint32(40, interleaved.length * 2, true);
    
        // write the PCM samples
        let lng = interleaved.length;
        let index = 44;
        let volume = 1;
        for (let i = 0; i < lng; i++){
            view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
            index += 2;
        }
    
        // our final binary blob
        const blob = new Blob ( [ view ], { type : 'audio/wav' } );
        
        const audioUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
    
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "a.wav";
        a.click();
        window.URL.revokeObjectURL(url);
    },
    getAudioPermission: async (callback) => {
        try {
            // window.stream = stream = HelperAudio.getStream();
            getUserMedia(_dataMedia, (stream) => {
                recording = true;
                leftchannel.length = rightchannel.length = 0;
                recordingLength = 0;
               
                context = new AudioContext();
                sampleRate = context.sampleRate;
                // creates a gain node
                volume = context.createGain();
                
                // creates an audio node from teh microphone incoming stream
                audioInput = context.createMediaStreamSource(stream);
                
                // Create analyser
                analyser = context.createAnalyser();
                
                // connect audio input to the analyser
                audioInput.connect(analyser);
                
                // connect analyser to the volume control
                // analyser.connect(volume);
                
                let bufferSize = 2048;
                let recorder = context.createScriptProcessor(bufferSize, 2, 2);

                analyser.connect(recorder);
        
                // finally connect the processor to the output
                recorder.connect(context.destination); 

                
                recorder.onaudioprocess = function(e) {
                    if (!recording) return;
                    
                    // Do something with the data, i.e Convert this to WAV
                    let left = e.inputBuffer.getChannelData(0);
                    let right = e.inputBuffer.getChannelData(1);
                   
                    if (!tested) {
                      tested = true;
                      // if this reduces to 0 we are not getting any sound
                      if ( !left.reduce((a, b) => a + b) ) {
                        alert("There seems to be an issue with your Mic");
                        // clean up;
                        HelperAudio.stop();
                        stream.getTracks().forEach(function(track) {
                          track.stop();
                        });
                        context.close();
                      }
                    }
                    // we clone the samples
                    leftchannel.push(new Float32Array(left));
                    rightchannel.push(new Float32Array(right));
                    recordingLength += bufferSize;
                };
                 
                //

                mediaRecorder = new MediaRecorder( stream );
                mediaRecorder.start();

                mediaRecorder.ondataavailable = function ( event ) {
                    chunks.push( event.data );
                }

                mediaRecorder.onstop = function () {
                    recorder.disconnect(context.destination);
                    // audioInput.disconnect(recorder);
                    analyser.disconnect(recorder);
                    stream.getTracks().forEach( function( track ) { track.stop() } );
                    
                    chunks = [];

                    //new
                    recording = false;

                      // we flat the left and right channels down
                    let leftBuffer = HelperAudio.mergeBuffers ( leftchannel, recordingLength );
                    let rightBuffer = HelperAudio.mergeBuffers ( rightchannel, recordingLength );
                    // we interleave both channels together
                    let interleaved = HelperAudio.interleave ( leftBuffer, rightBuffer );
                    
                    ///////////// WAV Encode /////////////////
                    // from http://typedarray.org/from-microphone-to-wav-with-getusermedia-and-web-audio/
                    //
                
                    // we create our wav file
                    let buffer = new ArrayBuffer(44 + interleaved.length * 2);
                    let view = new DataView(buffer);
                
                    // RIFF chunk descriptor
                    HelperAudio.writeUTFBytes(view, 0, 'RIFF');
                    view.setUint32(4, 44 + interleaved.length * 2, true);
                    HelperAudio.writeUTFBytes(view, 8, 'WAVE');
                    // FMT sub-chunk
                    HelperAudio.writeUTFBytes(view, 12, 'fmt ');
                    view.setUint32(16, 16, true);
                    view.setUint16(20, 1, true);
                    // stereo (2 channels)
                    view.setUint16(22, 2, true);
                    view.setUint32(24, sampleRate, true);
                    view.setUint32(28, sampleRate * 4, true);
                    view.setUint16(32, 4, true);
                    view.setUint16(34, 16, true);
                    // data sub-chunk
                    HelperAudio.writeUTFBytes(view, 36, 'data');
                    view.setUint32(40, interleaved.length * 2, true);
                
                    // write the PCM samples
                    let lng = interleaved.length;
                    let index = 44;
                    let volume = 1;
                    for (let i = 0; i < lng; i++){
                        view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
                        index += 2;
                    }
                
                    // our final binary blob
                    const blob = new Blob ( [ view ], { type : 'audio/wav' } ); 
                    
                    //new
                    let fileObj = new File([blob], `sound-${new Date().valueOf()}.${blob.type.split("/")[1]}`, {type: blob.type});
                    callback.onStopRecording(fileObj);
                }   

                callback.onGetPermission();
            }, (err) => {
                console.log(err);
                Helper.doToast("Microphone permission denied.")
            })
        } catch(err) {
            console.log('Issue getting mic', err);
        }
    },
    getStream: (constraints) => {
        if (!constraints) {
            constraints = _dataMedia;
        }

        return navigator.mediaDevices.getUserMedia(constraints);
    },
    stopRecording: () => {
        if(mediaRecorder && mediaRecorder.state !== "inactive") {
            mediaRecorder.stop();
        }
    }
}

export default HelperAudio;

