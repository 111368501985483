import React, { useState, useEffect } from 'react';
import './ChatRoomHeaderGroupInCommon.scss';
import { connect } from 'react-redux';
import Helper from '../../../../../helper/Helper';
import { taptalk, tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { IoIosArrowBack } from 'react-icons/io';
import { ModalHeader, ModalBody } from 'reactstrap';
import noGroup from '../../../../../assets/img/chatroom/no-group.svg';
import iconGroupMini from '../../../../../assets/img/icon-group-mini.svg';

var ChatRoomHeaderGroupInCommon = ({
    activeRoom,
    activeRoomConst,
    activeModalRoomInfo,
    setActiveModalRoomInfo,
    onClickGroupInCommon,
    userData,
}) => {
    let [isLoading, setIsLoading] = useState(false);
    let [groupInCommon, setGroupInCommon] = useState(null);
    let [tempGroupInCommon, setTempGroupInCommon] = useState({
      room: "",
      lastUpdate: false,
      data: false
    })

    useEffect(() => {
      fetchGroupInCommon();
    }, [])

    useEffect(() => {
        if(activeRoom.roomID === tempGroupInCommon.room && tempGroupInCommon.lastUpdate) {
            setGroupInCommon(tempGroupInCommon.data);
            setIsLoading(false);
        } 
    }, [tempGroupInCommon])

    let fetchGroupInCommon = () => {
        setIsLoading(true);

        tapCoreChatRoomManager.fetchGroupInCommon(userData.userID, {
            onSuccess: (res) => {
                setTempGroupInCommon({
                    room: activeRoom.roomID,
                    lastUpdate: new Date().valueOf(),
                    data: res
                })
            },
            onError: (errCode, errMessage) => {
                console.log(errCode, errMessage)
            }
        })
    }

    return (
        <React.Fragment>
            <ModalHeader className={`member-list-header room-info-member-list ${activeModalRoomInfo === activeRoomConst.groupInCommon ? "active-room-info-member-list" : ""}`}> 
                <IoIosArrowBack 
                    className="header-room-info-button back-arrow-modal" 
                    onClick={() => {
                        setActiveModalRoomInfo(activeRoomConst.main);
                    }}
                />

                <b className="room-info-title">{userData.fullname}</b>
            </ModalHeader>

            <ModalBody 
                className={`room-info-member-list ${activeModalRoomInfo === activeRoomConst.groupInCommon ? "active-room-info-member-list" : ""} room-modal-group-in-common-message`}
            >
                {groupInCommon &&
                    (groupInCommon.length === 0 ?
                        <div className="no-group-in-common-message">
                            <img src={noGroup} alt="" className="no-group-in-common-message-img" />

                            <p className="no-group-in-common-desc">
                                You and {userData.fullname} have no groups in Common
                            </p>
                        </div>
                        :
                        <>
                            <div className='group-in-common-counter'>
                                <b>{groupInCommon.length} GROUP{groupInCommon.length > 1 ? "S" : ""} IN COMMON</b>
                            </div>

                            <div className='group-in-common-list-wrapper'>
                                {groupInCommon.map((v, i) => {
                                    return (
                                        <div className="group-in-common-list" key={`group-in-common-list-${i}`} onClick={() => onClickGroupInCommon(v)}>
                                            <div className="room-info-avatar" style={{background: taptalk.getRandomColor(v.name)}}>
                                                {v.imageURL.thumbnail !== "" ?
                                                    <img src={v.imageURL.thumbnail} alt="" className="room-info-avatar-image" />
                                                    :
                                                    <div className="user-avatar-name">
                                                        {Helper.renderUserAvatarWord(v.name, true)}
                                                    </div>
                                                }

                                                <img src={iconGroupMini} alt="" className='mini-group-icon' />
                                            </div>

                                            <div className="message-info-main">
                                                <p className="user-name">
                                                    <b>{v.name}</b>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )
                }

                {isLoading &&
                    <div className="loading-message-wrapper">
                    <div className="lds-ring">
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    </div>
                }
            </ModalBody>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderGroupInCommon);