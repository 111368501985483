import React, { useState, useEffect } from 'react';
import './ChatRoomHeaderReport.scss';
import { connect } from 'react-redux';
import { taptalk, tapCoreContactManager } from '@taptalk.io/web-sdk';
import { IoIosArrowBack } from 'react-icons/io';
import { ModalHeader, ModalBody } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import PopupConfirmation from '../../../../reuseableComponent/popupConfirmation/PopupConfirmation';

const REPORT_CATEGORY = {
    reportUserFalseInfo: "Sending false information",
    reportUserPretend: "Pretending to be someone else",
    reportUserScam: "Scam or fraud",
    reportUserDangerous: "Dangerous Organization",
    reportOthers: "Others"
}

const MAX_CATEGORY_LENGTH = 100;
const MAX_REASON_LENGTH = 2000;

var ChatRoomHeaderReport = ({
    activeRoom,
    activeRoomConst,
    activeModalRoomInfo,
    setActiveModalRoomInfo,
    userData,
    userClick
}) => {

    let [isLoadingSubmitReport, setIsLoadingSubmitReport] = useState(false);
    let [selectedReportCategory, setSelectedReportCategory] = useState(false);
    let [otherCategoryText, setOtherCategoryText] = useState("");
    let [reportReason, setReportReason] = useState("");
    let [letterCountText, setLetterCountText] = useState("0/" + MAX_REASON_LENGTH);
    let [showCategoryError, setShowCategoryError] = useState(false);
    let [showReasonError, setShowReasonError] = useState(false);
    let [showSubmitReportSuccess, setShowSubmitReportSuccess] = useState(false);
    let [showSubmitReportFailure, setShowSubmitReportFailure] = useState(false);
    let [showSubmitCloseConfirmation, setShowSubmitCloseConfirmation] = useState(false);
    let [showLoadingCloseConfirmation, setShowLoadingCloseConfirmation] = useState(false);
    let [showSubmitReportConfirmation, setShowSubmitReportConfirmation] = useState(false);

    useEffect(() => {
        if (selectedReportCategory) {
            setShowCategoryError(false);
        }
        if (selectedReportCategory !== REPORT_CATEGORY.reportOthers) {
            setShowReasonError(false);
        }
    }, [selectedReportCategory]);

    useEffect(() => {
        if (!otherCategoryText) {
            return;
        }
        if (otherCategoryText.length > MAX_CATEGORY_LENGTH) {
            setOtherCategoryText(otherCategoryText.substring(0, MAX_CATEGORY_LENGTH));
        }
        if (otherCategoryText.length > 0) {
            setShowReasonError(false);
        }
    }, [otherCategoryText]);

    useEffect(() => {
        setLetterCountText(reportReason.length.toString() + "/" + MAX_REASON_LENGTH);
    }, [reportReason]);

    let onBackPressed = () => {
        if (isLoadingSubmitReport && !showSubmitCloseConfirmation && !showLoadingCloseConfirmation) {
            toggleLoadingCloseConfirmation();
        }
        else if ((selectedReportCategory || otherCategoryText || reportReason) && !showSubmitCloseConfirmation && !showLoadingCloseConfirmation) {
            toggleSubmitCloseConfirmation();
        }
        else if (userClick || (userData && activeRoom.type !== 1)) {
            setActiveModalRoomInfo(activeRoomConst.memberDetail);
        }
        else {
            setActiveModalRoomInfo(activeRoomConst.main);
        }
    }

    let submitReport = () => {
        if (!selectedReportCategory) {
            setShowCategoryError(true);
            return;
        }
        if (selectedReportCategory === REPORT_CATEGORY.reportOthers && (!otherCategoryText || otherCategoryText.length === 0)) {
            setShowReasonError(true);
            return;
        }
        toggleSubmitReportConfirmation();
    }

    let callReportUserAPI = async () => {
        toggleSubmitReportConfirmation();
        setIsLoadingSubmitReport(true);

        let splitRoomID = activeRoom.roomID.split("-");
        let reportedUserID = activeRoom.type === 1 ? splitRoomID[0] === taptalk.getTaptalkActiveUser().userID ? splitRoomID[1] : splitRoomID[0] : userData ? userData.userID : "";

        tapCoreContactManager.reportUser(
            reportedUserID, 
            selectedReportCategory === REPORT_CATEGORY.reportOthers ? otherCategoryText : selectedReportCategory,
            selectedReportCategory === REPORT_CATEGORY.reportOthers,
            reportReason,
            {
                onSuccess : (response) => {
                    setIsLoadingSubmitReport(false);
                    toggleShowSubmitReportSuccess();
                    console.log(response.message)
                },

                onError : (errorCode, errorMessage) => {
                    setIsLoadingSubmitReport(false);
                    toggleShowSubmitReportFailure();
                    console.log(errorCode, errorMessage);
                }
            }
        )
    }

    let toggleShowSubmitReportSuccess = () => {
        if (showSubmitReportSuccess) {
            setShowSubmitReportSuccess(false);
            setActiveModalRoomInfo(activeRoomConst.main);
        }
        else {
            setShowSubmitReportSuccess(true);
        }
    }
    
    let toggleShowSubmitReportFailure = () => {
        setShowSubmitReportFailure(!showSubmitReportFailure);
    }

    let toggleSubmitCloseConfirmation = () => {
        setShowSubmitCloseConfirmation(!showSubmitCloseConfirmation);
    }

    let toggleLoadingCloseConfirmation = () => {
        setShowLoadingCloseConfirmation(!showLoadingCloseConfirmation);
    }

    let toggleSubmitReportConfirmation = () => {
        setShowSubmitReportConfirmation(!showSubmitReportConfirmation);
    }
      
    return (
        <React.Fragment>
            <ModalHeader className={`member-list-header room-info-member-list active-room-info-member-list`}> 
                <IoIosArrowBack 
                    className="header-room-info-button back-arrow-modal" 
                    onClick={() => {onBackPressed()}}
                />
  
                <b className="room-info-title">
                    {
                        activeModalRoomInfo === activeRoomConst.reportUser ? "Report User" :
                        "Report" 
                    }
                </b>
            </ModalHeader>
  
            <ModalBody className={`room-info-member-list active-room-info-member-list room-modal-report`}>
                <div><p>You may report a user if you find them inappropriate, please choose one of the reason so we know how to handle this properly.</p></div>

                <div className="custom-radio">
                    {
                        activeModalRoomInfo === activeRoomConst.reportUser &&
                        <div>
                            <div></div>
                            <div>
                                <input 
                                    type="radio" 
                                    id={REPORT_CATEGORY.reportUserFalseInfo} 
                                    name="report_category" 
                                    value={REPORT_CATEGORY.reportUserFalseInfo} 
                                    onChange={(e) => {setSelectedReportCategory(REPORT_CATEGORY.reportUserFalseInfo)}}
                                    disabled={isLoadingSubmitReport}
                                />
                                <label htmlFor={REPORT_CATEGORY.reportUserFalseInfo}>{REPORT_CATEGORY.reportUserFalseInfo}</label>
                            </div>
                            <div>
                                <input 
                                    type="radio" 
                                    id={REPORT_CATEGORY.reportUserPretend} 
                                    name="report_category" 
                                    value={REPORT_CATEGORY.reportUserPretend} 
                                    onChange={(e) => {setSelectedReportCategory(REPORT_CATEGORY.reportUserPretend)}}
                                    disabled={isLoadingSubmitReport}
                                />
                                <label htmlFor={REPORT_CATEGORY.reportUserPretend}>{REPORT_CATEGORY.reportUserPretend}</label>
                            </div>
                            <div>
                                <input 
                                    type="radio" 
                                    id={REPORT_CATEGORY.reportUserScam} 
                                    name="report_category" 
                                    value={REPORT_CATEGORY.reportUserScam} 
                                    onChange={(e) => {setSelectedReportCategory(REPORT_CATEGORY.reportUserScam)}}
                                    disabled={isLoadingSubmitReport}
                                />
                                <label htmlFor={REPORT_CATEGORY.reportUserScam}>{REPORT_CATEGORY.reportUserScam}</label>
                            </div>
                            <div>
                                <input 
                                    type="radio" 
                                    id={REPORT_CATEGORY.reportUserDangerous} 
                                    name="report_category" 
                                    value={REPORT_CATEGORY.reportUserDangerous} 
                                    onChange={(e) => {setSelectedReportCategory(REPORT_CATEGORY.reportUserDangerous)}}
                                    disabled={isLoadingSubmitReport}
                                />
                                <label htmlFor={REPORT_CATEGORY.reportUserDangerous}>{REPORT_CATEGORY.reportUserDangerous}</label>
                            </div>
                        </div>
                    }
                    <div>
                        <input 
                            type="radio" 
                            id={REPORT_CATEGORY.reportOthers} 
                            name="report_category" 
                            value={REPORT_CATEGORY.reportOthers} 
                            onChange={(e) => {setSelectedReportCategory(REPORT_CATEGORY.reportOthers)}}
                            disabled={isLoadingSubmitReport}
                        />
                        <label htmlFor={REPORT_CATEGORY.reportOthers}>{REPORT_CATEGORY.reportOthers}</label>
                    </div>
                </div>

                {
                    showCategoryError &&
                    <p className="room-modal-report-error">Please select a category</p>
                }

                {
                    selectedReportCategory === REPORT_CATEGORY.reportOthers &&
                    <input 
                        type="text" 
                        className={showReasonError ? "room-modal-report-text-input-others-error" : "room-modal-report-text-input-others"}
                        value={otherCategoryText}
                        placeholder="Please specify your reason"
                        maxLength={100}
                        onChange={(e) => {setOtherCategoryText(e.target.value)}}
                        autoComplete="off"
                        disabled={isLoadingSubmitReport}
                    />
                }

                {
                    showReasonError &&
                    <p className="room-modal-report-error">This field is required</p>
                }

                <div style={{marginTop: "12px"}}>
                    <b className="room-modal-report-why">Why do you want to report this user? </b>
                    <span className="room-modal-report-optional">(Optional)</span>
                </div>
                
                <textarea
                    className="room-modal-report-text-input"
                    value={reportReason}
                    placeholder="Tell us why..." 
                    maxLength={2000}
                    onChange={(e) => {setReportReason(e.target.value)}}
                    disabled={isLoadingSubmitReport}
                    rows={1}
                />

                <p className="room-modal-report-letter-count">{letterCountText}</p>

                {isLoadingSubmitReport ?
                    <ButtonWithLoadingOrIcon
                        text="Submit Report"
                        className="main-button-48 red-button room-modal-report-submit-button"
                        isLoading
                        loadingWhite
                        position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Submit Report"
                        className="main-button-48 red-button room-modal-report-submit-button"
                        onClickAction={() => submitReport()}
                    />
                }
            </ModalBody>
            
            <PopupConfirmation
                isOpen={showSubmitCloseConfirmation}
                toggle={toggleSubmitCloseConfirmation}
                title="You haven't submitted your report"
                description="Your report has not been submitted, are you sure you want to cancel and discard the report?"
            >
                <ButtonWithLoadingOrIcon
                    text="Yes"
                    className="main-button-48 orange-button confirmation-button"
                    onClickAction={() => onBackPressed()}
                />
            </PopupConfirmation>
            
            <PopupConfirmation
                isOpen={showLoadingCloseConfirmation}
                toggle={toggleLoadingCloseConfirmation}
                title="Your report might have not been submitted"
                description="Your report might have not been submitted because you clicked back while we are trying to submit your report, are you sure you want to go back?"
            >
                <ButtonWithLoadingOrIcon
                    text="Yes"
                    className="main-button-48 orange-button confirmation-button"
                    onClickAction={() => onBackPressed()}
                />
            </PopupConfirmation>
            
            <PopupConfirmation
                isOpen={showSubmitReportConfirmation}
                toggle={toggleSubmitReportConfirmation}
                title="Submit Report"
                description="You will submit a report for this user, you can tell us why so we can help you. Are you sure you want to submit now?"
            >
                <ButtonWithLoadingOrIcon
                    text="Submit"
                    className="main-button-48 red-button confirmation-button"
                    onClickAction={() => callReportUserAPI()}
                />
            </PopupConfirmation>
            
            <PopupConfirmation
                isOpen={showSubmitReportSuccess}
                toggle={toggleShowSubmitReportSuccess}
                title="Report has been submitted"
                description="Thank you for reporting!"
                cancelButtonText="OK"
                cancelButtonStyle="orange-button"
                singleButton={true}
            />
            
            <PopupConfirmation
                isOpen={showSubmitReportFailure}
                toggle={toggleShowSubmitReportFailure}
                title="Failed to submit report"
                description="There has been an error while submitting, please try again."
                cancelButtonText="OK"
                cancelButtonStyle="orange-button"
                singleButton={true}
            />

        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderReport);
