import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './BlockingScreenLoading.scss';

let BlockingScreenLoading = (props) => {
    let {
      isOpen,
      toggle,
      loadingText,
    } = props;

    return (        
        <Modal 
            className={loadingText ? "modal-blocking-screen-loading-text" : "modal-blocking-screen-loading-notext"}
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalBody className="">
                <div className="loading-image">
                    <div className="lds-ring">
                        <div /><div /><div /><div />
                    </div>
                </div>
                <br />
                {
                    loadingText &&
                    <div className="loading-text">
                        <b>{loadingText}</b>
                    </div>
                }
            </ModalBody>
        </Modal>
    )
}

export default BlockingScreenLoading;
