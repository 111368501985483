import React, { useState, useEffect } from 'react';
import './ChatRoomMessageInfoComp.scss';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import CheckMarkDoubleLight from '../../../../assets/img/chatroom/icon-double-check-light.svg';
import CheckMarkLight from '../../../../assets/img/chatroom/icon-check-light.svg';
import AirplaneLight from '../../../../assets/img/chatroom/icon-airplane-light.svg';
import IconStarGrey from '../../../../assets/img/chatroom/icon-star-grey.svg';
import IconStarWhite from '../../../../assets/img/chatroom/icon-star-white.svg';
import IconPinGrey from '../../../../assets/img/chatroom/icon-pinned-grey.svg';
import IconPinWhite from '../../../../assets/img/chatroom/icon-pinned-white.svg';
import HelperChat from '../../../../helper/HelperChat';
import { CHAT_TYPE } from '../../../../helper/HelperConst';
import { connect } from 'react-redux';
import { FiEye } from 'react-icons/fi';
import { tapCoreMessageManager } from "@taptalk.io/web-sdk";

var ChatRoomMessageInfoComp = (props) => {
    let { 
        isOut,
        message,
        className,
        isWhiteIcon,
        allStarredMessages,
        allPinnedMessages,
        lastClickBubble
    } = props;

    let [readCounter, setReadCounter] = useState(0);

    useEffect(() => {
        if(lastClickBubble) {
            runGetTotalCount();
        }
    }, [lastClickBubble])

    let runGetTotalCount = () => {
        tapCoreMessageManager.getTotalReadCount(message.messageID, {
            onSuccess: (val) => {
                setReadCounter(val);
            },
            onError: (errCode, errorMessage) => {
                console.log(errCode, errorMessage);
            }
        });
    }

    let onClickTestGetMessageInfo = () => {
        tapCoreMessageManager.fetchMessageInfo(message.messageID, {
            onSuccess: (data) => {
                console.log(data);
            },
            onError: (errCode, errorMessage) => {
                console.log(errCode, errorMessage);
            }
        });
    }
    
    return (
        <p className={`message-info ${isOut ? 'message-info-out' : 'message-info-in'} ${className}`} onClick={onClickTestGetMessageInfo}>
            {(isOut && allPinnedMessages.messages[message.messageID]) &&
                <img src={isOut ? IconPinWhite : ((message.data.caption === '' && message.type !== CHAT_TYPE.TAPChatMessageTypeFile) ? IconPinWhite : IconPinGrey)} className="icon-pin-message-info-out" alt="" />
            }

            {readCounter > 0 &&
                <>
                    <FiEye className='icon-read-eye' />{" "}{readCounter}{" "}<span className="centered-dot" />{" "} 
                </>
            }

            {allStarredMessages[message.messageID] &&
                <img src={isOut ? IconStarWhite : ((message.data.caption === '' && message.type !== CHAT_TYPE.TAPChatMessageTypeFile && message.type !== CHAT_TYPE.TAPChatMessageTypeVoice) ? IconStarWhite : IconStarGrey)} className="icon-star-message-info" alt="" />
            }

            {message.isMessageEdited &&
                <>
                    Edited{" "}<span className="centered-dot" />{" "} 
                </>
            }

            {HelperChat.getDateMonthYear(message.created)} <span className="centered-dot" /> {HelperChat.getHourMinute(message.created)}

            {isOut ?
                (isWhiteIcon ?
                    <>
                        {(message.isSending && !message.isRead) &&
                            <img src={AirplaneLight} alt="" />
                        }

                        {(!message.isSending && !message.isDelivered && !message.isRead) &&
                            <img src={CheckMarkLight} alt="" />
                        }

                        {(!message.isSending && message.isDelivered && !message.isRead) &&
                            <img src={CheckMarkDoubleLight} alt="" />
                        }

                        {message.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }
                    </>
                    :
                    <>
                        {(message.isSending && !message.isRead) &&
                            <img src={AirplaneDark} alt="" />
                        }

                        {(!message.isSending && !message.isDelivered && !message.isRead) &&
                            <img src={CheckMarkDark} alt="" />
                        }

                        {(!message.isSending && message.isDelivered && !message.isRead) &&
                            <img src={CheckMarkDoubleDark} alt="" />
                        }

                        {message.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }

                        {/* {!message.isDelivered &&
                            <img src={CheckMarkDark} alt="" />
                        } */}
                    </>
                )
                :
                ""
            }

            {(!isOut && allPinnedMessages.messages[message.messageID]) &&
                <img src={((message.data.caption === '' && message.type !== CHAT_TYPE.TAPChatMessageTypeFile && message.type !== CHAT_TYPE.TAPChatMessageTypeVoice) ? IconPinWhite : IconPinGrey)} className="icon-pin-message-info-in" alt="" />
            }
        </p>
    );
}

const mapStateToProps = state => ({
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages
});

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInfoComp);
