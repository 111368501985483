import React, { useState, useEffect } from 'react';
import './RoomListMyAccount.scss'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FiChevronLeft, FiChevronRight, FiDownload, FiX } from 'react-icons/fi';
import { MdChevronRight } from 'react-icons/md';
// import IconEdit from '../../../assets/img/icon-edit.svg';
// import { Scrollbars } from 'react-custom-scrollbars';
// import { IoIosArrowForward } from "react-icons/io";
import { taptalk, tapCoreRoomListManager, tapCoreContactManager } from '@taptalk.io/web-sdk';
import Helper from "../../../helper/Helper";
import HelperDate from "../../../helper/HelperDate";
import HelperChat from '../../../helper/HelperChat';
import { connect } from 'react-redux';
import { clearActiveRoom } from '../../../redux/actions/reduxActionActiveRoom';
import { clearMentionUsername } from '../../../redux/actions/reduxActionMentionUsername';
import { setBlockedUserIDs } from '../../../redux/actions/reduxActionBlockedUserIDs';
import iconLogout from '../../../assets/img/logout-white.svg';
import iconTrash from '../../../assets/img/icon-trash-cross.svg';
import iconTrashWhite from '../../../assets/img/icon-trash-cross-white.svg';
// import muteOrange from "../../../assets/img/icon-mute-orange.svg";
import unmuteOrange from "../../../assets/img/icon-unmute-orange.svg";
import iconPlus from '../../../assets/img/icon-plus.svg';
import iconMinus from '../../../assets/img/icon-minus.svg';
import iconGallery from '../../../assets/img/icon-gallery.svg';
import iconGalleryWhite from '../../../assets/img/icon-gallery-white.svg';
import iconStar from '../../../assets/img/icon-star.svg';
import iconChat from '../../../assets/img/icon-chat.svg';
import iconRemove from '../../../assets/img/icon-trash.svg';
import iconStarred from '../../../assets/img/icon-starred.svg';
import iconWarning from '../../../assets/img/icon-warning-red.svg';
import iconBlock from '../../../assets/img/icon-block-red.svg';
import iconSharedMedia from '../../../assets/img/icon-paperclip-vertical-orange.svg';
import iconGroup from '../../../assets/img/icon-group.svg';
import ProfileCarousel from '../../reuseableComponent/profileCarousel/ProfileCarousel';
import MuteOptionModal from "../../reuseableComponent/muteOptionModal/MuteOptionModal";
import ChatRoomUpdateModal from '../../chatRoom/chatRoomMain/chatRoomUpdateModal/ChatRoomUpdateModal';
import PopupConfirmation from '../../reuseableComponent/popupConfirmation/PopupConfirmation';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import BlockingScreenLoading from '../../reuseableComponent/blockingScreenLoading/BlockingScreenLoading';
import { MUTE_OPTION } from '../../../helper/HelperConst';

const MAXIMUM_USER_PHOTO = 10;

let InputField = ({className="", label, value, optional=false, placeholder="", onChange = () => {}, editMode, visible, children, childrenAfter = false, disabled = false, maxLength = null}) => {
    return visible || editMode ? <div className={"input-my-account " + className}>
        <label>
            <b>{label}</b> {optional && editMode ? <span style={{fontSize: "13px", color: "#848484"}}>(Optional)</span> : ""}
            {maxLength && editMode ? <span style={{fontSize: 12, color: "#A8A8A8", float: "right"}}>{value.length}/{maxLength}</span> : ""}
        </label>
        {childrenAfter ? "" : children}
        {
            editMode ?
            <input type="text" alt="" value={value} onChange={onChange} disabled={disabled} placeholder={placeholder} maxLength={maxLength} />
            :
            <p className='input'>{value}</p>
        }
        
        {childrenAfter ? children : ""}
    </div> : ""
}

var RoomListMyAccount = (props) => {
    let [newAvatar, setNewAvatar] = useState(0);
    let [userData, setUserData] = useState()
    let [waitresponseLogout, setWaitresponseLogout] = useState(false);
    let [valAvatarImages, setValAvatarImages] = useState([{fullsizeImageURL: "", thumbnailImageURL: ""}]);
    //   let [modalUnableToUpload, setModalUnableToUpload] = useState(false);
    //   let [modalCancelUpload, setModalCancelUpload] = useState(false);
    let [valFullName, setValFullName] = useState('');
    let [valBio, setValBio] = useState('');
    let [valUsername, setValUsername] = useState('');
    let [valPhone, setValPhone] = useState('');
    let [valEmail, setValEmail] = useState('');
    let [editMode, setEditMode] = useState(false);


    let [showProfilePicMenu, setShowProfilePicMenu] = useState(false);
    let [modalConfirmSaveChanges, setModalConfirmSaveChanges] = useState(false);
    let [modalUnsavedChanges, setModalUnsavedChanges] = useState(false);
    //   let [errorEditProfile, setErrorEditProfile] = useState('');
    let [isLoadingEditProfile, setIsLoadingEditProfile] = useState(false);

    let [modalSetAsMainPhoto, setModalSetAsMainPhoto] = useState(false)
    let [modalDeletePhoto, setModalDeletePhoto] = useState(false)
    let [isLoadingUpdateImage, setIsLoadingUpdateImage] = useState(false)
    let [targetImgID, setTargetImgID] = useState('')
    let [targetImgCreatedTime, setTargetImgCreatedTime] = useState('')
    let [currentIdx, setCurrentIdx] = useState(0)
    let [modalMaximumProfilePicture, setModalMaximumProfilePicture] = useState(false)
    let [showMuteOptionModal, setShowMuteOptionModal] = useState(false);

    let [showBlockUserConfirmation, setShowBlockUserConfirmation] = useState(false);
    let [showUnblockUserConfirmation, setShowUnblockUserConfirmation] = useState(false);
    let [isBlockedContact, setIsBlockedContact] = useState(false);
    let [isLoadingBlockUser, setIsLoadingBlockUser] = useState(false);
    let [blockErrorModalTitle, setBlockErrorModalTitle] = useState(false);

    useEffect(() => {
        getMyAccountData();
        // bodyClickListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps

        if (props.myAccountModal && props.roomData && props.roomData.room.type === 1) {
            if (props.blockedUserIDs && props.blockedUserIDs.length > 0) {
                const otherUserID = HelperChat.getOtherUserIDFromRoomID(props.roomData.room.roomID);
                setIsBlockedContact(props.blockedUserIDs.includes(otherUserID));
            }
        }
        else if (props.userID) {
            setIsBlockedContact(props.blockedUserIDs.includes(props.userID));
        }
        
        // Contact blocked/unblocked listener
        tapCoreContactManager.addContactListener({
            onContactBlocked: (user) => {
                if (props.roomData && props.roomData.room.type === 1) {
                    const otherUserID = HelperChat.getOtherUserIDFromRoomID(props.roomData.room.roomID);
                    if (otherUserID === user.userID) {
                        setIsBlockedContact(true);
                    }
                }
                else if (props.userID && props.userID === user.userID) {
                    setIsBlockedContact(true);
                }
            },
            onContactUnblocked: (user) => {
                if (props.roomData && props.roomData.room.type == 1) {
                    const otherUserID = HelperChat.getOtherUserIDFromRoomID(props.roomData.room.roomID);
                    if (otherUserID === user.userID) {
                        setIsBlockedContact(false);
                    }
                }
                else if (props.userID && props.userID === user.userID) {
                    setIsBlockedContact(false);
                }
            }
        });
    }, [props.myAccountModal, props.memberDetail]);

    //   let bodyClickListener = () => {
    //     let target = document.querySelector('body');
        
    //     target.addEventListener('click', function(e) {
    //         if(!e.target.classList.contains("input-file-content") && !e.target.classList.contains("change-avatar-button") ) {
    //             setTimeout(() => {
    //                 setShowProfilePicMenu(false)
    //             }, 500);
    //         }
    //     })
    //   }

    let getMyAccountData = () => {
        if(props.memberDetail) {
            setValFullName(props.memberDetail.fullname);
            setValUsername(props.memberDetail.username);
            setValPhone(props.memberDetail.phone);
            setValEmail(props.memberDetail.email);
            setValBio(props.memberDetail.bio);
            getListUserPhoto(props.memberDetail.userID)
        } else {
            let userID;
            if(props.userID) userID = props.userID;
            else userID = taptalk.getTaptalkActiveUser().userID;

            tapCoreRoomListManager.getUserByIdFromApi(userID, {
                onSuccess: (response) => {
                    setUserData(response.user);
                    setValFullName(response.user.fullname);
                    setValUsername(response.user.username);
                    setValPhone(response.user.phone);
                    setValEmail(response.user.email);
                    setValBio(response.user.bio)
                },
        
                onError: (errorCode, errorMessage) => {
                    Helper.doToast(errorMessage, "fail")
                }
            })
            getListUserPhoto(userID)
        }
    }

    let detectChanges = () => {
        if(userData) {
        if(valFullName !== userData.fullname) return true
        if(valUsername !== userData.username) return true
        if(valPhone !== userData.phone) return true
        if(valEmail !== userData.email) return true
        if(valBio !== userData.bio) return true
        return false
        }
    }

    let removeChanges = () => {
        if(userData) {
            setValFullName(userData.fullname);
            setValUsername(userData.username);
            setValPhone(userData.phone);
            setValEmail(userData.email);
            setValBio(userData.bio)
        }
    }

    let onClickLogout = () => {
        setWaitresponseLogout(true);
        
        taptalk.logoutAndClearAllTapTalkData({
            onSuccess: (successMessage) => {
                setWaitresponseLogout(false);
                props.clearActiveRoom();
                window.location.href = "/login";
            },
            onError: (errorCode, errorMessage) => {
                setWaitresponseLogout(false);
                props.clearActiveRoom();
                window.location.href = "/login";
            }
        })
    };

    //   let cancelUploadClick = () => {
    //     setNewAvatar(false);
    //   };

    let getListUserPhoto = (userID) => {
        taptalk.getListUserPhoto(userID, {
            onSuccess: (response) => {
                setValAvatarImages(response.photos)
            },

            onError: (errorCode, errorMessage) => {
                Helper.doToast(errorMessage, "fail")
            }
        })
    }

    let onChangeAvatarAction = (e) => {
        let fileType = ["image/png", "image/jpg", "image/jpeg"];
        let reader = new FileReader();

        if(e.target.files && e.target.files.length > 0 && fileType.includes(e.target.files[0].type)) {
            reader.onload = function(e) {
                document.getElementById("my-account-avatar-image").src = e.target.result;
            }

            reader.readAsDataURL(e.target.files[0]);

            setNewAvatar(1);

            taptalk.uploadUserPhoto(e.target.files[0], {
                onSuccess: (message) => {
                    getListUserPhoto()
                    setNewAvatar(3);
                    setCurrentIdx(0)
                    setTimeout(() => {
                        setNewAvatar(0);
                    }, 1000);
                },
                onError: (errorCode, errorMessage) => {
                    setNewAvatar(4);
                    Helper.doToast(errorMessage, "fail")
                }
            })
        }
    }

    let generateModalMaximumProfilePicture = () => {
        return <Modal className="modal-info-confirmation" isOpen={modalMaximumProfilePicture}>
        <ModalBody>
            <b className="modal-info-confirmation-title">You have reached maximum profile picture.</b>

            <p className="modal-info-confirmation-desc">
                You can only have 10 profile picture at a time, remove some picture to upload new ones.
            </p>

            <div className="modal-info-confirmation-button-wrapper">
                <button className="orange-button" onClick={() => setModalMaximumProfilePicture()} style={{width: "100%", margin: "auto"}}>
                    <b>OK</b>
                </button>
            </div>
        </ModalBody>
    </Modal>
    }

    let generateModalUnableToUpload = () => {
        //   modalUnableToUpload
        return (
            <Modal isOpen={false} className="modal-upload-avatar" toggle={() => props.toggleMyAccountModal()} >
                <ModalBody>
                    <b>Unable to upload</b>
                    <p>
                        An error occurred while uploading your profile picture, would you like to try again?
                    </p>

                    <div className="modal-upload-avatar-button-wrapper">
                        <button className="button-cancel smaller-hover">
                            <b>Cancel</b>
                        </button>

                        <button className="button-retry smaller-hover">
                            <b>Retry</b>
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    //   let toggleModalUnableToUpload = () => {

    //   }

    let generateModalSetAsMainPhoto = () => {
        return (
            // modalCancelUpload
            <Modal className="modal-info-confirmation" isOpen={modalSetAsMainPhoto}>
                <ModalBody>
                    <b className="modal-info-confirmation-title">Set as Main Photo?</b>

                    <p className="modal-info-confirmation-desc">
                        You will be replacing the main photo with this photo. Are you sure you want to continue?
                    </p>

                    <div className="modal-info-confirmation-button-wrapper">
                        <button 
                            className='no-fill-grey-border-button'
                            onClick={() => {
                                setModalSetAsMainPhoto(false);
                            }}
                        >
                            <b>Cancel</b>
                        </button>
                        
                        {isLoadingUpdateImage ?
                            <button className="orange-button">
                                <div className="lds-ring">
                                    <div /><div /><div />
                                </div>
                            </button>
                            :
                            <button className="orange-button" onClick={() => submitSetAsMainPhoto()}>
                                <b>Replace</b>
                            </button>
                        }
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    let generateModalDeletePhoto = () => {
        return (
            // modalCancelUpload
            <Modal className="modal-info-confirmation" isOpen={modalDeletePhoto}>
                <ModalBody>
                    <b className="modal-info-confirmation-title">Remove Photo?</b>

                    <p className="modal-info-confirmation-desc">
                        You will be removing this profile picture. Are you sure you want to continue?
                    </p>

                    <div className="modal-info-confirmation-button-wrapper">
                        <button 
                            className='no-fill-grey-border-button'
                            onClick={() => {
                                setModalDeletePhoto(false);
                            }}
                        >
                            <b>Cancel</b>
                        </button>
                        
                        {isLoadingUpdateImage ?
                            <button className="red-button">
                                <div className="lds-ring">
                                    <div /><div /><div />
                                </div>
                            </button>
                            :
                            <button className="red-button" onClick={() => submitDeletePhoto()}>
                                <b>Remove</b>
                            </button>
                        }
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    let generateModalCancelUpload = () => {
        return (
        // modalCancelUpload
        <Modal isOpen={false} className="modal-upload-avatar" toggle={() => props.toggleMyAccountModal()} >
            <ModalBody>
                <b>Cancel Upload?</b>
                <p>
                    You are uploading a picture to change your avatar. Are you sure you want to cancel this progress?
                </p>

                <div className="modal-upload-avatar-button-wrapper">
                    <button className="button-cancel smaller-hover">
                        <b>Continue</b>
                    </button>

                    <button className="button-cancel-red smaller-hover">
                        <b>Cancel Upload</b>
                    </button>
                </div>
            </ModalBody>
        </Modal>
        )
    }

    let generateModalConfirmSaveChanges = () => {
        return (
            <Modal className="modal-info-confirmation" isOpen={modalConfirmSaveChanges}>
                <ModalBody>
                    <b className="modal-info-confirmation-title">Save Changes?</b>

                    <p className="modal-info-confirmation-desc">
                        You will be saving new changes on your profile. Are you sure you want to continue?
                    </p>

                    {/* {errorEditProfile.length > 0 &&
                        <p className="modal-info-confirmation-error"> 
                            {errorEditProfile}
                        </p>
                    } */}

                    <div className="modal-info-confirmation-button-wrapper">
                        <button 
                            className='no-fill-grey-border-button'
                            onClick={() => {
                                setModalConfirmSaveChanges(false);
                            }}
                        >
                            <b>Cancel</b>
                        </button>
                        
                        {isLoadingEditProfile ?
                            <button className="orange-button">
                                <div className="lds-ring">
                                    <div /><div /><div />
                                </div>
                            </button>
                            :
                            <button className="orange-button" onClick={() => submitEditProfile()}>
                                <b>Save</b>
                            </button>
                        }
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    let generateModalUnsavedChanges = () => {
        return (
            <Modal className="modal-info-confirmation" isOpen={modalUnsavedChanges}>
                <ModalBody>
                    <b className="modal-info-confirmation-title">You have unsaved changes!</b>

                    <p className="modal-info-confirmation-desc">
                        All updates will not be saved. Are you sure you want to continue?
                    </p>

                    <div className="modal-info-confirmation-button-wrapper">
                        <button 
                            className='no-fill-grey-border-button'
                            onClick={() => {
                                setModalUnsavedChanges(false);
                            }}
                        >
                            <b>Cancel</b>
                        </button>

                        <button className="red-button" onClick={() => {
                            removeChanges()
                            setModalUnsavedChanges(false)
                            setEditMode(false)
                        }}>
                            <b>Yes</b>
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    let generateModalBlockConfirmation = () => {
        return (
            <PopupConfirmation
                isOpen={showBlockUserConfirmation}
                toggle={toggleBlockUserConfirmation}
                title="Block User"
                description="Blocking someone will not allow them to see your updates, and you will not receive any messages or calls from this contact. Are you sure you want to continue?"
            >
                {/* {
                    isLoadingBlockUser ?
                    <ButtonWithLoadingOrIcon
                        text="Block"
                        className="main-button-48 red-button confirmation-button"
                        isLoading
                        loadingWhite
                        position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Block"
                        isload
                        className="main-button-48 red-button confirmation-button"
                        onClickAction={() => blockUser()}
                    />
                } */}
                <ButtonWithLoadingOrIcon
                    text="Block"
                    isload
                    className="main-button-48 red-button confirmation-button"
                    onClickAction={() => blockUser()}
                />
            </PopupConfirmation>
        );
    }

    let generateBlockingScreenLoading = () => {
        return (
            <BlockingScreenLoading isOpen={isLoadingBlockUser} />
        )
    }

    let toggleBlockUserConfirmation = () => {
        setShowBlockUserConfirmation(!showBlockUserConfirmation);
    }
    
    let blockUser = () => {
        toggleBlockUserConfirmation();
        setIsLoadingBlockUser(true);
        tapCoreContactManager.blockUser(
            props.userID,
            {
                onSuccess : (response) => {
                    setIsLoadingBlockUser(false);
                    setIsBlockedContact(true);
                    let _blockedUserIDs = props.blockedUserIDs;
                    const index = _blockedUserIDs.indexOf(props.userID);
                    if (index === -1) {
                      _blockedUserIDs.push(props.userID);
                      props.setBlockedUserIDs(_blockedUserIDs);
                    }
                },

                onError : (errorCode, errorMessage) => {
                    setIsLoadingBlockUser(false);
                    setBlockErrorModalTitle("Failed to block user");
                    console.log(errorCode, errorMessage);
                }
            }
        )
    }

    let generateModalUnblockConfirmation = () => {
        return (
            <PopupConfirmation
                isOpen={showUnblockUserConfirmation}
                toggle={toggleUnblockUserConfirmation}
                title={"Unblock " + (props.memberDetail ? props.memberDetail.fullname : (props.roomData && props.roomData.room && props.roomData.room.type === 1) ? props.roomData.room.name : "User") + "?"}
                description="Unblocking lets you receive messages and calls from this contact. Are you sure you want to continue?"
            >
                {/* {
                    isLoadingBlockUser ?
                    <ButtonWithLoadingOrIcon
                        text="Yes"
                        className="main-button-48 orange-button confirmation-button"
                        isLoading
                        loadingWhite
                        position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Yes"
                        className="main-button-48 orange-button confirmation-button"
                        onClickAction={() => unblockUser()}
                    />
                } */}
                <ButtonWithLoadingOrIcon
                    text="Yes"
                    className="main-button-48 orange-button confirmation-button"
                    onClickAction={() => unblockUser()}
                />
            </PopupConfirmation>
        );
    }

    let toggleUnblockUserConfirmation = () => {
        setShowUnblockUserConfirmation(!showUnblockUserConfirmation);
    }
    
    let unblockUser = () => {
        toggleUnblockUserConfirmation();
        setIsLoadingBlockUser(true);
        tapCoreContactManager.unblockUser(
            props.userID,
            {
                onSuccess : (response) => {
                    setIsLoadingBlockUser(false);
                    setIsBlockedContact(false);
                    let _blockedUserIDs = props.blockedUserIDs;
                    const index = _blockedUserIDs.indexOf(props.userID);
                    if (index !== -1) {
                      _blockedUserIDs.splice(index, 1);
                      props.setBlockedUserIDs(_blockedUserIDs);
                    }
                },

                onError : (errorCode, errorMessage) => {
                    setIsLoadingBlockUser(false);
                    setBlockErrorModalTitle("Failed to unblock user");
                    console.log(errorCode, errorMessage);
                }
            }
        )
    }

    let toggleBlockErrorModal = () => {
        if (blockErrorModalTitle) {
            setBlockErrorModalTitle(false);
        }
    }

    let generateModalBlockError = () => {
        return (
            <PopupConfirmation
                isOpen={blockErrorModalTitle}
                toggle={toggleBlockErrorModal}
                title={blockErrorModalTitle}
                description="There has been an error while submitting, please try again."
                cancelButtonText="OK"
                cancelButtonStyle="orange-button"
                singleButton={true}
            />
        );
    }

    //   let toggleModalCancelUpload = () => {
        
    //   }
    let ProfilePicMenu = ({ main = false, imgID, imgUrl = "", imgCreatedTime }) => {
        return (
            <div className="input-file-wrapper-chat" style={{display: showProfilePicMenu ? 'block' : 'none'}}>
                {
                    main ? ""
                    : <div className="input-file-content"
                            onClick={() => {
                                setTargetImgID(imgID)
                                setModalSetAsMainPhoto(true)
                            }}
                        >
                            <img src={iconGallery} alt="" />
                            <img src={iconGalleryWhite} alt="" />
                            <span>Set as Main Photo</span> 
                        </div>
                }
                <div className="input-file-content"
                    onClick={() => {
                    let name = imgUrl.split('/')[-1]
                    Helper.downloadURL(imgUrl, name)
                    }}
                >
                    <FiDownload />
                    <span>Save Image</span>
                </div>
                <div className="input-file-content"
                    onClick={() => {
                        setTargetImgID(imgID)
                        setTargetImgCreatedTime(imgCreatedTime)
                        setModalDeletePhoto(true)
                    }}
                >
                    <img src={iconTrash} alt=""/>
                    <img src={iconTrashWhite} alt=""/>
                    <span>Remove Photo</span> 
                </div>
            </div>
        )
    }

    let submitEditProfile = () => {
        setIsLoadingEditProfile(true)
        taptalk.updateBio(valBio, {
            onSuccess: (successMessage) => {
                let temp = {...userData}
                temp.bio = valBio;
                setUserData(temp);
                setModalConfirmSaveChanges(false)
                setIsLoadingEditProfile(false)
                setEditMode(false)
            },
            onError: (errorCode, errorMessage) => {
                Helper.doToast(errorMessage);
                setModalConfirmSaveChanges(false)
                setIsLoadingEditProfile(false)
            }
        })
        
    }

    let submitSetAsMainPhoto = () => {
        setIsLoadingUpdateImage(true)
        taptalk.setMainUserPhoto(targetImgID, {
            onSuccess: (successMessage) => {
                setIsLoadingUpdateImage(false)
                setModalSetAsMainPhoto(false)
                getListUserPhoto()
                setCurrentIdx(0)
            },
            onError: (errorCode, errorMessage) => {
                setIsLoadingUpdateImage(false)
                setModalSetAsMainPhoto(false)
                Helper.doToast(errorMessage);
            }
        })
    }

    let submitDeletePhoto = () => {
        setIsLoadingUpdateImage(true)
        taptalk.deleteUserPhoto(targetImgID, targetImgCreatedTime, {
            onSuccess: (successMessage) => {
                setIsLoadingUpdateImage(false)
                setModalDeletePhoto(false)
                getListUserPhoto()
            },
            onError: (errorCode, errorMessage) => {
                setIsLoadingUpdateImage(false)
                setModalDeletePhoto(false)
                Helper.doToast(errorMessage);
            }
        })
    }

    let onClickBackButton = () => {
        props.clearMentionUsername();
        setShowProfilePicMenu(false)

        if(!editMode) {
            props.toggleMyAccountModal();
        }else {
            if(detectChanges()) setModalUnsavedChanges(true)
            else setEditMode(false)
        }
    }

    let onClickCloseButton = () => {
        props.closeModalRoomInfo();
    }

    //   let [medias, setMedias] = useState([])

    //   useEffect(() => {
    //     if(props.memberDetail) {
    //         tapCoreChatRoomManager.getRoomMedia(`${taptalk.getTaptalkActiveUser().userID}-${props.memberDetail.userID}`, {
    //             onSuccess: (data) => {
    //                 if(data.media && data.media.length > 0) setMedias(data.media)
    //                 else setMedias(false)
    //             },
    //             onError: (errorCode, errorMessage) => {
    //                 Helper.doToast(errorMessage)
    //                 setMedias(false)
    //             }
    //         })
    //     } else if(props.chatRoomDataForHeader) {
    //         setMedias(true)
    //     } else {
    //         setMedias(false)
    //     }
    //   }, [props.userID, props.chatRoomDataForHeader, props.memberDetail])

    //   let generateSharedMedia = () => {
    //       return <React.Fragment>
    //            {/* {!props.memberDetail && <div style={{height: 28}}></div>} */}
    //            <div className="shared-media-info">
    //                 <b className="shared-media-title">SHARED MEDIA</b>
    //                 <div className="shared-media-list-wrapper">
    //                     {medias && medias.length > 0 ?
    //                         medias.map((item, index) => {
    //                             return (
    //                                 <React.Fragment key={`shared-media-${index}`}>
    //                                     {item.messageType === 1002 &&
    //                                         // console.log(value.data)
    //                                         <ChatRoomHeaderInfoImage 
    //                                             mediaData={item}
    //                                             toggleModalFileProps={() => props.toggleModalFile()} 
    //                                         />
    //                                     }

    //                                     {item.messageType === 1003 &&
    //                                         <ChatRoomHeaderInfoVideo 
    //                                             mediaData={item}
    //                                             toggleModalFileProps={() => props.toggleModalFile()}
    //                                         />
    //                                     }
    //                                 </React.Fragment>
    //                             )
    //                         })
    //                         : (props.chatRoomDataForHeader !== null && props.chatRoomDataForHeader !== undefined ?
    //                             Object.keys(props.chatRoomDataForHeader).map((value, index) => {
    //                                 return (
    //                                     !props.chatRoomDataForHeader[value].isDeleted && 
    //                                         <React.Fragment key={`shared-media-${index}`}>
    //                                             {props.chatRoomDataForHeader[value].type === 1002 &&
    //                                                 // console.log(value.data)
    //                                                 <ChatRoomHeaderInfoImage 
    //                                                     mediaData={props.chatRoomDataForHeader[value]}
    //                                                     toggleModalFileProps={() => props.toggleModalFile()} 
    //                                                 />
    //                                             }
        
    //                                             {props.chatRoomDataForHeader[value].type === 1003 &&
    //                                                 <ChatRoomHeaderInfoVideo 
    //                                                     mediaData={props.chatRoomDataForHeader[value]}
    //                                                     toggleModalFileProps={() => props.toggleModalFile()}
    //                                                 />
    //                                             }
    //                                         </React.Fragment>
    //                                 )
    //                             }) : "")
    //                     }
    //                 </div>
    //             </div>
    //       </React.Fragment>
    //   }

    let toggleMuteOptionModal = () => {
        setShowMuteOptionModal(!showMuteOptionModal);
    }

    let generateMuteUntil = (v) => {
        let text = "";
        let nowTime = new Date().valueOf();
        let timeDif = v - nowTime;
        let val24Hr = 86400000;
        
        if(timeDif > val24Hr) {
            text = `${HelperDate.formatToString(new Date(v), `dd MMM yyyy`)}`;
        }else {
            text = `${HelperDate.formatToString(new Date(v), "HH:mm")}`;
        }

        return `until ${text}`;
    }

    return (
        <React.Fragment>
            <ChatRoomUpdateModal uploadStatus={newAvatar} />
            <Modal isOpen={props.myAccountModal} className="my-account-modal" toggle={() => props.toggleMyAccountModal()} >
                {/* <Scrollbars autoHide autoHideTimeout={500}
                        renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}> */}
                    <MuteOptionModal 
                        isShow={showMuteOptionModal}
                        toggle={toggleMuteOptionModal}
                        options={props.mutedRooms[props.activeRoom ? props.activeRoom.roomID : ""] ? 
                            [
                                {
                                    label: "Unmute",
                                    val: "unmute"
                                }
                            ]
                            :
                            MUTE_OPTION
                        }
                        _className={props.mutedRooms[props.activeRoom ? props.activeRoom.roomID : ""] ? "modal-option-mute-unmute-1" : ""}
                    />

                    <ModalHeader>
                        <div className="header-top-title abc">
                            {!props.userClick ?
                                <FiChevronLeft 
                                    // className="close-my-account"     
                                    onClick={onClickBackButton}
                                    height={32}
                                    width={32}
                                />
                                :
                                <FiX 
                                    className="close-modal-account-button"     
                                    onClick={onClickCloseButton}
                                    height={32}
                                    width={32}
                                />
                            }
                            
                            <p>
                                <b className="my-account-title">
                                    {(props.roomData && props.roomData.room.roomID === Helper.savedMessageRoomID()) ?
                                        (!props.userClick ? "Saved Messages" : valFullName)
                                        :
                                        (editMode ? "Account Details" : valFullName)
                                    }
                                </b>
                            </p>
                            
                            {(
                                // (props.roomData && props.roomData.room.type === 2) ||
                                props.userID ||
                                (props.userClick && props.activeRoom && Helper.isSavedMessageRoom(props.activeRoom.roomID)) ||
                                props.mentionUsername.username
                            ) ?
                                <div></div>
                                :
                                (editMode ?
                                    <p 
                                        className='edit-mode-button'
                                        onClick={() => {setModalConfirmSaveChanges(true)}}
                                    >
                                        Save
                                    </p>
                                    :
                                    <p 
                                        className='edit-mode-button'
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </p>
                                )
                            }
                            
                        </div>
                    </ModalHeader>

                    <ModalBody>
                    <div className="my-account-avatar-wrapper">
                        <div className="my-account-avatar">
                            {valAvatarImages && valAvatarImages.length > 0 ?
                                <ProfileCarousel total={valAvatarImages.length} currentIndex={currentIdx} setCurrentIndex={(idx) => setCurrentIdx(idx)}>
                                    {
                                        valAvatarImages.map((item, index) => <div key={`ProfileCarousel-${index}`}><img draggable={false} src={item.fullsizeImageURL} alt="" key={index} id="my-account-avatar-image" /></div>)
                                    }
                                </ProfileCarousel>
                                
                                :
                                <div className='no-avatar-placeholder-wrapper' style={{background: taptalk.getRandomColor(valFullName)}}>
                                    <div className='no-avatar-placeholder'>
                                        {Helper.renderUserAvatarWord(valFullName, false)}
                                    </div>
                                </div>
                            }
                        </div>
                        
                        {/* {newAvatar &&
                            <div className="upload-avatar-progress-wrapper">
                                <div className="upload-progress-border">
                                    <IoIosClose onClick={() => cancelUploadClick()} />
                                </div>
                            </div>
                        } */}
                    </div>
                    
                    {!newAvatar && valAvatarImages && !props.userID ?
                        ((
                            (props.roomData && props.roomData.room.type === 2) ||
                            (props.activeRoom && Helper.isSavedMessageRoom(props.activeRoom.roomID))
                        ) ?
                            <div></div>
                            :
                            editMode && valAvatarImages.length > 0 && valAvatarImages[currentIdx] ?
                                <label className="change-avatar-button" onClick={() => setShowProfilePicMenu(!showProfilePicMenu)}>
                                    <b>Edit Profile Picture</b>
                                    <ProfilePicMenu main={currentIdx === 0} imgID={valAvatarImages[currentIdx].id} imgUrl={valAvatarImages[currentIdx].fullsizeImageURL} imgCreatedTime={valAvatarImages[currentIdx].createdTime} />
                                </label>
                                :
                                valAvatarImages.length < MAXIMUM_USER_PHOTO ?
                                    <label className="change-avatar-button" htmlFor="change-avatar-input">
                                        <b>Set New Profile Picture</b>
                                    </label>
                                    :
                                    <label className="change-avatar-button" onClick={() => setModalMaximumProfilePicture(true)}>
                                        <b>Set New Profile Picture</b>
                                    </label>
                        )
                        :
                        ""
                        // <div className="avatar-uploading-wrapper">
                        //     <div className="lds-ring">
                        //         <div />
                        //         <div />
                        //         <div />
                        //         <div />
                        //     </div>
                        //     <b>Uploading</b>
                        // </div>
                    }

                    <input type="file" id="change-avatar-input" onChange={(e) => onChangeAvatarAction(e)} accept="image/jpeg, image/jpg, image/png" />
                    
                    {(props.showStarred && !Helper.isSavedMessageRoom(props.activeRoom.roomID)) &&
                        <div className="bottom-action-wrapper action-wrapper">
                            <ul>
                                <li onClick={toggleMuteOptionModal} className='mute-unmute-option'>
                                    <img src={unmuteOrange} alt="" />

                                    {props.mutedRooms[props.activeRoom.roomID] ? 
                                        <>
                                            Muted
                                            <p className='mute-unmute-option-value'>
                                                {props.mutedRooms[props.activeRoom.roomID].expiredAt === 0 ?
                                                    "Always"
                                                    :
                                                    generateMuteUntil(props.mutedRooms[props.activeRoom.roomID].expiredAt)
                                                }
                                            </p> 
                                        </>
                                        :
                                        <>
                                            Mute
                                            <p className='mute-unmute-option-value'>
                                                OFF
                                            </p> 
                                        </> 
                                    }

                                    <MdChevronRight />
                                </li>
                            </ul>
                        </div>
                    
                    }
                    
                    <div className={"profile-info-wrapper " + (editMode ? "" : "not-edit")}>
                        <InputField
                            label="Full Name" 
                            value={valFullName}
                            onChange={() => console.log("change")} 
                            editMode={editMode} 
                            visible={false}
                            disabled
                        />

                        <InputField
                            label="Bio" 
                            value={valBio}
                            onChange={(e) => setValBio(e.target.value)} 
                            editMode={editMode} 
                            visible={valBio}
                            placeholder="Input your bio here."
                            maxLength={100}
                        />

                        <InputField
                            label="Username" 
                            value={valUsername}
                            onChange={() => console.log("change")} 
                            editMode={editMode} 
                            visible={valUsername}
                            childrenAfter
                            disabled
                        >
                                {/* <div style={{marginTop: '10px'}} /> */}
                                {/* <span className="username-rule">Username is always required.</span>
                                <span className="username-rule">Must be between 4-32 characters.</span>
                                <span className="username-rule">Can Only contain a-z, 0-9, underscores and dot.</span>
                                <span className="username-rule">Can't start with number or underscore or dot.</span>
                                <span className="username-rule">Can't end with underscore or dot.</span>
                                <span className="username-rule">Can't contain consecutive underscores, consecutive dot.</span>
                                <span className="username-rule">Underscore followed with dot, and otherwise.</span> */}
                        </InputField>

                        <InputField
                            label="Phone Number" 
                            value={editMode ? valPhone : "+62 " + valPhone}
                            onChange={() => console.log("change")} 
                            editMode={editMode} 
                            visible={valPhone}
                            className="phone-input-wrapper"
                            disabled
                        >
                            {
                                editMode ?
                                <input type="text" className="phone-input" value={"+62"} onChange={() => console.log("change")} disabled/>
                                // <div className="phone-input-wrapper">
                                //     <label><b>Code</b></label>
                                //     <input type="text" className="phone-input" value={"+62"} onChange={() => console.log("change")} disabled/>
                                // </div>
                                : ""
                            }
                        </InputField>

                        <InputField
                            label="Email Address" 
                            value={valEmail}
                            onChange={() => console.log("change")} 
                            editMode={editMode} 
                            visible={valEmail}
                            optional={true}
                            placeholder="e.g example@work.com"
                            disabled
                        />

                        {/* <div className="input-my-account">
                            <label>
                                <b>Password</b> <span style={{fontSize: "13px", color: "#848484"}}>(Optional)</span>
                                <div className="my-accout-action-button change-password">
                                    <b>Change Password</b>
                                    <IoIosArrowForward />
                                </div>
                            </label>
                        </div> */}
                    </div>
                    
                    {props.showStarred &&
                        <div className="bottom-action-wrapper action-wrapper">
                            <ul>
                                <li onClick={props.onClickStarred}>
                                    <img src={iconStarred} alt="" />
                                    Starred Message
                                    <MdChevronRight />
                                </li>
                                <li onClick={props.onClickSharedMedia}>
                                    <img src={iconSharedMedia} alt="" />
                                    Shared Media
                                    <MdChevronRight />
                                </li>
                                <li onClick={() => props.onClickGroupInCommon(userData)}>
                                    <img src={iconGroup} alt="" />
                                    Group in Common
                                    <MdChevronRight />
                                </li>
                            </ul>
                        </div>
                    }

                    {
                        props.showStarred &&
                        props.roomData && props.roomData.room.type === 1 &&
                        props.userContacts && 
                        (!props.userContacts[props.roomData.room.name.substr(0, 1).toUpperCase()] || 
                        props.userContacts[props.roomData.room.name.substr(0, 1).toUpperCase()].findIndex(
                            value => value.user.userID === HelperChat.getOtherUserIDFromRoomID(props.roomData.room.roomID)
                        ) === -1) &&
                        !isBlockedContact &&
                        props.blockedUserIDs.indexOf(HelperChat.getOtherUserIDFromRoomID(props.roomData.room.roomID)) === -1 &&
                        <div className="bottom-action-wrapper action-wrapper">
                            <ul>
                                <li onClick={() => props.onClickAddToContact(
                                    {
                                        fullname:props.roomData.room.name, 
                                        userID: HelperChat.getOtherUserIDFromRoomID(props.roomData.room.roomID)
                                    }
                                )}>
                                    <img src={iconPlus} alt="" />
                                    Add To Contacts

                                    {props.isLoadingAddContact &&
                                        <div className="lds-ring">
                                            <div /><div /><div />
                                        </div>
                                    }
                                </li>
                            </ul>
                        </div>
                    }

                    {props.showStarred &&
                        <div className="bottom-action-wrapper action-wrapper">
                            <ul>
                                <li onClick={props.onClickReportUser} className="action-red">
                                    <img src={iconWarning} alt="" />
                                    Report User
                                </li>
                                {
                                    isBlockedContact ?
                                    <li onClick={() => toggleUnblockUserConfirmation()} className="action-red">
                                        <img src={iconBlock} alt="" />
                                        Unblock User
                                    </li>
                                    :
                                    <li onClick={() => toggleBlockUserConfirmation()} className="action-red">
                                        <img src={iconBlock} alt="" />
                                        Block User
                                    </li>
                                }
                            </ul>
                        </div>
                    }

                    {(!props.showStarred && !editMode && !props.roomData) &&
                        <div className="bottom-action-wrapper action-wrapper">
                            <ul>
                                <li onClick={props.onClickBlockedContacts} className="action-red">
                                    <img src={iconBlock} alt="" />
                                    <p style={{color: '#191919'}}>Blocked Contacts</p>
                                </li>
                            </ul>
                        </div>
                    }

                    {props.roomData && props.roomData.room.type === 2 &&
                        <div className={`modal-room-info-content active-modal-room-info-content`}>
                                <React.Fragment>
                                    {
                                        props.memberDetail &&
                                        props.memberDetail.userID &&
                                        <div className="bottom-action-wrapper action-wrapper">
                                            <ul>
                                                <li onClick={toggleMuteOptionModal} className='mute-unmute-option'>
                                                    <img src={unmuteOrange} alt="" />

                                                    {props.mutedRooms[HelperChat.getRoomIDFromOtherUserID(props.memberDetail.userID)] ? 
                                                        <>
                                                            Muted
                                                            <p className='mute-unmute-option-value'>
                                                                {props.mutedRooms[HelperChat.getRoomIDFromOtherUserID(props.memberDetail.userID)].expiredAt === 0 ?
                                                                    "Always"
                                                                    :
                                                                    generateMuteUntil(props.mutedRooms[HelperChat.getRoomIDFromOtherUserID(props.memberDetail.userID)].expiredAt)
                                                                }
                                                            </p> 
                                                        </>
                                                        :
                                                        <>
                                                            Mute
                                                            <p className='mute-unmute-option-value'>
                                                                OFF
                                                            </p> 
                                                        </> 
                                                    }

                                                    <MdChevronRight />
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    
                                    <div className="top-action-wrapper action-wrapper">
                                        <ul>   
                                            {
                                            // props.userContacts && props.userContacts[props.memberDetail.fullname.substr(0, 1).toUpperCase()] ?
                                            //     ""
                                            //     :
                                                
                                                props.memberDetail &&
                                                props.userContacts && 
                                                (!props.userContacts[props.memberDetail.fullname.substr(0, 1).toUpperCase()] ||
                                                props.userContacts[props.memberDetail.fullname.substr(0, 1).toUpperCase()].findIndex(value => value.user.userID === props.memberDetail.userID) === -1) &&
                                                !isBlockedContact &&
                                                props.blockedUserIDs.indexOf(props.memberDetail.userID) === -1 &&
                                                    <li onClick={() => props.onClickAddToContact(props.memberDetail)}>
                                                        <img src={iconPlus} alt="" />
                                                        Add To Contacts

                                                        {props.isLoadingAddContact &&
                                                            <div className="lds-ring">
                                                                <div /><div /><div />
                                                            </div>
                                                        }
                                                    </li>
                                            }

                                            {/* {contactList.findIndex(value => value.user.userID === memberDetail.userID) !==  -1 && */}
                                                <li onClick={() => props.onClickSendMessage()}>
                                                    <img src={iconChat} alt="" />
                                                    Send Message
                                                    <FiChevronRight />
                                                </li>
                                            {/* } */}

                                            {
                                                props.memberDetail &&
                                                !props.isMemberAdmin && 
                                                props.roomData.adminUserIDs && 
                                                props.roomData.adminUserIDs.findIndex(value => value === taptalk.getTaptalkActiveUser().userID) !== -1 &&
                                                props.roomData.participants &&
                                                props.roomData.participants.findIndex(value => value.userID === props.memberDetail.userID) !== -1 &&
                                                <li onClick={() => props.promoteToAdmin()}>
                                                    <img src={iconStar} alt="" />
                                                    Promote to Admin

                                                    {props.isLoadingPromoteDemote &&
                                                        <div className="lds-ring">
                                                            <div /><div /><div />
                                                        </div>
                                                    }
                                                </li>
                                            }

                                            {
                                                props.memberDetail &&
                                                props.isMemberAdmin && 
                                                props.roomData.adminUserIDs && 
                                                props.roomData.adminUserIDs.findIndex(value => value === taptalk.getTaptalkActiveUser().userID) !== -1 &&
                                                props.roomData.participants &&
                                                props.roomData.participants.findIndex(value => value.userID === props.memberDetail.userID) !== -1 &&
                                                <li onClick={() => props.demoteFromAdmin()}>
                                                    <img src={iconMinus} alt="" />
                                                    Demote from Admin

                                                    {props.isLoadingPromoteDemote &&
                                                        <div className="lds-ring">
                                                            <div /><div /><div />
                                                        </div>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                    </div>

                                    <div className="bottom-action-wrapper action-wrapper">
                                        <ul>
                                            {/* {roomDataOriginal.participants.length > 2 && */}
                                            {
                                                props.memberDetail &&
                                                props.roomData.adminUserIDs && 
                                                props.roomData.adminUserIDs.findIndex(value => value === taptalk.getTaptalkActiveUser().userID) !== -1 &&
                                                props.roomData.participants &&
                                                props.roomData.participants.findIndex(value => value.userID === props.memberDetail.userID) !== -1 &&
                                                <li onClick={() => props.onClickRemoveMember()} className="action-red">
                                                    <img src={iconRemove} alt="" />
                                                    Remove Member
                                                </li>
                                            }
                                            <li onClick={props.onClickReportUser} className="action-red">
                                                <img src={iconWarning} alt="" />
                                                Report User
                                            </li>
                                            {
                                                isBlockedContact ?
                                                <li onClick={() => toggleUnblockUserConfirmation()} className="action-red">
                                                    <img src={iconBlock} alt="" />
                                                    Unblock User
                                                </li>
                                                :
                                                <li onClick={() => toggleBlockUserConfirmation()} className="action-red">
                                                    <img src={iconBlock} alt="" />
                                                    Block User
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </React.Fragment>
                        </div>
                    }

                    {/* {medias ? generateSharedMedia() : ""} */}

                        {
                            editMode ?
                            <div className="input-my-account">
                                <label>
                                    {waitresponseLogout ?
                                        <div className="my-accout-action-button logout">
                                            <div className="lds-ring">
                                                <div />
                                                <div />
                                                <div />
                                                <div />
                                            </div>
                                            <b>Logout</b>
                                        </div>
                                        :
                                        <div className="my-accout-action-button logout"  onClick={() => onClickLogout()}>
                                            <img src={iconLogout} alt="" />
                                            <b>Logout</b>
                                        </div>
                                    }
                                </label>
                            </div>
                            : ""
                        }
                    </ModalBody>
                    
                    {/* <ModalFooter> */}
                        {/* <button className="orange-button">
                            Update
                        </button> */}
                    {/* </ModalFooter> */}
                {/* </Scrollbars> */}

            </Modal>

            {generateModalUnableToUpload()}
            {generateModalCancelUpload()}
            {generateModalUnsavedChanges()}
            {generateModalConfirmSaveChanges()}
            {generateModalSetAsMainPhoto()}
            {generateModalDeletePhoto()}
            {generateModalMaximumProfilePicture()}
            {generateModalBlockConfirmation()}
            {generateModalUnblockConfirmation()}
            {generateBlockingScreenLoading()}
            {generateModalBlockError()}
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    userClick: state.userClick,
    activeRoom: state.activeRoom,
    mutedRooms: state.mutedRooms,
    mentionUsername: state.mentionUsername,
    blockedUserIDs: state.blockedUserIDs
})

const mapDispatchToProps = {
  clearActiveRoom,
  clearMentionUsername,
  setBlockedUserIDs
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomListMyAccount);