const INITIAL_STATE = {
  roomID: "",
  urls: [],
  url: "",
  title: "",
  description: "",
  image: "",
  siteName: "",
  type: "",
  isFetching: false,
  setHeightChatRoom: false
};

let reducerLinkPreview = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_LINK_PREVIEW':
        return action.linkPreview;
      case 'CLEAR_LINK_PREVIEW':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerLinkPreview;