const INITIAL_STATE = {
  show: false,
  roomID: null,
  data: null
};

let reduxReducerVoice = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_VOICE_MESSAGE':
        return action.voiceNote;
      case 'CLEAR_VOICE_MESSAGE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerVoice;