const INITIAL_STATE = {};

let reduxReducerAllStarredMessages = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ALL_STARRED_MESSAGES':
        return action.allStarredMessages;
      case 'CLEAR_ALL_STARRED_MESSAGES':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerAllStarredMessages;