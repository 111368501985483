import React, { useState, useEffect } from 'react';
import './ChatRoomInputVoice.scss';
import { FiTrash2 } from 'react-icons/fi';
import { FaPlay, FaStop, FaPause } from 'react-icons/fa';
import Airplane from '../../../../../assets/img/icon-airplane.svg';
import HelperAudio from '../../../../../helper/HelperAudio';
import { setVoiceMessage, clearVoiceMessage } from '../../../../../redux/actions/reduxActionVoice';
import { setPlayVoice } from '../../../../../redux/actions/reduxActionPlayVoice';
// import { MdInsertDriveFile, MdImage } from 'react-icons/md';
// import AddImage from "../../../../assets/img/add-image.svg";
// import Airplane from '../../../../assets/img/icon-airplane.svg';
// import Helper from "../../../../helper/Helper";
// import { clearForwardMessage } from '../../../../redux/actions/reduxActionForwardMessage';
import { connect } from 'react-redux';

var ChatRoomInputVoice = (props) => {
  let [recordTimer, setRecordTimer] = useState(0);
  let [runRecordTimer, setRunRecordTimer] = useState(true);
  let [printDuration, setPrintDuration] = useState(0);
  let [voiceInputWaveSurfer, setVoiceInputWaveSurfer] = useState(false);
  let [isPlayVoice, setIsPlayVoice] = useState(false);
  let [latestChangeAudio, setLatestChangeAudio] = useState({
    time: false,
    file: null
  });

  useEffect(() => {
    if(latestChangeAudio.time && props.voiceNote.show) {
      let _audio = {...props.voiceNote};
      _audio.data = latestChangeAudio.file;
      props.setVoiceMessage(_audio);
    }
  }, [latestChangeAudio])

  useEffect(() => {
    HelperAudio.getAudioPermission({
      onGetPermission: () => {
        
      },
      onStopRecording: (file) => {
        let _latestChangeAudio = {...latestChangeAudio};
        _latestChangeAudio = {
          time: new Date().valueOf(),
          file: file
        }

        setLatestChangeAudio(_latestChangeAudio);
      }
    })
  }, [])

  useEffect(() => {
    if(props.activeRoom && (props.activeRoom.roomID !== props.voiceNote.roomID)) {

      if(isPlayVoice) {
        onClickPause();
      }
      
      onClickTrash();
    }
  }, [props.activeRoom])

  useEffect(() => {
    if(voiceInputWaveSurfer && props.playVoice.last_play && props.playVoice.message && (props.playVoice.message.messageID !== 0)) {
        onClickPause();
    }
  }, [props.playVoice])

  useEffect(() => {
    let intervalTimer;

    if (runRecordTimer) {
      intervalTimer = setInterval(() => {
        setRecordTimer(recordTimer => recordTimer + 1000);
        // setPrintDuration
      }, 1000);
    }
      return () => clearInterval(intervalTimer);
  }, [runRecordTimer]);

  useEffect(() => {
    if(props.voiceNote.data) {
      var wavesurfer = window.WaveSurfer.create({
          container: '#waveform',
          waveColor: '#FFCB99',
          progressColor: '#ff7d00',
          barWidth: 3,
          barHeight: 5, // the height of the wave
          barGap: 2 ,
          // backend: 'MediaElement',
          hideScrollbar: true,
      });

      setVoiceInputWaveSurfer(wavesurfer);
    }
  }, [props.voiceNote])

  useEffect(() => {
    if(voiceInputWaveSurfer) {
      voiceInputWaveSurfer.load(URL.createObjectURL(props.voiceNote.data));

      voiceInputWaveSurfer.on("finish", () => {
        setIsPlayVoice(false);
        voiceInputWaveSurfer.seekTo(0);
      })

      voiceInputWaveSurfer.on("seek", () => {
        var currentTime = voiceInputWaveSurfer.getCurrentTime();
        setPrintDuration(currentTime.toFixed(1)*1000);
        onClickPause();
      })

      voiceInputWaveSurfer.on('audioprocess', function() {
        if(voiceInputWaveSurfer.isPlaying()) {
          var currentTime = voiceInputWaveSurfer.getCurrentTime();

          setPrintDuration(currentTime.toFixed(1)*1000);
        }
      });
    }
  }, [voiceInputWaveSurfer])

  let convertTimer = (time) => {
    return new Date(time).toISOString().substr(14, 5);
  }
  
  let onClickTrash = () => {
    HelperAudio.stopRecording();
    props.clearVoiceMessage();
  }

  let onClickStop = () => {
    setPrintDuration(recordTimer);
    setRunRecordTimer(false);
    HelperAudio.stopRecording();
  }

  let onClickSendVoice = () => {
    let _voice = {...props.voiceNote};
    props.runningVoiceMessageProps(_voice.data, recordTimer);
    props.clearVoiceMessage();
  }

  let onClickPause = () => {
      setIsPlayVoice(false);
      voiceInputWaveSurfer.pause();
  }

  let onClickPlay = () => {
    props.setPlayVoice({
      last_play: new Date().valueOf(),
      message: {
        messageID: 0
      }
    })

    setIsPlayVoice(true);
    voiceInputWaveSurfer.play();
  }

  return (
    <div className="chat-room-input-voice-wrapper">
      <FiTrash2 className="remove-recorder-icon" onClick={onClickTrash} />

      {props.voiceNote.data &&
        // <audio id="track" controls>
        //   <source src={URL.createObjectURL(props.voiceNote.data)} type={props.voiceNote.data.type} />
        // </audio>
        <div id="waveform"></div>
      }


      {runRecordTimer &&
        <div className="circle-recorder" />
      }
     
      <p className="recorder-timer">
        {convertTimer(
          runRecordTimer ?
            recordTimer
            :
            printDuration
        )}
      </p>

      {runRecordTimer ?
        <button className="button-stop-wrapper" id="input-voice-play" onClick={onClickStop}>
          <FaStop />
        </button>
        :
          !isPlayVoice ?
            <button 
              className={`button-stop-wrapper voice-play-pause-button ${!isPlayVoice ? 'active-voice-play-pause-button' : ''}`}
              id="input-voice-play" 
              onClick={onClickPlay}
            >
              <FaPlay />
            </button>
            :
            <button 
              className={`button-stop-wrapper general-pause-button voice-play-pause-button ${isPlayVoice ? 'active-voice-play-pause-button' : ''}`}
              id="input-voice-pause" 
              onClick={onClickPause}
            >
              <FaPause />
            </button>
        }
      }

      <button 
        className="chat-send-wrapper" 
        disabled={runRecordTimer}
        onClick={onClickSendVoice}
      >
          <img src={Airplane} alt="" />
      </button>

    </div>
  );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    voiceNote: state.voiceNote,
    playVoice: state.playVoice
});

const mapDispatchToProps = {
  setVoiceMessage,
  clearVoiceMessage,
  setPlayVoice
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomInputVoice);
