import React, { useState, useEffect, useRef } from 'react';
import './ChatRoomMain.scss';
import { Modal, ModalBody } from 'reactstrap';
import { FiUpload, FiCopy, FiTrash } from "react-icons/fi";
import { FaReply } from 'react-icons/fa';
import MediaQuery from 'react-responsive';
// import ChatRoomNewContact from './chatRoomNewContact/ChatRoomNewContact';
import ChatRoomChatInfo from './chatRoomChatInfo/ChatRoomChatInfo';
import ChatRoomMessageIn from './chatRoomMessageIn/ChatRoomMessageIn';
import ChatRoomMessageInFile from './chatRoomMessageInFile/ChatRoomMessageInFile';
import ChatRoomMessageInImage from './chatRoomMessageInImage/ChatRoomMessageInImage';
import ChatRoomMessageInVideo from './chatRoomMessageInVideo/ChatRoomMessageInVideo';
import ChatRoomMessageInLocation from './chatRoomMessageInLocation/ChatRoomMessageInLocation';
import ChatRoomMessageInVoice from './chatRoomMessageInVoice/ChatRoomMessageInVoice';
import ChatRoomMessageOut from './chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutFile from './chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from './chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from './chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutLocation from './chatRoomMessageOutLocation/ChatRoomMessageOutLocation';
import ChatRoomMessageOutVoice from './chatRoomMessageOutVoice/ChatRoomMessageOutVoice';
import ChatRoomInputMessage from './chatRoomInputMessage/ChatRoomInputMessage';
import ChatRoomInputEditMessage from './chatRoomInputMessage/chatRoomInputEditMessage/ChatRoomInputEditMessage';
import ChatRoomSelectForward from '../chatRoomSelectForward/ChatRoomSelectForward';
import ChatRoomReportMessage from '../chatRoomReportMessage/ChatRoomReportMessage';
import PopupConfirmation from '../../reuseableComponent/popupConfirmation/PopupConfirmation';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
// import { Scrollbars } from 'react-custom-scrollbars';
import { taptalk, tapCoreMessageManager, tapCoreChatRoomManager, tapCoreContactManager } from '@taptalk.io/web-sdk';
import { setActiveRoom } from '../../../redux/actions/reduxActionActiveRoom';
import { clearGoToChatBubble } from '../../../redux/actions/reduxActionGoToChatBubble';
import { setDraftMessage } from "../../../redux/actions/reduxActionDraftMessage";
import { setReplyMessage, clearReplyMessage } from '../../../redux/actions/reduxActionReplyMessage';
import { setForwardMessage, clearForwardMessage } from '../../../redux/actions/reduxActionForwardMessage';
import { setLinkPreview, clearLinkPreview } from '../../../redux/actions/reduxActionLinkPreview';
import { setEditMessage, clearEditMessage } from '../../../redux/actions/reduxActionEditMessage';
import { setAllStarredMessages } from '../../../redux/actions/reduxActionAllStarredMessage';
import { clearActiveMessage } from '../../../redux/actions/reduxActionActiveMessage';
import { setMultiForward } from '../../../redux/actions/reduxActionMultiForward';
import { setAllPinnedMessages } from '../../../redux/actions/reduxActionAllPinnedMessage';
import { setPinnedRoomID } from '../../../redux/actions/reduxActionPinnedRoomID';
import { clearParticipantList } from '../../../redux/actions/reduxActionParticipantList';
import { setBlockedUserIDs } from '../../../redux/actions/reduxActionBlockedUserIDs';
import { setMessageInfo } from '../../../redux/actions/reduxActionMessageInfo';
import { connect } from 'react-redux';
import Helper from "../../../helper/Helper";
import HelperChat from "../../../helper/HelperChat";
import WebWorker from "../../../helper/HelperWebWorker";
import { CHAT_TYPE } from "../../../helper/HelperConst";
import iconClock from '../../../assets/img/icon-clock.png';
import ScheduledMessageTimePicker from '../../reuseableComponent/scheduledMessageTimePicker/ScheduledMessageTimePicker';
import BlockingScreenLoading from '../../reuseableComponent/blockingScreenLoading/BlockingScreenLoading';

// var style = {
//     scrollStyle: {
//       position: "relative",
//       backgroundColor: "#ff7d00",
//       right: "-2px",
//       width: "5px",
//       borderRadius: "8px"
//     }
// };

var ChatRoomMain = (props) => {
  let [chatDataRoomMain, setChatDataRoomMain] = useState([]);
  let [chatDataRoomMainTemp, setChatDataRoomMainTemp] = useState(false);
  let [isLoadingChat, setIsLoadingChat] = useState(false);
  let chatRoomMesageListRef = useRef("messageLists");

  let [roomIsDeleted, setRoomIsDeleted] = useState(false);

  let [isNoLongerParticipantOrUserDelete, setIsNoLongerParticipantOrUserDelete] = useState(false);
  let [isShowLoadingBlocking, setIsShowLoadingBlocking] = useState(false);

  let [fileUploadProgress, setFileUploadProgress] = useState({});

  let [hasMoreChatBefore, setHasMoreChatBefore] = useState(false);

  let [showDropFileHere, setShowDropFileHere] = useState(false);
  let [lastDragAndDropFiles, setLastDragAndDropFiles] = useState({
    files: [],
    time: ""
  });

  let [showBlockingChatFirst, setShowBlockingChatFirst] = useState(true);
  let [mentionList, setMentionList] = useState({});
  let [webWorkerMentionList, setWebWorkerMentionList] = useState(null);
  let [webWorkerMyMentionCounter, setWebWorkerMyMentionCounter] = useState(null);
  let [myMentionCounter, setMyMentionCounter] = useState({});
  let [myMentionCounterTemp, setMyMentionCounterTemp] = useState(false);
  let [isShowModalForward, setIsShowModalForward] = useState(false);
  let [openModalActionMessage, setOpenModalActionMessage] = useState(false);
  let [latestForwardOnly, setLatestForwardOnly] = useState({
    time: false,
    roomID: false,
    message: false
  })
  // let [showBubbleCount, setShowBubbleCount] = useState(0);
    let [showDeleteRoomConfirmation, setShowDeleteRoomConfirmation] = useState(false);
    let [isLoadingDeleteRoom, setIsLoadingDeleteRoom] = useState(false);
    // let [roomIDToDelete, setRoomIDToDelete] = useState("");
    let [showFailedDeleteRoom, setShowFailedDeleteRoom] = useState(false);

    let [isShowScheduledMessageDateTimePicker, setIsShowScheduledMessageDateTimePicker] = useState(false);
    let [scheduledMessageTime, setScheduledMessageTime] = useState(new Date());
    let [isScheduledMessageLoading, setIsScheduledMessageLoading] = useState(false);

    let [reportedMessage, setReportedMessage] = useState(false);
  
    let [isBlockedContact, setIsBlockedContact] = useState(false);
    let [showUnblockUserConfirmation, setShowUnblockUserConfirmation] = useState(false);
    let [isLoadingUnblockUser, setIsLoadingUnblockUser] = useState(false);

  let toggleModalActionMessage = () => {
    setOpenModalActionMessage(!openModalActionMessage);
  }

  let toggleModalForward = async (isSelect = false) => {
    HelperChat.resetChatRoomHeightAndInputText();
    !isSelect && props.clearForwardMessage();
    setIsShowModalForward(!isShowModalForward);
  }

  let reverseMessagesObject = (object) => {
    var newObject = {};
    var keys = [];

    for (var key in object) {
        keys.push(key);
    }

    for (var i = keys.length - 1; i >= 0; i--) {
      var value = object[keys[i]];
      newObject[keys[i]]= value;
    }       

    return newObject;
  }

  useEffect(() => {
    if(latestForwardOnly.time && (latestForwardOnly.roomID && latestForwardOnly.roomID === props.activeRoom.roomID)) {
      let _chatDataRoomMain = {...chatDataRoomMain};
      _chatDataRoomMain[latestForwardOnly.message.localID] = latestForwardOnly.message;
      setChatDataRoomMain(_chatDataRoomMain);
    }
  }, [latestForwardOnly])

  useEffect(() => {
    if(props.activeMessage) {
      toggleModalActionMessage();
    }else {
      setOpenModalActionMessage(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeMessage])

  useEffect(() => {
    if(props.lastClickStarMessage.time) {
      scrollToReply(props.lastClickStarMessage.localID);
    }
  }, [props.lastClickStarMessage])

  //set webworker
  useEffect(() => {
    setWebWorkerMentionList(
      new WebWorker(() => {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('message', function(e) {
          let { chatDataRoomMain, activeRoom, chatType } = e.data;
          let hashmap = {};
          
          let updateMessageMentionIndexes = (message) => {
            let originalText;
            if (message.type === chatType.TAPChatMessageTypeText || message.type === chatType.TAPChatMessageTypeLink) {
                originalText = message.body;
            } else if ((message.type === chatType.TAPChatMessageTypeImage || message.type === chatType.TAPChatMessageTypeVideo) && null !== message.data) {
                originalText = message.data.caption;
            } else if (message.type === chatType.TAPChatMessageTypeLocation && null !== message.data) {
                originalText = message.data.address;
            } else {
                return false;
            }
            if (null === originalText) {
                return false;
            }
            let mentionIndexes = [];
            
            if (originalText.includes("@")) {
                let length = originalText.length;
                let startIndex = -1;
                for (let i = 0; i < length; i++) {
                    if (originalText.charAt(i) === '@' && startIndex === -1) {
                        // Set index of @ (mention start index)
                        startIndex = i;
                    } else {
                        let endOfMention = originalText.charAt(i) === ' ' || originalText.charAt(i) === '\n';
    
                        if (i === (length - 1) && startIndex !== -1) {
                            let endIndex = endOfMention ? i : (i + 1);
                            if (endIndex > (startIndex + 1)) {
                                mentionIndexes.push(startIndex);
                                mentionIndexes.push(endIndex);
                            }
                            startIndex = -1;
                        } else if (endOfMention && startIndex !== -1) {
                            // End index for mentioned username
                            //String username = originalText.substring(startIndex + 1, i);
                            //if (vm.getRoomParticipantsByUsername().includesKey(username)) {
                            if (i > (startIndex + 1)) {
                                mentionIndexes.push(startIndex);
                                mentionIndexes.push(i);
                            }
                            //}
                            startIndex = -1;
                        }
                    }
                }
                if (mentionIndexes.length > 0) {
                    return {
                        localID: [message.localID],
                        mentionIndex: mentionIndexes
                    }
                }else {
                    return false;
                }
            }
          }
        
          Object.keys(chatDataRoomMain).map((val) => {
            let result = updateMessageMentionIndexes(chatDataRoomMain[val], activeRoom);

            if(result) {
              hashmap[result.localID] = result.mentionIndex;
            }
            
            return null;
          })
          
          // eslint-disable-next-line no-restricted-globals
          self.postMessage({
            data: {
              activeRoom: activeRoom,
              mentionList: hashmap
            }
          })
        })
      })
    )
    //mentionList

    setWebWorkerMyMentionCounter(
      new WebWorker(() => {
        // eslint-disable-next-line no-restricted-globals
        self.addEventListener('message', function(e) {
          let { chatDataRoomMain, activeRoom, chatType, activeUser } = e.data;
          let hashmap = {};

          let isActiveUserMentioned = (message) => {
            if (message.user.username === activeUser.username ||
                (message.type !== chatType.TAPChatMessageTypeText && 
                 message.type !== chatType.TAPChatMessageTypeLink && 
                 message.type !== chatType.TAPChatMessageTypeImage && 
                 message.type !== chatType.TAPChatMessageTypeVideo) ||
                activeUser === null ||
                message.isRead
            ) {
                return false;
            }
            
            let text = message.body;
            if (null === text || text === "") {
                return false;
            }

            return (
              text.includes(" @" + activeUser.username + " ") ||
              text.includes(" @" + activeUser.username + "\n") ||
              (text.includes(" @" + activeUser.username) && text.endsWith(activeUser.username)) ||
              text.includes("\n@" + activeUser.username + " ") ||
              text.includes("\n@" + activeUser.username + "\n") ||
              (text.includes("\n@" + activeUser.username) && text.endsWith(activeUser.username)) ||
              text.startsWith("@" + activeUser.username) && text.includes("@" + activeUser.username + " ") ||
              text.startsWith("@" + activeUser.username) && text.includes("@" + activeUser.username + "\n") ||
              text === "@" + activeUser.username
            );
          }
          
          Object.keys(chatDataRoomMain).map((val) => {
            let result = isActiveUserMentioned(chatDataRoomMain[val]);
            
            if(result) {
              hashmap[chatDataRoomMain[val].localID] = chatDataRoomMain[val];
            }
            
            return null;
          })
          
          // eslint-disable-next-line no-restricted-globals
          self.postMessage({
            data: {
              activeRoom: activeRoom,
              myMentionCounter: hashmap
            }
          })
        })
      })
    )
    //myMentionCounter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let runWebWorkerMentionList = async () => {
      if(webWorkerMentionList !== null) {
        webWorkerMentionList.addEventListener('message', (e) => {
          let { data } = e.data;
          setMentionList(data.mentionList);
        })
      }
    } 

    runWebWorkerMentionList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webWorkerMentionList])

  useEffect(() => {
    let runWebWorkerMyMentionCounter = async () => {
      if(webWorkerMyMentionCounter !== null) {
        webWorkerMyMentionCounter.addEventListener('message', (e) => {
          let { data } = e.data;
          if(Object.keys(data.myMentionCounter).length > 0) {
            setMyMentionCounterTemp({
              time: new Date(),
              list: data.myMentionCounter,
              room: data.activeRoom
            })
          }
        })
      }
    }

    runWebWorkerMyMentionCounter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webWorkerMyMentionCounter])
  //set webworker

  useEffect(() => {
    let runWebWorkerMyMentionCounterTemp = async () => {
      if(myMentionCounterTemp) {
        if(props.activeRoom.roomID === myMentionCounterTemp.room.roomID) {
          setMyMentionCounter(myMentionCounterTemp.list);
        }
      }
    }

    runWebWorkerMyMentionCounterTemp();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myMentionCounterTemp])

  useEffect(() => {
    let runFileUploadProgress = async () => {
      let _fileUploadProgress = {...fileUploadProgress};

      if(Object.keys(_fileUploadProgress).length > 0) {
        let _localID = Object.keys(_fileUploadProgress)[0];
        let _chatDataRoomMain = {...chatDataRoomMain};
        
        if(_fileUploadProgress[_localID].message !== null) {
          _chatDataRoomMain[_fileUploadProgress[_localID].message.localID] = _fileUploadProgress[_localID].message;
          props.runSetChatRoomDataForHeader(_chatDataRoomMain);
          setChatDataRoomMain(_chatDataRoomMain);
          scrollChatViewToBottom();
        }else {
          if(_chatDataRoomMain[_localID]) {
            _chatDataRoomMain[_localID].bytesUpload = _fileUploadProgress[_localID].bytes;
            _chatDataRoomMain[_localID].percentageUpload = _fileUploadProgress[_localID].percentage;
            props.runSetChatRoomDataForHeader(_chatDataRoomMain);
            setChatDataRoomMain(_chatDataRoomMain);
          }
        }

        setFileUploadProgress({});
      }
    }

    runFileUploadProgress();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadProgress])
  
  useEffect(() => {
    let runFirst = async () => {
      let chatData = tapCoreChatRoomManager.getCurrentChatInRoom(props.activeRoom.roomID);

      setShowBlockingChatFirst(true);
      
      if(Object.keys({...chatData}).length > 0) {
        Object.keys(chatData).map((value) => {
          if(!chatData[value].isHidden) {
            setShowBlockingChatFirst(false);
            return null;
          }

          return null;
        })
      }

      // setShowBubbleCount(0);
      setMyMentionCounter({});
      props.runSetChatRoomDataForHeader(chatData);
      setChatDataRoomMain(reverseMessagesObject(chatData));
      setRoomIsDeleted(false);
      setIsNoLongerParticipantOrUserDelete(false);

      if (props.activeRoom.type === 1 && props.blockedUserIDs && props.blockedUserIDs.length > 0) {
        const otherUserID = HelperChat.getOtherUserIDFromRoomID(props.activeRoom.roomID);
        setIsBlockedContact(props.blockedUserIDs.includes(otherUserID));
      }
      else {
        setIsBlockedContact(false);
      }
  
      setTimeout(function() {
        if(props.activeRoom !== null) { 
          getMessageAfter();
        }
      }, 0);
  
      setIsNoLongerParticipantOrUserDelete(false);

      tapCoreMessageManager.getStarredMessageIds(props.activeRoom.roomID, {
        onSuccess: (res) => {
          props.setAllStarredMessages(res.messages)
        },
        onError: (errCode, errMes) => {

        }
      })  

      tapCoreMessageManager.fetchPinnedMessages(props.activeRoom.roomID, {
        onSuccess: (_res) => {
          tapCoreMessageManager.getPinnedMessageIds(props.activeRoom.roomID, {
            onSuccess: (res) => {
              props.setAllPinnedMessages(
                {
                  roomID: props.activeRoom.roomID,
                  messages: {...res}.messages,
                  pinMessages: {..._res},
                  activePinnedMessageIndex: 0,
                  pinMessagesInsideModal: null
                }
              )
            },
            onError: (errCode, errMes) => {
    
            }
          })
        },
        onError: (errCode, errMes) => {

        }
      })
      
      // Contact blocked/unblocked listener
      tapCoreContactManager.addContactListener({
        onContactBlocked: (user) => {
          if (props.activeRoom.type === 1) {
            const otherUserID = HelperChat.getOtherUserIDFromRoomID(props.activeRoom.roomID);
            if (otherUserID === user.userID) {
              setIsBlockedContact(true);
            }
          }
        },
        onContactUnblocked: (user) => {
          if (props.activeRoom.type === 1) {
            const otherUserID = HelperChat.getOtherUserIDFromRoomID(props.activeRoom.roomID);
            if (otherUserID === user.userID) {
              setIsBlockedContact(false);
            }
          }
        }
      });
    }

    runFirst();

    let onlineCallbackOnChatRoom = () => {
      if(props.activeRoom !== null) {
        setTimeout(() => {
          getMessageAfter(true)
        }, 5000);
      }
    }

    window.addEventListener('online', onlineCallbackOnChatRoom);
    
    return () => {
      window.removeEventListener('online', onlineCallbackOnChatRoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeRoom]);

  useEffect(() => {
    let runReplyMessage = async () => {
      let _replyMessage = {...props.replyMessage};

      let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
      let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 108 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
      let heightNew = 0;

      let setNewHeight = (height, isSet) => {
        heightNew = height;
        elChatRoomMain.style.setProperty("max-height", "calc(100% - " + (heightNew - 51) + "px)", "important");
        _replyMessage.setHeightChatRoom = isSet;
        props.setReplyMessage(_replyMessage);
      }
      
      if(_replyMessage.message && !_replyMessage.setHeightChatRoom) {
        setNewHeight(chatRoomContainerHeight + 68, true);
      }
    }

    runReplyMessage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.replyMessage])

  useEffect(() => {
    let runForwardMessage = async () => {
      let _forwardMessage = {...props.forwardMessage};
      let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
      let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 108 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
      let heightNew = 0;
      
      let setNewHeight = (height, isSet) => {
        heightNew = height;
        elChatRoomMain.style.setProperty("max-height", "calc(100% - " + (heightNew - 51) + "px)", "important");
        _forwardMessage.setHeightChatRoom = isSet;
        props.setForwardMessage(_forwardMessage);
      }
      
      setIsShowModalForward((_forwardMessage.message && !_forwardMessage.target) ? true : false);

      if(_forwardMessage.message && !_forwardMessage.setHeightChatRoom && _forwardMessage.target) {
        setNewHeight(chatRoomContainerHeight + 68, true);
      }
    }

    runForwardMessage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forwardMessage])

  useEffect(() => {
    let runLinkPreview = async () => {
      let _linkPreview = {...props.linkPreview};

      let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
      let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 108 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
      let heightNew = 0;

      let setNewHeight = (height, isSet) => {
        heightNew = height;
        elChatRoomMain.style.setProperty("max-height", "calc(100% - " + (heightNew - 51) + "px)", "important");
        _linkPreview.setHeightChatRoom = isSet;
        props.setLinkPreview(_linkPreview);
      }
      
      if (_linkPreview.url && !_linkPreview.setHeightChatRoom) {
        setNewHeight(chatRoomContainerHeight + 68, true);
      }
    }

    runLinkPreview();
  }, [props.linkPreview])

  useEffect(() => {
    let runEditMessage = async () => {
      let _editMessage = {...props.editMessage};
      let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
      let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 108 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
      let heightNew = 0;
      
      let setNewHeight = (height, isSet) => {
        heightNew = height;
        elChatRoomMain.style.setProperty("max-height", "calc(100% - " + (heightNew - 51) + "px)", "important");
        _editMessage.setHeightChatRoom = isSet;
        props.setEditMessage(_editMessage);
      }

      if(_editMessage.message && !_editMessage.setHeightChatRoom) {
        setNewHeight(chatRoomContainerHeight + 68, true);
      }
    }

    runEditMessage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMessage])

  useEffect(() => {
    let runChatDataRoomMainTemp = () => {
      if(chatDataRoomMainTemp) {
        if(chatDataRoomMainTemp.activeRoom.roomID === props.activeRoom.roomID) {
          props.runSetChatRoomDataForHeader(chatDataRoomMainTemp.chats);
          setChatDataRoomMain(chatDataRoomMainTemp.chats);

          if (chatDataRoomMainTemp.isFirstLoad) {
            scrollChatViewToBottom();
          } 
        
          if(chatDataRoomMainTemp.scrollingBackHeight) {
            setTimeout(() => {
              if(document.querySelectorAll(".chat-room-main-content")[0]) {
                chatRoomMesageListRef.current.scrollTop = document.querySelectorAll(".chat-room-main-content")[0].scrollHeight - chatDataRoomMainTemp.scrollingBackHeight;
              }
            }, 0)
          }

          if(chatDataRoomMainTemp.findLocalID) {
            if(!chatDataRoomMainTemp.chats[chatDataRoomMainTemp.findLocalID] && chatDataRoomMainTemp.hasMore) {
              getMessageBefore(false, false, chatDataRoomMainTemp.findLocalID);
            }else if(!chatDataRoomMainTemp.chats[chatDataRoomMainTemp.findLocalID] && !chatDataRoomMainTemp.hasMore) {
              setIsShowLoadingBlocking(false);
            }else {
              setTimeout(() => {
                scrollToReply(chatDataRoomMainTemp.findLocalID);
                setIsShowLoadingBlocking(false);
              }, 0)
            }
          }
        }
      }
    }

    runChatDataRoomMainTemp();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDataRoomMainTemp])

  useEffect(() => {
    // setRoomIsDeleted
    // setIsNoLongerParticipantOrUserDelete

    let lengthChatData = Object.keys(chatDataRoomMain).length - 1;
    let lastChat = chatDataRoomMain[Object.keys(chatDataRoomMain)[lengthChatData]];

    let runNoLongerParticipantOrUserDelete = () => {
      setIsNoLongerParticipantOrUserDelete(true);
      props.clearReplyMessage(); 
      props.clearForwardMessage();
    };

    if(
      lastChat &&
      lastChat.action === 'user/delete' &&
      lastChat.type === 9001
    ) {
      runNoLongerParticipantOrUserDelete();
    }else if(
      lastChat &&
      lastChat.action === 'room/removeParticipant' &&
      lastChat.type === 9001 &&
      lastChat.target.targetID === taptalk.getTaptalkActiveUser().userID
    ) {
      // setRoomIsDeleted(true);
      runNoLongerParticipantOrUserDelete();
    }else {
      setIsNoLongerParticipantOrUserDelete(false);
    }
    
    if(props.goToChatBubble.localID) {
      scrollToReply(props.goToChatBubble.localID);
    }

    //mention
    let runMention = async () => {
      if(props.activeRoom.type === 2 && webWorkerMentionList !== null) {
        webWorkerMentionList.postMessage({
            activeRoom: props.activeRoom,
            chatDataRoomMain: chatDataRoomMain,
            chatType: CHAT_TYPE
        })
      }
    }

    let runMyMentionCounter = async () => {
      if(props.activeRoom.type === 2 && webWorkerMyMentionCounter !== null) {
        webWorkerMyMentionCounter.postMessage({
            activeRoom: props.activeRoom,
            chatDataRoomMain: chatDataRoomMain,
            chatType: CHAT_TYPE,
            activeUser: taptalk.getTaptalkActiveUser()
        })
      }
    }

    runMyMentionCounter();
    runMention();
    //mention
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDataRoomMain]);

  useEffect(() => {
    let listenerNewMessage = props.messageListenerNewMessageProps === null ? null : props.messageListenerNewMessageProps;

    if(props.activeRoom !== null) {

      if(listenerNewMessage !== null) {
        // console.log('new', listenerNewMessage)
        if(props.activeRoom.roomID === listenerNewMessage.room.roomID) {
          let cloneChatDataRoomMain = {...chatDataRoomMain};

          if(!listenerNewMessage.isHidden) {
            setShowBlockingChatFirst(false);
          }

          if(listenerNewMessage.user.userID === taptalk.getTaptalkActiveUser().userID) {
            // room/deleted
            if(listenerNewMessage.action === "room/delete" && listenerNewMessage.type === 9001) {
              setRoomIsDeleted(true);
            }

            // room/leave
            if(listenerNewMessage.action === "room/leave" && listenerNewMessage.type === 9001) {
              props.setActiveRoom(null);
            }

            // room/removeParticipant

            // if message was from me
            cloneChatDataRoomMain[listenerNewMessage.localID] = listenerNewMessage;
            
            //imediately scrolling to bottom
            if(listenerNewMessage.action !== "message/pin" && listenerNewMessage.action !== "message/unpin") {
              scrollChatViewToBottom();
            }
          }else {
            //if chat was from another device
            let el = document.querySelectorAll(".chat-room-main-content")[0];
            
            if(el.scrollTop === (el.scrollHeight - el.offsetHeight)) {
              scrollChatViewToBottom();
            }
            cloneChatDataRoomMain[listenerNewMessage.localID] = listenerNewMessage;
            unreadMessageAction();
            // unreadMessageAction({[listenerNewMessage.localID]: listenerNewMessage});
          }

          props.runSetChatRoomDataForHeader(cloneChatDataRoomMain);
          setChatDataRoomMain(cloneChatDataRoomMain);
        }
        
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messageListenerNewMessageProps]);

  useEffect(() => {
    let listenerUpdateMessage = props.messageListenerUpdateMessageProps === null ? null : props.messageListenerUpdateMessageProps;
    
    if(props.activeRoom !== null) {

      if(listenerUpdateMessage !== null) { 
        if(props.activeRoom.roomID === listenerUpdateMessage.room.roomID) {
          let cloneChatData= {...chatDataRoomMain};
          cloneChatData[listenerUpdateMessage.localID] = listenerUpdateMessage;

          props.runSetChatRoomDataForHeader(cloneChatData);
          setChatDataRoomMain(cloneChatData);
          // if(listenerUpdateMessage.isDelivered && !listenerUpdateMessage.isRead) {
          //   let cloneChatDataUndelivered = {...chatDataRoomMain};

          //   if(cloneChatDataUndelivered[listenerUpdateMessage.localID]) {
          //     cloneChatDataUndelivered[listenerUpdateMessage.localID].isDelivered = true;
          //     setChatDataRoomMain(cloneChatDataUndelivered);
          //   }
          // }

          // //set all chat data isRead to true 
          // if(listenerUpdateMessage.isRead) {
          //   let cloneChatDataUnRead = {...chatDataRoomMain};

          //   Object.keys(cloneChatDataUnRead).map(function(i) {
          //     if(!cloneChatDataUnRead[i].isRead) {
          //       cloneChatDataUnRead[i].isRead = true;
          //       setChatDataRoomMain(cloneChatDataUnRead);
          //     }

          //     return null;
          //   });
          // }
          
        }

      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.messageListenerUpdateMessageProps]);

  // let unreadMessageAction = (arrayOfMessage) => {
  //   //if show on view port
  //   let _myMentionCounter = {...myMentionCounter};
  //   let unreadMessageArray = [];

  //   Object.keys(arrayOfMessage).map(function(i) {
  //     if(!arrayOfMessage[i].isRead && (arrayOfMessage[i].user.userID !== taptalk.getTaptalkActiveUser().userID)) {
  //       unreadMessageArray.push(arrayOfMessage[i].messageID);

  //       if(i) {
  //         delete _myMentionCounter[i];
  //       }
  //     }

  //     return null;
  //   });

  //   setMyMentionCounter(_myMentionCounter)

  //   if(unreadMessageArray.length > 0) {
  //     tapCoreMessageManager.markMessageAsRead(unreadMessageArray);
  //   }
  // }

  let unreadMessageAction = () => {
    tapCoreMessageManager.markAllMessagesInRoomAsRead(props.activeRoom.roomID);

    setMyMentionCounter([]);
  }
  
  let generateNewFileUploadProgress = (localID, percentage, bytes, message = null) => {
    let newFileUploadProgress = {
      [localID]: {
        percentage: percentage,
        bytes: bytes,
        message: message
      }
    }

    return newFileUploadProgress;
  }

  let scrollChatViewToBottom = async () => {
    setTimeout(function() {
      if(chatRoomMesageListRef.current !== null) {
        chatRoomMesageListRef.current.scrollTop = chatRoomMesageListRef.current.scrollHeight;
      }
    }, 0);
  }

  let onScrollListener = async () => {
    let currentViewHeight = chatRoomMesageListRef.current.scrollHeight;
    if(chatRoomMesageListRef.current.scrollTop === 0 && props.activeRoom && hasMoreChatBefore) {
      getMessageBefore(false, currentViewHeight);
    }
  }

  let getMessageAfter = async (isReconnect) => {
    setTimeout(function() {
      if(!isReconnect) {
       setIsLoadingChat(true);
      }

      tapCoreMessageManager.getNewerMessagesAfterTimestamp(props.activeRoom.roomID, {
        onSuccess: (messages) => {
              setIsLoadingChat(false);
              // unreadMessageAction(messages);

              unreadMessageAction();

              if(messages !== null) {
                // setChatDataRoomMain(reverseMessagesObject(messages));
                setChatDataRoomMainTemp({
                  chats: reverseMessagesObject({...messages}),
                  activeRoom: props.activeRoom,
                  timeStamp: new Date().valueOf()
                })
        
                if(!isReconnect) {
                  Object.keys(messages).length < 50 && getMessageBefore(true, false);
                }
                
                if(!props.goToChatBubble.localID) {
                  scrollChatViewToBottom();
                }
              }
          },
          onError: (errorCode, errorMessage) => {
              setIsLoadingChat(false);
              console.log(errorCode, errorMessage);
          }
      });
    }, isReconnect ? 0 : 100);
  }

  let getMessageBefore = async (isFirstLoad= false, scrollingBackHeight, findLocalID) => {
    let numberOfItems = 50;
    let roomID = props.activeRoom.roomID;

    if(!findLocalID) {
      setIsLoadingChat(true);
    }else {
      setIsShowLoadingBlocking(true);
    }
    
    setTimeout(function() {
      if(roomID && roomID === props.activeRoom.roomID) {
        tapCoreMessageManager.getOlderMessagesBeforeTimestamp(roomID, numberOfItems, {
          onSuccess: (messages, hasMore) => {
              if(roomID === props.activeRoom.roomID || props.activeRoom !== null ) {
                setHasMoreChatBefore(hasMore);
                setIsLoadingChat(false);
                unreadMessageAction();
                // unreadMessageAction({...messages});
                // setChatDataRoomMain(reverseMessagesObject({...messages}));
                setChatDataRoomMainTemp({
                  chats: reverseMessagesObject({...messages}),
                  activeRoom: props.activeRoom,
                  timeStamp: new Date().valueOf(),
                  scrollingBackHeight: scrollingBackHeight,
                  findLocalID: findLocalID,
                  isFirstLoad: isFirstLoad,
                  hasMore: hasMore
                })
              }
          },
          onError: (errorCode, errorMessage) => {
              setIsLoadingChat(false);
              console.log(errorCode, errorMessage);
          }
        });
      }
    }, 100);
  }

  // let isBubbleOnViewPort = async (message) => {
  //   unreadMessageAction({[message.localID]: message});
  // }

  let isMessageOut = (messageData, activeUser) => {
    let isOut;
    let isSavedMessageRoom = props.activeRoom.roomID === Helper.savedMessageRoomID();
    
    if(isSavedMessageRoom && Helper.isForwardMessageIgnoreRoom(messageData)) {
      isOut = false;
    }else if(messageData.user.userID !== activeUser){
      isOut = false;
    }else {
      isOut = true;
    }
    
    return isOut;
  }

  let generateMessageBubble = (messageData, index) => {
    let activeUser = taptalk.getTaptalkActiveUser().userID;

    switch(messageData.type) {
      case CHAT_TYPE.TAPChatMessageTypeText:
      case CHAT_TYPE.TAPChatMessageTypeLink:
        return (
          !isMessageOut(messageData, activeUser) ?
            <ChatRoomMessageIn 
              key={index} 
              singleChatDataProps={messageData} 
              activeUserID={activeUser}
              onReplyMessage={onReplyMessage}
              onForwardMessage={onForwardMessage}
              onStarredMessage={onStarredMessage}
              onUnstarredMessage={onUnstarredMessage}
              onPinnedMessage={onPinnedMessage}
              onUnpinnedMessage={onUnpinnedMessage}
              onReportMessage={onReportMessage}
              scrollToReply={scrollToReply}
              mentionList={mentionList[messageData.localID]}
              //isBubbleOnViewPort={isBubbleOnViewPort}
            />
            :
            <ChatRoomMessageOut
              key={index} 
              singleChatDataProps={messageData} 
              activeUserID={activeUser}
              onReplyMessage={onReplyMessage}
              onForwardMessage={onForwardMessage}
              onEditMessage={onEditMessage}
              onStarredMessage={onStarredMessage}
              onUnstarredMessage={onUnstarredMessage}
              onPinnedMessage={onPinnedMessage}
              onUnpinnedMessage={onUnpinnedMessage}
              scrollToReply={scrollToReply}
              mentionList={mentionList[messageData.localID]}
              onMessageInfo={onMessageInfo}
              //isBubbleOnViewPort={isBubbleOnViewPort}
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeImage:
          return (
            !isMessageOut(messageData, activeUser) ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
                :
                <ChatRoomMessageInImage 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                />
                :
                <ChatRoomMessageOutImage 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  onEditMessage={onEditMessage}
                  onMessageInfo={onMessageInfo}
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeVideo:
          return (
            !isMessageOut(messageData, activeUser) ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
                :
                <ChatRoomMessageInVideo 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]} 
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
                :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                />
                :
                <ChatRoomMessageOutVideo 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  onEditMessage={onEditMessage}
                  onMessageInfo={onMessageInfo}
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeSystemMessage:
          return (
            <ChatRoomChatInfo 
              key={index} 
              singleChatDataProps={messageData} 
              activeUserID={activeUser}
              onReplyMessage={onReplyMessage}
              onForwardMessage={onForwardMessage}
              onStarredMessage={onStarredMessage}
              onUnstarredMessage={onUnstarredMessage}
              onPinnedMessage={onPinnedMessage}
              onUnpinnedMessage={onUnpinnedMessage}
              scrollToReply={scrollToReply}
              mentionList={mentionList[messageData.localID]}
            />
          )
      case CHAT_TYPE.TAPChatMessageTypeLocation:
          return (
            !isMessageOut(messageData, activeUser) ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply} 
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
                :
                <ChatRoomMessageInLocation 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                />
                :
                <ChatRoomMessageOutLocation 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  onMessageInfo={onMessageInfo}
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeFile:
          return (
            !isMessageOut(messageData, activeUser) ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
                :
                <ChatRoomMessageInFile 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply} 
                  mentionList={mentionList[messageData.localID]}
                />
                :
                <ChatRoomMessageOutFile 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  onMessageInfo={onMessageInfo}
                />
          )
      
      case CHAT_TYPE.TAPChatMessageTypeVoice:
          return (
            !isMessageOut(messageData, activeUser) ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
                :
                <ChatRoomMessageInVoice
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  onReportMessage={onReportMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  //isBubbleOnViewPort={isBubbleOnViewPort}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply} 
                  mentionList={mentionList[messageData.localID]}
                />
                :
                <ChatRoomMessageOutVoice
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  onForwardMessage={onForwardMessage}
                  onStarredMessage={onStarredMessage}
                  onUnstarredMessage={onUnstarredMessage}
                  onPinnedMessage={onPinnedMessage}
                  onUnpinnedMessage={onUnpinnedMessage}
                  scrollToReply={scrollToReply}
                  mentionList={mentionList[messageData.localID]}
                  onMessageInfo={onMessageInfo}
                />
          )
      default:
        break;
    }
  }

  let onInputNewMessage = (messageText) => {
    // messageText =  HelperChat.trimBody(messageText);
    messageText = messageText.trim();

    let clearDraftMessage = async () => {
      let _draftMessage = {...props.draftMessage};

      if(props.activeRoom && _draftMessage[props.activeRoom.roomID]) {
        _draftMessage[props.activeRoom.roomID] = false;
        props.setDraftMessage(_draftMessage)
      }
    }

    clearDraftMessage();

    tapCoreChatRoomManager.sendStopTypingEmit(props.activeRoom.roomID);

    if(props.forwardMessage.message && messageText === "") {
      let _forwardMessage = {...props.forwardMessage};
      let timeOutForward = 0;
      let a = [];

      if(_forwardMessage.message.multipleMessages) {
        Object.keys(_forwardMessage.message.multipleMessages).map((v, i) => {
          if(i > 0) { timeOutForward += 700; }
          a.push(_forwardMessage.message.multipleMessages[v]);
          setTimeout(() => {
            // onForwardOnly(_forwardMessage.message.multipleMessages[v]);
          }, timeOutForward)

          return null;
        })

        onForwardOnly(a);

        props.clearForwardMessage();
      }else {
        onForwardOnly([_forwardMessage.message]);
      }
    }else {
      if((props.activeRoom) && (messageText !== "") && (messageText.replace(/\s/g, '').length !== 0)) {
        let split4000char = messageText.match(/.{1,4000}/gs);
  
        for(let i in split4000char) {
          if(props.forwardMessage.message && props.forwardMessage.message.multipleMessages) {
            let _multiForward = {...props.forwardMessage.message.multipleMessages};
            let forwardLength = Object.keys(_multiForward).length;
  
            Object.keys(_multiForward).map((v, i) => {
              runSendTextOrLinkMessage(i, split4000char, _multiForward[v], !(forwardLength === (i + 1)));

              return null;
            })
          }else {
            runSendTextOrLinkMessage(i, split4000char, props.forwardMessage.message, false);
          }
        }
      }
    }
  }

  let runSendTextOrLinkMessage = (i, split4000char, forwardMessage, isForwardOnly) => {
    const messageBody = i+1 === split4000char.length ?split4000char[i] : split4000char[i];
    const urls = Helper.getUrlsFromString(messageBody);

    if (scheduledMessageTime.valueOf() > new Date().valueOf()) {
      generateScheduledMessageAndOpenChatRoom(messageBody, urls);
      return;
    }

    if ((props.linkPreview.url && props.linkPreview.roomID === props.activeRoom.roomID) || urls.length > 0) {
      // Send link message
      tapCoreMessageManager.sendLinkMessage(
        messageBody, 
        props.activeRoom, 
        handleSendTextOrLinkMessageCallback,
        props.linkPreview.urls.length > 0 ? props.linkPreview.urls : urls, 
        props.linkPreview.title ? props.linkPreview.title : "", 
        props.linkPreview.description ? props.linkPreview.description : "", 
        props.linkPreview.image ? props.linkPreview.image : "", 
        props.linkPreview.siteName ? props.linkPreview.siteName : "", 
        props.linkPreview.type ? props.linkPreview.type : "", 
        props.replyMessage.message, 
        forwardMessage, 
        isForwardOnly, 
        (Helper.isSavedMessageRoom(props.activeRoom.roomID) && 
        Helper.isForwardMessageIgnoreRoom(props.replyMessage.message)) ? 
          props.replyMessage.message.forwardFrom.fullname :
          false
      );
    }
    else {
      // No url in body, send as text message
      tapCoreMessageManager.sendTextMessage(
        messageBody, 
        props.activeRoom, 
        handleSendTextOrLinkMessageCallback,
        props.replyMessage.message, 
        forwardMessage, 
        isForwardOnly, 
        (Helper.isSavedMessageRoom(props.activeRoom.roomID) && 
          Helper.isForwardMessageIgnoreRoom(props.replyMessage.message)) ? 
          props.replyMessage.message.forwardFrom.fullname :
          false
      );
    }
  };

  function handleSendTextOrLinkMessageCallback(message) { 
    // message.body = split4000char[i];

    handleSendOrScheduledMessageFinished(true);
  
    props.setNewEmitMessageProps(message);

    let currentChatRoomData = chatDataRoomMain;

    currentChatRoomData[message.localID] = message;
    props.runSetChatRoomDataForHeader(currentChatRoomData);
    setChatDataRoomMain(currentChatRoomData);
    scrollChatViewToBottom();
  }

  let generateScheduledMessageAndOpenChatRoom = (scheduledMessageBody, urls) => {
    setIsScheduledMessageLoading(true);

    let message;
    if ((props.linkPreview.url && props.linkPreview.roomID === props.activeRoom.roomID) || urls.length > 0) {
      let messageData = {
        urls: props.linkPreview.urls.length > 0 ? props.linkPreview.urls : urls, 
        url: props.linkPreview.urls.length > 0 ? props.linkPreview.urls[0] : urls[0], 
        title: props.linkPreview.title, 
        description: props.linkPreview.description, 
        image: props.linkPreview.image, 
        siteName: props.linkPreview.siteName, 
        type: props.linkPreview.type, 
      }
      message = tapCoreMessageManager.constructTapTalkMessageModelWithQuote(
        scheduledMessageBody,
        props.activeRoom,
        CHAT_TYPE.TAPChatMessageTypeLink,
        messageData,
        props.replyMessage.message,
        null
      );
    }
    else {
      message = tapCoreMessageManager.constructTapTalkMessageModelWithQuote(
        scheduledMessageBody,
        props.activeRoom,
        CHAT_TYPE.TAPChatMessageTypeText,
        "",
        props.replyMessage.message,
        null
      );
    }
    message.created = scheduledMessageTime.valueOf();
    
    props.setShowScheduledMessagesChatRoom(true);
    let _pendingScheduledMessages = props.pendingScheduledMessages.slice();
    _pendingScheduledMessages.push(message);
    props.setPendingScheduledMessages(_pendingScheduledMessages);
    handleSendOrScheduledMessageFinished(false);
  }

  let handleSendOrScheduledMessageFinished = (clearInput = false) => {
    setIsScheduledMessageLoading(false);
    hideScheduledMessageDateTimePicker();
    if (clearInput) {
      props.clearReplyMessage(); 
      props.clearLinkPreview(); 
      props.clearForwardMessage();
      props.clearParticipantList();
      HelperChat.resetChatRoomHeightAndInputText();
    }
  }

  let onSendEditMessage = (editMessageModel, val) => {
    val = val.trim();

    let _message = {...editMessageModel.message};
    
    _message.isMessageEdited = true;
    
    if ((_message.data !== "") && (typeof _message.data.caption !== "undefined")) {
        _message.data.caption = val;
    }
    else {
        _message.body = val;
    }

    if (_message.type === CHAT_TYPE.TAPChatMessageTypeText || _message.type === CHAT_TYPE.TAPChatMessageTypeLink) {
      const urls = Helper.getUrlsFromString(val);
      if ((props.linkPreview.url && props.linkPreview.roomID === props.activeRoom.roomID) || urls.length > 0) {
        // Message contains url
        if (_message.data !== "") {
          _message.data.urls = props.linkPreview.urls ? props.linkPreview.urls : urls;
          _message.data.url = props.linkPreview.urls ? props.linkPreview.urls[0] : urls[0];
          _message.data.title = props.linkPreview.title ? props.linkPreview.title : "";
          _message.data.description = props.linkPreview.description ? props.linkPreview.description : "";
          _message.data.image = props.linkPreview.image ? props.linkPreview.image : "";
          _message.data.siteName = props.linkPreview.siteName ? props.linkPreview.siteName : "";
          _message.data.type = props.linkPreview.type ? props.linkPreview.type : "";
        } 
        else {
          _message.data = {
              urls: props.linkPreview.urls ? props.linkPreview.urls : urls, 
              url: props.linkPreview.urls ? props.linkPreview.urls[0] : urls[0], 
              title: props.linkPreview.title ? props.linkPreview.title : "", 
              description: props.linkPreview.description ? props.linkPreview.description : "", 
              image: props.linkPreview.image ? props.linkPreview.image : "", 
              siteName: props.linkPreview.siteName ? props.linkPreview.siteName : "", 
              type: props.linkPreview.type ? props.linkPreview.type : "", 
          };
        }

        if (_message.type === CHAT_TYPE.TAPChatMessageTypeText) {
            // Change text message to link message
            _message.type = CHAT_TYPE.TAPChatMessageTypeLink;
        }
      }
      else {
          // Change link message to text message and remove data
          _message.type = CHAT_TYPE.TAPChatMessageTypeText;
          _message.data = "";
      }
      props.clearLinkPreview();
    }

    tapCoreMessageManager.sendEmitWithEditedMessageModel(_message, (message) => {
      let currentChatRoomData = chatDataRoomMain;
      
      currentChatRoomData[message.localID] = message;
      props.runSetChatRoomDataForHeader(currentChatRoomData);
    });
  }

  let onStartUploadFile = (message) => {
    setFileUploadProgress(generateNewFileUploadProgress(message.localID, 0, 0, message));
  }

  let onProgressUploadFile = (localID, percentage, bytes) => {
    setFileUploadProgress(generateNewFileUploadProgress(localID, percentage, bytes));
  }

  let runningVoiceMessage = (file, duration) => {
    let runVoice = (forwardMessage, isForwardOnly) => {
      tapCoreMessageManager.sendVoiceMessage(file, duration, props.activeRoom, 
        {
          onStart: (message) => {
            props.setNewEmitMessageProps(message);
            onStartUploadFile(message);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage(); 
            props.clearForwardMessage();
          },
  
          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },
  
          onSuccess: (message) => {
            
          },
  
          onError: (errorCode, errorMessage) => {
            Helper.doToast(errorMessage);
            console.log(errorCode, errorMessage);
          }
        }, 
        props.replyMessage.message, 
        forwardMessage, 
        isForwardOnly,
        (Helper.isSavedMessageRoom(props.activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(props.replyMessage.message)) ? 
          props.replyMessage.message.forwardFrom.fullname
          :
          false
      );
    }

    if(props.forwardMessage.message && props.forwardMessage.message.multipleMessages) {
      let _multiForward = {...props.forwardMessage.message.multipleMessages};
      let forwardLength = Object.keys(_multiForward).length;

      Object.keys(_multiForward).map((v, i) => {
        runVoice(_multiForward[v], !(forwardLength === (i + 1)));

        return null;
      })
    }else {
      runVoice(props.forwardMessage.message, false);
    }
  }

  let onForwardOnly = (forwardMessages, room = false) => {
    tapCoreMessageManager.sendForwardMessages(room ? room : props.activeRoom, (mes) => {
      let _latestForwardOnly = {...latestForwardOnly};

      _latestForwardOnly = {
        time: new Date().valueOf(),
        message: mes,
        roomID: room ? room.roomID : props.activeRoom.roomID
      }
      
      setLatestForwardOnly(_latestForwardOnly);
    }, forwardMessages)
  }

  let runningFileMessage = (file, index) => {
      tapCoreMessageManager.sendFileMessage(file, props.activeRoom, 
        {
          onStart: (message) => {
            props.setNewEmitMessageProps(message);
            onStartUploadFile(message);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage(); 
            props.clearForwardMessage();
          },
  
          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },
  
          onSuccess: (message) => {
            
          },
  
          onError: (errorCode, errorMessage) => {
            Helper.doToast(errorMessage);
            console.log(errorCode, errorMessage);
          }
        }, 
        props.replyMessage.message, 
        (index > 0 || (props.forwardMessage.message && props.forwardMessage.message.multipleMessages)) ? false : props.forwardMessage.message,
        false,
        (Helper.isSavedMessageRoom(props.activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(props.replyMessage.message)) ? 
          props.replyMessage.message.forwardFrom.fullname
          :
          false
      );
  }

  let runningImageMessage = (file, caption, index) => {
    tapCoreMessageManager.sendImageMessage(file, caption, props.activeRoom, 
        {
          onStart: (message) => {
            props.setNewEmitMessageProps(message);
            onStartUploadFile(message);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage(); 
            props.clearForwardMessage();
          },

          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },

          onSuccess: (message) => {
            
          },

          onError: (errorCode, errorMessage) => {
            Helper.doToast(errorMessage);
            console.log(errorCode, errorMessage);
          }
      }, 
      props.replyMessage.message, 
      (index > 0 || (props.forwardMessage.message && props.forwardMessage.message.multipleMessages)) ? false : props.forwardMessage.message,
      false,
      (Helper.isSavedMessageRoom(props.activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(props.replyMessage.message)) ? 
        props.replyMessage.message.forwardFrom.fullname
        :
        false
    );
  }

  let runningVideoMessage = (file, caption, index) => {
    tapCoreMessageManager.sendVideoMessage(file, caption, props.activeRoom, 
        {
          onStart: (message) => {
            onStartUploadFile(message);
            props.setNewEmitMessageProps(message);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage(); 
            props.clearForwardMessage();
          },

          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },

          onSuccess: (message) => {
            
          },

          onError: (errorCode, errorMessage) => {
            Helper.doToast(errorMessage);
            console.log(errorCode, errorMessage);
          }
      }, 
      props.replyMessage.message,
      (index > 0 || (props.forwardMessage.message && props.forwardMessage.message.multipleMessages)) ? false : props.forwardMessage.message,
      false,
      (Helper.isSavedMessageRoom(props.activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(props.replyMessage.message)) ? 
        props.replyMessage.message.forwardFrom.fullname
        :
        false
    );
  }

  let generateBubbleThisGroupUnavailable = () => {
    return (
      <div className="room-is-unavailable">
        Sorry, this group is unavailable
      </div>
    )
  }

  let onStarredMessage = async (message) => {
    let _allStarred = {...props.allStarredMessages};
    _allStarred[message.messageID] = true;
    props.setAllStarredMessages(_allStarred);

    tapCoreMessageManager.starMessage(props.activeRoom.roomID, [message.messageID], {
      onSuccess: (res) => {
        console.log(res)
      },
      onError: (errCode, errMessage) => {
        console.log(errCode, errMessage)
      }
    })
  }
  
  let onUnstarredMessage = async (message) => {
    let _allStarred = {...props.allStarredMessages};
    delete _allStarred[message.messageID];
    props.setAllStarredMessages(_allStarred);

    tapCoreMessageManager.unstarMessage(props.activeRoom.roomID, [message.messageID], {
      onSuccess: (res) => {
        console.log(res)
      },
      onError: (errCode, errMessage) => {
        console.log(errCode, errMessage)
      }
    })
  }

  let onPinnedMessage = async (message) => {
    tapCoreMessageManager.pinMessage(props.activeRoom.roomID, [message], {
      onSuccess: (res) => {
        // let _allPinned = {...props.allPinnedMessages};
        // _allPinned.messages[message.messageID] = true;
        // _allPinned.pinMessages = res;
        // _allPinned.messages = pinnedIDs;
        // _allPinned.pinMessages = pinnedMessages;
        // _allPinned.activePinnedMessageIndex = _allPinned.pinMessages.messages.findIndex(v => v.messageID === message.messageID);
        // props.setAllPinnedMessages(_allPinned);
      },

      onError: (errCode, errMessage) => {
        console.log(errCode, errMessage)
      }
    })
  }
  
  let onUnpinnedMessage = async (message) => {
    // let _allPinned = {...props.allPinnedMessages};
    // let _pinnedMessages = _allPinned.pinMessages.messages;
    // delete _allPinned.messages[message.messageID];
    // _pinnedMessages.splice(Helper.findIndexOfArray(_pinnedMessages, message.messageID, "messageID"), 1);
    
    // props.setAllPinnedMessages(_allPinned);

    tapCoreMessageManager.unpinMessage(props.activeRoom.roomID, [message.messageID], false, {
      onSuccess: (res) => {
        // console.log(res)
      },
      onError: (errCode, errMessage) => {
        console.log(errCode, errMessage)
      }
    })
  }

  let onReplyMessage = async (message) => {
    HelperChat.resetChatRoomHeight();
    props.clearForwardMessage();
    props.clearEditMessage();

    let _replyMessage = {...props.replyMessage};
    _replyMessage.message = message;
    props.setReplyMessage(_replyMessage)
  }

  let onEditMessage = async (message) => {
    HelperChat.resetChatRoomHeightAndInputText();
    props.clearForwardMessage();
    props.clearReplyMessage();
    props.clearLinkPreview();

    let _editMessage = {...props.editMessage};
    _editMessage.message = message;
    props.setEditMessage(_editMessage);

    let _message = {...message};
    if (_message.type = CHAT_TYPE.TAPChatMessageTypeLink &&
        _message.data.title &&
        _message.data.description
    ) {
      let _linkPreview = {
        urls: _message.data.urls ? _message.data.urls : [], 
        url: _message.data.urls ? _message.data.urls[0] : "", 
        title: _message.data.title ? _message.data.title : "", 
        description: _message.data.description ? _message.data.description : "", 
        image: _message.data.image ? _message.data.image : "", 
        siteName: _message.data.siteName ? _message.data.siteName : "", 
        type: _message.data.type ? _message.data.type : "", 
      };
      props.setLinkPreview(_linkPreview);
    } 
  }

  let onForwardMessage = async (message) => {
    HelperChat.resetChatRoomHeightAndInputText();
    props.clearForwardMessage();
    props.clearReplyMessage();
    props.clearEditMessage();
    props.clearLinkPreview();

    let _multiForward = {...props.multiForward};

    if(!_multiForward[props.activeRoom.roomID]) {
      _multiForward[props.activeRoom.roomID] = {};
    }

    let _length = Object.keys(_multiForward[props.activeRoom.roomID]).length;

    if(_multiForward[props.activeRoom.roomID]["message_"+message.messageID]) {
      delete _multiForward[props.activeRoom.roomID]["message_"+message.messageID]
    }else {
      if(_length < 30) {
        Object.assign(_multiForward[props.activeRoom.roomID], {["message_"+message.messageID]: message});
      }
    }

    if(Object.keys(_multiForward[props.activeRoom.roomID]).length === 0) {
      delete _multiForward[props.activeRoom.roomID];
    }

    props.setMultiForward(_multiForward);
  }

  let onReportMessage = (message) => {
    setReportedMessage(message);
  }

  let onMessageInfo = (message) => {
    let _mes = {...props.messageInfo};
    _mes.isShow = true;
    _mes.message = message;
    _mes.lastOpen = new Date().valueOf();
    props.setMessageInfo(_mes);
  }

  let clearReportedMessage = () => {
    if (reportedMessage) {
      setReportedMessage(false);
    }
  }

  let scrollToReply = (localID) => {
    let targetScroll = document.querySelectorAll(".chat-room-main-content")[0];
    let targetLocalID = document.querySelector(`#message-${localID}`);
    props.clearGoToChatBubble();

    if(targetLocalID !== null) {
        targetScroll.scrollTop = targetLocalID.offsetTop;

        // targetScroll.scrollTo({
        //     top: targetLocalID.offsetTop,
        //     behavior: 'smooth',
        // });
        
        targetLocalID.classList.add("highlight-chat-bubble");

        setTimeout(() => {
            targetLocalID.classList.remove("highlight-chat-bubble");
        }, 2000);
    }else {
      getMessageBefore(false, false, localID)
    }
  }

  let runSetLastDragAndDropFiles = () => {
    setLastDragAndDropFiles({
      files: [],
      time: ""
    })
  }

  let toggleDropFileHere = () => {
    setShowDropFileHere(!showDropFileHere);
  }

  let hidedropFileHere = () => {
    setShowDropFileHere(false);
  }

  let checkIsFileorMedia = (files) => {
    let _files = Array.from(files);

    if(_files.length > 0) {
      let isMedia = null;
  
      _files.map((value) => {
        if(value.type.split("/")[0] === "video" || value.type.split("/")[0] === "image") {
          isMedia === null && (isMedia = true);
        }else {
          isMedia = false;
        }
  
        return null;
      })
  
      if(!isMedia) {
        runningFileMessage(_files);
      }else {
        setLastDragAndDropFiles({
          files: _files,
          time: new Date().valueOf()
        })
      }
    }
  }

  let generateViewDropFileHere = () => {
    let handleDropFile = (e) => {
      toggleDropFileHere();
      e.preventDefault();
      let files= e.dataTransfer.files;
      checkIsFileorMedia(files);
    }

    return (
      <div className={`drop-file-here-wrapper ${showDropFileHere ? "active-drop-file-here-wrapper" : ""}`}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {handleDropFile(e)}}
      >
        <div className="drop-file-here-content">
          <div className="drop-file-here-inner-content">
            <FiUpload />

            <p>
              Drop your files, image or video here
            </p>
          </div>
        </div>
      </div>
    )
  }

  let onClickMentionCounter = () => {
    scrollToReply(Object.keys(myMentionCounter)[0]);
    
    setMyMentionCounter({});
  }

  let deleteMessageAction = async (message) => {
    // let _allPinned = {...props.allPinnedMessages};
    // let _pinnedMessages = _allPinned.pinMessages.messages;

    // if(_allPinned[message.roomID]) {
    //   delete _allPinned.messages[message.messageID];
    // }
    
    // if(Helper.findIndexOfArray(_allPinned.pinnedMessages, message.messageID, "messageID") !== -1) {
    //   _allPinned.pinnedMessages.splice(Helper.findIndexOfArray(_allPinned.pinnedMessages, message.messageID, "messageID"), 1);
    // }
    
    // props.setAllPinnedMessages(_allPinned);
    
    let messageIDs = [];
    messageIDs.push(message.messageID);
    tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
  }

  let toggleDeleteRoomConfirmation = () => {
    if (isLoadingDeleteRoom) {
        return;
    }
      setShowDeleteRoomConfirmation(!showDeleteRoomConfirmation);
      // if (showDeleteRoomConfirmation) {
      //     setRoomIDToDelete("");
      // }
  }

  let toggleFailedDeleteRoomModal = () => {
      setShowFailedDeleteRoom(!showFailedDeleteRoom);
  }

  let actionDeleteRoom = (roomID) => {
      // setRoomIDToDelete(roomID);
      toggleDeleteRoomConfirmation();
  }

  let deleteChatRoomMessages = () => {

      let actionUnpinRoom = (roomID, callback = false) => {
          let _pinnedRoomID = {...props.pinnedRoomID};

          delete _pinnedRoomID[roomID];

          setPinnedRoomID(_pinnedRoomID);

          HelperChat.unpinRoomAction([roomID], {
              onSuccess: (res, res2) => {
                  if (callback) {
                      callback(true)
                  }
              },
              onError: (errCode, errMes) => {
                  console.log(errCode, errMes)
                  if (callback) {
                      callback(false)
                  }
              }
          })
      }

      let callDeleteRoomAPI = () => {
          tapCoreChatRoomManager.deleteAllChatRoomMessages([props.activeRoom.roomID], {
              onSuccess: (res) => {
                  props.deleteLocalChatRoomProps(props.activeRoom.roomID);
              },
              onError: (err) => {
                  props.deleteLocalChatRoomProps(props.activeRoom.roomID);
              }
          })
      }

      setIsLoadingDeleteRoom(true);
      if (props.pinnedRoomID[props.activeRoom.roomID]) {
          actionUnpinRoom(props.activeRoom.roomID, (isSuccess) => {
              if (isSuccess) {
                  callDeleteRoomAPI();
              }
              else {
                  setIsLoadingDeleteRoom(false);
                  toggleDeleteRoomConfirmation();
                  toggleFailedDeleteRoomModal();
              }
          });
      }
      else {
          callDeleteRoomAPI();
      }
  }

  let modalActionMessage = () => {
    let myAccountID = taptalk.getTaptalkActiveUser().userID;
    let message = props.activeMessage;

    let isShowCopyForward = () => {
      let show = false;

      if (message.type === CHAT_TYPE.TAPChatMessageTypeText || 
          message.type === CHAT_TYPE.TAPChatMessageTypeLocation ||
          message.type === CHAT_TYPE.TAPChatMessageTypeLink
      ) {
        show = true;
      }

      return show;
    }

    let isShowDelete = () => {
      let show = true;

      if(myAccountID !== message.user.userID) {
        show = false;
      }

      return show;
    }

    return (
      <Modal className="modal-action-message" isOpen={openModalActionMessage}>
        <ModalBody>
            <div className="action-message-wrapper-chat">
              {isShowCopyForward() &&
                <div 
                  className="action-message-content" 
                  onClick={() => {
                    props.clearActiveMessage();
                    HelperChat.copyToClipBoard(props.activeMessage.body);
                  }}
                >
                    <FiCopy />
                    <span>Copy</span>
                </div>
              }
              
              <div 
                className="action-message-content" 
                onClick={() => {
                  props.clearActiveMessage();
                  onReplyMessage(props.activeMessage)
                }}
              >
                  <FaReply />
                  <span>Reply</span> 
              </div>
              
              {
              // isShowCopyForward() &&
                <div 
                  className="action-message-content action-message-forward" 
                  onClick={() => {
                    props.clearActiveMessage();
                    onForwardMessage(props.activeMessage);
                }}>
                    <FaReply />
                    <span>Forward</span> 
                </div>
              }

              {isShowDelete() &&
                <div 
                  className="action-message-content" 
                  onClick={() => {
                    props.clearActiveMessage();
                    deleteMessageAction(props.activeMessage);
                  }}
                >
                    <FiTrash />
                    <span>Delete</span> 
                </div>
              }
            </div>

            <div className="cancel-action-message" onClick={props.clearActiveMessage}>
              <b>Cancel</b>
            </div>
        </ModalBody>
      </Modal>
    )
  }

  // let countVisibleBubble = () => {
  //   let bubbleIn = document.querySelectorAll(".chat-room-message-out-wrapper").length;
  //   let bubbleOut = document.querySelectorAll(".chat-room-message-in-wrapper").length;
    
  //   return bubbleIn + bubbleOut;
  // }

  let chatRoomMainContentHeight = () => {
    let el = document.querySelectorAll(".chat-room-main-content");

    return el.length > 0 ? el[0].clientHeight : 0;
  }

  let hideScheduledMessageDateTimePicker = () => {
    setScheduledMessageTime(new Date());
    setIsShowScheduledMessageDateTimePicker(false);
  }

  let toggleScheduledMessageDateTimePicker = () => {
    let scheduledMessageBody = document.querySelectorAll(".main-textarea-input")[0].value;
    scheduledMessageBody = scheduledMessageBody.trim();
    if (isShowScheduledMessageDateTimePicker) {
      setScheduledMessageTime(new Date());
      setIsShowScheduledMessageDateTimePicker(false);
    }
    else if (scheduledMessageBody) {
      let initialScheduledTime = new Date(new Date().valueOf() + 60000);
      initialScheduledTime.setSeconds(0);
      setScheduledMessageTime(initialScheduledTime);
      setIsShowScheduledMessageDateTimePicker(true);
    }
  }

  let onScheduledTimePickerCalendarChanged = (date) => {
    let _scheduledMessageTime = new Date(scheduledMessageTime.valueOf());
    const selectedHour = _scheduledMessageTime.getHours();
    const selectedMinute = _scheduledMessageTime.getMinutes();
    _scheduledMessageTime = date;
    _scheduledMessageTime.setHours(selectedHour);
    _scheduledMessageTime.setMinutes(selectedMinute);
    setScheduledMessageTime(_scheduledMessageTime);
  }

  let onScheduledTimePickerHourChanged = (e) => {
    let _scheduledMessageTime = new Date(scheduledMessageTime.valueOf());
      if (e.target.value < 0) {
          e.target.value = 0;
      }
      if (e.target.value > 23) {
          e.target.value = 23;
      }
      _scheduledMessageTime.setHours(e.target.value);
      setScheduledMessageTime(_scheduledMessageTime);
      if (parseInt(e.target.value, 10) < 10) {
          e.target.value = "0" + e.target.value;
      }
      while (e.target.value.charAt(0) === "0" && e.target.value.length > 2) {
        e.target.value = e.target.value.substring(1);
      }
  }

  let onScheduledTimePickerMinuteChanged = (e) => {
    let _scheduledMessageTime = new Date(scheduledMessageTime.valueOf());
      if (e.target.value < 0) {
          e.target.value = 0;
      }
      if (e.target.value > 59) {
          e.target.value = 59;
      }
      _scheduledMessageTime.setMinutes(e.target.value);
      setScheduledMessageTime(_scheduledMessageTime);
      if (parseInt(e.target.value, 10) < 10) {
          e.target.value = "0" + e.target.value;
      }
      while (e.target.value.charAt(0) === "0" && e.target.value.length > 2) {
        e.target.value = e.target.value.substring(1);
      }
  }

  let submitScheduledMessage = () => {
    const scheduledMessageBody = document.querySelectorAll(".main-textarea-input")[0].value;
    if (!scheduledMessageBody) {
      return;
    }
    onInputNewMessage(scheduledMessageBody);
  }

  let toggleUnblockUserConfirmation = () => {
    setShowUnblockUserConfirmation(!showUnblockUserConfirmation);
  }
    
  let unblockUser = () => {
    toggleUnblockUserConfirmation();
    setIsLoadingUnblockUser(true);
    const otherUserID = HelperChat.getOtherUserIDFromRoomID(props.activeRoom.roomID);
      tapCoreContactManager.unblockUser(otherUserID, {
        onSuccess: (res) => {
          let _blockedUserIDs = props.blockedUserIDs;
          const index = _blockedUserIDs.indexOf(otherUserID);
          if (index !== -1) {
            _blockedUserIDs.splice(index, 1);
            props.setBlockedUserIDs(_blockedUserIDs);
          }
          setIsBlockedContact(false);
          setIsLoadingUnblockUser(false);
          console.log(res.message)
        },
        onError: (errCode, errMes) => {
          setIsLoadingUnblockUser(false);
          console.log(errCode, errMes);
        }
    });
  }

  return (
    <div 
      className={`
      wrap-all-chatroom
      ${(!Helper.isObjectEmpty(props.allPinnedMessages.messages) && props.allPinnedMessages.roomID === props.activeRoom.roomID) ?
        'chat-room-with-header-height-100'
        :
        ''
      } 
    `}
      onDragEnter={() => {toggleDropFileHere()}}
      onDragLeave={() => {toggleDropFileHere()}}
      onPaste={(e) => {
        checkIsFileorMedia(e.clipboardData.files);
      }}
    >
      {generateViewDropFileHere()}

      {props.activeMessage &&
        <MediaQuery maxDeviceWidth={767}>
          {modalActionMessage()}
        </MediaQuery>
      }

      <ChatRoomSelectForward
        isOpen={isShowModalForward}
        toggle={toggleModalForward}
        onForwardOnly={onForwardOnly}
      />

      {
        reportedMessage &&
        <ChatRoomReportMessage
          reportedMessage={reportedMessage}
          clearReportedMessage={clearReportedMessage}
        />
      }

      <div className={`chat-room-main-wrapper ${isNoLongerParticipantOrUserDelete ? "hide-reply-forward" : ""}`}
           style={{maxHeight: `calc(100% - ${isNoLongerParticipantOrUserDelete ? '129' : '57'}px)`}}
          //  onScroll={() => setLastScroll(new Date())}
      > 
          {Object.keys(myMentionCounter).length > 0 &&
            <div className="mention-counter" onClick={onClickMentionCounter}>
              <div className="mention-counter-badge">{Object.keys(myMentionCounter).length}</div>
              <b>@</b>
            </div>
          }

          {isShowLoadingBlocking &&
            <div className="chat-room-main-wrapper-loading-blocking">
                  <div className="lds-ring">
                    <div /><div /><div /><div />
                  </div>
            </div>
          }

          {showBlockingChatFirst &&
            <div className="chat-room-main-wrapper-firstchat-blocking">
              {props.activeRoom.roomID === Helper.savedMessageRoomID() ?
                <div className='no-saved-message-container'>
                  <p>
                    <b>Save specific messages to quickly refer back to them later.</b>
                  </p>

                  <ul>
                    <li>Forward messages here</li>
                    <li>Send media and files to save them</li>
                    <li>Access this chat personally from any device</li>
                  </ul>
                </div>
                :
                <div className="chat-room-main-wrapper-firstchat-blocking-content">
                    {taptalk.getTaptalkActiveUser() !== null && 
                      <div className="avatar-outer-wrapper">
                        <div className="avatar-content my-avatar">
                          {taptalk.getTaptalkActiveUser().imageURL.thumbnail === '' ?
                            <div className="avatar-abbreviation">
                                <b>{Helper.renderUserAvatarWord(taptalk.getTaptalkActiveUser().fullname, false)}</b>
                            </div>
                            :
                            <img src={taptalk.getTaptalkActiveUser().imageURL.thumbnail} alt="" />
                          }
                        </div>

                        <div className="avatar-content recipient-avatar">
                          {props.activeRoom.imageURL.thumbnail === '' ?
                            <div className="avatar-abbreviation">
                                <b>{Helper.renderUserAvatarWord(props.activeRoom.name, props.activeRoom.type === 2)}</b>
                            </div>
                            :
                            <img src={props.activeRoom.imageURL.thumbnail} alt="" />
                          }
                        </div>
                      </div>
                    }
                    
                    <p>
                      <b>Start a conversation with {props.activeRoom.name}</b>
                    </p>

                    <span>Say hi to {props.activeRoom.name} and start a conversation</span>
                </div>
              }
            </div>
          }

          {roomIsDeleted && generateBubbleThisGroupUnavailable()}
          
          {/* <Scrollbars autoHide autoHideTimeout={500}
                  renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}> */}
              <div className="chat-room-main-content" 
                    onScroll={() => onScrollListener()} ref={chatRoomMesageListRef}
                    style={{paddingTop: chatDataRoomMain && (chatRoomMainContentHeight() < 400) ? '240px' : '0'}}
                    // style={{maxHeight: `calc(100vh - ${isNoLongerParticipantOrUserDelete ? '190' : '120'}px)`}}
              >
                {/*<Scrollbars autoHide autoHideTimeout={500}
                  renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}> */}
                {isLoadingChat &&
                  <div className="loading-message-wrapper">
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                }

                {/* <div id="chat-room-data" data={JSON.stringify(chatDataRoomMain)} /> */}

                {chatDataRoomMain !== null &&
                  !roomIsDeleted &&
                    Object.keys(chatDataRoomMain).map((value, index) => {
                      if(!chatDataRoomMain[value].isHidden) {
                        return (
                          generateMessageBubble(chatDataRoomMain[value], index)
                        )
                      }

                      return null;
                    })
                }
                {/* </Scrollbars> */}
              </div>
          {/* </Scrollbars> */}
      </div>
      
      {(!roomIsDeleted && !isNoLongerParticipantOrUserDelete && !isBlockedContact) &&
        // <ChatRoomInputMessage 
        //     onInputNewMessageProps={onInputNewMessage}
        //     runningFileMessageProps={runningFileMessage}
        //     runningImageMessageProps={runningImageMessage}
        //     runningVideoMessageProps={runningVideoMessage}
        // />
        (!props.editMessage.message ?
          <ChatRoomInputMessage 
            onForwardOnly={onForwardOnly}
            onInputNewMessageProps={onInputNewMessage}
            runningVoiceMessageProps={runningVoiceMessage}
            runningFileMessageProps={runningFileMessage}
            runningImageMessageProps={runningImageMessage}
            runningVideoMessageProps={runningVideoMessage}
            parentProps={props.parentProps}
            lastDragAndDropFilesProps={lastDragAndDropFiles}
            setLastDragAndDropFilesProps={runSetLastDragAndDropFiles}
            hidedropFileHereProps={hidedropFileHere}
            toggleScheduledMessageDateTimePicker={toggleScheduledMessageDateTimePicker}
            scheduledMessageTime={scheduledMessageTime}
            showScheduledMessagesChatRoom={false}
            setShowScheduledMessagesChatRoom={props.setShowScheduledMessagesChatRoom}
          />
          :
          <ChatRoomInputEditMessage
            onInputNewMessageProps={onInputNewMessage}
            parentProps={props.parentProps}
            onSendEditMessage={onSendEditMessage}
          />
        )
      }

      {(isNoLongerParticipantOrUserDelete && props.activeRoom.deleted === 0) &&
        <div className="no-longer-participant">
          <p>
            <img src={iconClock} alt="" />
            <b>This is a chat History</b>
          </p>

          <p>
            You are no longer a participant in this group
          </p>

          <button className="red-button" onClick={() => actionDeleteRoom()}>
            <b>{props.pinnedRoomID[props.activeRoom.roomID] ? "Unpin and Delete Chat" : "Delete Chat"}</b>
          </button>
        </div>
      }

      {(isNoLongerParticipantOrUserDelete && props.activeRoom.deleted !== 0) &&
        <div className="no-longer-participant">
          <p>
            <img src={iconClock} alt="" />
            <b>This is a chat History</b>
          </p>

          <p>
            This user is no longer available
          </p>

          <button className="red-button" onClick={() => actionDeleteRoom()}>
            <b>{props.pinnedRoomID[props.activeRoom.roomID] ? "Unpin and Delete Chat" : "Delete Chat"}</b>
          </button>
        </div>
      }

      {
        isBlockedContact &&
        <div className="unblock-contact-button" onClick={() => toggleUnblockUserConfirmation()}>
          <b>Unblock</b>
        </div>
      }

      {
        isShowScheduledMessageDateTimePicker &&
        <ScheduledMessageTimePicker
          isOpen={isShowScheduledMessageDateTimePicker}
          toggle={toggleScheduledMessageDateTimePicker}
          date={scheduledMessageTime}
          onCalendarChanged={onScheduledTimePickerCalendarChanged}
          onHourChanged={onScheduledTimePickerHourChanged}
          onMinuteChanged={onScheduledTimePickerMinuteChanged}
          submitScheduledMessage={submitScheduledMessage}
          isLoading={isScheduledMessageLoading}
          disabledDays={{
            before: new Date(),
            // after: new Date(new Date().setMonth(new Date().getMonth()+6))
          }}
          {...props}
        />
      }
            
      <PopupConfirmation
          isOpen={showDeleteRoomConfirmation}
          toggle={toggleDeleteRoomConfirmation}
          title={(props.pinnedRoomID[props.activeRoom.roomID] ? "Unpin and " : "") + "Delete Chat"}
          description={"Are you sure you want to " + (props.pinnedRoomID[props.activeRoom.roomID] ? "unpin and " : "") + "delete this conversation?"}
      >
      {!isLoadingDeleteRoom ?
          <ButtonWithLoadingOrIcon
              text="Delete for me"
              className="main-button-48 red-button confirmation-button"
              onClickAction={() => deleteChatRoomMessages()}
          />
          :
          <ButtonWithLoadingOrIcon
              text="Delete for me"
              className="main-button-48 red-button confirmation-button"
              isLoading
              loadingWhite
              position="left"
          />
      }
      </PopupConfirmation>
      
      <PopupConfirmation
          isOpen={showFailedDeleteRoom}
          toggle={toggleFailedDeleteRoomModal}
          title="Oops! Failed to delete chat room"
          cancelButtonText="OK"
          cancelButtonStyle="orange-button"
          singleButton={true}
      />

      <PopupConfirmation
          isOpen={showUnblockUserConfirmation}
          toggle={toggleUnblockUserConfirmation}
          title={"Unblock " + (props.activeRoom ? props.activeRoom.name : "User") + "?"}
          description="Unblocking lets you receive messages and calls from this contact. Are you sure you want to continue?"
      >
          {/* {
              isLoadingUnblockUser ?
              <ButtonWithLoadingOrIcon
                  text="Yes"
                  className="main-button-48 orange-button confirmation-button"
                  isLoading
                  loadingWhite
                  position="left"
              />
              :
              <ButtonWithLoadingOrIcon
                  text="Yes"
                  className="main-button-48 orange-button confirmation-button"
                  onClickAction={() => unblockUser()}
              />
          } */}
          <ButtonWithLoadingOrIcon
              text="Yes"
              className="main-button-48 orange-button confirmation-button"
              onClickAction={() => unblockUser()}
          />
      </PopupConfirmation>

      <BlockingScreenLoading isOpen={isLoadingUnblockUser} />
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  replyMessage: state.replyMessage,
  forwardMessage: state.forwardMessage,
  linkPreview: state.linkPreview,
  editMessage: state.editMessage,
  goToChatBubble: state.goToChatBubble,
  activeMessage: state.activeMessage,
  draftMessage: state.draftMessage,
  allStarredMessages: state.allStarredMessages,
  lastClickStarMessage: state.lastClickStarMessage,
  multiForward: state.multiForward,
  userClick: state.userClick,
  allPinnedMessages: state.allPinnedMessages,
  pinnedRoomID: state.pinnedRoomID,
  blockedUserIDs: state.blockedUserIDs,
  messageInfo: state.messageInfo
});

const mapDispatchToProps = {
  setActiveRoom,
  setReplyMessage,
  setForwardMessage,
  setLinkPreview,
  clearReplyMessage,
  clearForwardMessage,
  clearLinkPreview,
  clearGoToChatBubble,
  clearActiveMessage,
  setDraftMessage,
  setAllStarredMessages,
  setMultiForward,
  setEditMessage,
  clearEditMessage,
  setAllPinnedMessages,
  clearParticipantList,
  setBlockedUserIDs,
  setMessageInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMain);
