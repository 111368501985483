const INITIAL_STATE = {
  blockedUserIDs: []
};

let reducerBlockedUserIDs = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_BLOCKED_USER_IDS':
        return action.blockedUserIDs;
      case 'CLEAR_BLOCKED_USER_IDS':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerBlockedUserIDs;