import React from 'react';
import './RoomListChatModalMaxPin.scss';
import { Modal, ModalBody } from 'reactstrap';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { connect } from 'react-redux';

var RoomListChatModalMaxPin = (props) => {
    return (
        <Modal className="modal-max-pin" toggle={props.toggle} isOpen={props.isOpen}>
            <ModalBody>
                <p className='modal-max-pin-desc'>
                    <b>You have reached the maximum of pinned chat rooms.</b>
                </p>

                <ButtonWithLoadingOrIcon 
                    className="main-button-40 orange-button"
                    text="OK"
                    onClickAction={props.toggle}
                />
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = state => ({

});
  
const mapDispatchToProps = {
    
};
  
export default connect(mapStateToProps, mapDispatchToProps)(RoomListChatModalMaxPin);
  