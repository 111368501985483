import { combineReducers } from "redux";
import appData from "./reduxReducerAppData";
import activeRoom from "./reduxReducerActiveRoom";
import previewImageOrVideo from "./reduxReducerPreviewImageOrVideo";
import replyMessage from "./reduxReducerReplyMessage";
import forwardMessage from "./reduxReducerForwardMessage";
import userContacts from "./reduxReducerUserContacts";
import userContactsNoGroup from "./reduxReducerUserContactsNoGroup";
import goToChatBubble from "./reduxReducerGoToChatBubble";
import participantList from "./reduxReducerParticipantList";
import mentionUsername from "./reduxReducerMentionUsername";
import activeMessage from "./reduxReducerActiveMessage";
import userClick from "./reduxReducerUserClick";
import blockingAddBlockContact from "./reduxReducerBlockingAddBlockContact";
import draftMessage from "./reduxReducerDraftMessage";
import allStarredMessages from "./reduxReducerAllStarredMessages";
import lastClickStarMessage from "./reduxReducerLastClickStarMessage";
import voiceNote from "./reduxReducerVoice";
import playVoice from "./reduxReducerPlayVoice";
import multiForward from "./reduxReducerMultiForward";
import editMessage from "./reduxReducerEditMessage";
import allPinnedMessages from "./reduxReducerAllPinnedMessages";
import mutedRooms from "./reduxReducerMutedRooms";
import pinnedRoomID from "./reduxReducerPinnedRoomID";
import linkPreview from "./reduxReducerLinkPreview";
import blockedUserIDs from "./reduxReducerBlockedUserIDs";
import messageInfo from "./reduxReducerMessageInfo";

const appReducer = combineReducers({
  appData,
  activeRoom,
  previewImageOrVideo,
  replyMessage,
  forwardMessage,
  userContacts,
  userContactsNoGroup,
  goToChatBubble,
  participantList,
  mentionUsername,
  activeMessage,
  userClick,
  blockingAddBlockContact,
  draftMessage,
  allStarredMessages,
  lastClickStarMessage,
  voiceNote,
  playVoice,
  multiForward,
  editMessage,
  allPinnedMessages,
  mutedRooms,
  pinnedRoomID,
  linkPreview,
  blockedUserIDs,
  messageInfo
})
  
const rootReducer = (state, action) => {  
    return appReducer(state, action)
}

export default rootReducer;