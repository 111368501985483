import React, { useState, useEffect } from 'react';
import './RoomListChat.scss';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FiChevronDown, FiBookmark } from "react-icons/fi";
import RoomListNoChat from '../roomListNoChat/RoomListNoChat';
import groupBadge from "../../../assets/img/group-badge.svg";
import deleteAccount from "../../../assets/img/delete-account-icon.svg";
import muteWhite from "../../../assets/img/icon-mute-white.svg";
import muteGrey from "../../../assets/img/icon-mute-grey.svg";
import pinWhite from "../../../assets/img/icon-white-pin.svg";
import pinGrey from "../../../assets/img/icon-grey-pin.svg";
import muteOrange from "../../../assets/img/icon-mute-orange.svg";
import unmuteOrange from "../../../assets/img/icon-unmute-orange.svg";
import pinOrange from "../../../assets/img/icon-pin-orange.svg";
import unpinOrange from "../../../assets/img/icon-unpin-orange.svg";
import trashIcon from "../../../assets/img/icon-trash-cross.svg";
import markAsUnreadOrange from "../../../assets/img/icon-mark-as-unread-orange.svg";
import soundNewMessage from "../../../assets/sound/new-message.mp3";
import Helper from '../../../helper/Helper';
import HelperChat from '../../../helper/HelperChat';
import WebWorker from "../../../helper/HelperWebWorker";
import { CHAT_TYPE, MAX_PINNED } from '../../../helper/HelperConst';
import { setActiveRoom } from '../../../redux/actions/reduxActionActiveRoom';
import { setMutedRooms } from '../../../redux/actions/reduxActionMutedRooms';
import { setPinnedRoomID } from '../../../redux/actions/reduxActionPinnedRoomID';
import { setBlockedUserIDs } from '../../../redux/actions/reduxActionBlockedUserIDs';
import { setUserContacts } from '../../../redux/actions/reduxActionUserContacts';
import { setUserContactsNoGroup } from '../../../redux/actions/reduxActionUserContactsNoGroup';
import { taptalk, tapCoreRoomListManager, tapCoreMessageManager, tapCoreChatRoomManager, tapCoreContactManager } from '@taptalk.io/web-sdk';
import { connect } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import RoomListChatModalMuteOption from "./roomListChatModalMuteOption/RoomListChatModalMuteOption";
import RoomListModalMaxPin from "./roomListChatModalMaxPin/RoomListChatModalMaxPin";
import PopupConfirmation from '../../reuseableComponent/popupConfirmation/PopupConfirmation';
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

const CONNECTING_STATUS = {
    disconnected: 1,
    loading: 2,
    connected: 3
};

//print room list
let isToday = (date) => {
    let today = new Date();
    let _date = new Date(date);
    return _date.getDate() === today.getDate() &&
        _date.getMonth() === today.getMonth() &&
        _date.getFullYear() === today.getFullYear()
}

let isYerterday = (date) => {
    let _date = new Date(date);
    let yesterday = new Date(new Date().setDate(new Date().getDate()-1));
    return _date.getDate() === yesterday.getDate() &&
        _date.getMonth() === yesterday.getMonth() &&
        _date.getFullYear() === yesterday.getFullYear() 
}

let ListRoomComponent = (props) => {
    let {
         dataLength, containerHeight, row, 
         mainProps, chatData, showRoomListAction, 
         mentionList, toggleShowRoomListAction, actionMarkAsRead,
         actionMarkAsUnread, removeMarkAsUnread, toggleModalMuteOption,
         actionUnmute, actionPinRoom, actionUnpinRoom,
         actionDeleteRoom
    } = props;
    
    return (
        <List
            className={``}
            height={containerHeight}
            itemCount={dataLength}
            itemSize={68}
            width={"100%"}
            // onScroll={onScrollRoomListListenerProps}
            // ref={refProps}
            itemData={{
                mainProps: mainProps,
                chatData: chatData,
                showRoomListAction: showRoomListAction,
                mentionList: mentionList,
                toggleShowRoomListAction: toggleShowRoomListAction,
                actionMarkAsRead: actionMarkAsRead,
                actionMarkAsUnread: actionMarkAsUnread,
                removeMarkAsUnread: removeMarkAsUnread,
                toggleModalMuteOption: toggleModalMuteOption,
                actionUnmute: actionUnmute,
                actionPinRoom: actionPinRoom,
                actionUnpinRoom: actionUnpinRoom,
                actionDeleteRoom: actionDeleteRoom
            }}
        >
            {row}
        </List>
    )
};

let printRowRoomList = ({ index, style, data }) => {
    // let _props = data.mainProps;
    let chatData = data.chatData[Object.keys(data.chatData)[index]];
    let mentionList = data.mentionList;

    // let onClickOpenCaseRoom = (room, caseData) => {
    //     _props.clearStartConversation();
    //     _props.clearStartConversationBa();
    //     _props.onClickRoomListProps(room, caseData)
    // }
    
    return (
        <>
            {/* {index === 0 &&
                <div style={style}>
                    <div class={`chat-list-wrapper chat-list-wrapper-saved-message ${data.mainProps.activeRoom === null ? "" : (data.mainProps.activeRoom.roomID === chatData.lastMessage.room.roomID ? "active-chat-list" : "")}`}>
                        <span class="dialog-date">Yesterday</span>

                        <div class="room-list-click-area"></div>
                        
                        <div class="chat-avatar-wrapper">
                            <div className="chat-avatar-circle-wrapper">
                                <FiBookmark />
                            </div>
                        </div>

                        <div class="dialog-message-wrapper">
                            <div class="dialog-top">
                                <b>Saved messages</b>
                            </div>
                            
                            <div class="dialog-bottom">
                                <p>Sekar: Comment</p>
                                <div class="message-status">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            } */}

            <div style={style}>{
                chatData &&
                <div 
                    className={`
                        chat-list-wrapper 
                        ${data.mainProps.activeRoom === null ? "" : (data.mainProps.activeRoom.roomID === chatData.lastMessage.room.roomID ? "active-chat-list" : "")}
                        ${chatData.lastMessage.room.roomID === Helper.savedMessageRoomID() ? 'chat-list-wrapper-saved-message' : ''}
                    `} 
                >   
                    <div 
                        onClick={() => {
                            data.removeMarkAsUnread(chatData.lastMessage.room.roomID);
                            data.mainProps.onClickRoomListProps(chatData.lastMessage)
                        }}
                        className="room-list-click-area" 
                    />
                    
                    <div className="chat-avatar-wrapper">
                        {chatData.lastMessage.room.roomID === Helper.savedMessageRoomID() ?
                            <div className="chat-avatar-circle-wrapper">
                                <FiBookmark />
                            </div>
                            :
                            (chatData.lastMessage.room.deleted !== 0 ?
                                <img src={deleteAccount} alt="" />
                                :
                                (chatData.lastMessage.room.imageURL.thumbnail === "" ?
                                    <div className="user-avatar-name" style={{background: taptalk.getRandomColor(chatData.lastMessage.room.name)}}>
                                        <b>{Helper.renderUserAvatarWord(chatData.lastMessage.room.name, chatData.lastMessage.room.type === 2)}</b>
                                    </div>
                                    :
                                    <img src={chatData.lastMessage.room.imageURL.thumbnail} alt="" />
                                )   
                            )
                        }

                        {chatData.lastMessage.room.type === 2 && <img src={groupBadge} alt="" className="group-badge" />}
                    </div>
                    <div className="dialog-message-wrapper">
                        <div className="dialog-top">
                            <p>
                                <b>
                                    {chatData.lastMessage.room.name}
                                </b>
                            </p>
                        
                            {data.mainProps.mutedRooms[chatData.lastMessage.room.roomID] &&
                                <img src={(data.mainProps.activeRoom && (chatData.lastMessage.room.roomID === data.mainProps.activeRoom.roomID)) ? muteWhite : muteGrey} alt="" className="room-list-mute-icon" />
                            }
                            
                            <span className="dialog-date">
                                {isToday(chatData.lastMessage.created) ?
                                    new Date(chatData.lastMessage.created).getHours()+":"+(new Date(chatData.lastMessage.created).getMinutes() < 10 ? "0" : "")+new Date(chatData.lastMessage.created).getMinutes()
                                    :
                                    isYerterday(chatData.lastMessage.created) ?
                                    "Yesterday"
                                    :
                                    new Date(chatData.lastMessage.created).getDate()+"/"+(new Date(chatData.lastMessage.created).getMonth() + 1)+"/"+new Date(chatData.lastMessage.created).getFullYear()
                                }
                            </span>
                        </div>
                        <div className="dialog-bottom">
                            {/* {chatData.lastMessage.recipientID === '0' &&
                                <span>
                                    {chatData.lastMessage.user.userID === taptalk.getTaptalkActiveUser().userID ?
                                        "You"
                                        :
                                        chatData.lastMessage.user.fullname
                                    }
                                </span>
                            } */}

                            <p>
                                {data.mainProps.draftMessage[chatData.lastMessage.room.roomID] ? `Draft: ${HelperChat.lineBreakRoomList(data.mainProps.draftMessage[chatData.lastMessage.room.roomID])}` : HelperChat.generateLastMessage(chatData.lastMessage)}
                            </p>

                            <div className="message-status">
                                {mentionList[chatData.lastMessage.room.roomID] &&
                                    (Object.keys(mentionList[chatData.lastMessage.room.roomID]).length > 0 &&
                                        <div className="mention-badge">
                                            @
                                        </div>
                                    )
                                }

                                {(
                                    (
                                        (
                                            (chatData.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID) && 
                                                chatData.unreadCount > 0
                                            ) ||
                                            chatData.isMarkAsUnread
                                    )
                                ) &&
                                    // unread badge
                                    <div className="unread-count-wrapper">
                                        {(chatData.unreadCount > 0 && chatData.lastMessage.room.roomID !== Helper.savedMessageRoomID()) &&
                                            <b>{chatData.unreadCount > 99 ? "99+" : chatData.unreadCount}</b>
                                        }
                                    </div>
                                    // unread badge
                                }

                                {(
                                    chatData.lastMessage.user.userID === taptalk.getTaptalkActiveUser().userID && 
                                    chatData.lastMessage.type !== 9001 && 
                                    !chatData.isMarkAsUnread &&
                                    chatData.lastMessage.room.roomID !== Helper.savedMessageRoomID()
                                ) &&
                                    //message status badge
                                    <img className="icon-chat-status" src={HelperChat.renderChatStatus(chatData.lastMessage, data.mainProps.activeRoom)} alt="" />
                                    //message status badge
                                }
                                
                                {data.mainProps.pinnedRoomID[chatData.lastMessage.room.roomID] &&
                                    <img className="room-list-pin-icon" src={(data.mainProps.activeRoom && (chatData.lastMessage.room.roomID === data.mainProps.activeRoom.roomID) ? pinWhite : pinGrey)} alt="" />
                                }
                                    
                                
                                <Dropdown isOpen={data.showRoomListAction[chatData.lastMessage.room.roomID]} toggle={() => data.toggleShowRoomListAction(chatData.lastMessage.room.roomID)}>
                                    <DropdownToggle className="toggle-dropdown">
                                        <FiChevronDown 
                                            className="show-room-list-action"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {(chatData.unreadCount > 0 || chatData.isMarkAsUnread) ?
                                            <DropdownItem onClick={() => data.actionMarkAsRead(chatData.lastMessage.room.roomID)}>
                                                <img src={markAsUnreadOrange} alt="" />
                                                Mark as Read
                                            </DropdownItem>
                                            :
                                            <DropdownItem onClick={() => data.actionMarkAsUnread(chatData.lastMessage.room.roomID)}>
                                                <img src={markAsUnreadOrange} alt="" />
                                                Mark as Unread
                                            </DropdownItem>
                                        }

                                        {!Helper.isSavedMessageRoom(chatData.lastMessage.room.roomID) &&
                                            <DropdownItem 
                                                onClick={() => 
                                                    data.mainProps.mutedRooms[chatData.lastMessage.room.roomID] ?
                                                        data.actionUnmute(chatData.lastMessage.room.roomID)
                                                        :
                                                        data.toggleModalMuteOption(chatData.lastMessage.room.roomID)
                                                }
                                            >
                                                <img src={data.mainProps.mutedRooms[chatData.lastMessage.room.roomID] ? unmuteOrange : muteOrange} alt="" />
                                                {data.mainProps.mutedRooms[chatData.lastMessage.room.roomID] ? "Unmute" : "Mute"}
                                            </DropdownItem>
                                        }

                                        <DropdownItem 
                                            onClick={() => 
                                                !data.mainProps.pinnedRoomID[chatData.lastMessage.room.roomID] ?
                                                    data.actionPinRoom(chatData.lastMessage.room.roomID)
                                                    :
                                                    data.actionUnpinRoom(chatData.lastMessage.room.roomID)
                                            }
                                        >
                                            <img src={!data.mainProps.pinnedRoomID[chatData.lastMessage.room.roomID] ? pinOrange : unpinOrange} alt="" />
                                            {!data.mainProps.pinnedRoomID[chatData.lastMessage.room.roomID] ? "Pin" : "Unpin"} 
                                        </DropdownItem>

                                        <DropdownItem 
                                            onClick={() => data.actionDeleteRoom(chatData.lastMessage.room.roomID)}
                                        >
                                            <img src={trashIcon} alt="" />
                                            Delete
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            }</div>
            
        </>
    )
};
//print room list

var RoomListChat = (props) => {
    let [showRoomListAction, setShowRoomListAction] = useState({});
    let [showRoomListActionOrigin, setShowRoomListActionOrigin] = useState({});
    let [chatData, setChatData] = useState(null);
    let [isRoomListLoadingFinish, setIsRoomListLoadingFinish] = useState(false);
    let [roomListHeight, setRoomListHeight] = useState(window.innerHeight - 53);
    let [lastWindowResize, setLastWindowResize] = useState(false);
    let [webWorkerMentionList, setWebWorkerMentionList] = useState(null);
    let [mentionList, setMentionList] = useState({});
    let [chatRoom, setChatRoom] = useState(false);
    let [modalMuteOption, setModalMuteOption] = useState({
        isShow: false,
        roomIDs: false
    });
    let [showModalMaxPin, setShowModalMaxPin] = useState(false);
    let [onDelaySound, setOnDelaySound] = useState(false);
    let [showDeleteRoomConfirmation, setShowDeleteRoomConfirmation] = useState(false);
    let [isLoadingDeleteRoom, setIsLoadingDeleteRoom] = useState(false);
    let [roomIDToDelete, setRoomIDToDelete] = useState("");
    let [showFailedDeleteRoom, setShowFailedDeleteRoom] = useState(false);
    let [isContactListenerSetupFinished, setIsContactListenerSetupFinished] = useState(false);
    
    //last edit message
    useEffect(() => {
        let runLastEdit = async () => {
            if(props.editMessage.lastEdit) {
                getUpdatedRoomList();
            }
        }

        runLastEdit();
    }, [props.editMessage])
    //last edit message
    
    //set webworker
    useEffect(() => {
        setWebWorkerMentionList(
            new WebWorker(() => {
                // eslint-disable-next-line no-restricted-globals
                self.addEventListener('message', function(e) {
                    let { chatRoom, chatType, activeUser } = e.data;
                    let hashmap = {};
                    
                    let isActiveUserMentioned = (message) => {
                        if (message.user.username === activeUser.username ||
                            (message.type !== chatType.TAPChatMessageTypeText && 
                             message.type !== chatType.TAPChatMessageTypeLink && 
                             message.type !== chatType.TAPChatMessageTypeImage && 
                             message.type !== chatType.TAPChatMessageTypeVideo) ||
                            activeUser === null ||
                            message.isRead
                        ) {
                            return false;
                        }
                        
                        let text = message.body;
                        if (null === text || text === "") {
                            return false;
                        }
            
                        return (
                          text.includes(" @" + activeUser.username + " ") ||
                          text.includes(" @" + activeUser.username + "\n") ||
                          (text.includes(" @" + activeUser.username) && text.endsWith(activeUser.username)) ||
                          text.includes("\n@" + activeUser.username + " ") ||
                          text.includes("\n@" + activeUser.username + "\n") ||
                          (text.includes("\n@" + activeUser.username) && text.endsWith(activeUser.username)) ||
                          text.startsWith("@" + activeUser.username) && text.includes("@" + activeUser.username + " ") ||
                          text.startsWith("@" + activeUser.username) && text.includes("@" + activeUser.username + "\n") ||
                          text === "@" + activeUser.username
                        );
                    }
                    
                    Object.keys(chatRoom).map((val) => {
                        hashmap[val] = {};
                        Object.keys(chatRoom[val].messages).map((_val) => {
                            let localID = chatRoom[val].messages[_val].localID;
                            let result = isActiveUserMentioned(chatRoom[val].messages[_val]);
                            
                            if(result) {
                                hashmap[val][localID] = chatRoom[val].messages[_val];
                            }

                            return null;
                        })

                        return null;
                    })
                    
                    // eslint-disable-next-line no-restricted-globals
                    self.postMessage({
                        data: {
                            mentionList: hashmap
                        }
                    })
                })
            })
        )
        //mentionList
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(webWorkerMentionList !== null) {
            webWorkerMentionList.addEventListener('message', (e) => {
                let { data } = e.data;
                setMentionList(data.mentionList);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webWorkerMentionList])
    //set webworker

    useEffect(() => {
        if(chatRoom && webWorkerMentionList !== null) {
            webWorkerMentionList.postMessage({
                chatRoom: chatRoom.list,
                chatType: CHAT_TYPE,
                activeUser: taptalk.getTaptalkActiveUser()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatRoom])

    useEffect(() => {
        if(lastWindowResize) {
            runSetRoomListHeight();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastWindowResize])

    useEffect(() => {
        getAllMarkAsUnread();

        setIsRoomListLoadingFinish(false);
        window.addEventListener("resize", () => {
            setLastWindowResize(new Date())
        })

        let onlineCallbackOpenCase = () => {
            getUpdatedRoomList();
        }

        window.addEventListener('online', onlineCallbackOpenCase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let _connectStatus = props.connectingStatusProps;
        let elRoomList = document.querySelectorAll(".room-list-chat-container")[0];

        if (elRoomList) {
            if (_connectStatus === CONNECTING_STATUS.disconnected || _connectStatus === CONNECTING_STATUS.loading) {
                // elRoomList.style.height = "calc(100vh - 135px)";
                elRoomList.style.height = "calc(100% - 79px)";
            } else {
                // elRoomList.style.height = "calc(100vh - 109px)";
                elRoomList.style.height = "calc(100% - 53px)";
            }
            
            setLastWindowResize(new Date())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.connectingStatusProps])

    useEffect(() => {
        if (props.newEmitMessageProps !== null) {
            if (isRoomListLoadingFinish) {
                // actionUpdateRoomList(props.newEmitMessageProps);
                getUpdatedRoomList();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.newEmitMessageProps])

    //notif
    useEffect(() => {
        if (onDelaySound) {
            setTimeout(() => {
                setOnDelaySound(false);
            }, 3000)
        }
    }, [onDelaySound])

    useEffect(() => {
        if(props.messageListenerNewMessageProps !== null && isRoomListLoadingFinish) {
            let myAccount = taptalk.getTaptalkActiveUser().userID;
            getUpdatedRoomList();
            if ('Notification' in window) {
                if (
                    (!props.messageListenerNewMessageProps.isHidden && props.messageListenerNewMessageProps.user.userID !== myAccount) &&
                    (!props.mutedRooms[props.messageListenerNewMessageProps.room.roomID])
                ) {
                    HelperChat.showNotificationMessage(props.messageListenerNewMessageProps);
                    playSoundNotification();
                }
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.messageListenerNewMessageProps]);

    useEffect(() => {
        if (props.messageListenerScheduledMessageSentProps !== null && isRoomListLoadingFinish) {
            let myAccount = taptalk.getTaptalkActiveUser().userID;
            getUpdatedRoomList();
            if ('Notification' in window) {
                if (
                    (!props.messageListenerNewMessageProps.isHidden && props.messageListenerNewMessageProps.user.userID === myAccount) &&
                    (!props.mutedRooms[props.messageListenerNewMessageProps.room.roomID])
                ) {
                    HelperChat.showNotificationMessage(props.messageListenerNewMessageProps, true);
                    playSoundNotification();
                }
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.messageListenerScheduledMessageSentProps]);
    //notif

    useEffect(() => {
        if(props.messageListenerUpdateMessageProps !== null && isRoomListLoadingFinish) {
            getUpdatedRoomList();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.messageListenerUpdateMessageProps]);

    useEffect(() => {
        if(props.forceRefreshRoomProps) {
            getUpdatedRoomList();
            props.undoForceRefreshRoomProps();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.forceRefreshRoomProps]);

    useEffect(() => {
        if (props.blockedUserIDs && 
            !props.blockedUserIDs.blockedUserIDs && // props.blockedUserIDs returns {blockedUserIDs: []} when not set
            !isContactListenerSetupFinished
        ) {
            // Contact blocked/unblocked listener
            tapCoreContactManager.addContactListener({
                onContactBlocked: (user) => {
                    let _blockedUserIDs = props.blockedUserIDs;
                    const index = _blockedUserIDs.indexOf(user.userID);
                    if (index === -1) {
                        _blockedUserIDs.push(user.userID);
                        props.setBlockedUserIDs(_blockedUserIDs);
                    }
                    syncContacts();
                },
                onContactUnblocked: (user) => {
                    let _blockedUserIDs = props.blockedUserIDs;
                    const index = _blockedUserIDs.indexOf(user.userID);
                    if (index !== -1) {
                        _blockedUserIDs.splice(index, 1);
                        props.setBlockedUserIDs(_blockedUserIDs);
                    }
                    syncContacts();
                }
            });
            setIsContactListenerSetupFinished(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.blockedUserIDs]);

    let syncContacts = async () => {
        tapCoreContactManager.getAllUserContacts({
            onSuccess: (response) => {
                props.setUserContactsNoGroup(response);
                let newContactGroup = {};
                
                response.map((value) => {
                    let alphabetGroupName = value.user.fullname[0].toUpperCase();
                    if (newContactGroup[alphabetGroupName] === undefined) {
                        newContactGroup[alphabetGroupName] = [];
                        newContactGroup[alphabetGroupName].push(value);
                    }
                    else {
                        newContactGroup[alphabetGroupName].push(value);
                    }
                    
                    props.setUserContacts(newContactGroup);
                    return null;
                })
            },
  
            onError: (errorCode, errorMessage) => {
                
            }
        });
    }

    let playSoundNotification = async () => {
        let soundNewMessage = document.getElementById("sound-new-message");
    
        let doSound = async () => {
            soundNewMessage.play();
        }
        
        if (!onDelaySound) {
            setOnDelaySound(true);
            doSound();
        } else {
            return;
        }
    }

    let removeMarkAsUnread = async (roomID) => {
        let _chatData = {...chatData};
        chatData[roomID].isMarkAsUnread = false;
        setChatData(_chatData);
    }

    let actionMarkAsRead = async (roomID) => {
        removeMarkAsUnread(roomID);
        tapCoreMessageManager.markAllMessagesInRoomAsRead(roomID);
    }

    let actionMarkAsUnread = (roomID) => {
        tapCoreChatRoomManager.markChatRoomAsUnread([roomID], {
            onSuccess: (res) => {
                props.setActiveRoom(null);
                setChatData(res);
            },
            onError: (errCode, errMess) => {
                console.log(errCode, errMess);
            }
        });
    }

    let toggleShowRoomListAction = (roomID) => {
        let _a = {...showRoomListAction};
        let _aOri = {...showRoomListActionOrigin};
        let _temp = _a[roomID];
        _a = _aOri;
        _a[roomID] = !_temp;
        setShowRoomListAction(_a);
    } 

    let getPinnedRoomID = () => {
        tapCoreRoomListManager.getPinnedRoomID({
            onSuccess: (res) => {
                props.setPinnedRoomID(res);
                getMutedRooms();
            },
            onError: (errCode, errMes) => {

            }
        })
    }

    let getMutedRooms = () => {
        tapCoreRoomListManager.getMutedRooms({
            onSuccess: (res) => {
                props.setMutedRooms(res);
                getBlockedUserIDs();
            },
            onError: (errCode, errMes) => {

            }
        })
    }

    let getBlockedUserIDs = () => {
        tapCoreContactManager.getBlockedUserIDs({
            onSuccess: (res) => {
                props.setBlockedUserIDs(res);
                getUpdatedRoomList();
            },
            onError: (errCode, errMes) => {

            }
        });
    }

    let getAllMarkAsUnread = () => {
        tapCoreChatRoomManager.getMarkedAsUnreadChatRoomList({
            onSuccess: (res) => {
                getPinnedRoomID();
            },
            onError: (errCode, errMess) => {
                console.log(errCode, errMess);
            }
        })
    } 

    let getUpdatedRoomList = function() {
        tapCoreRoomListManager.getUpdatedRoomList({
            onSuccess: (roomLists, chatRooms) => {
                let actionSetShowRoomList = async () => {
                    let a = {};
                    Object.keys(roomLists).map(v => {
                        a[v] = false;
                        return null;
                    })

                    setShowRoomListAction(a);
                    setShowRoomListActionOrigin(a);
                }
                
                actionSetShowRoomList();

                setChatRoom({
                    list: chatRooms,
                    time: new Date()
                });

                setIsRoomListLoadingFinish(true);
                setChatData(roomLists);
            },
            onError: (errorCode, errorMessage) => {
                setIsRoomListLoadingFinish(true);
                console.log(errorCode, errorMessage);
            }
        });
    };

    let runSetRoomListHeight = () => {
        let elRoomListWrapper = document.querySelectorAll(".room-list-container")[0];
        let min = props.connectingStatusProps === CONNECTING_STATUS.connected ? 53 : 79;
        let defaultHeight = elRoomListWrapper.offsetHeight - min;
        setRoomListHeight(defaultHeight);
    }

    let toggleModalMuteOption = (roomID = false) => {
        let _modalMuteOption = {...modalMuteOption};
        _modalMuteOption.isShow = !_modalMuteOption.isShow;
        _modalMuteOption.roomIDs = !roomID ? false : [roomID];
        setModalMuteOption(_modalMuteOption);
    }

    let toggleModalMaxPin = () => {
        setShowModalMaxPin(!showModalMaxPin);
    }

    let toggleDeleteRoomConfirmation = () => {
        if (isLoadingDeleteRoom) {
            return;
        }
        setShowDeleteRoomConfirmation(!showDeleteRoomConfirmation);
        if (showDeleteRoomConfirmation) {
            setRoomIDToDelete("");
        }
    }

    let toggleFailedDeleteRoomModal = () => {
        setShowFailedDeleteRoom(!showFailedDeleteRoom);
    }

    let actionUnmute = (roomID) => {
        let _mutedRooms = {...props.mutedRooms};

        delete _mutedRooms[roomID];

        props.setMutedRooms(_mutedRooms);

        HelperChat.unmuteAction([roomID], {
            onSuccess: (res) => {
            },
            onError: (errCode, errMes) => {
                console.log(errCode, errMes)
            }
        })
    }
    
    let actionPinRoom = (roomID) => {
        let _pinnedRoomID = {...props.pinnedRoomID};

        if(Object.keys(_pinnedRoomID).length >= MAX_PINNED) {
            setShowModalMaxPin(true);
        }else {
            let newPinned = {
                [roomID]: roomID
            };
            
            props.setPinnedRoomID(Object.assign({...newPinned}, {..._pinnedRoomID}));
    
            HelperChat.pinRoomAction([roomID], {
                onSuccess: (res, res2) => {
                    setChatData(res2);
                },
                onError: (errCode, errMes) => {
                    console.log(errCode, errMes);
    
                    if(errCode === "MAX_PINNED") {
                        setShowModalMaxPin(true);
                    }
                }
            })
        }

    }

    let actionUnpinRoom = (roomID, callback = false) => {
        let _pinnedRoomID = {...props.pinnedRoomID};

        delete _pinnedRoomID[roomID];

        props.setPinnedRoomID(_pinnedRoomID);

        HelperChat.unpinRoomAction([roomID], {
            onSuccess: (res, res2) => {
                setChatData(res2);
                if (callback) {
                    callback(true)
                }
            },
            onError: (errCode, errMes) => {
                console.log(errCode, errMes)
                if (callback) {
                    callback(false)
                }
            }
        })
    }

    let actionDeleteRoom = (roomID) => {
        setRoomIDToDelete(roomID);
        toggleDeleteRoomConfirmation();
    }

    let deleteChatRoomMessages = () => {

        let callDeleteRoomAPI = () => {
            tapCoreChatRoomManager.deleteAllChatRoomMessages([roomIDToDelete], {
                onSuccess: (res) => {
                    props.deleteLocalChatRoomProps(roomIDToDelete);
                    setIsLoadingDeleteRoom(false);
                    toggleDeleteRoomConfirmation();
                },
                onError: (err) => {
                    setIsLoadingDeleteRoom(false);
                    toggleDeleteRoomConfirmation();
                    toggleFailedDeleteRoomModal();
                }
            })
        }

        setIsLoadingDeleteRoom(true);
        if (props.pinnedRoomID[roomIDToDelete]) {
            actionUnpinRoom(roomIDToDelete, (isSuccess) => {
                if (isSuccess) {
                    callDeleteRoomAPI();
                }
                else {
                    setIsLoadingDeleteRoom(false);
                    toggleDeleteRoomConfirmation();
                    toggleFailedDeleteRoomModal();
                }
            });
        }
        else {
            callDeleteRoomAPI();
        }
    }

    return (
        <div className="room-list-chat-container" style={props.style}>
            <audio id="sound-new-message">
                <source src={soundNewMessage} type="audio/mpeg" />
            </audio>

            <RoomListChatModalMuteOption 
                toggle={toggleModalMuteOption}
                modalMuteOption={modalMuteOption}
            />

            <RoomListModalMaxPin 
                toggle={toggleModalMaxPin}
                isOpen={showModalMaxPin}
            />
            
            <PopupConfirmation
                isOpen={showDeleteRoomConfirmation}
                toggle={toggleDeleteRoomConfirmation}
                title={(props.pinnedRoomID[roomIDToDelete] ? "Unpin and " : "") + "Delete Chat"}
                description={"Are you sure you want to " + (props.pinnedRoomID[roomIDToDelete] ? "unpin and " : "") + "delete this conversation?"}
            >
            {!isLoadingDeleteRoom ?
                <ButtonWithLoadingOrIcon
                    text="Delete for me"
                    className="main-button-48 red-button confirmation-button"
                    onClickAction={() => deleteChatRoomMessages()}
                />
                :
                <ButtonWithLoadingOrIcon
                    text="Delete for me"
                    className="main-button-48 red-button confirmation-button"
                    isLoading
                    loadingWhite
                    position="left"
                />
            }
            </PopupConfirmation>
            
            <PopupConfirmation
                isOpen={showFailedDeleteRoom}
                toggle={toggleFailedDeleteRoomModal}
                title="Oops! Failed to delete chat room"
                cancelButtonText="OK"
                cancelButtonStyle="orange-button"
                singleButton={true}
            />

            {!isRoomListLoadingFinish ?
                <div className="room-list-loading-wrapper"> 
                    <div className="lds-ring">
                        <div /><div /><div /><div />
                    </div>
                    <br />
                    <b>Loading Room List</b>
                </div>

                :

                chatData !== null &&
                    Object.keys(chatData).length > 0 ?
                        <ListRoomComponent
                            removeMarkAsUnread={removeMarkAsUnread}
                            actionMarkAsRead={actionMarkAsRead}
                            actionMarkAsUnread={actionMarkAsUnread}
                            toggleShowRoomListAction={toggleShowRoomListAction}
                            showRoomListAction={showRoomListAction}
                            chatData={chatData}
                            row={printRowRoomList}
                            dataLength={Object.keys(chatData).length}
                            containerHeight={roomListHeight}
                            mainProps={props}
                            listNameProps={"list-room"}
                            mentionList={mentionList}
                            toggleModalMuteOption={toggleModalMuteOption}
                            actionUnmute={actionUnmute}
                            actionPinRoom={actionPinRoom}
                            actionUnpinRoom={actionUnpinRoom}
                            actionDeleteRoom={actionDeleteRoom}
                        />
                        :
                        <RoomListNoChat />
                }
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    draftMessage: state.draftMessage,
    editMessage: state.editMessage,
    mutedRooms: state.mutedRooms,
    pinnedRoomID: state.pinnedRoomID,
    blockedUserIDs: state.blockedUserIDs,  
    userContacts: state.userContacts,
});
  
const mapDispatchToProps = {
    setActiveRoom,
    setMutedRooms,
    setPinnedRoomID,
    setBlockedUserIDs,
    setUserContacts,
    setUserContactsNoGroup,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(RoomListChat);
  