import React, { useState, useEffect } from 'react';
import './ChatRoomMessageInVoice.scss';
import { FaPause, FaPlay } from 'react-icons/fa';
import iconDelete from "../../../../assets/img/delete-account-icon.svg";
import HelperChat from '../../../../helper/HelperChat';
import Helper from '../../../../helper/Helper';
import { taptalk, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import 'react-circular-progressbar/dist/styles.css';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setPlayVoice } from '../../../../redux/actions/reduxActionPlayVoice';
import { setUserClick } from '../../../../redux/actions/reduxActionUserClick';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';
import { connect } from 'react-redux';

const LONG_PRESS = 700;

var ChatRoomMessageInVoice = (props) => {
    let [lastClickBubble, setLastClickBubble] = useState(false);
    let [voiceInWaveSurfer, setVoiceInWaveSurfer] = useState(false);
    let [isPlayVoice, setIsPlayVoice] = useState(false);
    let [printDuration, setPrintDuration] = useState(0);
    let {
        activeRoom,
        singleChatDataProps,
        activeUserID,
        onForwardMessage,
        onReplyMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        onReportMessage,
        // isBubbleOnViewPort,
        onClickToStarMessage,
        setPlayVoice,
        playVoice,
        forStarredAndForPinned,
        multiForward,
        noForward
    } = props;

    let delay;

    useEffect(() => {
        if(playVoice.last_play && playVoice.message && (playVoice.message.messageID !== singleChatDataProps.messageID)) {
            onClickPause();
        }
    }, [playVoice])

    useEffect(() => {
        if(!voiceInWaveSurfer) {
            var wavesurfer = window.WaveSurfer.create({
                container: `#waveform-${forStarredAndForPinned ? "starred-" : ""}${singleChatDataProps.messageID}`,
                waveColor: '#FFCB99',
                progressColor: '#ff7d00',
                barWidth: 3,
                barHeight: 5, // the height of the wave
                barGap: 2 ,
                // backend: 'MediaElement',
                hideScrollbar: true,
            });

            setVoiceInWaveSurfer(wavesurfer);
            setPrintDuration(singleChatDataProps.data.duration);
        }
    }, [singleChatDataProps])

    useEffect(() => {
        if(voiceInWaveSurfer) {
            voiceInWaveSurfer.load(!singleChatDataProps.data.url ? URL.createObjectURL(singleChatDataProps.data.fileObject) : singleChatDataProps.data.url);
    
            voiceInWaveSurfer.on("finish", () => {
                setIsPlayVoice(false);
                voiceInWaveSurfer.seekTo(0);
            })

            voiceInWaveSurfer.on("seek", () => {
                var currentTime = voiceInWaveSurfer.getCurrentTime();
                setPrintDuration(currentTime.toFixed(1)*1000);
                onClickPause();
            })
        
            voiceInWaveSurfer.on('audioprocess', function() {
                if(voiceInWaveSurfer.isPlaying()) {
                    var currentTime = voiceInWaveSurfer.getCurrentTime();
                    setPrintDuration(Math.ceil(currentTime.toFixed(1))*1000);
                }
            });
        }
    }, [voiceInWaveSurfer])

    let convertTimer = (time) => {
        return new Date(time).toISOString().substr(14, 5);
    }

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }

    let onClickPlay = async () => {
        if(!forStarredAndForPinned) {
            setPlayVoice({
                last_play: new Date().valueOf(),
                message: singleChatDataProps
            })
            
            setIsPlayVoice(true);
            voiceInWaveSurfer.play();
        }

        // [...document.querySelectorAll(".general-pause-button")].map(v => {
        //     v.click();
        //     return null;
        // })
    }

    let onClickPause = async () => {
        setIsPlayVoice(false);

        if(voiceInWaveSurfer) {
            voiceInWaveSurfer.pause();
        }
    }

    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }
      

    return (
        <div 
            className="chat-room-message-voice-in-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
            onClick={() => { 
                if(onClickToStarMessage) {
                    onClickToStarMessage(singleChatDataProps.localID);
                }

                if(activeRoom.type === 2) {
                    setLastClickBubble(new Date().valueOf());
                }
            }}    
        >
            {
                (
                    activeRoom &&
                    !singleChatDataProps.isDeleted &&
                    multiForward[activeRoom.roomID] &&
                    Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
                ) &&
                <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
            }

            {(
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
                <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-in">
                    <input 
                        type="checkbox" 
                        id={`message-${singleChatDataProps.messageID}`} 
                        checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                    />
                    <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
                </div>
            }

            {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                <div 
                    className="group-sender-avatar-wrapper" 
                    style={{background: taptalk.getRandomColor(Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname)}} 
                    onClick={() => props.setUserClick({
                        userID: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.userID, 
                        username: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.username
                    })}
                >
                    {(singleChatDataProps.user.deleted && singleChatDataProps.user.deleted !== 0) ?
                        <img src={iconDelete} alt="" />
                        :
                        (Helper.isSavedMessageRoom(activeRoom.roomID) ?
                            <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.forwardFrom.fullname)}</b>
                            :
                            (singleChatDataProps.user.imageURL.thumbnail !== "" ? 
                                <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" />
                                :
                                <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                            )   
                        )
                    }
                </div>
            }

            <div className="message-in-bubble">
                <div className="click-area-voice">
                    {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                <b>{Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname}</b>
                            </b>
                        </p>
                    }
                    
                    {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            
                            <p>
                                From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                            </p>
                        </div>
                    }
                    

                    {Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyInMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    <div className="file-inner-wrapper">
                        <div className="file-icon-wrapper">
                            <div className="icon-status-wrapper">
                                <FaPlay className={`general-play-button voice-play-pause-button ${!isPlayVoice ? 'active-voice-play-pause-button' : ''}`} onClick={() => onClickPlay()} />
                                
                                <FaPause className={`general-pause-button voice-play-pause-button ${isPlayVoice ? 'active-voice-play-pause-button' : ''}`} onClick={() => onClickPause()} />
                            </div>
                        </div>

                        <div className="message-bubble-voice-wrapper">
                           <div className="voice-bar-content-wrapper">
                                <div id={`waveform-${forStarredAndForPinned ? "starred-" : ""}${singleChatDataProps.messageID}`} className="waveform-in-voice">
                                    
                                </div>
                           </div>

                           <p className="sound-bar-timer">
                            {convertTimer(printDuration)}
                           </p>
                        </div>
                    </div>

                    <ChatRoomMessageInfoComp 
                        message={singleChatDataProps}
                        lastClickBubble={lastClickBubble}
                    />

                </div>
                
                <ChatRoomMessageActionComp
                    isIn
                    buttonList={Helper.isSavedMessageRoom(activeRoom.roomID) ?
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "copy",
                                text: "Copy",
                                action: () => HelperChat.copyToClipBoard(singleChatDataProps.body),
                            },
                            {
                                val: "delete",
                                text: "Delete Message",
                                action: () => deleteMessageAction(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                        ]
                        :
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "copy",
                                text: "Copy",
                                action: () => HelperChat.copyToClipBoard(singleChatDataProps.body),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                            {
                                val: "report",
                                text: "Report",
                                action: () => onReportMessage(singleChatDataProps), 
                            },
                        ]
                    }
                    singleChatDataProps={singleChatDataProps}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    playVoice: state.playVoice,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setUserClick,
  setPlayVoice,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInVoice);
