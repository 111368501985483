import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import './ChatRoomMessageOutLocation.scss';
import MarkerMap from '../../../../assets/img/marker-map.png';
import MessageDeleteWhite from "../../../../assets/img/icon-notallowed-white.svg";
import HelperChat from '../../../../helper/HelperChat';
import Helper from '../../../../helper/Helper';
import { tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import { connect } from 'react-redux';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';

const LONG_PRESS = 700;

var ChatRoomMessageOutLocation = (props) => {
    let [lastClickBubble, setLastClickBubble] = useState(false);

    let { 
        activeRoom,
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        onForwardMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        onMessageInfo,
        scrollToReply,
        mentionList,
        onClickToStarMessage,
        multiForward,
        noForward,
        isScheduledMessage,
        sendScheduledMessageNow,
        rescheduleMessage,
        // editScheduledMessage,
        deleteScheduledMessage
    } = props;

    let delay;

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }
    
    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }
    
    let MarkerMapComponent = () => <div className="marker-map"><img src={MarkerMap} alt="" /></div>;
    
    return (
        <div 
            className="chat-room-message-out-location-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
            onClick={() => { 
                if(onClickToStarMessage) {
                    onClickToStarMessage(singleChatDataProps.localID);
                }

                if(activeRoom.type === 2) {
                    setLastClickBubble(new Date().valueOf());
                }
            }}    
        >
            {
                (
                    activeRoom &&
                    !singleChatDataProps.isDeleted &&
                    multiForward[activeRoom.roomID] &&
                    Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
                ) &&
                <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
            }
            
            {singleChatDataProps.isDeleted ?
                <div className="message-out-bubble deleted-bubble">
                    <React.Fragment>
                        <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <div className="message-out-bubble message-out-bubble-location" 
                    // style={
                    //         Helper.isReplyMessage(singleChatDataProps) ? 
                    //             (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
                    //                 {paddingTop: "64px"} 
                    //                 :
                    //                 {paddingTop: "84px"}
                    //             )
                    //             : 
                    //             {}
                    // }
                >
                    {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            
                            <p>
                                From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                            </p>
                        </div>
                    }

                    {Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyOutMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                            scrollToReply={scrollToReply}
                        />
                    }

                    <div className="location-wrapper">
                        <a href={`http://maps.google.com?q=${singleChatDataProps.data.latitude} ${singleChatDataProps.data.longitude}`} target="_blank" rel="noopener noreferrer">
                            <div className="location-content"
                                //  style={{borderRadius: props.Helper.isForwardMessage(singleChatDataProps, activeRoom) ? "0" : "16px 2px 0 0"}}
                            >
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_KEY}}
                                    defaultCenter={{
                                        lat: singleChatDataProps.data.latitude,
                                        lng: singleChatDataProps.data.longitude
                                    }}
                                    defaultZoom={16}
                                    draggable={false}
                                >
                                    <MarkerMapComponent
                                        lat={singleChatDataProps.data.latitude}
                                        lng={singleChatDataProps.data.longitude}
                                    />
                                </GoogleMapReact>
                            </div>
                        </a>
                    </div>

                    <span 
                        className="message-body" 
                        dangerouslySetInnerHTML={{
                            __html: mentionList ?
                                HelperChat.generateMentionSpan(HelperChat.lineBreakChatRoom(singleChatDataProps.data.address), mentionList)
                                :
                                HelperChat.lineBreakChatRoom(singleChatDataProps.data.address)
                        }}
                    />
                    
                    <ChatRoomMessageInfoComp 
                        isOut
                        message={singleChatDataProps}
                        lastClickBubble={lastClickBubble}
                    />

                    <ChatRoomMessageActionComp
                        buttonList={
                            isScheduledMessage && !singleChatDataProps.isSending ?
                            [
                                {
                                    val: "send",
                                    text: "Send Now",
                                    action: () => sendScheduledMessageNow(singleChatDataProps),
                                },
                                {
                                    val: "reschedule",
                                    text: "Reschedule",
                                    action: () => rescheduleMessage(singleChatDataProps),
                                },
                                {
                                    val: "copy",
                                    text: "Copy",
                                    action: () => HelperChat.copyToClipBoard(singleChatDataProps.data.address),
                                },
                                {
                                    val: "delete",
                                    text: "Delete",
                                    action: () => deleteScheduledMessage(singleChatDataProps),
                                },
                            ]
                            :
                            [
                                {
                                    val: "reply",
                                    text: "Reply",
                                    action: () => onReplyMessage(singleChatDataProps),
                                },
                                {
                                    val: "forward",
                                    text: "Forward",
                                    action: () => onForwardMessage(singleChatDataProps),
                                },
                                {
                                    val: "copy",
                                    text: "Copy",
                                    action: () => HelperChat.copyToClipBoard(singleChatDataProps.data.address),
                                },
                                {
                                    val: "delete",
                                    text: "Delete Message",
                                    action: () => deleteMessageAction(singleChatDataProps),
                                },
                                {
                                    val: "star",
                                    text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                    action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                                },
                                {
                                    val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                    text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                    action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                                },
                                {
                                    val: "messageInfo",
                                    text: "Message Info",
                                    action: () => onMessageInfo(singleChatDataProps)
                                }
                            ]
                        }
                    />
                </div>
            }

             
            {(
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
                <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-out">
                    <input 
                        type="checkbox" 
                        id={`message-${singleChatDataProps.messageID}`} 
                        checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                    />
                    <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutLocation);
