const CHAT_TYPE = {
    TAPChatMessageTypeText : 1001,
    TAPChatMessageTypeImage : 1002,
    TAPChatMessageTypeVideo : 1003,
    TAPChatMessageTypeFile : 1004,
    TAPChatMessageTypeLocation : 1005,
    TAPChatMessageTypeContact : 1006,
    TAPChatMessageTypeSticker : 1007,
    TAPChatMessageTypeVoice : 1008,
    TAPChatMessageTypeAudio : 1009,
    TAPChatMessageTypeLink : 1010,
    TAPChatMessageTypeSystemMessage : 9001,
    TAPChatMessageTypeUnreadMessageIdentifier : 9002
}

const MAX_PINNED = 10;

const MUTE_OPTION = [
    {
        label: "1 Hour",
        val: 3600000
    },
    {
        label: "8 Hours",
        val: 28800000
    },
    {
        label: "3 Days",
        val: 259200000
    },
    {
        label: "Always",
        val: undefined
    },
]

export { CHAT_TYPE , MUTE_OPTION, MAX_PINNED };