import React, { useState, useEffect, useRef } from 'react';
import './Register.scss';
import LogoTaptalk from '../../assets/img/logo-taptalk.svg';
import IconEdit from '../../assets/img/iconEdit.svg';
import IconCheckCircle from '../../assets/img/icon-check-circle-green.svg';
import IconCloseRed from '../../assets/img/icon-close-circle-red.svg';
import IconCheckboxInactive from '../../assets/img/icon-checkbox-inactive.svg';
import IconCheckboxActive from '../../assets/img/icon-checkbox-active.svg';
import IconCheckboxError from '../../assets/img/icon-checkbox-error.svg';
import FlagGlobe from '../../assets/img/flag-globe.svg';
import axios from 'axios';
import Helper from "../../helper/Helper";
import { connect } from 'react-redux';
import { taptalk } from '@taptalk.io/web-sdk';
import { FiAlertCircle, FiArrowRight, FiChevronDown, FiInfo, FiUser, FiWifiOff } from 'react-icons/fi';

var Register = (props) => {

  let authenticationHeader = {
    "App-Key": btoa(`${props.appData.data.appID}:${props.appData.data.appSecret}`),
    "Authorization": "",
    "Device-Model": navigator.appName,
    "Device-Platform": "web",
    // "Server-Key": btoa(`${props.appData.data.serverID}:${props.appData.data.serverSecret}`)
  };

  let [isLoadingRegister, setIsLoadingRegister] = useState(false);
  let [country, setCountry] = useState(false);
  let [beautifiedPhone, setBeautifiedPhone] = useState("");
  let [valFullName, setValFullName] = useState("");
  let [valUsername, setValUsername] = useState("");
  let [valPhone, setValPhone] = useState("");
  let [valEmail, setValEmail] = useState("");
  let [fullNameErrorText, setFullNameErrorText] = useState("");
  let [usernameErrorText, setUsernameErrorText] = useState("");
  let [emailErrorText, setEmailErrorText] = useState("");
  let [isUsernameValid, setIsUsernameValid] = useState(false);
  let [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);
  let [isPrivacyPolicyError, setIsPrivacyPolicyError] = useState(false);
  let [searchUsernameTimeout, setSearchUsernameTimeout] = useState(0);
  let [valAvatarImage, setValAvatarImage] = useState("");
  let [successRegister, setSuccessRegister] = useState(false); 
  let [isOnline, setIsOnline] = useState(false);
  let usernameRef = useRef(valUsername);
  let profilePictureInputRef = useRef();

  const FULL_NAME_MAX_LENGTH = 200;
  const USERNAME_MIN_LENGTH = 4;
  const USERNAME_MAX_LENGTH = 32;

  useEffect(() => {
    if (taptalk.isAuthenticated()) {
      window.location.href = "/chat";
    }
    else if (props.history.location.state) {
      setCountry(props.history.location.state.country);
      setValPhone(props.history.location.state.valPhone);

      const isOnline = window.navigator.onLine;
      setIsOnline(isOnline);
      window.addEventListener('offline', function (event) {
        setIsOnline(false);
      });
      window.addEventListener('online', function (event) {
        setIsOnline(true);
      });
    }
    else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (taptalk.isAuthenticated()) {
      window.location.href = "/chat";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taptalk.isAuthenticated()]);

  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successRegister])

  useEffect(() => {
    setBeautifiedPhone(Helper.phoneBeautify(valPhone));
  }, [valPhone]);

  useEffect(() => {
    validateFullName(fullNameErrorText && valFullName);
  }, [valFullName]);

  useEffect(() => {
    setIsUsernameValid(false);
    if (searchUsernameTimeout) {
      clearTimeout(searchUsernameTimeout);
    }
    if (validateUsername(usernameErrorText && valUsername.length >= USERNAME_MIN_LENGTH)) {
      setSearchUsernameTimeout(setTimeout(() => {
        if (valUsername.length >= USERNAME_MIN_LENGTH) {
          checkUsernameExists(`${valUsername}`);
        }
      }, 1000));
    }
  }, [valUsername]);

  useEffect(() => {
    if (emailErrorText) {
      validateEmail();
    }
  }, [valEmail]);

  let onChangeFullName = (e) => {
    setValFullName(e.target.value);
  }

  let onChangeUsername = (e) => {
    setValUsername(e.target.value);
    usernameRef.current = e.target.value;
  }

  let onChangeEmail = (e) => {
    setValEmail(e.target.value);
  }

  let togglePrivacyPolicyChecked = () => {
    if (isLoadingRegister) {
      return;
    }
    if (!isPrivacyPolicyChecked) {
      setIsPrivacyPolicyError(false);
    }
    setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked);
  }

  let requestAccessToken = (token) => {
    let appData = props.appData.data;
    let appID = appData.appID;
    let appSecret = appData.appSecret;
    let baseApiUrl = appData.baseApiUrl;

    taptalk.init(appID, appSecret, baseApiUrl);

    taptalk.authenticateWithAuthTicket(token, false, {
      onSuccess: (successMessage) => {
        if (valAvatarImage) {
          taptalk.uploadUserPhoto(valAvatarImage, {
            onSuccess: (message) => {
              setSuccessRegister(true);
            },
            onError: (errorCode, errorMessage) => {
                Helper.doToast("Unable to upload profile picture, please try again later from account settings.", "fail", <FiInfo />);
                setSuccessRegister(true);
            }
          });
        }
        else {
          setSuccessRegister(true);
        }
        // window.location.href = "/chat";
      },
      onError: (errorCode, errorMessage) => {
        setIsLoadingRegister(false);
        Helper.doToast("Please input your correct phone number", "fail", <FiInfo />);
        console.log(errorCode, errorMessage);
      }
    })
  }

  let selectProfilePicture = (e) => {
    if (isLoadingRegister) {
      return;
    }

    let fileType = ["image/png", "image/jpg", "image/jpeg"];
    let reader = new FileReader();

    if (e.target.files && e.target.files.length > 0 && fileType.includes(e.target.files[0].type)) {
        reader.onload = function(e) {
            document.getElementById("avatar-image").src = e.target.result;
        }

        reader.readAsDataURL(e.target.files[0]);

        setValAvatarImage(e.target.files[0]);
    }
  }

  let validateForm = () => {
    let isFullNameValid = validateFullName(true);
    let isEmailValid = validateEmail();
    if (!isUsernameValid) {
      validateUsername(true);
    }

    return isFullNameValid && isUsernameValid && isEmailValid && isPrivacyPolicyChecked;
  }

  let validateFullName = (checkMinLength) => {
    if (checkMinLength && !valFullName) {
      setFullNameErrorText("This field is required");
      return false;
    }
    if (valFullName && valFullName.length > FULL_NAME_MAX_LENGTH) {
      setFullNameErrorText(`Maximum ${FULL_NAME_MAX_LENGTH} characters`);
      return false;
    }
    if (valFullName && !/^[a-zA-Z ]+$/.test(valFullName)) {
      setFullNameErrorText("Invalid Full Name");
      return false;
    }
    setFullNameErrorText("");
    return true;
  }

  let validateUsername = (checkMinLength) => {
    if (checkMinLength && !valUsername) {
      setUsernameErrorText("This field is required");
      return false;
    }
    let firstChar;
    if (valUsername) {
      firstChar = valUsername.charAt(0);
    }
    else {
      firstChar = '';
    }
    if ((firstChar >= '0' && firstChar <= '9') || firstChar === '.' || firstChar === '_') {
        setUsernameErrorText("Cant start with numeric, underscore (_) and period (.)");
        return false;
    }
    if ((checkMinLength && valUsername.length < USERNAME_MIN_LENGTH) || (valUsername && valUsername.length > USERNAME_MAX_LENGTH)) {
      setUsernameErrorText(`Must be between ${USERNAME_MIN_LENGTH}-${USERNAME_MAX_LENGTH} characters`);
      return false;
    }
    if (valUsername) {
      const alphanumericSymbolRegex = /^[A-Za-z0-9._]+$/;
      if (!alphanumericSymbolRegex.test(valUsername)) {
        setUsernameErrorText("Only a-z, 0-9, underscore (_) and period (.) allowed");
        return false;
      }
      if (valUsername.includes("..") || valUsername.includes("__") || valUsername.includes("._") || valUsername.includes("_.")) {
          setUsernameErrorText("Can't contain consecutive underscores, consecutive dot, underscore followed with dot, and otherwise");
          return false;
      }
      if (valUsername.endsWith(".") || valUsername.endsWith("_")) {
          setUsernameErrorText("Can’t end with underscore (_) and period (.)");
          return false;
      }
    }
    setUsernameErrorText("");
    return true;
  }

  let validateEmail = () => {
    if (!valEmail) {
      setEmailErrorText("");
      return true;
    }
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(valEmail)) {
      setEmailErrorText("Invalid email address");
      return false;
    }
    setEmailErrorText("");
    return true;
  }

  let checkUsernameExists = (username) => {
		let url = `${props.appData.data.baseApiUrl}/v1/client/user/exists/username`;

		let data = {
      username: username,
			ignoreCase: false
    };
    
    axios.post(url, data, {headers: authenticationHeader})
      .then(function (response) {
        if (usernameRef.current !== username) {
          // Username has changed
          return;
        }
        let _response = response.data;

        if (_response.error.code === "") {
          if (_response.data.exists) {
            setUsernameErrorText("Username has been taken");
          }
          else {
            setIsUsernameValid(true);
            setUsernameErrorText("");
          }
        }
        else {
          setUsernameErrorText("Unable to verify username, please check your connection and try again");
          console.log(_response.error.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let registerAction = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (isLoadingRegister) {
      return;
    }

    if (!validateForm()) {
      if (!isPrivacyPolicyChecked) {
        Helper.doToast("You need to accept privacy policy first to continue", "fail", <FiInfo />);
        setIsPrivacyPolicyError(true);
      }
      return;
    }

    setIsLoadingRegister(true);

		let url = `${props.appData.data.baseApiUrl}/v1/client/register`;

    let _phone = valPhone.replaceAll(" ", "");

    let data = {
      "fullname": valFullName,
      "email": valEmail,
      // "countryID": country.id,
      "countryID": 1, // TODO: TEST
      "phone": _phone,
      "username": valUsername,
    };
    
    axios.post(url, data, {headers: authenticationHeader})
      .then(function (response) {
        let _response = response.data;

        if (_response.error.code === "") {
          requestAccessToken(_response.data.ticket);
        }
        else {
          setIsLoadingRegister(false);
          if (isOnline) {
            Helper.doToast(_response.error.message, "fail", <FiInfo />);
          }
          else {
            Helper.doToast("Please check your network", "fail", <FiWifiOff />);
          }
          console.log(_response.error.message);
        }
      })
      .catch(function (error) {
        setIsLoadingRegister(false);
        console.log(error);
      });
  }

  return (
    <div className="register-wrapper">
      <div className="register-main-content">
        <img className="logo-image" src={LogoTaptalk} alt="" />
        <p className="subtitle">Chat Messenger</p>
        <div className="register-card">
          <b className="title-welcome">Register</b>
          <p>It seems that this is your first time using TapTalk.io, please fill out the information needed below.</p>
          
          <div className="avatar-wrapper">
            {
              valAvatarImage ?
              <img id="avatar-image" className="avatar-image" />
              :
              <FiUser />
            }
          </div>

          <div
            className={`select-profile-picture${(isLoadingRegister) ? ` loading` : ``}`}
            htmlFor="select-profile-picture-input"
            onClick={(e) => profilePictureInputRef.current.click()}
          >
            <b>{valAvatarImage ? "Change" : "Upload Image"}</b>
            <img src={IconEdit} />
          </div>

          <input
            type="file"
            id="select-profile-picture-input"
            ref={profilePictureInputRef}
            onChange={(e) => selectProfilePicture(e)}
            accept="image/jpeg, image/jpg, image/png"
          />

          <form onSubmit={(e) => registerAction(e)}>
            <div className={`form-field-wrapper${(isLoadingRegister) ? ` loading` : ``}`}>
              <b>Full Name</b>
              <div className={`text-field-wrapper${(isLoadingRegister) ? ` loading` : fullNameErrorText ? ` error` : ``}`}>
                <input
                  id="full-name-input"
                  type="text"
                  value={valFullName}
                  placeholder="E.g. Anthony Georgie"
                  disabled={isLoadingRegister}
                  onChange={(e) => onChangeFullName(e)}
                />
              </div>
              {
                fullNameErrorText &&
                <div className="input-error-info">
                  <FiAlertCircle />
                  <b>{fullNameErrorText}</b> 
                </div>
              }
            </div>

            <div className={`form-field-wrapper${(isLoadingRegister) ? ` loading` : ``}`}>
              <b>Username</b>
              <div className={`text-field-wrapper${(isLoadingRegister) ? ` loading` : usernameErrorText ? ` error` : ``}`}>
                <input
                  id="username-input"
                  type="text"
                  value={valUsername}
                  placeholder="E.g. ThonyG_123"
                  disabled={isLoadingRegister}
                  style={{paddingRight: "56px"}}
                  onChange={(e) => onChangeUsername(e)}
                />
                {
                  valUsername && usernameErrorText ?
                  <img src={IconCloseRed} />
                  :
                  isUsernameValid ?
                  <img src={IconCheckCircle} />
                  :
                  <></>
                }
              </div>
              {
                usernameErrorText &&
                <div className="input-error-info">
                  <FiAlertCircle />
                  <b>{usernameErrorText}</b> 
                </div>
              }
            </div>

            <div className={`form-field-wrapper${(isLoadingRegister) ? ` loading` : ``}`}>
              <b>Phone Number</b>
              <div className="phone-wrapper">
                <img src={!country ? FlagGlobe : country.flagIconURL} alt="" onError={(e) => {e.target.onerror = null; e.target.src = FlagGlobe;}} />
                <FiChevronDown />
                {
                  country &&
                  <p>+{country.callingCode}</p>
                }
                <p className="beautified-phone-label">{beautifiedPhone}</p>
              </div>
            </div>
            
            <div className={`form-field-wrapper${(isLoadingRegister) ? ` loading` : ``}`}>
              <b>Email Address</b>
              <p>(Optional)</p>
              <div className={`text-field-wrapper${(isLoadingRegister) ? ` loading` : emailErrorText ? ` error` : ``}`}>
                <input
                  id="email-input"
                  type="text"
                  value={valEmail}
                  placeholder="name@email.com"
                  disabled={isLoadingRegister}
                  onChange={(e) => onChangeEmail(e)}
                />
              </div>
              {
                emailErrorText &&
                <div className="input-error-info">
                  <FiAlertCircle />
                  <b>{emailErrorText}</b> 
                </div>
              }
            </div>
            
            <div className={`privacy-policy-wrapper${(isLoadingRegister) ? ` loading` : ``}`}>
              <img 
                src={isPrivacyPolicyChecked ? IconCheckboxActive : isPrivacyPolicyError ? IconCheckboxError : IconCheckboxInactive}
                onClick={togglePrivacyPolicyChecked}
              />
              <p>
                By Registering, I have read and agree to 
                <a href="https://taptalk.io/privacy-policy/" target="_blank" rel="noopener noreferrer">TapTalk.io’s Privacy Policy</a>
              </p>
            </div>

            <button
              className={`orange-button${(isLoadingRegister) ? `-loading` : ``}`}
              style={{marginTop: "24px"}}
            >
              Continue
              {
                isLoadingRegister ?
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
                :
                <FiArrowRight className="button-icon" />
              }
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  appData: state.appData,
});

export default connect(mapStateToProps, null)(Register);