import React, { useState, useEffect } from 'react';
import './ChatRoomHeaderInfoDocument.scss';
import Helper from '../../../../../helper/Helper';
import HelperDate from '../../../../../helper/HelperDate';
import { tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FiDownload } from 'react-icons/fi';
import { FiFile } from 'react-icons/fi';
import ChatRoomSharedMediaHoverDropDownMenu from '../chatRoomHeaderSharedMedia/chatRoomSharedMediaHoverDropDownMenu/ChatRoomSharedMediaHoverDropDownMenu';

var ChatRoomHeaderInfoDocument = (props) => {
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [fileSrc, setFileSrc] = useState('');
    let [fileContentType, setFileContentType] = useState('');
    let [isFileExistInDB, setIsFileExistInDB] = useState(false);
    let [onFileDownloadProgress, setOnFileDownloadProgress] = useState(false);
    // let [fileExtension, setFileExtension] = useState("");
    let [fileFromUrl, setFileFromUrl] = useState(false);

    useEffect(() => {
        let isUnmounted = false;
        // let splitFileName = props.mediaData.data.fileName ? props.mediaData.data.fileName.split(".") : props.mediaData.body.split('.');

        if (props.mediaData.data.url) {
            setFileFromUrl(true);
            setFileSrc(props.mediaData.data.url);
            setFileContentType(props.mediaData.data.mediaType);
        } else if (props.mediaData.data.fileURL) {
            if (props.mediaData.data.fileURL !== "") {
                setFileFromUrl(true);
                setFileSrc(props.mediaData.data.fileURL);
                setFileContentType(props.mediaData.data.mediaType);
            } else {
                setFileFromUrl(false);
                tapCoreChatRoomManager.getFileFromDB(props.mediaData.data.fileID, function(data) {
                    if (data) {
                        if (!isUnmounted) {
                            setFileSrc(`data:${data.type};base64,${data.file}`);
                            setFileContentType(data.type);
                            setIsFileExistInDB(true);
                        }
                    } else {
                        if (!isUnmounted) {
                            setIsFileExistInDB(false);
                        }
                    }
                })
            }
        }
        
        // if (!isUnmounted) {
        //     setFileExtension(splitFileName[splitFileName.length - 1].toUpperCase());
        // }

        return () => {
            isUnmounted = true;
        }
    }, [props.mediaData])

    let downloadFile = () => {
        setOnFileDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(props.mediaData, {
            onSuccess: (data) => {
                setOnFileDownloadProgress(false);
                setFileSrc(`data:${data.contentType};base64,${data.base64}`);
                setFileContentType(data.contentType);
                setIsFileExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
            },

            onError: (errorCode, errorMessage) => {
                setOnFileDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    let openFile = () => {
        if (!isFileExistInDB && !onFileDownloadProgress && !fileFromUrl) {
            downloadFile();
        }
        else if (fileFromUrl) {
            window.open(fileSrc, "_blank");
            // openFileFromUrl(fileSrc);
        }
        else if (isFileExistInDB) {
            const byteCharacters = atob(fileSrc.substr(`data:${fileContentType};base64,`.length));
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const slice = byteCharacters.slice(offset, offset + 1024);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, {type: fileContentType});
            const blobUrl = URL.createObjectURL(blob);

            window.open(blobUrl, '_blank');
        }
    }

    // let openFileFromUrl = async (url) => {
    //     let blob = await fetch(url).then(r => r.blob());
    //     const blobUrl = URL.createObjectURL(blob);
    //     window.open(blobUrl, '_blank');
    // }

    return (
        <div className="room-data-document-wrapper">
            <div className="room-data-document-action-icon-wrapper">
                {((!isFileExistInDB && !onFileDownloadProgress && !fileFromUrl)) &&
                    <div className="icon-status-wrapper">
                        <FiDownload className="room-data-document-action-icon" />
                    </div>
                }
        
                {(onFileDownloadProgress) && 
                    <div className="icon-status-wrapper">
                        <div className="onprocess-wrapper">
                            <CircularProgressbar value={percentageDownload} />
                            <FiDownload className="room-data-document-action-icon-downloading" />
                        </div>
                    </div>
                }
                
                {(isFileExistInDB || fileFromUrl) &&
                    <FiFile className="room-data-document-action-icon" />
                }
            </div>

            <div className="room-data-document-label-wrapper">
                <b className="room-data-document-filename">{props.mediaData.data.fileName.replace(/\.[^/.]+$/, "")}</b>
                <p className="room-data-document-info">
                    {Helper.bytesToSize(props.mediaData.data.size)}   •   {HelperDate.formatToString(new Date(props.mediaData.created), "d/M/yyyy   •   HH:mm")}
                </p>
            </div>

            <div className="room-data-document-separator"></div>

            <span className="room-data-document-clickable" onClick={openFile}></span>

            <ChatRoomSharedMediaHoverDropDownMenu 
                singleChatDataProps={props.mediaData}
                activeRoom={props.mediaData.room}
                buttonList={[{
                    val: "viewInChat",
                    text: "View in Chat",
                    action: () => props.onClickToMessage(props.mediaData.localID),
                }]}
            />
        </div>
    )
}

export default ChatRoomHeaderInfoDocument;
