import React, { useState, useEffect } from 'react';
import './ChatRoomMessageInFile.scss';
import { MdInsertDriveFile } from 'react-icons/md';
import iconDelete from "../../../../assets/img/delete-account-icon.svg";
// import { MdClose } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import HelperChat from '../../../../helper/HelperChat';
import Helper from '../../../../helper/Helper';
import { taptalk, tapCoreChatRoomManager, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setUserClick } from '../../../../redux/actions/reduxActionUserClick';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';
import { connect } from 'react-redux';

const LONG_PRESS = 700;

var ChatRoomMessageInFile = (props) => {
    let [lastClickBubble, setLastClickBubble] = useState(false);
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [bytesDownload, setBytesDownload] = useState(0);
    let [fileSrc, setFileSrc] = useState('');
    let [fileContentType, setFileContentType] = useState('');
    let [isFileExistInDB, setIsFileExistInDB] = useState(false);
    let [onFileDownloadProgress, setOnFileDownloadProgress] = useState(false);
    let [fileExtension, setFileExtension] = useState("");
    let [fileFromUrl, setFileFromUrl] = useState(false);
    let { 
        activeRoom,
        singleChatDataProps,
        activeUserID,
        onForwardMessage,
        onReplyMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        onReportMessage,
        // isBubbleOnViewPort,
        onClickToStarMessage,
        multiForward,
        noForward
    } = props;

    // useEffect(() => {
    //     let el = document.querySelectorAll('.chat-room-main-content')[0];
    //     let element = document.querySelector(`#message-${singleChatDataProps.localID}`);
        
    //     let domRect = element.getBoundingClientRect();

    //     let logit = async () => {
    //         if(!singleChatDataProps.isRead && 
    //         (domRect.y > 0 || domRect.y < window.innerHeight) && 
    //         singleChatDataProps.user.userID !== taptalk.getTaptalkActiveUser().userID
    //         ) {
    //             isBubbleOnViewPort(singleChatDataProps);
    //         }
    //     }

    //     let watchScroll = () => {
    //         el.addEventListener("scroll", logit);        
    //     }

    //     watchScroll();

    //     return () => {
    //         el.removeEventListener("scroll", logit);
    //     };
    // });

    useEffect(() => {
        let splitFileName = singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName.split('.') : singleChatDataProps.body.split('.');
        let isUnmounted = false;
        
        if(singleChatDataProps.data.url) {
            setFileFromUrl(true);
            setFileSrc(singleChatDataProps.data.url);
            setFileContentType(singleChatDataProps.data.mediaType);
        }else if(singleChatDataProps.data.fileURL) {
            if(singleChatDataProps.data.fileURL !== "") {
                setFileFromUrl(true);
                setFileSrc(singleChatDataProps.data.fileURL);
                setFileContentType(singleChatDataProps.data.mediaType);
            }else {
                setFileFromUrl(false);
                tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
                    if(data) {
                        if(!isUnmounted) {
                            setFileSrc(`data:${data.type};base64,${data.file}`);
                            setFileContentType(data.type);
                            setIsFileExistInDB(true);
                        }
                        
                    }else {
                        if(!isUnmounted) {
                            setIsFileExistInDB(false);
                        }
                    }
                })
            }
        }

        if(!isUnmounted) {
            setFileExtension(splitFileName[splitFileName.length - 1].toUpperCase());
        }

        return () => {
            isUnmounted = true;
        }
        
    }, [singleChatDataProps])

    let downloadFile = () => {
        setOnFileDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnFileDownloadProgress(false);
                setFileSrc(`data:${data.contentType};base64,${data.base64}`);
                setFileContentType(data.contentType);
                setIsFileExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
                setBytesDownload(HelperChat.bytesToSize(bytes));
            },

            onError: (errorCode, errorMessage) => {
                setOnFileDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    // let getFileBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setFileSrc(`data:${data.type};base64,${data.file}`);
    //             setIsFileExistInDB(true);
    //         }else {
    //             setIsFileExistInDB(false);
    //         }
    //     })
    // }

    // let downloadFileToStorage = (file, fileName) => {
    //     fetch(file)
    //         .then(resp => resp.blob())
    //         .then(blob => {
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             const fileID = `file-${new Date().valueOf()}`;

    //             a.style.display = 'none';
    //             a.href = url;
    //             a.id = fileID;

    //             // the filename you want
    //             a.download = fileName;

    //             document.body.appendChild(a);
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //             a.remove();
    //         });
    // }

    let clickBubble = () => {
        if (fileFromUrl) {
            window.open(fileSrc, "_blank");
        }
        else if (isFileExistInDB) {
            const byteCharacters = atob(fileSrc.substr(`data:${fileContentType};base64,`.length));
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const slice = byteCharacters.slice(offset, offset + 1024);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, {type: fileContentType});
            const blobUrl = URL.createObjectURL(blob);

            window.open(blobUrl, '_blank');
        }
    }

    let delay;

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }

    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }
      
    return (
        <div 
            className="chat-room-message-file-in-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
            onClick={() => { 
                if(onClickToStarMessage) {
                    onClickToStarMessage(singleChatDataProps.localID);
                }

                if(activeRoom.type === 2) {
                    setLastClickBubble(new Date().valueOf());
                }
            }}    
        >
            {
                (
                    activeRoom &&
                    !singleChatDataProps.isDeleted &&
                    multiForward[activeRoom.roomID] &&
                    Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
                ) &&
                <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
            }

            {(
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
                <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-in">
                    <input 
                        type="checkbox" 
                        id={`message-${singleChatDataProps.messageID}`} 
                        checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                    />
                    <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
                </div>
            }
            
            {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                <div 
                    className="group-sender-avatar-wrapper" 
                    style={{background: taptalk.getRandomColor(Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname)}} 
                    onClick={() => props.setUserClick({
                        userID: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.userID, 
                        username: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.username
                    })}
                >
                    {(singleChatDataProps.user.deleted && singleChatDataProps.user.deleted !== 0) ?
                        <img src={iconDelete} alt="" />
                        :
                        (Helper.isSavedMessageRoom(activeRoom.roomID) ?
                            <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.forwardFrom.fullname)}</b>
                            :
                            (singleChatDataProps.user.imageURL.thumbnail !== "" ? 
                                <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" />
                                :
                                <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                            )   
                        )
                    }
                </div>
            }

            <div className="message-in-bubble"
            //     style={
            //         Helper.isReplyMessage(singleChatDataProps) ? 
            //            (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
            //                {paddingTop: "64px"} 
            //                :
            //                {paddingTop: "84px"}
            //            )
            //            : 
            //            {}
            //    }
            >
                <div className="click-area-file" onClick={clickBubble}>
                    {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            
                            <p>
                                From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                            </p>
                        </div>
                    }
                    {/* <div className="replied-file-message">
                        <div className="file-icon-wrapper">
                            <MdInsertDriveFile />
                        </div>

                        <div className="file-detail-wrapper">
                            <div className="filename-wrapper">
                                <b>file.docx</b>
                            </div>
                            285 KB
                        </div>
                    </div> */}

                    {/* {props.forwarded &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>Keju Manis</b>
                        </div>
                    } */}

                    {/* {Helper.isReplyMessage(singleChatDataProps) &&
                        <div className="reply-message reply-file">
                            {singleChatDataProps.quote.fileType !== "" &&  
                                <div className="reply-file-thumbnail">
                                    {singleChatDataProps.quote.fileType === "file" ? 
                                        <MdInsertDriveFile />
                                        :
                                        <img src={''} alt="" />
                                    }
                                </div>
                            }

                            <div className="reply-text-wrapper">
                                <p className="reply-from">
                                    <b> 
                                        {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                            "You"
                                            :
                                            singleChatDataProps.replyTo.fullname
                                        }
                                    </b>
                                </p>
                                <p className="reply-text">{singleChatDataProps.quote.content}</p>
                            </div>
                        </div>
                    } */}

                    {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                <b>{Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname}</b>
                            </b>
                        </p>
                    }

                    {Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyInMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    <div className="file-inner-wrapper">
                        <div className="file-icon-wrapper">
                            {/* {props.status === 'onprogress' ?
                                <div className="onprocess-wrapper">
                                    <MdClose />
                                </div>

                                :

                                props.status === 'success' ?
                                    <MdInsertDriveFile />
                                    :
                                    <MdFileDownload />
                            } */}
                            {(!isFileExistInDB && !onFileDownloadProgress && !fileFromUrl) &&
                                <div className="icon-status-wrapper">
                                    <FiDownload onClick={() => downloadFile()} />
                                </div>
                            }

                            {onFileDownloadProgress && 
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={percentageDownload} />
                                        {/* <MdClose onClick={() => console.log('cancel')} /> */}
                                        <FiDownload />
                                    </div>
                                </div>
                            }
                            
                            {(isFileExistInDB || fileFromUrl) &&
                                // <a href={fileSrc} target="_blank" rel="noopener noreferrer" download={singleChatDataProps.data.fileName}>
                                // <MdInsertDriveFile onClick={() => downloadFileToStorage(fileFromUrl ? singleChatDataProps.data.url : fileSrc, singleChatDataProps.data.fileName ? singleChatDataProps.data.fileName : singleChatDataProps.body)} />
                                <a href={fileSrc} target="_blank" rel="noopener noreferrer">
                                    <MdInsertDriveFile />
                                </a>
                            }
                        </div>

                        <div className="message-bubble-file-wrapper">
                            <p>
                                <b>
                                    {singleChatDataProps.data.fileName ?
                                        singleChatDataProps.data.fileName.replace(fileExtension.toLowerCase(), '').replace('.', '')
                                        :
                                        singleChatDataProps.body.replace(fileExtension.toLowerCase(), '').replace('.', '')
                                    }
                                </b>
                            </p>
                            <div className={`file-size-and-extension ${onFileDownloadProgress ? 'file-size-and-extension-download-progress' : ''}`}>
                                {onFileDownloadProgress && `${bytesDownload} / `}{singleChatDataProps.data.size !== 0 && HelperChat.bytesToSize(singleChatDataProps.data.size)}{" "+fileExtension}
                            </div>
                        </div>
                    </div>

                    <ChatRoomMessageInfoComp 
                        message={singleChatDataProps}
                        lastClickBubble={lastClickBubble}
                    />

                </div>
                
                <ChatRoomMessageActionComp
                    isIn
                    buttonList={Helper.isSavedMessageRoom(activeRoom.roomID) ?
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete Message",
                                action: () => deleteMessageAction(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                        ]
                        :
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                            {
                                val: "report",
                                text: "Report",
                                action: () => onReportMessage(singleChatDataProps), 
                            },
                        ]
                    }
                    singleChatDataProps={singleChatDataProps}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setUserClick,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInFile);
