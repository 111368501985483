import { taptalk } from 'taptalksdk-web';
import { toast } from "react-toastify";
import React from 'react';
import HelperDate from "./HelperDate";

var Helper = {
    doToast: (text = "YOUR TOAST", _className = null, icon = null) => {
        const config = {
          autoClose: 3000,
          position: "bottom-left",
          className: _className === null ? "ToastContent" : "ToastContent-"+_className,
          hideProgressBar: true
        };

        if (icon) {
            toast(<span>{icon}{text}</span>, config);
        }
        else {
            toast(text, config);
        }
    },
    renderUserAvatarWord: (text, isGroup) => {
        let userAvatarWord = "";
        let nameSplit = text.split(" ");
        let secondName = nameSplit.length > 1 ? nameSplit[1] : '';

        if(isGroup) {
            userAvatarWord = nameSplit[0].substr(0, 1);
        }else {
            userAvatarWord = nameSplit[0].substr(0, 1);
            userAvatarWord += secondName.substr(0, 1);
        }

        return userAvatarWord;
    },

    getHourMinute: (timestamp) => {
        var time = new Date(timestamp);
        return time.getHours()+":"+(time.getMinutes() < 10 ? ("0" + time.getMinutes()) : time.getMinutes())
    },

    forceLogout: () => {
        taptalk.clearUserData();
        window.location.href = "/login";
    },

    copyToClipBoard: (str) => {
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    },

    bytesToSize : (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return (bytes / Math.pow(1024, i)).toFixed(2).replace('.00', '') + ' ' + sizes[i];
    },

    msToTime(duration) {
        // var milliseconds = parseInt((duration % 1000) / 100),
        var seconds = Math.floor((duration / 1000) % 60);
        var minutes = Math.floor((duration / (1000 * 60)) % 60);
        var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;
    },

    getLastActivityString(timestamp) {
        let result = "";

        let arrayTime = [
            31536000000, //1 year 0 
            2592000000, // 30 day 1
            604800000, // 7 day 2
            86400000, // 1 day 3 
            3600000, // 1 hour 4
            60000, //  1 min 5
            1000 // 1 sec 6
        ];

        let objectTime = (key, time) => {
            let timeHashmap = {
                "tap_active_recently": "Active recently",
                "tap_format_d_minute_ago": `Active ${Math.floor(time)} minute ago`,
                "tap_format_d_minutes_ago": `Active ${Math.floor(time)} minutes ago`,
                "tap_format_d_hour_ago": `Active ${Math.floor(time)} hour ago`,
                "tap_format_d_hours_ago": `Active ${Math.floor(time)} hours ago`,
                "tap_active_yesterday": `Active yesterday`,
                "tap_format_d_day_ago": `Active ${Math.floor(time)} day ago`,
                "tap_format_d_days_ago": `Active ${Math.floor(time)} days ago`,
                "tap_format_s_last_active": `Last active ${time}`,
                "tap_active_a_week_ago": "Active a week ago"
            }

            return timeHashmap[key];
        }

        let timeGap;
        let timeNow = new Date();
        timeGap = timeNow.valueOf() - timestamp;
        let midnightTimeGap;
        let timestampMidnight = new Date(timestamp);
        let tommorow = timestampMidnight.setDate(timestampMidnight.getDate() + 1);
        let midnightFromSendTime = new Date(tommorow).setHours(0, 0, 0, 0);
        midnightTimeGap = midnightFromSendTime.valueOf() - timestamp;
        
        if (timestamp === 0) {
            result = "";
        } else if ((midnightTimeGap > timeGap) && (timeGap < arrayTime[5])) {
            result = objectTime("tap_active_recently");
        } else if ((midnightTimeGap > timeGap) && (timeGap < arrayTime[4]) && (timeGap < 120000)) {
            let numberOfMinutes = timeGap / arrayTime[5];
            result = objectTime("tap_format_d_minute_ago", numberOfMinutes);
        
        } else if ((midnightTimeGap > timeGap) && (timeGap < arrayTime[4])) {
            let numberOfMinutes = timeGap / arrayTime[5];
            result = objectTime("tap_format_d_minutes_ago", numberOfMinutes);
        
        } else if ((midnightTimeGap > timeGap) && (timeGap < 7200000)) {
            let numberOfHour = timeGap / arrayTime[4];
            result = objectTime("tap_format_d_hour_ago", numberOfHour);
        
        } else if (midnightTimeGap > timeGap) {
            let numberOfHour = timeGap / arrayTime[4];
            result = objectTime("tap_format_d_hours_ago", numberOfHour);
        } else if ((arrayTime[3] + midnightTimeGap) > timeGap) {
            result = objectTime("tap_active_yesterday");
            
        } else if (((arrayTime[3] * 6 + midnightTimeGap) >= timeGap) && (timeGap < 172800000)) {
            let numberOfDays = timeGap / arrayTime[3];
            result = objectTime("tap_format_d_day_ago", numberOfDays);
            
            
        } else if ((arrayTime[3] * 6 + midnightTimeGap) >= timeGap) {
        
            let numberOfDays = timeGap / arrayTime[3];
            result = objectTime("tap_format_d_days_ago", numberOfDays);
        
        } else if (timeGap <= arrayTime[2]) {
        
            result = objectTime("tap_active_a_week_ago");
        
        } else {
        
            let date = new Date(timestamp);
            let sdf = HelperDate.formatToString(date, "dd MMM yyyy");
            result = objectTime("tap_format_s_last_active", sdf);
        
        }

        return result;
    },

    downloadURL: (url, name = "Download", fileType = "jpg") => {
        // var link = document.createElement('a');
        // link.href = url;
        // link.download = name + "." + fileType;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        fetch(url)
        .then(response => response.blob())
        .then(blob => {
            // setFetching(false);
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";

            a.download = name;
            document.body.appendChild(a);
            a.click();
        })
        .catch((e) => console.log("error", e));
    },

    savedMessageRoomID() {
        let user = taptalk.getTaptalkActiveUser().userID;
        
        return `${user}-${user}`;
    },

    savedMessageRoomModel() {
        return {
            "roomID": this.savedMessageRoomID(),
            "xcRoomID": "",
            "name": "Saved Messages",
            "type": 1,
            "imageURL": {
            "thumbnail": "",
            "fullsize": ""
            },
            "color": "",
            "isLocked": false,
            "lockedTime": 0,
            "deleted": 0,
            "isDeleted": false
        }
    },

    isSavedMessageRoom(roomID) {
        return roomID === this.savedMessageRoomID();
    },

    isForwardMessage(message, room) {
        return (
            (
                message.forwardFrom &&
                message.forwardFrom.fullname &&
                message.forwardFrom.fullname !== ""
            ) &&
            room.roomID !== this.savedMessageRoomID()
        )
    },

    isForwardMessageIgnoreRoom(message) {
        return (
            message.forwardFrom &&
            message.forwardFrom.fullname &&
            message.forwardFrom.fullname !== ""
        )
    },

    isReplyMessage(message) {
        return (
            message.replyTo &&
            message.replyTo.localID &&
            message.replyTo.localID !== ""
        )
    },

    isObjectEmpty(obj) {
        let isEmpty = Object.keys(obj).length === 0;

        return isEmpty;
    },

    findIndexOfArray(array, val, key) {
        let idx = -1;

        idx = array.findIndex((v => v[key] === val));

        return idx;
    },

    async getMetadataFromUrl(url) {
        let decode = (str) => str.replace(/&#(\d+);/g, function(match, dec) {
          return String.fromCharCode(dec);
        })

        try {
            const html = await (await fetch(/*"https://cors-anywhere.herokuapp.com/" + */url, {
            method: 'GET',
            timeout: 5000,
            headers: {
                'Content-Type': 'text/plain',
            },
            })).text();
            var metadata = {};
            html.replace(/<meta.+(property|name)="(.*?)".+content="(.*?)".*\/>/igm, (m, p0, p1, p2) => { metadata[p1] = decode(p2) } );
            return metadata;
        } 
        catch(error) {
            console.log(error);
            return false;
        }
    },
    
    getUrlsFromString(string, ignorePrefix = false) {
        const expression = /(\b((https?|ftp|file):\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?)/gi;
        const regex = new RegExp(expression);
        const urls = string.match(regex);
        if (urls !== null && urls !== undefined) {
            if (!ignorePrefix) {
                let _urls = urls.slice();
                _urls.forEach((url, index) => {
                    const urlWithPrefix = this.checkAndAddUrlPrefix(url);
                    urls[index] = urlWithPrefix;
                });
            }
            return urls;
        }
        return [];
    },

    checkAndAddUrlPrefix(url) {
        const pattern = /^((http|https|ftp):\/\/)/;
        if (!pattern.test(url)) {
          url = "http://" + url;
        }
        return url;
    },

    autoFocusField (target) {
        let _target = document.querySelectorAll(`.${target}`);
    
        if (_target.length > 0) {
            _target[0].focus();
        }
    },

    phoneBeautify(phone, ignoreCountryCode = false) {
        let result = "";
        let countryCode = phone.substr(0, 3) === "+62" ? "62" : (phone.substr(0, 2) === "62" ? "62" : "");
        let _phone = phone.substr(0, 3) === "+62" ? phone.replace("+62", "") : (phone.substr(0, 2) === "62" ? phone.replace("62", "") : phone);
        let _phoneLength = _phone.length;
        
        if(_phoneLength > 5) {
            if(_phoneLength < 6) {
                result = _phone.substr(0);
            }
    
            if(_phoneLength < 8 && _phoneLength > 5) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4);
            }
    
            if(_phoneLength === 8) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4);
            }
        
            if(_phoneLength === 9) {
                result = _phone.substr(0, 3)+" "+_phone.substr(3, 3)+" "+_phone.substr(6, 3);
            }
        
            if(_phoneLength === 10) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 3)+" "+_phone.substr(7, 3);
            }
        
            if(_phoneLength === 11) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 3);
            }
        
            if(_phoneLength === 12) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 4);
            }
        
            if(_phoneLength === 13) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 3)+" "+_phone.substr(7, 3)+" "+_phone.substr(10, 3);
            }
        
            if(_phoneLength === 14) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 3)+" "+_phone.substr(11, 3);
            }

            if(_phoneLength === 15) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 4)+" "+_phone.substr(12, 3);
            }
        
            if(_phoneLength > 15) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 3)+" "+_phone.substr(11, 3)+" "+_phone.substr(14);
            }
        
            return countryCode !== "" ? (ignoreCountryCode ? result: (countryCode+" "+result)) : result;
        }else {
            return phone;
        }
    }
}

export default Helper;