import React, { useState, useEffect } from 'react';
import './ChatRoomHeaderPinMessage.scss';
import { connect } from 'react-redux';
import ChatRoomMessageIn from '../../../chatRoomMain/chatRoomMessageIn/ChatRoomMessageIn';
import ChatRoomMessageInFile from '../../../chatRoomMain/chatRoomMessageInFile/ChatRoomMessageInFile';
import ChatRoomMessageInImage from '../../../chatRoomMain/chatRoomMessageInImage/ChatRoomMessageInImage';
import ChatRoomMessageInVideo from '../../../chatRoomMain/chatRoomMessageInVideo/ChatRoomMessageInVideo';
import ChatRoomMessageInLocation from '../../../chatRoomMain/chatRoomMessageInLocation/ChatRoomMessageInLocation';
import ChatRoomMessageInVoice from '../../../chatRoomMain/chatRoomMessageInVoice/ChatRoomMessageInVoice';
import ChatRoomMessageOut from '../../../chatRoomMain/chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutFile from '../../../chatRoomMain/chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from '../../../chatRoomMain/chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from '../../../chatRoomMain/chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutLocation from '../../../chatRoomMain/chatRoomMessageOutLocation/ChatRoomMessageOutLocation';
import ChatRoomMessageOutVoice from '../../../chatRoomMain/chatRoomMessageOutVoice/ChatRoomMessageOutVoice';
import ActionUnpinOrange from "../../../../../assets/img/chatroom/message-action/action-unpin-orange.svg";
import { setAllPinnedMessages, clearAllPinnedMessages } from '../../../../../redux/actions/reduxActionAllPinnedMessage';
import { taptalk, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { IoIosArrowBack } from 'react-icons/io';
import { ModalHeader, ModalBody } from 'reactstrap';
import { CHAT_TYPE } from '../../../../../helper/HelperConst';
import Helper from '../../../../../helper/Helper';

var ChatRoomHeaderPinMessage = ({
    activeRoom,
    activeRoomConst,
    activeModalRoomInfo,
    setActiveModalRoomInfo,
    onClickToStarMessage,
    toggleMyAccountModal,
    style,
    allPinnedMessages,
    setAllPinnedMessages,
    clearAllPinnedMessages
}) => {
  let [isLoadingChat, setIsLoadingChat] = useState(false);
  // let [pinMessages, setpinMessages] = useState(null);
  // let [tempPinMessages, setTempPinMessages] = useState({
  //   room: "",
  //   lastUpdate: false,
  //   data: false
  // })

  useEffect(() => {
    fetchPinned(false);
  }, [])

  // useEffect(() => {
  //   if(activeRoom.roomID === tempPinMessages.room && tempPinMessages.lastUpdate) {
  //     setpinMessages(tempPinMessages.data);
  //     setIsLoadingChat(false);
  //   } 
  // }, [tempPinMessages])

  let fetchPinned = (loadMore) => {
    setIsLoadingChat(true);

    tapCoreMessageManager.fetchPinnedMessages(activeRoom.roomID, {
      onSuccess: (res) => {
        let _allPinnedMessages = {...allPinnedMessages};
        _allPinnedMessages.pinMessagesInsideModal = res.messages;
        
        setAllPinnedMessages(_allPinnedMessages);
        // setTempPinMessages({
        //   room: activeRoom.roomID,
        //   lastUpdate: new Date().valueOf(),
        //   data: res
        // })
        setIsLoadingChat(false);
      },
      onError: (errCode, errMessage) => {
        console.log(errCode, errMessage)
      }
    })
  }

  let isMessageOut = (messageData, activeUser) => {
    let isOut;
    let isSavedMessageRoom = activeRoom.roomID === Helper.savedMessageRoomID();
    
    if(isSavedMessageRoom && Helper.isForwardMessageIgnoreRoom(messageData)) {
      isOut = false;
    }else if(messageData.user.userID !== activeUser){
      isOut = false;
    }else {
      isOut = true;
    }
    
    return isOut;
  }

  let generateMessageBubble = (messageData, index) => {
      let activeUser = taptalk.getTaptalkActiveUser().userID;
      switch(messageData.type) {
        case CHAT_TYPE.TAPChatMessageTypeText:
        case CHAT_TYPE.TAPChatMessageTypeLink:
          return (
            !isMessageOut(messageData, activeUser) ?
              <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onClickToStarMessage={onClickToStarMessage}
                noForward
              />
              :
              <ChatRoomMessageOut 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onClickToStarMessage={onClickToStarMessage}
                noForward
              />
          )
        case CHAT_TYPE.TAPChatMessageTypeImage:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeVideo:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeLocation:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInLocation 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutLocation 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeFile:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInFile 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutFile 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeVoice:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInVoice
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    forPinnedAndForPinned
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutVoice
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    forPinnedAndForPinned
                    noForward
                  />
            )
        default:
          break;
      }
  }

  let onScrollListener = () => {
    if(!isLoadingChat && allPinnedMessages.pinMessages.hasMore) {
      let el = document.querySelectorAll(".room-modal-pin-message")[0];
      let _scrollHeight = el.scrollHeight;
      let _scrollPosition = el.scrollTop + el.clientHeight;
      
      if(_scrollHeight === _scrollPosition) {
        fetchPinned(true);
      }
    }
  }

  let unpinAllMessages = () => {
    toggleMyAccountModal();

    let _messageIDs = [];
    let _allPinned = {...allPinnedMessages};
    
    Object.keys(_allPinned.messages).map((v) => {
      // delete _allPinned.messages[v];
      
      _messageIDs.push(v);
      return null;
    })
    
    // setAllPinnedMessages(_allPinned);

    _allPinned = {
      messages: {},
      pinMessages: {
        hasMore: false,
        messages: [],
        pageNumber: 0,
        totalItems: 0,
        totalPages: 0
      },
      activePinnedMessageIndex: 0,
      pinMessagesInsideModal: null
    };

    _allPinned.roomID = activeRoom.roomID;

    setAllPinnedMessages(_allPinned);

    tapCoreMessageManager.unpinMessage(activeRoom.roomID, _messageIDs, true, {
      onSuccess: (res) => {
        console.log(res)
      },
      onError: (errCode, errMessage) => {
        console.log(errCode, errMessage)
      }
    })
  }

  return (
      <React.Fragment>
          <ModalHeader className={`member-list-header room-info-member-list ${activeModalRoomInfo === activeRoomConst.pinMessage ? "active-room-info-member-list" : ""}`}> 
              <IoIosArrowBack 
                  className="header-room-info-button back-arrow-modal" 
                  onClick={() => {
                      // setActiveModalRoomInfo(activeRoomConst.main);
                      toggleMyAccountModal();
                  }}
              />

              <b className="room-info-title">Pinned Messages</b>
          </ModalHeader>

          <ModalBody 
            className={`room-info-member-list ${activeModalRoomInfo === activeRoomConst.pinMessage ? "active-room-info-member-list" : ""} room-modal-pin-message`}
            onScroll={() => onScrollListener()}
          >
              <div 
                className='room-modal-pin-message-inner-wrapper'
                onScroll={() => onScrollListener()}
              >
                {allPinnedMessages.pinMessagesInsideModal ?
                  (allPinnedMessages.pinMessagesInsideModal.length === 0 ?
                    <div className="no-pinned-message">

                      <p className="no-pinned-title">
                        <b>No Pinned Messages</b>
                      </p>

                      <p className="no-pinned-desc">
                        Add pin to find important text later. 
                      </p>
                    </div>
                    :
                    allPinnedMessages.pinMessagesInsideModal.slice(0).reverse().map((value, index) => {
                      if(!value.isHidden) {
                        return (
                            <React.Fragment key={`pinned-${index}`}>
                                <div className="room-modal-pin-message-wrapper">
                                  {generateMessageBubble(value, index)}
                                </div>
                            </React.Fragment>
                        )
                      }

                      return null;
                    })
                  )
                  :
                  ""
                }
                
                {isLoadingChat &&
                  <div className="loading-message-wrapper">
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                }
              </div>

              <div className='unpin-all-message-wrapper' onClick={unpinAllMessages}>
                  <img src={ActionUnpinOrange} alt="" />
                  <b>Unpin All Messages</b>
              </div>  
          </ModalBody>
      </React.Fragment>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  allPinnedMessages: state.allPinnedMessages
});

const mapDispatchToProps = {
  setAllPinnedMessages,
  clearAllPinnedMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderPinMessage);