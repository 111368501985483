import React, { useState, useRef, useEffect } from 'react';
import './ChatRoomInputEditMessage.scss';
import { FiSmile } from 'react-icons/fi';
import { tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { connect } from 'react-redux';
import Airplane from '../../../../../assets/img/icon-airplane.svg';
import Helper from '../../../../../helper/Helper';
import HelperChat from '../../../../../helper/HelperChat';
import { setEditMessage, clearEditMessage } from '../../../../../redux/actions/reduxActionEditMessage';
import { setLinkPreview, clearLinkPreview } from '../../../../../redux/actions/reduxActionLinkPreview';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import ChatRoomEditMessage from "../chatRoomEditMessage/ChatRoomEditMessage";
import ChatRoomMentionList from "../chatRoomMentionList/ChatRoomMentionList";
import ChatRoomInputLinkPreview from '../chatRoomInputLinkPreview/ChatRoomInputLinkPreview';
import { CHAT_TYPE } from "../../../../../helper/HelperConst";

var ChatRoomInputMessage = (props) => {
  let chatRoomMesageInputRef = useRef("messageInput");
  let [isActiveButtonSend, setIsActiveButtonSend] = useState(false);
  let [isTyping, setIsTyping] = useState(false);
  let [typingTimeoutID, setTypingTimeoutID] = useState(0);
  let [selectedMention, setSelectedMention] = useState(false);

  let [listOfParticipant, setListOfParticipant] = useState([]);

  let [linkPreviewTimeoutID, setLinkPreviewTimeoutID] = useState(0);

  useEffect(() => {
    if(props.editMessage.message) {
        chatRoomMesageInputRef.current.value = 
          (props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeText || 
           props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLink) ? 
           props.editMessage.message.body : 
           props.editMessage.message.data.caption;
    }
  }, [props.editMessage])

  useEffect(() => {
      setIsActiveButtonSend(
        ((props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeText || 
          props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLink) && 
          chatRoomMesageInputRef.current.value.length === 0) ?
          false :
          true
      )
  }, [chatRoomMesageInputRef.current.value])

  useEffect(() => {
    // setIsActiveButtonSend(false);
    setListOfParticipant([]);

    if(props.activeRoom !== null) {
      let selectorInputText = document.querySelectorAll(".main-textarea-input")[0];
      selectorInputText.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeRoom])

  useEffect(() => {
    let elTextInput = document.getElementsByClassName("main-textarea-input")[0];

    if(props.editMessage.message) {
      elTextInput.focus();
    }

    // input growing
    let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
    let maxHeightChatroom = 108; // 158
    
    let selInputRoomHeight = (height) => {
      elChatRoomMain.style.setProperty("max-height", "calc(100% - "+(height - 51)+"px)", "important");
    };

    let onInputMessageListener = (e) => {
      let defaultTextAreaHeight = 40;       
      let maxTextareaHeight = 120 + 20; // 5 rows - 20px per row
      let replyMessageHeight = 68;

      if(e.target.value === "") {
          elTextInput.removeAttribute("style");

          if(props.replyMessage.message || props.forwardMessage.message) {
            elChatRoomMain.style.setProperty("max-height", "calc(100% - 125px)", "important");
          }else {
            elChatRoomMain.removeAttribute("style");
          }
      }

      let textAreaHeightBefore = e.target.style.height === "" ? 40 : parseInt(e.target.style.height.replace("px", ""));
      
      elTextInput.style.height = "";
      elTextInput.style.height = elTextInput.scrollHeight + "px";

      let textareaCurrentHeight = parseInt(e.target.style.height.replace("px", ""));
      let textareaActualCurrentHeight = elTextInput.offsetHeight;
      if(textAreaHeightBefore !== textareaCurrentHeight) {
          if(textareaActualCurrentHeight <= maxTextareaHeight) {
              if(textAreaHeightBefore < textareaCurrentHeight) {
                let chatRoomContainerMaxHeightVal = maxHeightChatroom + (textareaActualCurrentHeight - defaultTextAreaHeight);
                selInputRoomHeight((props.replyMessage.message || props.forwardMessage.message) ? chatRoomContainerMaxHeightVal + replyMessageHeight : chatRoomContainerMaxHeightVal);
              }

              if(textAreaHeightBefore > textareaCurrentHeight) {
                let chatRoomContainerMaxHeightVal = maxHeightChatroom - (defaultTextAreaHeight - textareaActualCurrentHeight);
                selInputRoomHeight((props.replyMessage.message || props.forwardMessage.message) ? chatRoomContainerMaxHeightVal + replyMessageHeight : chatRoomContainerMaxHeightVal);
              }
          }
      }
    }

    elTextInput.addEventListener("input", onInputMessageListener);

    //input growing
    return () => {
      elTextInput.removeEventListener("input", onInputMessageListener)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMessage]);

  let startTyping = async () => {
    if (isTyping) {
        return;
    }

    setIsTyping(true);
    
    tapCoreChatRoomManager.sendStartTypingEmit(props.activeRoom.roomID);
  }

  let stopTyping = async () => {
    if (!isTyping) {
        return;
    }

    clearTimeout(typingTimeoutID);

    setIsTyping(false);
    setTypingTimeoutID(0);
    
    tapCoreChatRoomManager.sendStopTypingEmit(props.activeRoom.roomID);
  }

  let isOnlySpace = (str) => {
    return str.trim().length === 0;
  }

  let actionSubmitEditMessage = async (e) => {
    if (isOnlySpace(chatRoomMesageInputRef.current.value) && 
        (props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeText ||
         props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLink)
    ) {
      props.clearEditMessage();
      HelperChat.resetChatRoomHeightAndInputText();
      setListOfParticipant([]);
    } else {
      let _edit = {...props.editMessage};
      
      _edit.lastEdit = new Date().valueOf();
      props.onSendEditMessage(_edit, chatRoomMesageInputRef.current.value);

      props.setEditMessage(_edit);
  
      if(listOfParticipant.length !== 0) {
        onClickMention(selectedMention.user);
      }else {
        e.preventDefault();
        // setIsActiveButtonSend(false);
    
        // props.onInputNewMessageProps(chatRoomMesageInputRef.current.value);
        chatRoomMesageInputRef.current.value = "";
        _edit.message = false;
        _edit.setHeightChatRoom = false;
        props.setEditMessage(_edit);
        HelperChat.resetChatRoomHeightAndInputText();
        setListOfParticipant([]);
      }
    }
  }

  let onSelectEmoji = (e) => {
    let target = document.querySelectorAll('.main-textarea-input')[0];
    target.value = target.value + e.native;
    // setIsActiveButtonSend(true);
  }

  let onChangeInputMessage = async (e) => {
    if(e.target.value.length > 0) {
      // setIsActiveButtonSend(true);
    }else {
      setListOfParticipant([]);
      // setIsActiveButtonSend(false);
    }
  }

  let keyPressInputMessage = async (e) => {
    if(e.which === 13 && !e.shiftKey) {        
        actionSubmitEditMessage(e);
    }

    // if(e.target.value.length > 0) {
    //   setIsActiveButtonSend(true);
    // }else {
    //   setIsActiveButtonSend(false);
    // }
  }


  let runOnChange = async (e) => {
    onChangeInputMessage(e);
  }

  let runOnKeyUp = async (e) => {
    keyPressInputMessage(e)
    
    if(e.target.value.length > 0 && e.which !== 38 && e.which !== 40) {
      checkAndSearchUserMentionList();
    }
    
    if (isTyping) {
        if (typingTimeoutID) {
            clearTimeout(typingTimeoutID);
        }

        setTypingTimeoutID(setTimeout(() => {
            stopTyping();
        }, 7000));
    }

    if (linkPreviewTimeoutID) {
      clearTimeout(linkPreviewTimeoutID);
    }
    // Note: Disabled fetching link preview: blocked by CORS
    // setLinkPreviewTimeoutID(setTimeout(() => {
    //   checkAndGenerateLinkPreview();
    // }, 500));
  }

  let runOnKeyDown = async (e) => {
    if(listOfParticipant.length > 0) {
      if(e.keyCode === 40 || e.keyCode === 38) {
        e.preventDefault();
      }
    }

    if(e.which === 13) {
      if(!e.shiftKey) {
          e.preventDefault();
      }
    }

    startTyping()
  }

  let checkAndSearchUserMentionList = async () => {
    if(props.activeRoom.type === 2) {
      let elInput = document.querySelectorAll(".main-textarea-input")[0];
      let s = elInput.value;

      if (s.includes("@")) {
        let cursorIndex = elInput.selectionStart;
        let loopIndex = elInput.selectionStart;

        while (loopIndex > 0) {
            // Loop text from cursor index to the left
            loopIndex--;
            let c = s[loopIndex];
            if (c === ' ' || c === '\n') {
              // Found space before @, return
              setListOfParticipant([]);
              return;
            }
            
            if (c === '@') {
                // Found @, start searching user
                let keyword = s.substring(loopIndex + 1, cursorIndex).toLowerCase();
                setListOfParticipant(
                  keyword === "" ?
                    props.participantList
                    :
                    props.participantList.filter((val) => {
                      return val.fullname.includes(keyword) || val.username.includes(keyword) 
                    })
                );
                  
                return;
            }
        }
        
        if(loopIndex < 1) {
          setListOfParticipant([]);
        }
      }else {
        setListOfParticipant([]);
      }
    }
  }

  let onClickMention = async (val) => {
    let elInput = document.querySelectorAll(".main-textarea-input")[0];
    let caretPosition = elInput.selectionStart;
    let s = elInput.value;
    let cursorIndex = elInput.selectionStart;
    let loopIndex = elInput.selectionStart;
    while (loopIndex > 0) {
      // Loop text from cursor index to the left
      loopIndex--;
      let c = s[loopIndex];
    
      if (c === ' ' || c === '\n') {
        setListOfParticipant([]);
        return;
      }        

      if (c === '@') {
        // Found @, start searching user
        let keyword = s.substring(loopIndex + 1, cursorIndex).toLowerCase();          
        let _val = [elInput.value.slice(0, caretPosition - keyword.length), val.username+ " ", elInput.value.slice(caretPosition)].join('');
        elInput.value = _val;
        elInput.focus();
        setListOfParticipant([]);
        return;
      }
    }
  }
  
  let runSetSelectionMention = (user, index) => {
    setSelectedMention(user ? 
      {
          user: user,
          index: index
      }
      :
      false
    );
  }

  let checkAndGenerateLinkPreview = async () => {
    let elInput = document.querySelectorAll(".main-textarea-input")[0];
    let inputText = elInput.value;
    let urls = Helper.getUrlsFromString(inputText)

    if (urls.length > 0) {
      let _linkPreview = {...props.linkPreview};
      let urlToFetch = urls[0];

      if (urlToFetch === _linkPreview.url) {
        // Metadata has been previously fetched
        return;
      }

      let isFetchedUrlValid = (fetchedUrl) => {
        let latestInputText = elInput.value;
        let latestUrls = Helper.getUrlsFromString(latestInputText)
        let latestUrl = latestUrls[0];
        return (//props.linkPreview.url === fetchedUrl && 
                latestUrls.length > 0 && 
                latestUrl === fetchedUrl
        );
      }

      // Set loading state
      _linkPreview.roomID = props.activeRoom.roomID;
      _linkPreview.urls = urls;
      _linkPreview.url = urlToFetch;
      _linkPreview.title = "";
      _linkPreview.description = "";
      _linkPreview.image = "";
      _linkPreview.siteName = "";
      _linkPreview.type = "";
      _linkPreview.isFetching = true;
      props.setLinkPreview(_linkPreview);

      // Generate metadata
      let metadata = await Helper.getMetadataFromUrl(urlToFetch);
      if (metadata) {
        if (isFetchedUrlValid(urlToFetch) && metadata["og:title"] && metadata["og:description"]) {
          _linkPreview.title = metadata["og:title"];
          _linkPreview.description = metadata["og:description"];
          _linkPreview.image = metadata["og:image"];
          _linkPreview.siteName = metadata["og:site_name"];
          _linkPreview.type = metadata["og:type"];
          _linkPreview.isFetching = false;
          props.setLinkPreview(_linkPreview);
        }
        // Ignore fetched metadata if user changed url in input text
      }
      else {
        // Failed to fetch metadata
        if (isFetchedUrlValid(urlToFetch)) {
          props.clearLinkPreview();
        }
      }
      // clearTimeout(linkPreviewTimeoutID);
      // setLinkPreviewTimeoutID(0);
    }
    else {
      // Text does not contain url
      props.clearLinkPreview();
    }
  }

  return (
    <div className={`chat-room-input-edit-message-wrapper`}>
        <div className="chat-room-reply-message-mention-wrapper">
          {listOfParticipant.length > 0 &&
            <ChatRoomMentionList 
                onClickMention={onClickMention}
                listOfParticipant={listOfParticipant}
                selectedMention={selectedMention}
                runSetSelectionMention={runSetSelectionMention}
            />
          }

          {props.editMessage.message && <ChatRoomEditMessage />}

          {props.linkPreview.url && <ChatRoomInputLinkPreview />}

        </div>

        <form 
          onSubmit={(e) => actionSubmitEditMessage(e)} 
        >
            <div className="chat-room-textarea-wrapper">
                <textarea rows={1}
                  className={`main-textarea-input`}
                  placeholder="Send Message..." 
                  onBlur={() => stopTyping()}
                  ref={chatRoomMesageInputRef}
                  onChange={(e) => {
                    runOnChange(e);
                  }}
                  onKeyUp={(e) => {
                    runOnKeyUp(e);
                  }}
                  onKeyDown={(e) => {
                    runOnKeyDown(e);
                  }}
                  onClick={checkAndSearchUserMentionList}
                  maxLength={
                    (props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeText || 
                     props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLink) ? 
                    "4000" : 
                    "100"
                  }
                />

                <div className="emoji-picker-outer-wrapper">
                  <FiSmile
                    className="emoji-toggle-button"
                  />

                  <div className="emoji-picker-main-wrapper">
                    <Picker 
                      onSelect={(e) => onSelectEmoji(e)} 
                      showPreview={false}
                    />
                  </div>
                </div>
            </div>
            {isActiveButtonSend ? 
              <button className="chat-send-wrapper">
                  <img src={Airplane} alt="" />
              </button>
              :
              <button className="chat-send-wrapper" disabled>
                  <img src={Airplane} alt="" />
              </button>
            }
        </form>
    </div>
  );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    participantList: state.participantList,
    editMessage: state.editMessage,
    replyMessage: state.replyMessage,
    forwardMessage: state.forwardMessage,
    linkPreview: state.linkPreview
});

const mapDispatchToProps = {
  setEditMessage,
  clearEditMessage,
  setLinkPreview,
  clearLinkPreview
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomInputMessage);
