import React from 'react';
import './RoomListChatModalMuteOption.scss';
import { Modal, ModalBody } from 'reactstrap';
import { MUTE_OPTION } from "../../../../helper/HelperConst"; 
import HelperChat from "../../../../helper/HelperChat";
import { setMutedRooms } from '../../../../redux/actions/reduxActionMutedRooms';
import { connect } from 'react-redux';

var RoomListChatModalMuteOption = (props) => {
    let submitMute = (val) => {
        let _modalMuteOption = {...props.modalMuteOption};

        if(val) {
            val = new Date().valueOf() + val;
        }

        props.toggle();

        let _mutedRooms = {...props.mutedRooms};

        _mutedRooms[_modalMuteOption.roomIDs[0]] = {
            roomID: _modalMuteOption.roomIDs[0],
            expiredAt: val ? val : 0
        }

        props.setMutedRooms(_mutedRooms);
        
        HelperChat.muteAction(_modalMuteOption.roomIDs, val, {
            onSuccess: (res) => {
            },
            onError: (errCode, errMes) => {
                console.log(errCode, errMes);
            }
        })
    }

    return (
        <Modal className="modal-mute-option" toggle={props.toggle} isOpen={props.modalMuteOption.isShow}>
            <ModalBody>
                <ul>
                    {MUTE_OPTION.map((v) => {
                        return (
                            <li onClick={() => submitMute(v.val)}>
                                <b>{v.label}</b>
                            </li>
                        )
                    })}
                </ul>
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = state => ({
    mutedRooms: state.mutedRooms
});
  
const mapDispatchToProps = {
    setMutedRooms
};
  
export default connect(mapStateToProps, mapDispatchToProps)(RoomListChatModalMuteOption);
  