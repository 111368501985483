import React, { useState, useEffect } from 'react';
import './ChatRoomMessageOutVoice.scss';
import { FaPause, FaPlay } from 'react-icons/fa';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setPlayVoice } from '../../../../redux/actions/reduxActionPlayVoice';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import { tapCoreMessageManager } from '@taptalk.io/web-sdk';
import 'react-circular-progressbar/dist/styles.css';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';
import Helper from '../../../../helper/Helper';
import { connect } from 'react-redux';

const LONG_PRESS = 700;

var ChatRoomMessageOutVoice = (props) => {
    let [voiceOutWaveSurfer, setVoiceOutWaveSurfer] = useState(false);
    let [isPlayVoice, setIsPlayVoice] = useState(false);
    let [printDuration, setPrintDuration] = useState(0);
    let [lastClickBubble, setLastClickBubble] = useState(false);

    let { 
        singleChatDataProps,
        activeUserID,
        status,
        onReplyMessage,
        onForwardMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        onMessageInfo,
        onClickToStarMessage,
        setPlayVoice,
        playVoice,
        forStarredAndForPinned,
        activeRoom,
        multiForward,
        noForward,
        isScheduledMessage,
        sendScheduledMessageNow,
        rescheduleMessage,
        editScheduledMessage,
        deleteScheduledMessage
    } = props;
    
    let delay;

    useEffect(() => {
        if(playVoice.last_play && playVoice.message && (playVoice.message.messageID !== singleChatDataProps.messageID)) {
            onClickPause();
        }
    }, [playVoice])

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }

    useEffect(() => {
        if(!voiceOutWaveSurfer) {
            var wavesurfer = window.WaveSurfer.create({
                container: `#waveform-${forStarredAndForPinned ? "starred-" : ""}${singleChatDataProps.messageID}`,
                waveColor: '#ffffff',
                progressColor: '#ff7d00',
                barWidth: 2,
                barHeight: 5, // the height of the wave
                barGap: 2 ,
                // backend: 'MediaElement',
                hideScrollbar: true,
            });
    
            setVoiceOutWaveSurfer(wavesurfer);
            setPrintDuration(singleChatDataProps.data.duration);
        }
    }, [singleChatDataProps])

    useEffect(() => {
        if(voiceOutWaveSurfer) {
           voiceOutWaveSurfer.load(!singleChatDataProps.data.url ? URL.createObjectURL(singleChatDataProps.data.fileObject) : singleChatDataProps.data.url);
    
            voiceOutWaveSurfer.on("finish", () => {
                setIsPlayVoice(false);
                voiceOutWaveSurfer.seekTo(0);
            })

            voiceOutWaveSurfer.on("seek", (e) => {
                var currentTime = voiceOutWaveSurfer.getCurrentTime();
                setPrintDuration(currentTime.toFixed(1)*1000);
                onClickPause();
            })
        
            voiceOutWaveSurfer.on('audioprocess', function() {
                if(voiceOutWaveSurfer.isPlaying()) {
                var currentTime = voiceOutWaveSurfer.getCurrentTime();
                  setPrintDuration(Math.ceil(currentTime.toFixed(1))*1000);
                }
            });
        }
    }, [voiceOutWaveSurfer])

    let convertTimer = (time) => {
        return new Date(time).toISOString().substr(14, 5);
    }

    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }

    let onClickPlay = async () => {
        if(!forStarredAndForPinned) {
            setPlayVoice({
                last_play: new Date().valueOf(),
                message: singleChatDataProps
            });
            setIsPlayVoice(true);
            voiceOutWaveSurfer.play();
        }

        // document.querySelectorAll(".general-pause-button").map(v => {
        //     v.click();
        //     return null;
        // })
    }

    let onClickPause = async () => {
        setIsPlayVoice(false);

        if(voiceOutWaveSurfer) {
            voiceOutWaveSurfer.pause();
        }
    }

    return (
        <div 
            className="chat-room-message-voice-out-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
            onClick={() => { 
                if(onClickToStarMessage) {
                    onClickToStarMessage(singleChatDataProps.localID);
                }

                if(activeRoom.type === 2) {
                    setLastClickBubble(new Date().valueOf());
                }
            }}    
        >
            {
                (
                    activeRoom &&
                    !singleChatDataProps.isDeleted &&
                    multiForward[activeRoom.roomID] &&
                    Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
                ) &&
                <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
            }

            <div className="message-out-bubble">
                <div className="click-area-voice">
                    {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            
                            <p>
                                From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                            </p>
                        </div>
                    }       
                    
                    {Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyOutMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }
                    
                    <div className="file-inner-wrapper">
                        <div className="file-icon-wrapper">
                            <div className="icon-status-wrapper">
                                <FaPlay className={`general-play-button voice-play-pause-button ${!isPlayVoice ? 'active-voice-play-pause-button' : ''}`} onClick={() => onClickPlay()} />
                                
                                <FaPause className={`general-pause-button voice-play-pause-button ${isPlayVoice ? 'active-voice-play-pause-button' : ''}`} onClick={() => onClickPause()} />
                            </div>
                        </div>

                       
                        <div className="message-bubble-voice-wrapper">
                            <div className="voice-bar-content-wrapper">
                                <div id={`waveform-${forStarredAndForPinned ? "starred-" : ""}${singleChatDataProps.messageID}`} className="waveform-in-voice">
                                    {/* {loadSound()} */}
                                </div>
                            </div>

                            <p className="sound-bar-timer">
                                {convertTimer(printDuration)}
                            </p>
                        </div>
                    </div>

                    <ChatRoomMessageInfoComp 
                        isOut
                        message={singleChatDataProps}
                        lastClickBubble={lastClickBubble}
                    />
                </div>
                
                <ChatRoomMessageActionComp
                     buttonList={
                        isScheduledMessage && !singleChatDataProps.isSending ?
                        [
                            {
                                val: "send",
                                text: "Send Now",
                                action: () => sendScheduledMessageNow(singleChatDataProps),
                            },
                            {
                                val: "reschedule",
                                text: "Reschedule",
                                action: () => rescheduleMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete",
                                action: () => deleteScheduledMessage(singleChatDataProps),
                            },
                        ]
                        :
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete Message",
                                action: () => deleteMessageAction(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                            {
                                val: "messageInfo",
                                text: "Message Info",
                                action: () => onMessageInfo(singleChatDataProps)
                            }
                        ]
                    }
                />

                {status === 'fail' &&
                    <React.Fragment>
                        <br />
                        <b className="failed-sending-voice-warning">Failed to send tap to retry</b>
                    </React.Fragment>
                }
            </div>
            
            {(
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
                <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-out">
                    <input 
                        type="checkbox" 
                        id={`message-${singleChatDataProps.messageID}`} 
                        checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                    />
                    <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    playVoice: state.playVoice,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setPlayVoice,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutVoice);