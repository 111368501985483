import React, { useState, useEffect } from 'react';
import './ChatRoomHeaderStarMessage.scss';
import { connect } from 'react-redux';
import ChatRoomMessageIn from '../../../chatRoomMain/chatRoomMessageIn/ChatRoomMessageIn';
import ChatRoomMessageInFile from '../../../chatRoomMain/chatRoomMessageInFile/ChatRoomMessageInFile';
import ChatRoomMessageInImage from '../../../chatRoomMain/chatRoomMessageInImage/ChatRoomMessageInImage';
import ChatRoomMessageInVideo from '../../../chatRoomMain/chatRoomMessageInVideo/ChatRoomMessageInVideo';
import ChatRoomMessageInLocation from '../../../chatRoomMain/chatRoomMessageInLocation/ChatRoomMessageInLocation';
import ChatRoomMessageInVoice from '../../../chatRoomMain/chatRoomMessageInVoice/ChatRoomMessageInVoice';
import ChatRoomMessageOut from '../../../chatRoomMain/chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutFile from '../../../chatRoomMain/chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from '../../../chatRoomMain/chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from '../../../chatRoomMain/chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutLocation from '../../../chatRoomMain/chatRoomMessageOutLocation/ChatRoomMessageOutLocation';
import ChatRoomMessageOutVoice from '../../../chatRoomMain/chatRoomMessageOutVoice/ChatRoomMessageOutVoice';
import { taptalk, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { IoIosArrowBack } from 'react-icons/io';
import { ModalHeader, ModalBody } from 'reactstrap';
import { CHAT_TYPE } from '../../../../../helper/HelperConst';
import Helper from '../../../../../helper/Helper';
import noStarred from '../../../../../assets/img/chatroom/no-starred.svg';

var ChatRoomHeaderStarMessage = ({
    activeRoom,
    activeRoomConst,
    activeModalRoomInfo,
    setActiveModalRoomInfo,
    onClickToStarMessage,
    style
}) => {
    let [isLoadingChat, setIsLoadingChat] = useState(false);
    let [starMessages, setStarMessages] = useState(null);
    let [tempStarMessages, setTempStarMessages] = useState({
      room: "",
      lastUpdate: false,
      data: false
    })

    useEffect(() => {
      fetchStarred(false);
  }, [])

  useEffect(() => {
    if(activeRoom.roomID === tempStarMessages.room && tempStarMessages.lastUpdate) {
      setStarMessages(tempStarMessages.data);
      setIsLoadingChat(false);
    } 
  }, [tempStarMessages])

  let fetchStarred = (loadMore) => {
    setIsLoadingChat(true);

      tapCoreMessageManager.fetchStarredMessages(activeRoom.roomID, {
        onSuccess: (res) => {
          setTempStarMessages({
            room: activeRoom.roomID,
            lastUpdate: new Date().valueOf(),
            data: res
          })
        },
        onError: (errCode, errMessage) => {
          console.log(errCode, errMessage)
        }
      })
  }

  let isMessageOut = (messageData, activeUser) => {
    let isOut;
    let isSavedMessageRoom = activeRoom.roomID === Helper.savedMessageRoomID();
    
    if(isSavedMessageRoom && Helper.isForwardMessageIgnoreRoom(messageData)) {
      isOut = false;
    }else if(messageData.user.userID !== activeUser){
      isOut = false;
    }else {
      isOut = true;
    }
    
    return isOut;
  }

  let generateMessageBubble = (messageData, index) => {
      let activeUser = taptalk.getTaptalkActiveUser().userID;
      switch(messageData.type) {
        case CHAT_TYPE.TAPChatMessageTypeText:
        case CHAT_TYPE.TAPChatMessageTypeLink:
          return (
            !isMessageOut(messageData, activeUser) ?
              <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onClickToStarMessage={onClickToStarMessage}
                noForward
              />
              :
              <ChatRoomMessageOut 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onClickToStarMessage={onClickToStarMessage}
                noForward
              />
          )
        case CHAT_TYPE.TAPChatMessageTypeImage:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeVideo:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeLocation:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInLocation 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutLocation 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeFile:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInFile 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutFile 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
            )
        case CHAT_TYPE.TAPChatMessageTypeVoice:
            return (
              !isMessageOut(messageData, activeUser) ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageInVoice
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    forStarredAndForPinned
                    noForward
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    noForward
                  />
                  :
                  <ChatRoomMessageOutVoice
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onClickToStarMessage={onClickToStarMessage}
                    forStarredAndForPinned
                    noForward
                  />
            )
        default:
          break;
      }
  }

  let onScrollListener = () => {
    if(!isLoadingChat && (starMessages && starMessages.hasMore)) {
      let el = document.querySelectorAll(".room-modal-star-message")[0];
      let _scrollHeight = el.scrollHeight;
      let _scrollPosition = el.scrollTop + el.clientHeight;
      
      if(_scrollHeight === _scrollPosition) {
        fetchStarred(true);
      }
    }
  }

  return (
      <React.Fragment>
          <ModalHeader className={`member-list-header room-info-member-list ${activeModalRoomInfo === activeRoomConst.starMessage ? "active-room-info-member-list" : ""}`}> 
              <IoIosArrowBack 
                  className="header-room-info-button back-arrow-modal" 
                  onClick={() => {
                      setActiveModalRoomInfo(activeRoomConst.main);
                  }}
              />

              <b className="room-info-title">Starred Messages</b>
          </ModalHeader>

          <ModalBody 
            className={`room-info-member-list ${activeModalRoomInfo === activeRoomConst.starMessage ? "active-room-info-member-list" : ""} room-modal-star-message`}
            onScroll={() => onScrollListener()}
          >
              {starMessages ?
                    starMessages.messages.length === 0 ?
                      <div className="no-starred-message">
                        <img src={noStarred} alt="" className="no-starred-message-img" />

                        <p className="no-starred-title">
                          <b>No Starred Messages</b>
                        </p>

                        <p className="no-starred-desc">
                          Add star to find important text later. 
                        </p>
                      </div>
                      :
                      starMessages.messages.map((value, index) => {
                        if(!value.isHidden) {
                          return (
                              <React.Fragment key={`starred-${index}`}>
                                  {generateMessageBubble(value, index)}

                                  <div className="room-modal-star-message-wrapper">
                                      <div />
                                  </div>
                              </React.Fragment>
                          )
                        }

                        return null;
                      })
                    :
                    ""
              }

              {isLoadingChat &&
                <div className="loading-message-wrapper">
                  <div className="lds-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </div>
              }
          </ModalBody>
      </React.Fragment>
  );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderStarMessage);