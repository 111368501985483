import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomMessageOutVideo.scss';
import { FaPlay } from 'react-icons/fa';
import Helper from '../../../../helper/Helper';
import HelperChat from '../../../../helper/HelperChat';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { IoIosClose } from "react-icons/io";
import { taptalk, tapCoreChatRoomManager, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';

const LONG_PRESS = 700;

var ChatRoomMessageOutVideo = (props) => {
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [bytesDownload, setBytesDownload] = useState(0);
    let [videoSrc, setVideoSrc] = useState('');
    let [isVideoExistInDB, setIsVideoExistInDB] = useState(false);
    let [onDownloadVideoProgress, setOnVideoDownloadProgress] = useState(false);
    let [videoFromUrl, setVideoFromUrl] = useState(false);
    let [isShowModalVideo, setIsShowModalVideo] = useState(false);
    let [lastClickBubble, setLastClickBubble] = useState(false);

    let { 
        activeRoom,
        singleChatDataProps,
        activeUserID,
        onForwardMessage,
        onReplyMessage,
        onEditMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        onMessageInfo,
        mentionList,
        onClickToStarMessage,
        multiForward,
        noForward,
        isScheduledMessage,
        sendScheduledMessageNow,
        rescheduleMessage,
        editScheduledMessage,
        deleteScheduledMessage
    } = props;

    let delay;

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted && !isShowModalVideo) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }

    useEffect(() => {
        let isUnmounted = false;
        let fetchFromDB = () => {
            tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
                if(data) {
                    if(!isUnmounted) {
                        setVideoSrc(data);
                        setIsVideoExistInDB(true);
                    }
                    
                }else {
                    if(!isUnmounted) {
                        setVideoSrc(singleChatDataProps.data.thumbnail);
                        setIsVideoExistInDB(false);
                    }
                }
            })
        }

        if(singleChatDataProps.data.url) {
            setVideoFromUrl(true);
            setVideoSrc(singleChatDataProps.data.url);
        }else if(singleChatDataProps.data.fileURL) {
            if(singleChatDataProps.data.fileURL !== "") {
                setVideoFromUrl(true);
                setVideoSrc(singleChatDataProps.data.fileURL);
            }else {
                setVideoFromUrl(false);
                fetchFromDB();
            }
        }else {
            fetchFromDB();
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps]);
    
    let downloadFile = () => {
        setOnVideoDownloadProgress(true);
        
        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnVideoDownloadProgress(false);
                getVideoBase64();
                setIsVideoExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
                setBytesDownload(HelperChat.bytesToSize(bytes));
            },

            onError: (errorCode, errorMessage) => {
                setOnVideoDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    let getVideoBase64 = () => {
        tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
            if(data) {
                setVideoSrc(data);
                setIsVideoExistInDB(true);
            }else {
                setVideoSrc(singleChatDataProps.data.thumbnail);
                setIsVideoExistInDB(false);
            }
        })
    }

    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }

    let toggleModalVideo = () => {
        if(isVideoExistInDB || videoFromUrl) {
            setIsShowModalVideo(!isShowModalVideo);
        }
    }
    
    let generateModalVideo = () => {
        return (
            <div>
              <Modal isOpen={isShowModalVideo} className={'modal-video'}>
                <ModalBody>
                    <div className="header-modal-preview">
                        <div className="message-info-wrapper">
                            {singleChatDataProps.user.imageURL.thumbnail === "" ?
                                <div className="user-avatar-name" 
                                    style={{background: taptalk.getRandomColor(singleChatDataProps.user.fullname, singleChatDataProps.room.type === 2)}}
                                >
                                    <b>{Helper.renderUserAvatarWord(singleChatDataProps.user.fullname, singleChatDataProps.room.type === 2)}</b>
                                </div>
                                :
                                <img className="user-avatar-image" src={singleChatDataProps.user.imageURL.thumbnail} alt="" />
                            }
                            <p className="fullname-text">
                                <b>{singleChatDataProps.user.fullname}</b>
                            </p>

                            <p className="timestamp-text">
                                <b>Sent <span className="centered-dot" /> {HelperChat.getDateMonthYear(singleChatDataProps.created)} {HelperChat.getHourMinute(singleChatDataProps.created)}</b>
                            </p>
                        </div>

                        <div className="modal-preview-action-button-wrapper">
                            <a className="modal-preview-action-button" href={!videoFromUrl ? `data:${videoSrc.type};base64, ${videoSrc.file}` : videoSrc} download={`taptalk_video__${new Date().valueOf()}`}>
                                <FiDownload />
                                <b>Download</b>
                            </a>
                        </div>

                        <div className="close-modal-preview-wrapper">
                            <IoIosClose className="close-modal-image" onClick={() => toggleModalVideo()} />
                        </div>
                    </div>

                    <div className="video-wrapper">
                        {videoFromUrl ?
                            <video controls>
                                <source src={videoSrc} type="video/mp4" />
                                <source src={videoSrc} type="video/ogg" />
                            </video>
                            :
                            <video controls>
                                <source src={`data:${videoSrc.type};base64, ${videoSrc.file}`} type="video/mp4" />
                                <source src={`data:${videoSrc.type}, ${videoSrc.file}`} type="video/ogg" />
                            </video>
                        }
                    </div>
                    
                </ModalBody>
              </Modal>
            </div>
        );
    }

    // let deleteMessageAction = (message) => {
    //     let messageIDs = [];
    //     messageIDs.push(message.messageID);
    //     tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    // }

    //   let renderChatStatus = (message) => {
    //     let messageStatus;
        
    //     if(message.isSending) {
    //         messageStatus = "sending";
    //     }

    //     if(!message.isSending && message.isDelivered) {
    //         messageStatus = "sent";
    //     }

    //     if(!message.isSending && message.isDelivered && !message.isRead) {
    //         messageStatus = "receive";
    //     }

    //     if(message.isRead) {
    //         messageStatus = "read";
    //     }

    //     if(!message.isDelivered) {
    //         messageStatus = "not delivered";
    //     }
        
    //     switch(messageStatus) {
    //         case "sending":
    //             return AirplaneDark;
    //         case "sent":
    //             return CheckMarkDark;
    //         case "receive":
    //             return CheckMarkDoubleDark;
    //         case "read":
    //             return CheckMarkDoubleWhite;
    //         case "not delivered":
    //             return CheckMarkDark;
    //     }
    //   }
    
    return (
        <div 
            className="chat-room-message-video-out-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
            onClick={() => { 
                if(onClickToStarMessage) {
                    onClickToStarMessage(singleChatDataProps.localID);
                }

                if(activeRoom.type === 2) {
                    setLastClickBubble(new Date().valueOf());
                }
            }}    
        >
            {
                (
                    activeRoom &&
                    !singleChatDataProps.isDeleted &&
                    multiForward[activeRoom.roomID] &&
                    Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
                ) &&
                <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
            }
            
            <div className={`message-out-bubble ${props.status !== 'uploaded' ? 'not-sent-message-bubble' : ''}`}
                // style={
                //     Helper.isReplyMessage(singleChatDataProps) ? 
                //         (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
                //             {paddingTop: "60px"} 
                //             :
                //             {paddingTop: "80px"}
                //         )
                //         : 
                //         {}
                // }
            >
                 {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        
                        <p>
                            From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                        </p>
                    </div>
                }
                {/* {Helper.isReplyMessage(singleChatDataProps) &&
                    <div className="reply-message reply-file">
                        {singleChatDataProps.quote.fileType !== "" &&  
                            <div className="reply-file-thumbnail">
                                {singleChatDataProps.quote.fileType === "file" ? 
                                    <MdInsertDriveFile />
                                    :
                                    <img src={''} alt="" />
                                }
                            </div>
                        }
    
                        <div className="reply-text-wrapper">
                            <p className="reply-from">
                                <b> 
                                    {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                        "You"
                                        :
                                        singleChatDataProps.replyTo.fullname
                                    }
                                </b>
                            </p>
                            <p className="reply-text">{singleChatDataProps.quote.content}</p>
                        </div>
                    </div>
                } */}

                {/* ${singleChatDataProps.data.caption !== "" && Helper.isReplyMessage(singleChatDataProps) ? "with-reply-caption" : ""}
                ${singleChatDataProps.data.caption === "" && Helper.isReplyMessage(singleChatDataProps) ? "with-reply-no-caption" : ""}
                ${singleChatDataProps.data.caption === "" ? "bubble-wrapper-without-caption" : ""} */}
                <div 
                    className={`
                        bubble-image-wrapper 
                        ${singleChatDataProps.data.caption !== "" ? "bubble-wrapper-with-caption" : ""}
                    `}
                >
                {   Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyOutMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    <div className={`video-inner-wrapper`}>
                        {!videoFromUrl &&
                            <span className="timer-filesize-wrapper">
                                {(singleChatDataProps.percentageUpload === undefined ?
                                    isVideoExistInDB ?
                                            HelperChat.msToTime(singleChatDataProps.data.duration)
                                            :
                                            !onDownloadVideoProgress ?
                                                HelperChat.bytesToSize(singleChatDataProps.data.size) + " - " + HelperChat.msToTime(singleChatDataProps.data.duration)
                                                :
                                                bytesDownload + " / " + HelperChat.bytesToSize(singleChatDataProps.data.size)
                                    :
                                    HelperChat.bytesToSize(singleChatDataProps.bytesUpload) + " / " + HelperChat.bytesToSize(singleChatDataProps.data.size)
                                )}
                            </span>
                        }
                        
                        {videoFromUrl ?
                            <video src={videoSrc} className="video-thumbnail" />
                            :
                            isVideoExistInDB ?
                                <video src={`data:${videoSrc.type};base64, ${videoSrc.file}`} className="video-thumbnail" />
                                :
                                <img src={'data:image/png;base64, '+singleChatDataProps.data.thumbnail} alt="" className="main-image-message" />
                        }

                        {((!isVideoExistInDB && !onDownloadVideoProgress && !videoFromUrl) && singleChatDataProps.percentageUpload === undefined) &&
                            <div className="icon-status-wrapper">
                                <FiDownload onClick={() => downloadFile()} />
                            </div>
                        }
                            
                        {onDownloadVideoProgress && 
                            <div className="icon-status-wrapper">
                                <div className="onprocess-wrapper">
                                    <CircularProgressbar value={percentageDownload} />
                                    {/* <MdClose onClick={() => console.log('cancel download')} /> */}
                                    <FiDownload />
                                </div>
                            </div>
                        }

                        {singleChatDataProps.bytesUpload !== undefined && 
                            <div className="icon-status-wrapper">
                                <div className="onprocess-wrapper">
                                    <CircularProgressbar value={singleChatDataProps.percentageUpload} />
                                    {/* <MdClose onClick={() => console.log('cancel upload')} /> */}
                                    <FiUpload />
                                </div>
                            </div>
                        }

                        {(isVideoExistInDB || videoFromUrl) &&
                            <div className="icon-status-wrapper">
                                <FaPlay onClick={() => toggleModalVideo()} />
                            </div>
                        }
                    </div>
                </div>
                
                {singleChatDataProps.data.caption !== '' && 
                    <p 
                        className="caption-text" 
                        dangerouslySetInnerHTML={{
                            __html: mentionList ?
                                HelperChat.generateMentionSpan(HelperChat.lineBreakChatRoom(singleChatDataProps.data.caption), mentionList)
                                :
                                HelperChat.lineBreakChatRoom(singleChatDataProps.data.caption)
                        }} 
                    />
                }

                <ChatRoomMessageInfoComp 
                    message={singleChatDataProps}
                    className={singleChatDataProps.data.caption === '' ? "message-info-dark message-info-in-media" : "message-info-in-media"}
                    isOut
                    isWhiteIcon
                    lastClickBubble={lastClickBubble}
                />

                <ChatRoomMessageActionComp
                    buttonList={
                        isScheduledMessage && !singleChatDataProps.isSending ?
                        [
                            {
                                val: "send",
                                text: "Send Now",
                                action: () => sendScheduledMessageNow(singleChatDataProps),
                            },
                            {
                                val: "reschedule",
                                text: "Reschedule",
                                action: () => rescheduleMessage(singleChatDataProps),
                            },
                            {
                                val: "edit",
                                text: "Edit",
                                action: () => editScheduledMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete",
                                action: () => deleteScheduledMessage(singleChatDataProps),
                            },
                        ]
                        :
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete Message",
                                action: () => deleteMessageAction(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: "edit",
                                text: "Edit",
                                action: () => onEditMessage(singleChatDataProps),
                                message: singleChatDataProps
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                            {
                                val: "messageInfo",
                                text: "Message Info",
                                action: () => onMessageInfo(singleChatDataProps)
                            }
                        ]
                    }
                />
            </div>

            {(isVideoExistInDB || videoFromUrl) && generateModalVideo()}

            {(
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
                <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-out">
                    <input 
                        type="checkbox" 
                        id={`message-${singleChatDataProps.messageID}`} 
                        checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                    />
                    <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutVideo);
