import React from 'react';
import './ChatRoomHeaderSharedMediaMonthlyList.scss';
import { connect } from 'react-redux';
import { CHAT_TYPE } from '../../../../../../helper/HelperConst';
import ChatRoomHeaderInfoImage from '../../chatRoomHeaderInfoImage/ChatRoomHeaderInfoImage';
import ChatRoomHeaderInfoVideo from '../../chatRoomHeaderInfoVideo/ChatRoomHeaderInfoVideo';
import ChatRoomHeaderInfoLink from '../../chatRoomHeaderInfoLink/ChatRoomHeaderInfoLink';
import ChatRoomHeaderInfoDocument from '../../chatRoomHeaderInfoDocument/ChatRoomHeaderInfoDocument';

var ChatRoomHeaderSharedMediaMonthlyList = ({
    monthString,
    activeSharedMediaTab,
    sharedMediaItems,
    toggleModalFile,
    sharedMediaTab,
    onClickToMessage
}) => {

    return (
        <React.Fragment>
            <div className="shared-media-monthly-item-list-container">
                <div className="shared-media-month-separator-container">
                    <b className="shared-media-month-separator-label">{monthString}</b>
                </div>
                <div className="shared-media-month-separator-line"></div>
                <div className={ 
                    activeSharedMediaTab === sharedMediaTab.media ? 
                    "shared-media-monthly-item-list-wrapper" :
                    ""
                }>
                    {
                        sharedMediaItems !== null &&
                        Object.keys(sharedMediaItems).map((value, index) => {
                            return (
                                !sharedMediaItems[value].isDeleted && 
                                    <React.Fragment key={`shared-media-${index}`}>
                                        {activeSharedMediaTab === sharedMediaTab.media && 
                                         sharedMediaItems[value].type === CHAT_TYPE.TAPChatMessageTypeImage &&
                                            <ChatRoomHeaderInfoImage 
                                                mediaData={sharedMediaItems[value]}
                                                toggleModalFileProps={toggleModalFile} 
                                                onClickToMessage={onClickToMessage}
                                            />
                                        }

                                        {activeSharedMediaTab === sharedMediaTab.media && 
                                         sharedMediaItems[value].type === CHAT_TYPE.TAPChatMessageTypeVideo &&
                                            <ChatRoomHeaderInfoVideo 
                                                mediaData={sharedMediaItems[value]}
                                                toggleModalFileProps={toggleModalFile}
                                                onClickToMessage={onClickToMessage}
                                            />
                                        }
                                        
                                        {activeSharedMediaTab === sharedMediaTab.links && 
                                         sharedMediaItems[value].type === CHAT_TYPE.TAPChatMessageTypeLink &&
                                            <ChatRoomHeaderInfoLink 
                                                mediaData={sharedMediaItems[value]}
                                                onClickToMessage={onClickToMessage}
                                            />
                                        }
                                        
                                        {activeSharedMediaTab === sharedMediaTab.documents && 
                                         sharedMediaItems[value].type === CHAT_TYPE.TAPChatMessageTypeFile &&
                                            <ChatRoomHeaderInfoDocument 
                                                mediaData={sharedMediaItems[value]}
                                                onClickToMessage={onClickToMessage}
                                            />
                                        }
                                    </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderSharedMediaMonthlyList);
