import React from "react";
import "./ChatRoomInputMultipleForward.scss";
import { connect } from "react-redux";
import { GoArrowRight } from "react-icons/go";
import { setMultiForward } from '../../../../../redux/actions/reduxActionMultiForward';
import { setForwardMessage } from '../../../../../redux/actions/reduxActionForwardMessage';
import { clearReplyMessage } from '../../../../../redux/actions/reduxActionReplyMessage';
import HelperChat from '../../../../../helper/HelperChat';
 
let ChatRoomInputMultipleForward = (props) => {
    let { 
        activeRoom, 
        setMultiForward, 
        multiForward,
        clearReplyMessage,
        setForwardMessage
    } = props;

    let onCancelMultiForward = async () => {
        let _multiForward = {...multiForward};
        delete _multiForward[activeRoom.roomID];
        setMultiForward(_multiForward);
    }

    let onSubmitForward = async () => {
        let _multiForward = {...multiForward};
        HelperChat.resetChatRoomHeightAndInputText();
        clearReplyMessage(); 

        if(Object.keys(_multiForward[activeRoom.roomID]).length === 1) {
            let _forwardMessage = {
                setHeightChatRoom: false,
                target: false,
                message: _multiForward[activeRoom.roomID][Object.keys(_multiForward[activeRoom.roomID])[0]]
            };
    
            setForwardMessage(_forwardMessage);
        }else {
            let _forwardMessage = {
                setHeightChatRoom: false,
                target: false,
                message: {
                    multipleMessages: _multiForward[activeRoom.roomID]
                }
            };

            setForwardMessage(_forwardMessage);
        }

        delete _multiForward[activeRoom.roomID];
        setMultiForward(_multiForward);
    }

    return (
        <div className="multiple-forward-input-wrapper">
            <b className="cancel-multiple-forward" onClick={onCancelMultiForward}>
                Cancel
            </b>
            
            <p className="selected-forward-counter">
                {multiForward[activeRoom.roomID] ? Object.keys(multiForward[activeRoom.roomID]).length : "0"}/30 Selected
            </p>

            <GoArrowRight className="icon-submit-multiple-forward" onClick={onSubmitForward} />
        </div>
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
    setMultiForward,
    clearReplyMessage,
    setForwardMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomInputMultipleForward);