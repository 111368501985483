import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "./ProfileCarousel.scss";

const ProfileCarousel = ({ children, total, setCurrentIndex = () => {}, currentIndex = 0 }) => {
  useEffect(() => {
    if(total > 1) {
      let ele = document.querySelector(".carousel.carousel-slider");
      ele.style.top = "3px";
    } else {
      let ele = document.querySelector(".carousel.carousel-slider");
      ele.style.top = "0px";
    }
  }, [total])
  return (
    <div className="profile-carousel-wrapper">
      <Carousel
        autoPlay={false}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={true}
        showIndicators={total > 1}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        onChange={(idx) => setCurrentIndex(idx)}
        selectedItem={currentIndex}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default ProfileCarousel;
