const INITIAL_STATE = {
    time: false,
    localID: false
};

let reduxReducerLastClickStarMessage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_LAST_CLICK_STAR_MESSAGE':
        return action.lastClickStarMessage;
      case 'CLEAR_ACTIVE_LAST_CLICK_STAR_MESSAGE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerLastClickStarMessage;