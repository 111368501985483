const INITIAL_STATE = {
  message: false,
  setHeightChatRoom: false,
  roomID: "",
  lastEdit: false
};

let reducerEditMessage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_EDIT_MESSAGE':
        return action.editMessage;
      case 'CLEAR_EDIT_MESSAGE':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reducerEditMessage;