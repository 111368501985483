import React from 'react';
import './PopupConfirmation.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithLoadingOrIcon from '../buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';

let PopupConfirmation = (props) => {
  let {
    isOpen,
    toggle,
    className,
    title,
    titleImage,
    description,
    children,
    cancelButtonText,
    cancelButtonStyle,
    singleButton,
  } = props;

  return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={`modal-confirmation ${className}`}
      >
        <ModalBody>
          {title && <b className="confirmation-title">{titleImage} {title}</b>}
          {description && <p className="confirmation-description">{description}</p>}
        </ModalBody>

        <ModalFooter>
            <ButtonWithLoadingOrIcon 
              text={cancelButtonText ? cancelButtonText : "Cancel"}
              className={(cancelButtonStyle ? cancelButtonStyle : "no-fill-button") + (singleButton ? " single-button" : " cancel-button") + " main-button-48"}
              onClickAction={toggle}
            />

          {children}
        </ModalFooter>
      </Modal>
  )
}

export default PopupConfirmation;
