import React, { useEffect, useState } from 'react';
import "./ChatRoomEditMessage.scss";
import { taptalk } from "@taptalk.io/web-sdk";
import { connect } from 'react-redux';
import { FiX, FiFile } from 'react-icons/fi';
import { setEditMessage, clearEditMessage } from '../../../../../redux/actions/reduxActionEditMessage';
import { CHAT_TYPE } from '../../../../../helper/HelperConst';
import HelperChat from '../../../../../helper/HelperChat';

let ChatRoomEditMessage = (props) => {
    let [myAccountData, setMyAccountAccountData] = useState(false);

    useEffect(() => {
        if(props.editMessage.message) {  
            let myAccountData = taptalk.getTaptalkActiveUser();
            setMyAccountAccountData(myAccountData)
        }else {
            setMyAccountAccountData(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editMessage])

    return (
        myAccountData &&
            <div className="chat-room-edit-message-wrapper">
                <div className="chat-room-edit-message-wrapper-inner">
                    <FiX 
                        className="cancel-edit-button" 
                        onClick={() => {
                            // HelperChat.resetHeightClearReply(props.editMessage);
                            HelperChat.resetChatRoomHeightAndInputText();
                            props.clearEditMessage(); 
                        }} 
                    />

                    <div className={`edit-content-outer-wrapper 
                                     ${(
                                         props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeText ||
                                         props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLink ||
                                         props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLocation ||
                                         props.editMessage.message.type === CHAT_TYPE.MessageTypeBroadcastText ||
                                         props.editMessage.message.type === CHAT_TYPE.MessageTypeWhatsAppBATemplateText ||
                                         props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVoice
                                         
                                       ) ? 
                                        "with-border" 
                                        : 
                                        ""
                                     }
                    `}>
                        {(
                            props.editMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeText &&
                            props.editMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeLink &&
                            props.editMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeLocation &&
                            props.editMessage.message.type !== CHAT_TYPE.MessageTypeBroadcastText &&
                            props.editMessage.message.type !== CHAT_TYPE.MessageTypeWhatsAppBATemplateText &&
                            props.editMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeVoice   
                        ) &&
                            <div className="edit-file-media-wrapper">
                                {props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeImage &&
                                    <img 
                                        src={props.editMessage.message.data.url ? props.editMessage.message.data.url: props.editMessage.message.data.fileURL}
                                        alt="edit" 
                                        className="edit-message-image"
                                    />
                                }

                                {props.editMessage.message.type === CHAT_TYPE.MessageTypeBroadcastImage &&
                                    <img 
                                        src={props.editMessage.message.data.url ? props.editMessage.message.data.url: props.editMessage.message.data.fileURL}
                                        alt="edit" 
                                        className="edit-message-image"
                                    />
                                }

                                {props.editMessage.message.type === CHAT_TYPE.MessageTypeWhatsAppBATemplateImage &&
                                    <img 
                                        src={props.editMessage.message.data.url ? props.editMessage.message.data.url: props.editMessage.message.data.fileURL}
                                        alt="edit" 
                                        className="edit-message-image"
                                    />
                                }

                                {props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVideo &&
                                    <video
                                        src={props.editMessage.message.data.url ? props.editMessage.message.data.url : props.editMessage.message.data.fileURL}
                                        className="edit-message-video"
                                    />
                                }

                                {props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile &&
                                    <div className="edit-message-file">
                                        <FiFile />
                                    </div>
                                }
                            </div>
                        }
                        
                        <div className="edit-name-text-wrapper with-image-or-video-or-file">
                            <p className="edit-name">
                                <b>Edit Message</b>
                            </p>

                            <p className="edit-text">
                                {props.editMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile ?
                                    `${HelperChat.bytesToSize(props.editMessage.message.data.size)} ${props.editMessage.message.data.fileName.split(".")[props.editMessage.message.data.fileName.split(".").length - 1].toUpperCase()}`
                                    :
                                    props.editMessage.message.body
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = state => ({
    editMessage: state.editMessage,
    activeRoom: state.activeRoom
});

const mapDispatchToProps = {
    setEditMessage,
    clearEditMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomEditMessage);