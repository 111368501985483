import React, { useEffect, useState } from 'react';
import "./ChatRoomForwardMessage.scss";
import { taptalk } from "@taptalk.io/web-sdk";
import { connect } from 'react-redux';
import { FiX, FiFile } from 'react-icons/fi';
import { setForwardMessage, clearForwardMessage } from '../../../../../redux/actions/reduxActionForwardMessage';
import HelperChat from '../../../../../helper/HelperChat';
import { CHAT_TYPE } from '../../../../../helper/HelperConst';

let ChatRoomForwardMessage = (props) => {
    let [myAccountData, setMyAccountAccountData] = useState(false);

    useEffect(() => {
        if(props.forwardMessage.message) {  
            let myAccountData = taptalk.getTaptalkActiveUser();
            setMyAccountAccountData(myAccountData)
        }else {
            setMyAccountAccountData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.forwardMessage])

    let printForweardFrom = () => {
        let _mes = props.forwardMessage.message.multipleMessages;
        let forwardFrom = {};
        let from = "";

        Object.keys(_mes).map((v, i) => {
            if(_mes[v].forwardFrom.fullname !== "" && !forwardFrom[_mes[v].forwardFrom.fullname]) {
                from += `${(i !== 0) ? ", " : ""}${_mes[v].forwardFrom.fullname}`;
                forwardFrom[_mes[v].forwardFrom.fullname] = true;
            }

            if(!forwardFrom[_mes[v].user.fullname]) {
                from += `${(i !== 0) ? ", " : ""}${_mes[v].user.fullname}`;
                forwardFrom[_mes[v].user.fullname] = true;
            }

            return null;
        })

        return from;
    }


    return (
        myAccountData &&
            <div className="chat-room-forward-message-wrapper">
                <div className="chat-room-forward-message-wrapper-inner">
                    <FiX 
                        className="cancel-forward-button" 
                        onClick={() => {
                            // HelperChat.resetHeightClearReply(props.forwardMessage);
                            HelperChat.resetChatRoomHeightAndInputText();
                            props.clearForwardMessage();
                        }} 
                    />

                    <div className={
                        `forward-content-outer-wrapper 
                        ${(
                            props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeText ||
                            props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLink ||
                            props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeLocation ||
                            props.forwardMessage.message.type === CHAT_TYPE.MessageTypeBroadcastText ||
                            props.forwardMessage.message.type === CHAT_TYPE.MessageTypeWhatsAppBATemplateText ||
                            props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVoice
                        ) ? 
                        "with-border" 
                        : 
                        ""
                        }
                    `}>
                        {props.forwardMessage.message.multipleMessages ?
                            <div className="forward-name-text-wrapper with-image-or-video-or-file">
                                <p className="forward-name">
                                    <b>Forward {Object.keys(props.forwardMessage.message.multipleMessages).length} Messages</b>
                                </p>

                                <p className="forward-text">
                                    From: {printForweardFrom()}
                                </p>
                            </div>
                            :
                            <>
                                {
                                    (
                                        props.forwardMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeText &&
                                        props.forwardMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeLink &&
                                        props.forwardMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeLocation &&
                                        props.forwardMessage.message.type !== CHAT_TYPE.MessageTypeBroadcastText &&
                                        props.forwardMessage.message.type !== CHAT_TYPE.MessageTypeWhatsAppBATemplateText &&
                                        props.forwardMessage.message.type !== CHAT_TYPE.TAPChatMessageTypeVoice
                                    ) &&
                                        <div className="forward-file-media-wrapper">
                                            {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeImage &&
                                                <img 
                                                    src={props.forwardMessage.message.data.url ? props.forwardMessage.message.data.url: props.forwardMessage.message.data.fileURL}
                                                    alt="reply" 
                                                    className="forward-message-image"
                                                />
                                            }

                                            {props.forwardMessage.message.type === CHAT_TYPE.MessageTypeBroadcastImage &&
                                                <img 
                                                    src={props.forwardMessage.message.data.url ? props.forwardMessage.message.data.url: props.forwardMessage.message.data.fileURL}
                                                    alt="reply" 
                                                    className="forward-message-image"
                                                />
                                            }

                                            {props.forwardMessage.message.type === CHAT_TYPE.MessageTypeWhatsAppBATemplateImage &&
                                                <img 
                                                    src={props.forwardMessage.message.data.url ? props.forwardMessage.message.data.url: props.forwardMessage.message.data.fileURL}
                                                    alt="reply" 
                                                    className="forward-message-image"
                                                />
                                            }

                                            {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeVideo &&
                                                <video
                                                    src={props.forwardMessage.message.data.url ? props.forwardMessage.message.data.url : props.forwardMessage.message.data.fileURL}
                                                    className="forward-message-video"
                                                />
                                            }

                                            {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile &&
                                                <div className="forward-message-file">
                                                    <FiFile />
                                                </div>
                                            }
                                        </div>
                                }

                                <div className="forward-name-text-wrapper with-image-or-video-or-file">
                                    <p className="forward-name">
                                        <b>
                                            {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile ?
                                                `${props.forwardMessage.message.data.fileName.split(".")[0]}`
                                                :
                                                (props.forwardMessage.message.forwardFrom.fullname !== "" ?
                                                    props.forwardMessage.message.forwardFrom.fullname
                                                    :
                                                    (props.forwardMessage.message.user.userID === myAccountData.userID ?
                                                        "You"
                                                        :
                                                        props.forwardMessage.message.user.fullname
                                                    )
                                                )
                                            }
                                        </b>
                                    </p>

                                    <p className="forward-text">
                                        {props.forwardMessage.message.type === CHAT_TYPE.TAPChatMessageTypeFile ?
                                            `${HelperChat.bytesToSize(props.forwardMessage.message.data.size)} ${props.forwardMessage.message.data.fileName.split(".")[props.forwardMessage.message.data.fileName.split(".").length - 1].toUpperCase()}`
                                            :
                                            props.forwardMessage.message.body
                                        }
                                    </p>
                                </div>
                            </>
                        }
                        
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = state => ({
    forwardMessage: state.forwardMessage,
    activeRoom: state.activeRoom
});

const mapDispatchToProps = {
    setForwardMessage,
    clearForwardMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomForwardMessage);