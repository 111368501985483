const INITIAL_STATE = false;

let reduxReducerMutedRooms = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_MUTED_ROOMS':
        return action.mutedRooms;
      case 'CLEAR_MUTED_ROOMS':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerMutedRooms;