import React from 'react';
import './ChatRoomHeaderSharedMediaTabButton.scss';
import { connect } from 'react-redux';

var ChatRoomHeaderSharedMediaTabButton = ({
    buttonText,
    tabIndex,
    activeSharedMediaTab,
    onSharedMediaTabButtonClicked
}) => {

    return (
        <React.Fragment>
            <div 
                className="shared-media-tab-button-container" 
                onClick={onSharedMediaTabButtonClicked}
            >
                <b 
                    className="shared-media-tab-button-label"
                    style={ activeSharedMediaTab === tabIndex ? 
                        { fontWeight: 700 } : 
                        { fontWeight: 500 } 
                    }
                >
                    {buttonText}
                </b>
                <div 
                    className="shared-media-tab-active-indicator"
                    style={ activeSharedMediaTab === tabIndex ? 
                        { display: "block" } : 
                        { display: "none" } 
                    }
                >
                </div>
            </div>
        </React.Fragment>    
    );
}

const mapStateToProps = () => ({
    
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomHeaderSharedMediaTabButton);
