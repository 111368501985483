import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomMessageInImage.scss';
import iconDelete from "../../../../assets/img/delete-account-icon.svg";
import { FiDownload } from 'react-icons/fi';
import { IoIosClose } from "react-icons/io";
import Helper from '../../../../helper/Helper';
import HelperChat from '../../../../helper/HelperChat';
import { taptalk, tapCoreChatRoomManager, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setUserClick } from '../../../../redux/actions/reduxActionUserClick';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import ChatRoomMessageActionComp from '../chatRoomMessageActionComp/ChatRoomMessageActionComp';
import { connect } from 'react-redux';

const LONG_PRESS = 700;

var ChatRoomMessageInImage = (props) => {
    let [lastClickBubble, setLastClickBubble] = useState(false);
    let [percentageDownload, setPercentageDownload] = useState(0);
    let [imageSrc, setImageSrc] = useState('');
    let [isImageExistInDB, setIsImageExistInDB] = useState(false);
    let [onDownloadImageProgress, setOnImageDownloadProgress] = useState(false);
    let [imageFromUrl, setImageFromUrl] = useState(false);
    let [isShowModalImage, setIsShowModalImage] = useState(false);
    let { 
        activeRoom,
        singleChatDataProps,
        activeUserID,
        onForwardMessage,
        onReplyMessage,
        onStarredMessage,
        onUnstarredMessage,
        onPinnedMessage,
        onUnpinnedMessage,
        onReportMessage,
        mentionList,
        // isBubbleOnViewPort,
        onClickToStarMessage,
        multiForward,
        noForward
    } = props;

    let delay;

    let onTouchStartEvent = () => {
        if(!singleChatDataProps.isDeleted && !isShowModalImage) {
            delay = setTimeout(() => {
                props.setActiveMessage(singleChatDataProps)
            }, LONG_PRESS)
        }
    }

    let onTouchEndEvent = () => {
        clearTimeout(delay);
    }
    
    // useEffect(() => {
    //     let el = document.querySelectorAll('.chat-room-main-content')[0];
    //     let element = document.querySelector(`#message-${singleChatDataProps.localID}`);
        
    //     let domRect = element.getBoundingClientRect();

    //     let logit = async () => {
    //         if(!singleChatDataProps.isRead && 
    //         (domRect.y > 0 || domRect.y < window.innerHeight) && 
    //         singleChatDataProps.user.userID !== taptalk.getTaptalkActiveUser().userID
    //         ) {
    //             isBubbleOnViewPort(singleChatDataProps);
    //         }
    //     }

    //     let watchScroll = () => {
    //         el.addEventListener("scroll", logit);        
    //     }

    //     watchScroll();

    //     return () => {
    //         el.removeEventListener("scroll", logit);
    //     };
    // });

    useEffect(() => {
        let isUnmounted = false;
        let fetchFromDB = () => {
            tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
                if(data) {
                    if(!isUnmounted) {
                        setImageSrc(data.file);
                        setIsImageExistInDB(true);
                    }
                    
                }else {
                    if(!isUnmounted) {
                        setImageSrc(singleChatDataProps.data.thumbnail);
                        setIsImageExistInDB(false);
                    }
                }
            })
        }

        if(singleChatDataProps.data.url) {
            setImageFromUrl(true);
            setImageSrc(singleChatDataProps.data.url);
        }else if(singleChatDataProps.data.fileURL) {
            if(singleChatDataProps.data.fileURL !== "") {
                setImageFromUrl(true);
                setImageSrc(singleChatDataProps.data.fileURL);
            }else {
                setImageFromUrl(false);
                fetchFromDB();
            }
        }else {
            fetchFromDB();
        }

        return () => {
            isUnmounted = true;
        }
    }, [singleChatDataProps])

    let downloadFile = () => {
        setOnImageDownloadProgress(true);

        tapCoreChatRoomManager.downloadMessageFile(singleChatDataProps, {
            onSuccess: (data) => {
                setOnImageDownloadProgress(false);
                setImageSrc(data.base64);
                setIsImageExistInDB(true);
            },

            onProgress: (message, percentage, bytes) => {
                setPercentageDownload(percentage);
            },

            onError: (errorCode, errorMessage) => {
                setOnImageDownloadProgress(false);
                console.log(errorCode, errorMessage);
            }
        })
    }

    //   let getImageBase64 = () => {
    //     tapCoreChatRoomManager.getFileFromDB(singleChatDataProps.data.fileID, function(data) {
    //         if(data) {
    //             setImageSrc(data.file);
    //             setIsImageExistInDB(true);
    //         }else {
    //             setImageSrc(singleChatDataProps.data.thumbnail);
    //             setIsImageExistInDB(false);
    //         }
    //     })
    //   }

    let toggleModalImage = () => {
        if(isImageExistInDB || imageFromUrl) {
            setIsShowModalImage(!isShowModalImage);
        }
    }

    let generateModalImage = () => {
            let zoomImage = (e) => {
                var zoomer = e.currentTarget;
                zoomer.style.backgroundSize = '200%';
                var offsetX = e.nativeEvent.offsetX;
                var offsetY = e.nativeEvent.offsetY;
                
                var x = offsetX/zoomer.offsetWidth*100
                var y = offsetY/zoomer.offsetHeight*100
                zoomer.style.backgroundPosition = x + '% ' + y + '%';
            }

            let zoomImageOut = (e) => {
                var zoomer = e.currentTarget;
                zoomer.style.backgroundSize = '0%';
            }

            return (
                 <div>
                    <Modal isOpen={isShowModalImage} className={'modal-image'}>
                        <ModalBody>
                            <div className="header-modal-preview">
                                <div className="message-info-wrapper">
                                    {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                                        <div 
                                            className="user-avatar-name" 
                                            style={{background: taptalk.getRandomColor(Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname)}} 
                                            onClick={() => props.setUserClick({userID: singleChatDataProps.user.userID, username: singleChatDataProps.user.username})}
                                        >
                                            {(singleChatDataProps.user.deleted && singleChatDataProps.user.deleted !== 0) ?
                                                <img className="user-avatar-image" src={iconDelete} alt="" />
                                                :
                                                (Helper.isSavedMessageRoom(activeRoom.roomID) ?
                                                    <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.forwardFrom.fullname)}</b>
                                                    :
                                                    (singleChatDataProps.user.imageURL.thumbnail !== "" ? 
                                                        <img className="user-avatar-image" src={singleChatDataProps.user.imageURL.thumbnail} alt="" />
                                                        :
                                                        <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                                                    )   
                                                )
                                            }
                                        </div>
                                    }

                                    <p className="fullname-text">
                                        <b>{Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname}</b>
                                    </p>

                                    <p className="timestamp-text">
                                        <b>Sent <span className="centered-dot" /> {HelperChat.getDateMonthYear(singleChatDataProps.created)} {HelperChat.getHourMinute(singleChatDataProps.created)}</b>
                                    </p>
                                </div>

                                <div className="modal-preview-action-button-wrapper">
                                    <a className="modal-preview-action-button" href={isImageExistInDB ? `data:image/png;base64, ${imageSrc}` : imageSrc} download={`taptalk_image_${new Date().valueOf()}`}>
                                        <FiDownload />
                                        <b>Download</b>
                                    </a>
                                </div>

                                <div className="close-modal-preview-wrapper">
                                    <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />
                                </div>
                            </div>

                            <div className="image-wrapper">
                                <figure 
                                    className="zoom" 
                                    style={{"backgroundImage": `url("${isImageExistInDB ? `data:image/png;base64, ${imageSrc}` : imageSrc}")`}} 
                                    onMouseMove={(e) => zoomImage(e, imageSrc)}
                                    onMouseLeave={(e) => zoomImageOut(e)}
                                >
                                    <img 
                                        src={isImageExistInDB ? `data:image/png;base64, ${imageSrc}` : imageSrc} 
                                        alt="" 
                                        className="image-preview-val"
                                    />
                                </figure>
                            </div>

                        </ModalBody>
                    </Modal>
                </div>
            );
    }

    let deleteMessageAction = (message) => {
        let messageIDs = [];
        messageIDs.push(message.messageID);
        tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
    }
      

    return (
        <div 
            className="chat-room-message-image-in-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
            onClick={() => { 
                if(onClickToStarMessage) {
                    onClickToStarMessage(singleChatDataProps.localID);
                }

                if(activeRoom.type === 2) {
                    setLastClickBubble(new Date().valueOf());
                }
            }}    
        >
            {
                (
                    activeRoom &&
                    !singleChatDataProps.isDeleted &&
                    multiForward[activeRoom.roomID] &&
                    Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
                ) &&
                <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
            }

            {(
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
                <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-in">
                    <input 
                        type="checkbox" 
                        id={`message-${singleChatDataProps.messageID}`} 
                        checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                    />
                    <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
                </div>
            }
            
            {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                <div 
                    className="group-sender-avatar-wrapper" 
                    style={{background: taptalk.getRandomColor(Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname)}} 
                    onClick={() => props.setUserClick({
                        userID: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.userID, 
                        username: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.username
                    })}
                >
                    {(singleChatDataProps.user.deleted && singleChatDataProps.user.deleted !== 0) ?
                        <img src={iconDelete} alt="" />
                        :
                        (Helper.isSavedMessageRoom(activeRoom.roomID) ?
                            <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.forwardFrom.fullname)}</b>
                            :
                            (singleChatDataProps.user.imageURL.thumbnail !== "" ? 
                                <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" />
                                :
                                <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                            )   
                        )
                    }
                </div>
            }

            <div className={`message-in-bubble ${props.status !== 'downloaded' ? 'not-sent-message-bubble' : ''}`}
            //     style={
            //         Helper.isReplyMessage(singleChatDataProps) ? 
            //            (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
            //                {paddingTop: "60px"} 
            //                :
            //                {paddingTop: "80px"}
            //            )
            //            : 
            //            {}
            //    }
            >
                {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                    <p className="group-sender-name-wrapper">
                        <b>
                            <b>{Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname}</b>
                        </b>
                    </p>
                }
                
                {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        
                        <p>
                            From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                        </p>
                    </div>
                }
                {/* {Helper.isReplyMessage(singleChatDataProps) &&
                    <div className="reply-message reply-file">
                        {singleChatDataProps.quote.fileType !== "" &&  
                            <div className="reply-file-thumbnail">
                                {singleChatDataProps.quote.fileType === "file" ? 
                                    <MdInsertDriveFile />
                                    :
                                    <img src={''} alt="" />
                                }
                            </div>
                        }

                        <div className="reply-text-wrapper">
                            <p className="reply-from">
                                <b> 
                                    {singleChatDataProps.replyTo.userID === taptalk.getTaptalkActiveUser().userID ?
                                        "You"
                                        :
                                        singleChatDataProps.replyTo.fullname
                                    }
                                </b>
                            </p>
                            <p className="reply-text">{singleChatDataProps.quote.content}</p>
                        </div>
                    </div>
                }   */}

                {/* ${singleChatDataProps.data.caption !== "" && Helper.isReplyMessage(singleChatDataProps) ? "with-reply-caption" : ""}
                ${singleChatDataProps.data.caption === "" && Helper.isReplyMessage(singleChatDataProps) ? "with-reply-no-caption" : ""}
                {singleChatDataProps.data.caption === "" ? "bubble-wrapper-without-caption" : ""} */}
                <div 
                    className={`
                        bubble-image-wrapper 
                        ${singleChatDataProps.data.caption !== "" ? "bubble-wrapper-with-caption" : ""}
                    `}
                >
                    {Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyInMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                        />
                    }

                    {imageFromUrl ?
                        <img src={imageSrc} className="image-val image-from-url" alt="" onClick={() => {toggleModalImage()}} />
                        :
                        <React.Fragment>    
                            {isImageExistInDB ? 
                                <img src={'data:image/png;base64, '+imageSrc} alt="" className="image-val main-image-message" onClick={() => {toggleModalImage()}} />
                                :
                                <img src={'data:image/png;base64, '+imageSrc} alt="" className="thumbnail-image-message" />
                            }
                            
                            {(!isImageExistInDB && !onDownloadImageProgress && !imageFromUrl) &&
                                <div className="icon-status-wrapper">
                                    <FiDownload onClick={() => downloadFile()} />
                                </div>
                            }
                                
                            {onDownloadImageProgress && 
                                <div className="icon-status-wrapper">
                                    <div className="onprocess-wrapper">
                                        <CircularProgressbar value={percentageDownload} />
                                        {/* <MdClose onClick={() => console.log('cancel')} /> */}
                                        <FiDownload />
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>

                {singleChatDataProps.data.caption !== '' && 
                    <p 
                        className="caption-text" 
                        dangerouslySetInnerHTML={{
                            __html: mentionList ?
                                HelperChat.generateMentionSpan(HelperChat.lineBreakChatRoom(singleChatDataProps.data.caption), mentionList)
                                :
                                HelperChat.lineBreakChatRoom(singleChatDataProps.data.caption)
                        }} 
                    />
                }

                {/* <div className="dark-cover">
                    {(!isImageExistInDB && !onDownloadImageProgress) &&
                        <div className="icon-status-wrapper">
                            <MdFileDownload onClick={() => downloadFile()} />
                        </div>
                    }
                        
                    {onDownloadImageProgress && 
                        <div className="icon-status-wrapper">
                            <div className="onprocess-wrapper">
                                <CircularProgressbar value={percentageDownload} />
                                <MdClose onClick={() => console.log('cancel')} />
                            </div>
                        </div>
                    }
                </div> */}

                <ChatRoomMessageInfoComp 
                    message={singleChatDataProps}
                    className={singleChatDataProps.data.caption === '' ? "message-info-dark message-info-in-media" : "message-info-in-media"}
                    lastClickBubble={lastClickBubble}
                />

                <ChatRoomMessageActionComp
                    isIn
                    buttonList={Helper.isSavedMessageRoom(activeRoom.roomID) ?
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "delete",
                                text: "Delete Message",
                                action: () => deleteMessageAction(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                        ]
                        :
                        [
                            {
                                val: "reply",
                                text: "Reply",
                                action: () => onReplyMessage(singleChatDataProps),
                            },
                            {
                                val: "forward",
                                text: "Forward",
                                action: () => onForwardMessage(singleChatDataProps),
                            },
                            {
                                val: "star",
                                text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                            },
                            {
                                val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                            },
                            {
                                val: "report",
                                text: "Report",
                                action: () => onReportMessage(singleChatDataProps), 
                            },
                        ]
                    }
                    singleChatDataProps={singleChatDataProps}
                />
            </div>

            {(isImageExistInDB || imageFromUrl) && generateModalImage()}
        </div>
  );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setActiveMessage,
  setUserClick,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInImage);
