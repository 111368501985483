import React, { useState } from 'react';
import './ChatRoomMessageIn.scss';
import HelperChat from '../../../../helper/HelperChat';
import Helper from '../../../../helper/Helper';
import { CHAT_TYPE } from '../../../../helper/HelperConst';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import iconDelete from "../../../../assets/img/delete-account-icon.svg";
import { taptalk, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import ChatRoomMessageActionComp from "../chatRoomMessageActionComp/ChatRoomMessageActionComp";
import ChatRoomMessageInfoComp from "../chatRoomMessageInfoComp/ChatRoomMessageInfoComp";
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { setActiveMessage } from '../../../../redux/actions/reduxActionActiveMessage';
import { setUserClick } from '../../../../redux/actions/reduxActionUserClick';
import { setMultiForward } from '../../../../redux/actions/reduxActionMultiForward';
import { connect } from 'react-redux';
import ChatRoomMessageLinkPreview from '../chatRoomMessageLinkPreview/ChatRoomMessageLinkPreview';

const LONG_PRESS = 700;

var ChatRoomMessageIn = (props) => {
  let {
      activeRoom,
      singleChatDataProps,
      activeUserID,
      onReplyMessage,
      onForwardMessage,
      onStarredMessage,
      onUnstarredMessage,
      onPinnedMessage,
      onUnpinnedMessage,
      onReportMessage,
      scrollToReply,
      mentionList,
    //   isBubbleOnViewPort,
      onClickToStarMessage,
      multiForward,
      noForward
  } = props;

  let [lastClickBubble, setLastClickBubble] = useState(false);

//   useEffect(() => {
//     let el = document.querySelectorAll('.chat-room-main-content')[0];
//     let element = document.querySelector(`#message-${singleChatDataProps.localID}`);
    
//     let domRect = element.getBoundingClientRect();

//     let logit = async () => {
//         if(!singleChatDataProps.isRead && 
//         (domRect.y > 0 || domRect.y < window.innerHeight) && 
//         singleChatDataProps.user.userID !== taptalk.getTaptalkActiveUser().userID
//         ) {
//             isBubbleOnViewPort(singleChatDataProps);
//         }
//     }

//     let watchScroll = () => {
//         el.addEventListener("scroll", logit);        
//     }

//     watchScroll();

//     return () => {
//         el.removeEventListener("scroll", logit);
//     };
//   });

  let delay;

  let onTouchStartEvent = () => {
    if(!singleChatDataProps.isDeleted) {
        delay = setTimeout(() => {
            props.setActiveMessage(singleChatDataProps)
        }, LONG_PRESS)
    }
  }

  let onTouchEndEvent = () => {
    clearTimeout(delay);
  }

  let deleteMessageAction = (message) => {
    let messageIDs = [];
    messageIDs.push(message.messageID);
    tapCoreMessageManager.markMessageAsDeleted(message.room.roomID, messageIDs, true);
  }
  
  return (
    <div 
        className="chat-room-message-in-wrapper" id={`message-${singleChatDataProps.localID}`} onTouchStart={onTouchStartEvent} onTouchEnd={onTouchEndEvent}
        onClick={() => { 
            if(onClickToStarMessage) {
                onClickToStarMessage(singleChatDataProps.localID);
            }

            if(activeRoom.type === 2) {
                setLastClickBubble(new Date().valueOf());
            }
        }}
    >
        {
            (
                activeRoom &&
                !singleChatDataProps.isDeleted &&
                multiForward[activeRoom.roomID] &&
                Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
            ) &&
            <div className="chat-room-forward-message-area-selectarea-wrapper" onClick={() => onForwardMessage(singleChatDataProps)} />
        }

        {(
            activeRoom &&
            !singleChatDataProps.isDeleted &&
            multiForward[activeRoom.roomID] &&
            Object.keys(multiForward[activeRoom.roomID]).length > 0 && !noForward
        ) &&
            <div className="custom-checkbox custom-checkbox-forward custom-checkbox-forward-in">
                <input 
                    type="checkbox" 
                    id={`message-${singleChatDataProps.messageID}`} 
                    checked={(multiForward[activeRoom.roomID] && multiForward[activeRoom.roomID]["message_"+singleChatDataProps.messageID]) ? true : false} 
                />
                <label htmlFor={`message-${singleChatDataProps.messageID}`} onClick={() => onForwardMessage(singleChatDataProps)} />
            </div>
        }
        
        {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
            <div 
                className="group-sender-avatar-wrapper" 
                style={{background: taptalk.getRandomColor(Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname)}} 
                onClick={() => props.setUserClick({
                    userID: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.userID, 
                    username: Helper.isSavedMessageRoom(activeRoom.roomID) && Helper.isForwardMessageIgnoreRoom(singleChatDataProps) ? singleChatDataProps.forwardFrom.userID : singleChatDataProps.user.username
                })}
            >
                {(singleChatDataProps.user.deleted && singleChatDataProps.user.deleted !== 0) ?
                    <img src={iconDelete} alt="" />
                    :
                    (Helper.isSavedMessageRoom(activeRoom.roomID) ?
                        <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.forwardFrom.fullname)}</b>
                        :
                        (singleChatDataProps.user.imageURL.thumbnail !== "" ? 
                            <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" />
                            :
                            <b>{HelperChat.renderUserAvatarWord(singleChatDataProps.user.fullname)}</b>
                        )   
                    )
                }
             </div>
        }
        
        {singleChatDataProps.isDeleted ?
            <div className={`message-in-bubble deleted-bubble ${singleChatDataProps.isDeleted ? 'deleted-group-in' : ''}`}>
                <React.Fragment>
                    {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                        <p className="group-sender-name-wrapper">
                            <b>{Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname}</b>
                        </p>
                    }

                    <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                    This message was deleted.
                </React.Fragment>
            </div> 
            :
            
            <React.Fragment>
                <div className="message-in-bubble"
                    //  style={
                    //      Helper.isReplyMessage(singleChatDataProps) ? 
                    //         (singleChatDataProps.replyTo.messageType === CHAT_TYPE.TAPChatMessageTypeText ?
                    //             {paddingTop: "64px"} 
                    //             :
                    //             {paddingTop: "84px"}
                    //         )
                    //         : 
                    //         {}
                    // }
                >
                    {(singleChatDataProps.room.type === 2 || Helper.isSavedMessageRoom(activeRoom.roomID)) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                <b>{Helper.isSavedMessageRoom(activeRoom.roomID) ? singleChatDataProps.forwardFrom.fullname : singleChatDataProps.user.fullname}</b>
                            </b>
                        </p>
                    }
                    
                    {Helper.isForwardMessage(singleChatDataProps, activeRoom) &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            
                            <p>
                                From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                            </p>
                        </div>
                    }


                    {Helper.isReplyMessage(singleChatDataProps) &&
                        <ChatRoomReplyInMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                            scrollToReply={scrollToReply}
                        />
                    }
                    
                    <span 
                        className="message-body" 
                        dangerouslySetInnerHTML={{
                            __html: mentionList ?
                                HelperChat.lineBreakChatRoom(HelperChat.generateMentionSpan(HelperChat.replaceTagHTML(singleChatDataProps.body), mentionList))
                                :
                                HelperChat.lineBreakChatRoom(HelperChat.replaceTagHTML(singleChatDataProps.body))
                        }} 
                    />

                    {singleChatDataProps.type === CHAT_TYPE.TAPChatMessageTypeLink &&
                        <ChatRoomMessageLinkPreview 
                            isOut={false}
                            message={singleChatDataProps}
                        />
                    }

                    <ChatRoomMessageInfoComp 
                        message={singleChatDataProps}
                        lastClickBubble={lastClickBubble}
                    />
                    
                    <ChatRoomMessageActionComp 
                        isIn
                        buttonList={Helper.isSavedMessageRoom(activeRoom.roomID) ?
                            [
                                {
                                    val: "reply",
                                    text: "Reply",
                                    action: () => onReplyMessage(singleChatDataProps),
                                },
                                {
                                    val: "forward",
                                    text: "Forward",
                                    action: () => onForwardMessage(singleChatDataProps),
                                },
                                {
                                    val: "copy",
                                    text: "Copy",
                                    action: () => HelperChat.copyToClipBoard(singleChatDataProps.body),
                                },
                                {
                                    val: "delete",
                                    text: "Delete Message",
                                    action: () => deleteMessageAction(singleChatDataProps),
                                },
                                {
                                    val: "star",
                                    text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                    action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                                },
                                {
                                    val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                    text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                    action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                                },
                            ]
                            :
                            [
                                {
                                    val: "reply",
                                    text: "Reply",
                                    action: () => onReplyMessage(singleChatDataProps),
                                },
                                {
                                    val: "forward",
                                    text: "Forward",
                                    action: () => onForwardMessage(singleChatDataProps),
                                },
                                {
                                    val: "copy",
                                    text: "Copy",
                                    action: () => HelperChat.copyToClipBoard(singleChatDataProps.body),
                                },
                                {
                                    val: "star",
                                    text: !props.allStarredMessages[singleChatDataProps.messageID] ? "Star" : "Unstar",
                                    action: () => !props.allStarredMessages[singleChatDataProps.messageID] ? onStarredMessage(singleChatDataProps) : onUnstarredMessage(singleChatDataProps), 
                                },
                                {
                                    val: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "pin" : "unpin",
                                    text: !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? "Pin" : "Unpin",
                                    action: () => !props.allPinnedMessages.messages[singleChatDataProps.messageID] ? onPinnedMessage(singleChatDataProps) : onUnpinnedMessage(singleChatDataProps), 
                                },
                                {
                                    val: "report",
                                    text: "Report",
                                    action: () => onReportMessage(singleChatDataProps), 
                                },
                            ]
                        }
                        singleChatDataProps={singleChatDataProps}
                    />
                </div>
            </React.Fragment>
        }
    </div>
  );
}


const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    replyMessage: state.replyMessage,
    allStarredMessages: state.allStarredMessages,
    allPinnedMessages: state.allPinnedMessages,
    multiForward: state.multiForward
});

const mapDispatchToProps = {
  setReplyMessage,
  setActiveMessage,
  setUserClick,
  setMultiForward
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageIn);
